import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { CilCalculation } from '../../models';

@Component({
  selector: 'app-calculate-result',
  templateUrl: './calculate-result.component.html',
  styleUrls: ['./calculate-result.component.css'],
})
export class CalculateResultComponent implements OnInit {
  @Input() calculation: CilCalculation;
  @HostBinding('style.width') width = '40%';

  constructor() {}

  ngOnInit(): void {}
}
