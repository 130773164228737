import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, of } from 'rxjs';
import { IFormDropdownOption } from '../../../../form-controls/form-controls.const';
import { MasterDataService } from '../../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class CareLabelService {
  private filterCareLabel = new BehaviorSubject<IFormDropdownOption[]>([]);
  public labelOptions = new BehaviorSubject<IFormDropdownOption[]>([]);
  public codeOptions = new BehaviorSubject<IFormDropdownOption[]>([]);

  get careLabelValues() {
    return this.filterCareLabel.value;
  }
  public values: IFormDropdownOption[];

  public careLabels: IFormDropdownOption[];
  constructor(private masterDataService: MasterDataService) {
    this.getCareLabelValues();
  }

  private getCareLabelValues() {
    this.masterDataService
      .getMasterData('care-label-codes', true)
      .subscribe((data) => {
        this.careLabels = data.map((careLabel) => careLabel.value);
        this.filterCareLabel.next(data);
      });
  }

  public filterCareLabelValue(registrationDate) {
    const filteredCareLabels = this.careLabels.filter(
      (value) =>
        moment(registrationDate).isSameOrAfter(moment(value.startDate)) &&
        moment(registrationDate).isSameOrBefore(
          moment(value.endDate ? value.endDate : '9999-12-31'),
        ),
    );
    this.filterCareLabel.next(filteredCareLabels);
    const LABEL_OPTIONS = filteredCareLabels?.map((value) => {
      return {
        value: value.label,
        label: value.label,
      };
    });
    const CODE_OPTIONS = filteredCareLabels?.map((option) => {
      return {
        value: option.value,
        label: option.value,
      };
    });
    this.labelOptions.next(LABEL_OPTIONS);
    this.codeOptions.next(CODE_OPTIONS);
  }
}
