import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-avatar-edit',
  templateUrl: './avatar-edit.component.html',
  styleUrls: ['./avatar-edit.component.scss'],
})
export class AvatarEditComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';

  avatarForm = new FormGroup({
    useCustomImage: new FormControl(),
  });

  constructor(
    public dialogRef: MatDialogRef<AvatarEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.avatarForm.patchValue({ useCustomImage: this.data.useCustomAvatar });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.convertBlobToBase64(event.blob).then((base64) => {
      this.croppedImage = base64;
    });
  }

  convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror =  () => {
        reject(new Error('Error reading the blob'));
      }
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(blob);
    });
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  public onDismiss(): void {
    this.dialogRef.close(null);
  }

  public onConfirm(): void {
    let avatar: string = '';
    let customAvatar: string = '';
    if (this.avatarForm.value.useCustomImage) {
      avatar = this.croppedImage;
      customAvatar = this.croppedImage;
    } else {
      avatar = this.data.providerAvatar;
      customAvatar = this.data.customAvatar;
    }

    this.dialogRef.close({
      avatar: avatar,
      customAvatar: customAvatar,
      useCustomAvatar: this.avatarForm.value.useCustomImage,
    });
  }
}
