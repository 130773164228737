<div class="c4p-question-set-input-small" style="word-wrap: break-word">
  <span *ngIf="required" class="c4p-question-label">{{
    '* ' + orderNum + '. ' + placeholder
  }}</span>
  <span *ngIf="!required" class="c4p-question-label">{{
    orderNum + '. ' + placeholder
  }}</span>
  <span
    *ngIf="hint && !isClientPortal"
    tippy
    [tippyOptions]="{
      content: hint,
      arrow: true,
      allowHTML: true,
      placement: 'right-end',
      theme: 'light',
      maxWidth: '350px',
      interactive: true
    }"
  >
    <mat-icon class="c4p-hint-icons">info</mat-icon>
  </span>
</div>
<div class="c4p-question-set-input" style="margin-left: -10px">
  <div style="flex-direction: column">
    <mat-radio-group
      class="c4p-radio-group"
      [style.flex-direction]="direction"
      [formControl]="valueControl"
      [required]="required"
      (blur)="onTouchedCallback()"
      #radioBtn
      (keydown.Tab)="onTab(radioBtn)"
    >
      <mat-radio-button
        class="c4p-radio-button-question-set"
        *ngFor="let option of options"
        [value]="option.value"
      >
        {{ option.label }}
      </mat-radio-button>
    </mat-radio-group>
    <span style="font-size: 85%" *ngIf="valueControl.touched">
      <mat-error *ngFor="let error of ngControl.errors | keyvalue">
        <span *ngIf="errorLabels && errorLabels[error.key]; else defaultError">
          {{ errorLabels[error.key] | translate }}
        </span>
        <ng-container #defaultError *ngIf="DEFAULT_ERRORS.has(error.key)">
          <span>{{ DEFAULT_ERRORS.get(error.key) | translate }}&nbsp;</span>
        </ng-container>
      </mat-error>
    </span>
  </div>
</div>
