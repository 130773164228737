import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class ExtraWorkService {
  public values: IFormDropdownOption[] = [];

  constructor(private masterDataService: MasterDataService) {
    this.getExtraWorkValues();
  }

  public getExtraWorkValues() {
    this.masterDataService.getMasterData('extra-work').subscribe((data) => {
      this.values = data.map((data) => {
        return { label: data.label, value: !!data.value };
      });
    });
  }
}
