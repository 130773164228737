import { AuditUpdateInfoModel } from '../models';

export class UpdateTimeByUtils {
  public static getLatestInfo(
    currentUpdateTimeValue: Date,
    currentUpdateByValue: string,
    data: AuditUpdateInfoModel,
  ): AuditUpdateInfoModel {
    const existingData = {
      updatedAt: currentUpdateTimeValue,
      updatedBy: currentUpdateByValue,
    };
    const combinedInfoList = [data, existingData];
    const newData = combinedInfoList.sort(
      (a, b) => b?.updatedAt.getTime() - a?.updatedAt.getTime(),
    );
    return newData[0];
  }

  public static getLatestObj(
    updateAt: AuditUpdateInfoModel[],
  ): AuditUpdateInfoModel {
    const sortedUpdates = updateAt.sort(
      (a, b) => b?.updatedAt.getTime() - a?.updatedAt.getTime(),
    )?.[0];
    return sortedUpdates;
  }
}
