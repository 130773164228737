import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../../services';

export enum HourStatus {
  DRAFT = 'draft',
  APPROVED = 'approved',
  ZPM_DRAFT = 'zpm_draft',
  REJECTED = 'rejected',
  DECLARED = 'declared',
}

@Pipe({
  name: 'hourStatus',
})
export class HourStatusPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}
  transform(value: string): string {
    switch (value) {
      case HourStatus.DRAFT:
        return this.languageService.translation('general.status.Draft');
      case HourStatus.APPROVED:
        return this.languageService.translation('general.status.Approved');
      case HourStatus.ZPM_DRAFT:
        return this.languageService.translation('general.status.Zpm_draft');
      case HourStatus.REJECTED:
        return this.languageService.translation('general.status.Rejected');
      case HourStatus.DECLARED:
        return this.languageService.translation('general.status.Declared');
      default:
        return value;
    }
  }
}
