import { AbstractControl } from '@angular/forms';
import { DateUtils } from '../utilities';

export function tillCurrentDateValidator(control: AbstractControl) {
  let currentDateTime = DateUtils.getCurrentTenantDayDate();
  currentDateTime.setHours(0, 0, 0, 0);

  let controlValue = new Date(control.value);
  controlValue.setHours(0, 0, 0, 0);

  if (currentDateTime < control.value) {
    return { response: true };
  }
  return null;
}
