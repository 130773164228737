import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';
import { MasterDataService } from '../../master-data/master-data.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class JurisdictionTypeService {
  public values: IFormDropdownOption[] = [];

  constructor(
    private masterDataService: MasterDataService,
    private translate: TranslateService,
  ) {
    this.getJurisdictionTypeValues();
  }

  public getJurisdictionTypeValues() {
    this.masterDataService
      .getMasterData('jurisdiction-types')
      .subscribe((data) => {
        this.values = data;
        for (let item of this.values) {
          item.translation = this.translate.instant(item.label);
        }
        this.changeLanguage();
        this.values.sort((a, b) =>
          a.translation !== b.translation
            ? a.translation < b.translation
              ? -1
              : 1
            : 0,
        );
      });
  }

  public changeLanguage() {
    for (let item of this.values) {
      item.translation = this.translate.instant(item.label);
    }
    this.values.sort((a, b) =>
      a.translation !== b.translation
        ? a.translation < b.translation
          ? -1
          : 1
        : 0,
    );
  }
}
