<div class="c4p-align-right">
  <div *ngFor="let menu of additionalMenus">
    <app-page-menu-additional
      [items]="menu.menuItems"
      [name]="menu.name"
    ></app-page-menu-additional>
  </div>

  <div *ngFor="let item of inlineItems">
    <button
      [disabled]="item.disabled"
      mat-icon-button
      (click)="item.method(item.tooltip)"
      matTooltip="{{ item.tooltip | translate }}"
    >
      <mat-icon matPrefix class="c4p-action-icons">{{ item.icon }}</mat-icon>
    </button>
  </div>

  <button
    mat-icon-button
    [matMenuTriggerFor]="menu"
    *ngIf="menuItems.length > 0"
  >
    <mat-icon class="c4p-action-icons">more_horiz</mat-icon>
  </button>

  <ng-container *ngIf="items && items.length > 0">
    <mat-menu #menu="matMenu" class="c4p-menu">
      <div *ngFor="let item of menuItems">
        <div
          mat-menu-item
          class="c4p-menu-item"
          (click)="item.method(item.tooltip)"
        >
          <mat-icon class="c4p-action-menu-icon">{{ item.icon }}</mat-icon>
          <span class="c4p-menu-title">{{ item.tooltip | translate }}</span>
        </div>
      </div>
    </mat-menu>
  </ng-container>
</div>
