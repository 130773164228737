<div
  style="word-wrap: break-word;"
  class="c4p-form-input{{ width }}"
  [ngClass]="{
    'full-width-form-group': fullWidth,
    'readonly-wrapper': readonly
  }"
>
  <span
    *ngIf="multiple"
    tippy
    [tippyOptions]="{
      allowHTML: true,
      placement: 'bottom',
      theme: 'light',
      content: tippyOptions
    }"
  >
    <mat-form-field>
      <mat-label>{{placeholder | translate}}</mat-label>

      <mat-select
        [formControl]="valueControl"
        [errorStateMatcher]="errorStateMatcher"
        [required]="required"
        (blur)="onTouchedCallback()"
        [multiple]="multiple"
        [compareWith]="compareObjects"
        [panelWidth]="maxContentSelectionPanel ? null : 'auto'"
      >
        <mat-option *ngIf="search"
          ><ngx-mat-select-search
            [showToggleAllCheckbox]="showToggleAllCheckbox"
            (toggleAll)="toggleAll($event)"
            [formControl]="searchControl"
            [clearSearchInput]="false"
            [searching]="isLoading"
            placeholderLabel="{{ 'general.actions.Search' | translate }}..."
            noEntriesFoundLabel="{{ 'general.labels.Empty' | translate }}"
          ></ngx-mat-select-search
        ></mat-option>
        <mat-option *ngIf="blank && !multiple" [value]="null"></mat-option>
        <ng-container *ngIf="!objectAsValue">
          <mat-option
            *ngFor="let option of options"
            [value]="option.value"
            [disabled]="option.disabled"
          >
            <ng-container *ngIf="showValue"
              >{{ option.value }}{{ delimiter }}</ng-container
            >
            {{ option.label | translate }}
          </mat-option>
          <mat-option
            *ngFor="let option of options$ | async"
            [value]="option.value"
            [disabled]="option.disabled"
          >
            <ng-container *ngIf="showValue"
              >{{ option.value }}{{ delimiter }}</ng-container
            >
            {{ option.label | translate }}
          </mat-option>
        </ng-container>
        <ng-container *ngIf="objectAsValue">
          <mat-option
            *ngFor="let option of options"
            [value]="option"
            [disabled]="option.disabled"
          >
            {{
              labelProperty
                ? option[labelProperty]
                : optionDisplayFunction && optionDisplayFunction(option)
            }}
          </mat-option>
          <mat-option
            *ngFor="let option of options$ | async"
            [value]="option"
            [disabled]="option.disabled"
          >
            {{
              labelProperty
                ? option[labelProperty]
                : optionDisplayFunction && optionDisplayFunction(option)
            }}
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngFor="let error of ngControl?.errors | keyvalue">
        <span *ngIf="errorLabels && errorLabels[error.key]; else defaultError">
          {{ errorLabels[error.key] | translate }}
        </span>
        <ng-container #defaultError *ngIf="DEFAULT_ERRORS.has(error.key)">
          <span>{{ DEFAULT_ERRORS.get(error.key) | translate }}&nbsp;</span>
        </ng-container>
      </mat-error>
      <span *ngIf="suffix" matSuffix>{{ suffix }}</span>
    </mat-form-field>
  </span>
  <mat-form-field *ngIf="!multiple">
    <mat-label>{{placeholder | translate}}</mat-label>

    <mat-select
      [formControl]="valueControl"
      [errorStateMatcher]="errorStateMatcher"
      [required]="required"
      (blur)="onTouchedCallback()"
      [multiple]="multiple"
      [compareWith]="compareObjects"
      [panelWidth]="maxContentSelectionPanel ? null : 'auto'"
    >
      <mat-option *ngIf="search"
        >
        <ngx-mat-select-search
          [showToggleAllCheckbox]="showToggleAllCheckbox"
          (toggleAll)="toggleAll($event)"
          [formControl]="searchControl"
          [clearSearchInput]="false"
          [searching]="isLoading"
          placeholderLabel="{{ 'general.actions.Search' | translate }}..."
          noEntriesFoundLabel="{{ 'general.labels.Empty' | translate }}"
        ></ngx-mat-select-search
      ></mat-option>
      <mat-option *ngIf="blank && !multiple" [value]="null"></mat-option>
      <ng-container *ngIf="!objectAsValue">

        <mat-option
          *ngFor="let option of options"
          [value]="option.value"
          [disabled]="option.disabled"
        >
          <ng-container *ngIf="showValue"
            >{{ option.value }}{{ delimiter }}</ng-container
          >
          {{ option.label | translate }}
        </mat-option>
        <mat-option
          *ngFor="let option of options$ | async"
          [value]="option.value"
          [disabled]="option.disabled"
        >
          <ng-container *ngIf="showValue"
            >{{ option.value }}{{ delimiter }}</ng-container
          >
          {{ option.label | translate }}
        </mat-option>
      </ng-container>
      <ng-container *ngIf="objectAsValue">
        <mat-option
          *ngFor="let option of options"
          [value]="option"
          [disabled]="option.disabled"
        >
          {{
            labelProperty
              ? option[labelProperty]
              : optionDisplayFunction && optionDisplayFunction(option)
          }}
        </mat-option>
        <mat-option
          *ngFor="let option of options$ | async"
          [value]="option"
          [disabled]="option.disabled"
        >
          {{
            labelProperty
              ? option[labelProperty]
              : optionDisplayFunction && optionDisplayFunction(option)
          }}
        </mat-option>
      </ng-container>
    </mat-select>
    <mat-error *ngFor="let error of ngControl?.errors | keyvalue">
      <span *ngIf="errorLabels && errorLabels[error.key]; else defaultError">
        {{ errorLabels[error.key] | translate }}
      </span>
      <ng-container #defaultError *ngIf="DEFAULT_ERRORS.has(error.key)">
        <span>{{ DEFAULT_ERRORS.get(error.key) | translate }}&nbsp;</span>
      </ng-container>
    </mat-error>
    <span *ngIf="suffix" matSuffix>{{ suffix }}</span>
  </mat-form-field>
</div>
