import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SideSheetConfig, SideSheetContent } from 'c4p-portal-util';

@Component({
  selector: 'app-sidesheet-loading',
  templateUrl: './sidesheet-loading.component.html',
  styleUrl: './sidesheet-loading.component.css'
})
export class SidesheetLoadingComponent implements OnInit, OnDestroy {
  @Input() sideSheetConfig: SideSheetConfig;
  @Input() sideSheetContent: SideSheetContent;

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

}
