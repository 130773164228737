import { MatDateFormats } from '@angular/material/core';
import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import * as moment_ from 'moment';
const moment = moment_;

export type BackendServiceType =
  | 'clients'
  | 'tenants'
  | 'applications'
  | 'client-network'
  | 'employees'
  | 'teams'
  | 'contact-persons';

// forms
export const DEFAULT_CONTROL_DEBOUNCE_TIME_AMOUNT = 100;
export const DEFAULT_VALUE_CHANGES_DEBOUNCE_TIME_AMOUNT = 150;
export const DEFAULT_AUTOCOMPLETE_DEBOUNCE_TIME_AMOUNT = 500;
export const DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT = 1000;
export const DEFAULT_LONG_REQUEST_DEBOUNCE_TIME_AMOUNT = 2000;

// abstract
export const DEFAULT_PLACEHOLDER = '';
export const DEFAULT_ERRORS_MAP = new Map([
  ['required', 'general.form.Mandatory'],
  ['email', 'general.form.InvalidEmail'],
  ['min', 'general.form.InvalidMin'],
  ['max', 'general.form.InvalidMax'],
  ['minlength', 'general.form.InvalidMinLength'],
  ['maxlength', 'general.form.InvalidMaxLength'],
  ['duplicate', 'general.form.InvalidDuplicate'],
  ['dividedby5', 'general.form.dividedby5'],
  ['pattern', 'general.form.InvalidPattern'],
  ['matDatepickerParse', 'general.form.InvalidDate'],
  ['matDatepickerMin', 'general.form.InvalidDateMin'],
  ['matDatepickerMax', 'general.form.InvalidDateMax'],
  ['matDatetimePickerParse', 'general.form.InvalidDateTime'],
  ['matDatetimePickerMin', 'general.form.InvalidDateTimeMin'],
  ['matDatetimePickerMax', 'general.form.InvalidDateTimeMin'],
  ['invalidTime', 'general.form.InvalidTime'],
  ['invalidTimeMin', 'general.form.InvalidTimeMin'],
  ['invalidTimeMax', 'general.form.InvalidTimeMax'],
  ['modulo', 'general.form.InvalidModulo'],
  ['invalidMinHour', 'general.form.InvalidMinHour'],
  ['invalidMaxHour', 'general.form.InvalidMaxHour'],
  ['urlValidator','general.form.SystemUrlMustStartWithHttp'],
  ['BsnInUse', 'general.form.BsnInUse'],
  ['organizationCode','general.form.InvalidOrganizationCode']
]);
export const DEFAULT_DATEPICKER_ERRORS_ARRAY = [
  'matDatepickerParse',
  'matDatepickerMin',
  'matDatepickerMax',
  'matDatetimePickerParse',
  'matDatetimePickerMin',
  'matDatetimePickerMax',
];

// text
export const DEFAULT_INPUT_TYPE = 'text';

// textarea
export const DEFAULT_TEXT_AREA_TYPE = 'text';
export const DEFAULT_TEXT_AREA_ROWS = 1;
export const DEFAULT_TEXT_AREA_COLUMNS = 10;
export const DEFAULT_TEXT_AREA_DISABLE_RESIZE = false;
export const DEFAULT_TEXT_AREA_LARGE = false;
export const DEFAULT_AUTO_RESIZE = true;

// checkbox
export const DEFAULT_CHECKBOX_LABEL_POSITION = 'after';

// dropdown
export const DEFAULT_DROPDOWN_SHOW_BLANK = true;
export interface IFormDropdownOption {
  label: string;
  value: any;

  disabled?: boolean;
  type?: string;
  percentage?: number;
  from?: number;
  to?: number;
  startDate?: Date;
  endDate?: Date;
  translation?: string;
  checked?: boolean;

  permission?: string;
  permissionOperator?: 'or' | 'and';
}

interface itemType {
  type: string;
  iconName: string;
}

export interface IFormListboxOption {
  id: string;
  item: string;
  date?: Date;
  title?: string;
  bold?: boolean;
  type?: itemType;
}

// radio
export const DEFAULT_RADIO_DIRECTION = 'row';

export const COLUMN_RADIO_DIRECTION = 'column';


// date
export const UTC_TIMEZONE = 'Africa/Accra';
export const USE_UTC = false;
export const USE_UTC_DATE = true;
export const DEFAULT_MOMENT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_MIN_DATE: moment.Moment = moment(
  '01/01/1900',
  'DD/MM/YYYY',
);
export const DEFAULT_MAX_DATE: moment.Moment = moment(
  '31/12/2999',
  'DD/MM/YYYY',
);
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const DATE_REGEX = (event) => {
  var v = event.currentTarget.value;
  var keyCode = event.keyCode || event.charCode;

  // skip non numeric
  if (
    event.key?.length === 1 &&
    /\D/.test(event.key)
    //&& event.key !== "/"
  ) {
    event.preventDefault();
    return false;
  }

  if (!(keyCode == 8 || keyCode == 46) && event.key !== '/') {
    if (v.match(/^\d{2}$/) !== null) {
      event.currentTarget.value = v + '/';
    } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
      event.currentTarget.value = v + '/';
    }
  } else {
    if (
      v.match(/^\d{2}\/\d{1}$/) !== null ||
      v.match(/^\d{2}\/\d{2}\/\d{1}$/) !== null
    ) {
      event.currentTarget.value = v.slice(0, -1);
    }
  }
};

//time
export const DEFAULT_TIME_SHOW_SPINNERS = false;
export const DEFAULT_TIME_SHOW_SECONDS = false;
export const DEFAULT_TIME_HOUR_STEP = 1;
export const DEFAULT_TIME_MINUTE_STEP = 1;
function replaceBadInputs(val) {
  // Replace impossible inputs as they appear
  // val = val.replace(/[^\dh:]/, '');
  // val = val.replace(/^[^0-2]/, '');
  // val = val.replace(/^([2-9])[4-9]/, '$1');
  // val = val.replace(/^\d[:h]/, '');
  // val = val.replace(/^([01][0-9])[^:h]/, '$1');
  // val = val.replace(/^(2[0-3])[^:h]/, '$1');
  // val = val.replace(/^(\d{2}[:h])[^0-5]/, '$1');
  // val = val.replace(/^(\d{2}h)./, '$1');
  // val = val.replace(/^(\d{2}:[0-5])[^0-9]/, '$1');
  // val = val.replace(/^(\d{2}:\d[0-9])./, '$1');

  val = val.replace(/^[^0-2]/, '');
  val = val.replace(/^([2-9])[4-9]/, '$1');
  val = val.replace(/^(\d{2}:[0-5])[^0-9]/, '$1');
  val = val.replace(/^(\d{2}:\d[0-9])./, '$1');
  return val;
}
export const TIME_REGEX = (event) => {
  var v = event.currentTarget.value;
  var keyCode = event.keyCode || event.charCode;

  // skip non numeric
  if (
    event.key.length === 1 &&
    /\D/.test(event.key)
    // &&
    // event.key !== "/" &&
    // event.key !== ":" &&
    // event.key !== " "
  ) {
    event.preventDefault();
    return false;
  }

  let inputModified = false;
  if (!(keyCode == 8 || keyCode == 46)) {
    if (v.match(/^\d{2}$/) !== null) {
      event.currentTarget.value = v + ':';
      inputModified = true;
    }
  } else {
    if (v.match(/^\d{2}:$/) !== null) {
      event.currentTarget.value = v.slice(0, -1);
      inputModified = true;
    }
  }

  if (!inputModified) {
    var val = v;
    var lastLength;
    do {
      // Loop over the input to apply rules repeately to pasted inputs
      lastLength = val.length;
      val = replaceBadInputs(val);
    } while (val.length > 0 && lastLength !== val.length);
    event.currentTarget.value = val;
  }
};

// datetime
export const DEFAULT_DATE_TIME_SHOW_SPINNERS = true;
export const DEFAULT_DATE_TIME_SHOW_SECONDS = false;
export const DEFAULT_DATE_TIME_HOUR_STEP = 1;
export const DEFAULT_DATE_TIME_MINUTE_STEP = 1;
export const APP_DATE_TIME_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY HH:mm',
  },
  display: {
    dateInput: 'DD/MM/YYYY HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const DATE_TIME_REGEX = (event) => {
  var v = event.currentTarget.value;
  var keyCode = event.keyCode || event.charCode;
  // skip non numeric
  if (
    event.key.length === 1 &&
    /\D/.test(event.key)
    // &&
    // event.key !== "/" &&
    // event.key !== ":" &&
    // event.key !== " "
  ) {
    event.preventDefault();
    return false;
  }

  if (
    !(keyCode == 8 || keyCode == 46)
    // &&
    // event.key !== "/" &&
    // event.key !== ":" &&
    // event.key !== " "
  ) {
    if (v.match(/^\d{2}$/) !== null) {
      event.currentTarget.value = v + '/';
    } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
      event.currentTarget.value = v + '/';
    } else if (v.match(/^\d{2}\/\d{2}\/\d{4}$/) !== null) {
      event.currentTarget.value = v + ' ';
    } else if (v.match(/^\d{2}\/\d{2}\/\d{4}\s\d{2}$/)) {
      event.currentTarget.value = v + ':';
    }
  } else {
    if (
      v.match(/^\d{2}\/\d{1}$/) !== null ||
      v.match(/^\d{2}\/\d{2}\/\d{1}$/) !== null ||
      v.match(/^\d{2}\/\d{2}\/\d{4}\s\d{1}$/) !== null ||
      v.match(/^\d{2}\/\d{2}\/\d{4}\s\d{2}:\d{1}$/) !== null
    ) {
      event.currentTarget.value = v.slice(0, -1);
    }
  }
};

// daterange
export const DEFAULT_START_DATE_PLACEHOLDER = 'employee.labels.StartDate';
export const DEFAULT_END_DATE_PLACEHOLDER = 'employee.labels.EndDate';

// froala editor settings
export const DEFAULT_QUILL_SETTINGS = {};

//patterns
export const PATTERN = {
  string: "^['A-Za-zÀ-ž-\\s]*$",
  stringDot: "^['A-Za-zÀ-ž-.\\s]*$",
  text: "^['A-Za-zÀ-ž-.,!?()0-9\\s]*$",
  numbersOnly: '^[0-9]*$',
  distance: '^[0-9]{0,3}$',
  email: '^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
  initials: "^['A-Za-zÀ-ž.\\s]*$",
  stringAndNumbers: "^['A-Za-zÀ-ž0-9-\\s]*$",
  phone: '^[0-9\\s]*$',
  phoneWithCharacters: '^[+]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s.0-9]*$',
  employeeBIG: '^[0-9]{1,11}$',
  postalCode: '^[0-9]{4}[A-Z]{1,2}$',
  decimalAndNumber: '^[+-]?([0-9]+.?[0-9]*|.[0-9]+)$',
  digits: '^-?[0-9]\\d*(\\.\\d{1,2})?$',
  wholeNumber: '^(0|[1-9][0-9]*)$',
};

export const AGB_LEADING_ZEROS = '00000000';
