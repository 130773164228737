import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IFormDropdownOption } from '../../../form-controls/form-controls.const';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable()
export class CbsCodeService {
  public lookupValues: string[] = [];

  public get values() {
    return this.values$.value;
  }

  public values$ = new BehaviorSubject<IFormDropdownOption[]>(null);

  constructor(private masterDataService: MasterDataService) {
    this.getCbsCodes();
  }

  public getCbsCodes() {
    this.masterDataService.getMasterData('cbs-codes').subscribe((data) => {
      data.map((cbsCode) => {
        cbsCode.label = cbsCode.label + ' - ' + cbsCode.value;
      });
      this.values$.next(data);
      for (let item of this.values) {
        this.lookupValues[item.value] = item.label;
      }
    });
  }
}
