<div>
  <h1>Dynamic Component</h1>
  <h2>I'm dynamically attached</h2>

  <div>
    <mat-form-field>
      <mat-label>Favorite food</mat-label>
      <input matInput placeholder="Ex. Pizza" value="Sushi">
    </mat-form-field>
  </div>
  
  <fieldset class="sidesheet-example-actions-container">
    <legend>GENERAL</legend>

    <button class="c4p-button sidesheet-example-actions-button" (click)="loadComponent()">
      Load Example Component 1
    </button>

    <button class="c4p-button sidesheet-example-actions-button" (click)="toggleBackdrop()">
      Toggle backdrop (Current is {{sideSheetConfig.hasBackdrop}})
    </button>

    <button class="c4p-button sidesheet-example-actions-button" (click)="toggleBackdropType()">
      Toggle backdrop transparancy or opaque (Current is {{sideSheetConfig.backdropType}})
    </button>

    <button class="c4p-button sidesheet-example-actions-button" (click)="toggleDisableCloseOnBackdropClick()">
      Toggle Disable Close on backdrop click (Current is {{sideSheetConfig.disableCloseOnBackdropClick}})
    </button>

    <button class="c4p-button sidesheet-example-actions-button" (click)="toggleFirstButtonPosition()">
      Change First Button Position (Current is {{this.sideSheetContent?.buttons?.[0].position}})
    </button>

  </fieldset>
  
  <fieldset class="sidesheet-example-actions-container">
    <legend>TITLE BAR</legend>

    <button class="c4p-button sidesheet-example-actions-button" (click)="toggleTitleBar()">
      Toggle title bar (Current is {{sideSheetConfig.titleBarVisible}})
    </button>

    <button class="c4p-button sidesheet-example-actions-button" (click)="toggleTitleBarCloseButton()">
      Toggle title bar close button (Current is {{sideSheetConfig.titleBarCloseVisible}})
    </button>

    <button class="c4p-button sidesheet-example-actions-button" (click)="toggleTitleBarFullscreenButton()">
      Toggle title bar fullscreen button (Current is {{sideSheetConfig.titleBarFullscreenVisible}})
    </button>
  </fieldset>

  <fieldset class="sidesheet-example-actions-container">
    <legend>BREADCRUMB</legend>

    <button class="c4p-button sidesheet-example-actions-button" (click)="toggleBreadcrumb()">
      Toggle breadcrumb (Current is {{sideSheetConfig.breadcrumbVisible}})
    </button>

    <button class="c4p-button sidesheet-example-actions-button" (click)="toggleBreadcrumbCloseButton()">
      Toggle breadcrumb close button (Current is {{sideSheetConfig.breadcrumbCloseVisible}})
    </button>

    <button class="c4p-button sidesheet-example-actions-button" (click)="toggleBreadcrumbFullscreenButton()">
      Toggle breadcrumb fullscreen button (Current is {{sideSheetConfig.breadcrumbFullscreenVisible}})
    </button>
  </fieldset>

  <fieldset class="sidesheet-example-actions-container">
    <legend>COMMAND LINE</legend>
    
    <button class="c4p-button sidesheet-example-actions-button" (click)="toggleCommandLine()">
      Toggle commandline  (Current is {{sideSheetConfig.commandLineVisible}})
    </button>

    <button class="c4p-button sidesheet-example-actions-button" (click)="toggleCommandlinePosition()">
      Change commandline position (Current is {{sideSheetConfig.commandLinePosition}})
    </button>
  </fieldset>

  <div>
    {{sideSheetContent.data ? (sideSheetContent.data | json) : "No data"}}
  </div>

  <div style="margin-top: 40px;">
    <h1>{{ eventText }}</h1>
  </div>

</div>