<mat-accordion class="accordion">
  <mat-expansion-panel
    #matExpansionPanel1
    [ngClass]="{ 'pointer-none': !doCollapse, shadownone: hideShadow }"
    [hideToggle]="hideToggle"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [expanded]="panelOpenState"
  >
    <mat-expansion-panel-header (click)="expandPanel(matExpansionPanel1, $event)">
      <mat-panel-title *ngIf="checkIfString">{{ title | translate }}</mat-panel-title>
      <ng-content select ="[panelDescription]"></ng-content>
      <ng-container *ngIf="!checkIfString">
        <span translate *ngIf="decoratedHeader" style="width: 50px; padding-right: 10px" class="text-decoration"
          >general.labels.SelectColumn</span
        >

        <mat-checkbox *ngIf="!decoratedHeader" [checked]="selected" style="width: 50px" click-stop-propagation (change)="onChangeCheckbox($event.checked)">
        </mat-checkbox>
        <ng-container *ngFor="let header of title; let i = index">
          <mat-panel-title
            style="width: 30px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: inline-block"
            [ngClass]="{
              'text-decoration pl-6': decoratedHeader,
              'c4p-status-icon text-white': i === 0 && !decoratedHeader,
              'c4p-flex': i === 5 && !decoratedHeader
            }"
            *ngIf="i !== 6"
          >
            <span *ngIf="checkIsBoolean(header)">
              <div style="text-align: center">
                <mat-icon
                        matPrefix
                        class="c4p-indicator-icon c4p-indicator-icon-{{
                      header
                    }}"
                                [matTooltip]="
                      (header === true
                        ? 'general.labels.Yes'
                        : header === false
                        ? 'general.labels.No'
                        : 'general.labels.Unknown'
                      ) | translate
                    "
                    >{{ 'radio_button_checked' }}
                </mat-icon>
              </div>
            </span>
            <span *ngIf="!checkIsBoolean(header)" matTooltip="{{ decoratedHeader ? (header | translate) : header }}">
              {{ decoratedHeader ? (header | translate) : header }}
            </span>
            <span *ngIf="isEditable && i === 8">
              <mat-icon
                class="c4p-action-icons"
                style="margin-left: 10px"
                (click)="onEdit()"
                [matTooltip]="'billing.labels.SetAcceptedAmount' | translate"
                >edit</mat-icon
              >
            </span>
          </mat-panel-title>
        </ng-container>
      </ng-container>
    </mat-expansion-panel-header>
    <ng-content></ng-content>
  </mat-expansion-panel>
</mat-accordion>
