import { AbstractDynamicElement } from './abstract-dynamic.model';
import { DynamicFormTypes } from './dynamic-form-types';

export class DynamicFormGroup extends AbstractDynamicElement {
  controls: Map<string, DynamicFormTypes>;
  formGroupName: string;

  constructor(formGroupName: string) {
    super();
    this._dynamicType = 'group';
    this.controls = new Map();
    this.formGroupName = formGroupName;
  }

  setControl(controlName: string, dynamicFormControl: DynamicFormTypes) {
    this.controls.set(controlName, dynamicFormControl);
  }

  removeControl(controlName: string) {
    this.controls.delete(controlName);
  }
}
