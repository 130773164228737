import { Input } from '@angular/core';
import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-alert',
  templateUrl: './error-alert.component.html',
  styleUrls: ['./error-alert.component.scss'],
})
export class ErrorAlertComponent implements OnInit {
  @HostBinding('style.width') public width: string = '100%';
  @Input() public errorLabel: string;
  @Input() public errorString: string;

  constructor() {}

  ngOnInit(): void {}
}
