import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PaginatorIntlService extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();

    this.translate.onDefaultLangChange.subscribe((e: Event) => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  getRangeLabel = function (page, pageSize, length) {
    const of = this.translate
      ? this.translate.instant('general.paginator.Of')
      : 'of';
    if (length === 0 || pageSize === 0) {
      return '0 ' + of + ' ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
  };

  translateLabels() {
    this.itemsPerPageLabel = this.translate.instant(
      'general.paginator.ItemsPerPage',
    );
    this.nextPageLabel = this.translate.instant('general.paginator.NextPage');
    this.previousPageLabel = this.translate.instant(
      'general.paginator.PreviousPage',
    );
    this.firstPageLabel = this.translate.instant('general.paginator.FirstPage');
    this.lastPageLabel = this.translate.instant('general.paginator.LastPage');
    this.changes.next();
  }
}
