import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {Observable, of, Subject} from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CareProgramService } from '../../..';
import { DialogComponent } from '../../../../general-components';
import { DateUtils, ModelUtils } from '../../../../utilities';
import {
  EmployeeInfoModel,
  TherapistModel,
} from '../../models/care-program.model';
import { CarefileEmployeeService } from '../../services/carefile-employee.service';
import { ClientOverviewService } from '../../services/client-overview.service';
import { DateRange } from 'moment-range';
import * as moment from 'moment';
import { Moment } from 'moment';
import * as uuid from 'uuid';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-main-therapist',
  templateUrl: './main-therapist.component.html',
  styleUrls: ['./main-therapist.component.css'],
})
export class MainTherapistComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<boolean>();
  @Input() parentForm: FormGroup;
  @Input() mainTherapist: EmployeeInfoModel[];
  isCarefileCareProgram: boolean;
  openedStream: string | null = null;
  get mainTherapistFormArray(): FormArray {
    return this.parentForm.get('therapists') as FormArray;
  }

  private get registartionDateControl(): FormControl {
    return this.careprogramService.careProgramForm.get(
      'registrationDate',
    ) as FormControl;
  }

  private get endDateControl(): FormControl {
    return this.careprogramService.careProgramForm.get(
      'endDate',
    ) as FormControl;
  }

  private get servicesFormControl(): FormControl {
    return this.careprogramService.careProgramForm.get(
      'services',
    ) as FormControl;
  }

  mainTherapistFilterObject: any = {};
  showWarningMessage = false;
  errorMessage: string;

  constructor(
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    private clientService: ClientOverviewService,
    private careprogramService: CareProgramService,
    private cdr: ChangeDetectorRef,
    public carefileEmployeeService: CarefileEmployeeService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.fillMainTherapists();
    this.setMainTherapistFilters();
    this.formConfirmSubscription();
    this.initSubsciptions();
  }
  buildForm(): FormGroup{
    return this.formBuilder.group({
      tempId: [uuid.v4()],
      employee: [null, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null],
      maxEndDate: [null],
      minStartDate: [null],
      maxStartDate: [],
    });
  }
  public buildMainTherapistFormGroup(
    mainTherapist?: TherapistModel,
  ): FormGroup {
    const formGroup = this.buildForm();

    if (mainTherapist && Object.keys(mainTherapist).length > 0) {
      formGroup
        .get('employee')
        .setValue(
          {
            id: mainTherapist.id,
            employeeNumber: mainTherapist.employeeNumber,
            fullName: mainTherapist.fullName,
          },
          { emitEvent: false },
        );
      formGroup
        .get('startDate')
        .setValue(mainTherapist.startDate, { emitEvent: false });
      formGroup
        .get('endDate')
        .setValue(mainTherapist.endDate, { emitEvent: false });
    }
    if (this.mainTherapistFormArray.disabled) formGroup.disable();

    return formGroup;
  }

  onchangeDate(changeEvent) {
    this.updateValidators();
    if (changeEvent === 'startDate') {
      this.getMaxEndDate();
    } else {
      this.getMinStartDate();
    }
    this.mainTherapistFormArray.markAllAsTouched();
  }

  updateValidators() {
    for (let control of this.mainTherapistFormArray.controls) {
      if (
        this.mainTherapistFormArray.value.findIndex(
          (dateObj) =>
            !!dateObj.startDate &&
            moment(dateObj.startDate).isAfter(control.value.startDate, 'days'),
        ) > -1
      ) {
        control.get('endDate').setValidators(Validators.required);
        control.get('endDate').updateValueAndValidity({ emitEvent: false });
      } else {
        control.get('endDate').clearValidators();
        control.get('endDate').updateValueAndValidity({ emitEvent: false });
      }
      const emptyEndDateExist =
        this.mainTherapistFormArray.value.findIndex(
          (dateObj) => !!dateObj.startDate && !dateObj.endDate,
        ) > -1;
      const startDates = [...this.mainTherapistFormArray.value]
        .filter(
          (dateStartDate) =>
            !!dateStartDate.startDate && !dateStartDate.endDate,
        )
        .map((dateStartDate) => new Date(dateStartDate.startDate!).getTime());
      if (emptyEndDateExist) {
        const globalMaxStartDate =
          startDates.length > 0
            ? moment(Math.min(...startDates))
                .subtract(1, 'day')
                .toDate()
            : null;
        if (
          (control.get('startDate').value && !control.get('endDate').value) ||
          !moment(control.get('endDate').value).isAfter(globalMaxStartDate)
        ) {
          control
            .get('maxEndDate')
            .setValue(control.get('endDate').value, { emitEvent: false });
        }
        if (
          !control.get('endDate').value ||
          moment(control.get('endDate').value).isAfter(globalMaxStartDate)
        ) {
          if (!control.get('startDate').value) {
            control
              .get('maxStartDate')
              .setValue(globalMaxStartDate, { emitEvent: false });
            control
              .get('maxEndDate')
              .setValue(globalMaxStartDate, { emitEvent: false });
            control.get('minStartDate').setValue(null, { emitEvent: false });
          }
        } else {
          control
            .get('maxStartDate')
            .setValue(control.get('endDate').value || new Date('2999/12/31'), {
              emitEvent: false,
            });
        }
      } else {
        control
          .get('maxStartDate')
          .setValue(
            control.get('endDate').value ? control.get('endDate').value : null,
            { emitEvent: false },
          );
      }
    }
  }

  getMaxEndDate() {
    for (let control of this.mainTherapistFormArray.controls) {
      if (control instanceof FormGroup) {
        const chosenStartDate = new Date(control.value.startDate);
        const endDate = control.value.endDate;
        const startDates = [...this.mainTherapistFormArray.value]
          .map((dateStartDate) => new Date(dateStartDate.startDate).getTime())
          .filter((filteredDate) => filteredDate > chosenStartDate?.valueOf());
        const maxEndDate =
          startDates.length > 0
            ? moment(Math.min(...startDates))
                .subtract(1, 'day')
                .toDate()
            : null;
        control.get('maxEndDate').setValue(maxEndDate, { emitEvent: false });
      }
    }
  }

  getMinStartDate() {
    for (let control of this.mainTherapistFormArray.controls) {
      if (control instanceof FormGroup) {
        const chosenEndDate = new Date(control.value.endDate);
        const startDate = control.value.startDate;
        const endDates = [...this.mainTherapistFormArray.value]
          .filter((dateEndDate) => !!dateEndDate.endDate)
          .filter((dateEnd) => control.value.tempId !== dateEnd.tempId)
          .map((dateStartDate) => new Date(dateStartDate.endDate!).getTime())
          .filter(
            (filteredStartDate) => filteredStartDate < chosenEndDate?.valueOf(),
          );
        const minStartDate =
          endDates.length > 0
            ? moment(Math.max(...endDates))
                .add(1, 'day')
                .toDate()
            : null;
        control
          .get('minStartDate')
          .setValue(minStartDate, { emitEvent: false });
      }
    }
  }

  private checkOverlapInDateLimits(
    chosenDateTime: number,
    openedStream,
  ): boolean {
    const chosenMoment = moment(chosenDateTime);
    for (let dateObject of this.mainTherapistFormArray.value) {
      if (dateObject.endDate && dateObject.tempId === openedStream) {
        return false;
      }
      if (
        dateObject.endDate &&
        chosenMoment.within(
          new DateRange(
            moment(dateObject.startDate).startOf('day'),
            moment(dateObject.endDate).startOf('day'),
          ),
        )
      ) {
        return true;
      }
    }
    return false;
  }

  dateFilter = (d: Date | Moment | null): boolean => {
    // waiting for opening the datepicker box before filtering dates
    if (this.openedStream !== null) {
      let pickedDate = d || DateUtils.getCurrentTenantDayMoment();
      if (!moment.isMoment(d)) {
        pickedDate = moment(d);
      }

      if (pickedDate) {
        return !this.checkOverlapInDateLimits(
          (pickedDate as Moment).valueOf(),
          this.openedStream,
        );
      }
    }
    return false;
  };

  onOpened(controlName: string): void {
    this.openedStream = controlName;
  }

  onClosed(): void {
    this.openedStream = null;
  }

  public addMainTherapist(
    mainTherapist: TherapistModel = new TherapistModel(),
  ) {
    this.showWarningMessage = false;
    const mainTherapistFormGroup =
      this.buildMainTherapistFormGroup(mainTherapist);
    this.mainTherapistFormArray.push(mainTherapistFormGroup);
    if (!mainTherapist || Object.keys(mainTherapist).length === 0) {
      this.mainTherapistFormArray.markAllAsTouched();
    }
    this.onchangeDate('startDate');
    this.onchangeDate('endDate');
  }

  public deleteMainTherapist(index: number): void {
    let confirm$: Observable<boolean>;
    const mainTherapistControl = this.mainTherapistFormArray.get([index]);

    if (!ModelUtils.isObjectEmpty(mainTherapistControl.value)) {
      confirm$ = this.dialog
        .open(DialogComponent, {
          data: {
            title: 'general.messages.DeleteItem',
            message:
              'careprogram.messages.AreYouSureYouWantToDeleteMainTherapist',
            confirmText: 'general.actions.delete',
          },
        })
        .afterClosed();
    } else {
      confirm$ = of(true);
    }

    confirm$.subscribe((res) => {
      if (res) {
        this.mainTherapistFormArray.markAsDirty();
        this.mainTherapistFormArray.removeAt(index);
        this.onchangeDate('startDate');
        this.onchangeDate('endDate');
      }
    });
  }

  private formConfirmSubscription() {
    this.careprogramService.confirmTriggered$.subscribe((confirmed) => {
      if (confirmed) {
        const hasActiveTherapist = (
          this.mainTherapistFormArray.value as Array<any>
        ).some((therapist) =>
          this.careprogramService.checkActiveTherapist(
            therapist.startDate,
            therapist.endDate,
          ),
        );
        if (!hasActiveTherapist) {
          this.errorMessage = this.translate.instant(
            'careprogram.messages.CurrentMainTherapistError',
          );
        } else {
          this.errorMessage = '';
        }
        this.showWarningMessage =
          this.mainTherapistFormArray.length == 0 ||
          this.mainTherapistFormArray.invalid ||
          !hasActiveTherapist;
      }
    });
  }

  private initSubsciptions() {
    this.registartionDateControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((registartionDate) => {
        this.mainTherapistFilterObject.registartionStartDate = registartionDate;
      });

    this.endDateControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((endDate) => {
        this.mainTherapistFilterObject.registartionEndDate = endDate;
      });

    this.servicesFormControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((services) => {
        this.mainTherapistFilterObject['carefileServices.ids'] = services.map(
          (s) => s.id,
        );
      });
  }

  private setMainTherapistFilters() {
    this.clientService.selectedCarefileId$
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.mainTherapistFilterObject = {
          carefileId: value?.carefileId,
          involved: true,
          registartionStartDate: this.registartionDateControl.value,
          'carefileServices.ids': this.servicesFormControl.value.map(
            (s) => s.id,
          ),
        };

        if (this.endDateControl.value)
          this.mainTherapistFilterObject.registartionEndDate =
            this.endDateControl.value;
        if (value && !value?.initValue) {
          this.mainTherapistFormArray.clear();
        }
        this.cdr.detectChanges();
      });
  }

  private fillMainTherapists() {
    if (this.mainTherapistFormArray.length === 0) {
      const therapists: TherapistModel[] =
        this.careprogramService.selectedCareProgram$.value.therapists;
      therapists.forEach((therapist) => this.addMainTherapist(therapist));
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
