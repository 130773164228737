export abstract class AgDateUtil {
  public static valueOrDefault(value: any, defaultValue: any): any {
    return value && value !== '' ? value : defaultValue;
  }

  public static dateTimeFormat(value: any): any {
    const date = new Date(value);
    return new Intl.DateTimeFormat('nl', {
      month: '2-digit',
      year: 'numeric',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }).format(date);
  }
  public static dateTimeFormatWithSecond(value: any): any {
    const date = new Date(value);
    return new Intl.DateTimeFormat('nl', {
      month: '2-digit',
      year: 'numeric',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(date);
  }

  public static timeDateFormat(value: any): any {
    if (value) {
      const date = new Date(value);
      return `${date.toLocaleTimeString('nl', {
        hour: '2-digit',
        minute: '2-digit',
      })} ${date.toLocaleDateString()}`;
    }
    return '';
  }

  public static dateFormatAsYYYMMDD() {
    const today = new Date();
    let month = '' + (today.getMonth() + 1);
    let day = '' + today.getDate();
    const year = today.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  public static isEmptyString(value: string | number = '') {
    if (typeof value === 'string' && value.trim() !== '') {
      return false;
    } else {
      return true;
    }
  }
}

export const defaultColDef = {
  sortable: true,
  filter: true,
  resizable: true,
  floatingFilter: true,
  wrapText: true,
  // autoHeight: true,
};

export function dateCellRenderer(data: any) {
  return data.value ? new Date(data.value).toLocaleDateString() : '';
}

export function timeCellRenderer(data: any) {
  return data.value ? new Date(data.value).toLocaleTimeString() : '';
}

export function dateTimeCellRenderer(data: any) {
  return data.value ? AgDateUtil.dateTimeFormat(data.value) : data.value;
}

export function timeDateCellRenderer(data: any) {
  return data.value ? AgDateUtil.timeDateFormat(data.value) : data.value;
}

export function statusCellRenderer(data: any) {
  return data.value ? AgDateUtil.timeDateFormat(data.value) : data.value;
}

export default {
  defaultColDef,
  dateCellRenderer,

  timeCellRenderer,
  dateTimeCellRenderer,
  timeDateCellRenderer,
};
