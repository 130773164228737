import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_SORT_DIRECTION,
  DEFAULT_DROPDOWN_PAGE_SIZE,
  DEFAULT_DROPDOWN_SORT_COLUMN,
  DEFAULT_DROPDOWN_SORT_DIRECTION,
  DEFAULT_SORT_COLUMN,
} from './pagination.const';
import { ITableView } from '../../general-components';

export class PaginationDataModel {
  pageIndex: number;
  pageSize: number;
  sortColumn: string;
  sortDirection: string;
  search: string;

  static fromTableView(tableview: ITableView) {
    const paginationData = new PaginationDataModel(
      tableview?.sortColumn,
      tableview?.sortDirection,
      tableview?.pageSize,
      tableview?.search,
    );
    if (tableview) {
      paginationData.pageIndex = tableview.pageIndex;
      paginationData.pageSize = tableview.pageSize;
    }
    return paginationData;
  }

  static dropdownPagination(
    sortColumn: string = DEFAULT_DROPDOWN_SORT_COLUMN,
    sortDirection: string = DEFAULT_DROPDOWN_SORT_DIRECTION,
    pageSize: number = DEFAULT_DROPDOWN_PAGE_SIZE,
  ): PaginationDataModel {
    return new PaginationDataModel(sortColumn, sortDirection, pageSize);
  }

  constructor(
    sortColumn: string,
    sortDirection?: string,
    pageSize?: number,
    search?: string,
  ) {
    this.pageIndex = DEFAULT_PAGE_NUMBER;
    this.pageSize = pageSize ? pageSize : DEFAULT_PAGE_SIZE;
    this.sortDirection = sortDirection ? sortDirection : DEFAULT_SORT_DIRECTION;
    this.sortColumn = sortColumn ? sortColumn : DEFAULT_SORT_COLUMN;
    this.search = search;
  }
}
