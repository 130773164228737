import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls/form-controls.const';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class ExportMethodService {
  public values: IFormDropdownOption[] = [];
  public lookupValues: string[] = [];

  constructor(private masterDataService: MasterDataService) {
    this.getExportMethods();
  }

  public getExportMethods() {
    this.masterDataService.getMasterData('export-types').subscribe((data) => {
      //TODO: Need to confirm the type name
      this.values = data;
      for (let item of this.values) {
        this.lookupValues[item.value] = item.label;
      }
    });
  }
}
