import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormInputTextComponent } from './form-input-text/form-input-text.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { DirectivesModule } from '../directives';
import { PipesModule } from '../pipes';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { InputCheckboxComponent } from './input-checkbox/input-checkbox.component';
import { InputCheckboxListComponent } from './input-checkbox-list/input-checkbox-list.component';
import { FormInputDropdownComponent } from './form-input-dropdown/form-input-dropdown.component';
import { FormInputTextAreaComponent } from './form-input-text-area/form-input-text-area.component';
import { FormInputCheckboxComponent } from './form-input-checkbox/form-input-checkbox.component';
import { FormInputRadioComponent } from './form-input-radio/form-input-radio.component';
import { FormInputDateComponent } from './form-input-date/form-input-date.component';
import { FormInputDateTimeComponent } from './form-input-date-time/form-input-date-time.component';
import { FormInputTimeComponent } from './form-input-time/form-input-time.component';
import { FormInputDateRangeComponent } from './form-input-date-range/form-input-date-range.component';
import { FormInputAutocompleteComponent } from './form-input-autocomplete/form-input-autocomplete.component';
import { MasterDataService } from '../services/master-data/master-data.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { FormInputMonthYearComponent } from './form-input-month-year/form-input-month-year.component';
import { FormInputWeekRangeComponent } from './form-input-week-range/form-input-week-range.component';

const moduleComponents = [
  InputCheckboxComponent,
  InputCheckboxListComponent,
  FormInputTextComponent,
  FormInputDropdownComponent,
  FormInputTextAreaComponent,
  FormInputCheckboxComponent,
  FormInputRadioComponent,
  FormInputDateComponent,
  FormInputTextAreaComponent,
  FormInputDateTimeComponent,
  FormInputTimeComponent,
  FormInputDateRangeComponent,
  FormInputAutocompleteComponent,
  FormInputMonthYearComponent,
  FormInputWeekRangeComponent,
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    DirectivesModule,
    NgxMatSelectSearchModule,
    PipesModule,
    ScrollingModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  bootstrap: [FormInputDateComponent],
  declarations: [...moduleComponents],
  exports: [...moduleComponents],
  providers: [
    MasterDataService,
    // { provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter, deps: [MAT_DATE_LOCALE] },
    // { provide: NGX_MAT_DATE_FORMATS, useValue: APP_DATE_TIME_FORMATS },
    // { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    provideNgxMask(),
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class FormControlsModule {}
