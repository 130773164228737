import { Component, Input } from '@angular/core';
import { FormControlBase } from '../abstract-form-control';
import { DEFAULT_INPUT_TYPE } from '../form-controls.const';

@Component({
  selector: 'app-form-input-text',
  templateUrl: './form-input-text.component.html',
  styleUrls: ['./form-input-text.component.scss'],
})
export class FormInputTextComponent extends FormControlBase<FormInputTextComponent> {
  @Input() type = DEFAULT_INPUT_TYPE;
  @Input() skipTranslate: boolean;
  @Input() step = 1;
  @Input() mask: string;
  @Input() dropSpecialCharacters: boolean = false;
  @Input() onlyNumber = false;

  numberOnly(event): boolean {
    if (!this.onlyNumber) return;
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
