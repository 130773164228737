import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'appDurationMinute',
  pure: true,
})
export class DurationMinutePipe implements PipeTransform {
  transform(startTime: string, endTime: any): any {
    if (startTime && endTime) {
      const [startHours, startMinutes] = startTime.split(':');
      const startMoment = moment().set({
        hours: +startHours,
        minutes: +startMinutes,
      });
      const [endHours, endMinutes] = endTime.split(':');
      const endMoment = moment().set({
        hours: +endHours,
        minutes: +endMinutes,
      });
      return moment.duration(endMoment.diff(startMoment)).asMinutes();
    }
  }
}
