import { Component, Inject, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { HttpUtils } from '../../../utilities/http.utils';
import { DocumentModel, FileDataModel } from '../../models/document.model';
import { DocumentService } from '../../service/document/document.service';
import { DialogComponent } from '../../../general-components/dialog/dialog.component';

@Component({
  selector: 'app-document-detail',
  templateUrl: './document-detail.component.html',
  styleUrls: ['./document-detail.component.scss'],
})
export class DocumentDetailComponent implements OnInit {
  public documentForm: FormGroup = null!;


  selectedFile: any;
  checkIfServiceLabelExist: boolean = false;

  constructor(
    private fb: FormBuilder,
    public documentService: DocumentService,
    public dialogRef: MatDialogRef<DocumentDetailComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.documentForm = this.documentBuildForm();
    if (this.documentService.services[0].label) {
      this.checkIfServiceLabelExist = true;
    }
    this.initModalData();
  }

  private initModalData() {
    if (this.data.document) {
      const document: DocumentModel = this.data.document;
      if (document) {
        // remove extension from filename
        if (document.fileData) {
          document.fileData.fileName = document.fileData.fileName.split('.')[0];
        }
        this.documentForm.patchValue(document);
      }
    }
    if (this.documentForm.value && !this.documentForm.value.id) {
      let services = this.documentService.services;

      if (services && services.length == 1) {
        this.documentForm.get('services').patchValue(services);
      }
      if (this.data.careprogramViewMode) {
        this.documentForm.get('services').patchValue(services);
      }
      let categories = this.documentService.categories;
      if (categories && categories.length == 1) {
        this.documentForm.get('category').patchValue(categories[0]);
      }
      if (services) {
        services.sort((a, b) => {
          if (a.name === b.name) {
            if (a.status !== b.status) {
              return a.status === 'open' ? -1 : 1;
            }
          }
        });
      }
    }
  }

  onCloseDialog() {
    if (this.documentForm.untouched) {
      this.dialogRef.close(null);
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'general.labels.Discard',
          message: 'careprogram.messages.AreYouSureYouWantToDiscard',
          confirmText: 'general.actions.Discard',
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.dialogRef.close(null);
        }
      });
    }
  }

  submit() {
    if (this.documentForm.valid) {
      const document: DocumentModel = this.documentForm.value;
      document.carefileId =
        this.documentService.documentParams$.value.carefileId;
      document.applicationId =
        this.documentService.documentParams$.value.applicationId;

      if (!this.documentForm.value.id) {
        // create
        if (this.selectedFile) {
          const file = this.selectedFile;
          const reader = new FileReader();

          reader.readAsDataURL(file);
          reader.onload = () => {
            const fileData = new FileDataModel();
            fileData.base64file = (<string>reader.result).split(',')[1];
            fileData.fileName = file.name;
            fileData.extension = file.name.substring(
              file.name.lastIndexOf('.') + 1,
            );
            fileData.fileSize = HttpUtils.calculateFileSize(file.size);

            document.fileData = fileData;
            this.dialogRef.close(document);
          };
        }
      } else {
        // update
        if (document.fileData) {
          // set the fileName to include extension
          document.fileData.fileName =
            document.fileData.fileName + '.' + document.fileData.extension;
          // keep the old fileData information
          document.fileData = {
            ...this.data.document?.fileData,
            ...document.fileData,
          };
        }
        this.dialogRef.close(document);
      }
    } else {
      this.documentForm.markAllAsTouched();
    }
  }
  documentBuildForm(){
    return this.fb.group({
      id: [],
      description: [null, Validators.required],
      category: [null, Validators.required],
      services: [null, Validators.required],
      fileData: this.fb.group({
        fileName: [],
        extension: [],
      }),
      checked: [],
    });
  }
}
