import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';

@Injectable({
  providedIn: 'root',
})
export class AutoinsertService {
  public values: IFormDropdownOption[] = [
    { value: true, label: 'masterData.autoInsert.True' },
    { value: false, label: 'masterData.autoInsert.False' },
  ];
  public lookupValues: string[] = [];

  constructor() {
    for (let i = 0; i < this.values.length; i++) {
      this.lookupValues[i] = this.values[i].label;
    }
    for (const item of this.values) {
      this.lookupValues[item.value] = item.label;
    }
  }
}
