import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DATE_QUERY_FORMAT } from './const';

export class FormUtils {
  public static enableFormControls(
    formGroup: FormGroup,
    formControls: string[],
  ): void {
    formControls.forEach((control) =>
      formGroup.get(control).enable({ emitEvent: false }),
    );
  }

  public static calculateDuration(startTime: string, endTime: string) {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);

    const durationMinutes =
      (endHours - startHours) * 60 + (endMinutes - startMinutes);
    const hours = Math.floor(durationMinutes / 60);
    const minutes = durationMinutes % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
      2,
      '0',
    )}`;
  }

  public static roundTimeToNearestTenMinutes(timeStr: string) {
    const [hours, minutes] = timeStr.split(':').map(Number);

    let roundedMinutes = Math.round(minutes / 5) * 5;

    if (roundedMinutes >= 60) {
      const roundedHours = (hours + 1) % 24;
      return `${roundedHours.toString().padStart(2, '0')}:00`;
    }

    return `${hours.toString().padStart(2, '0')}:${roundedMinutes
      .toString()
      .padStart(2, '0')}`;
  }

  public static disableFormControls(
    formGroup: FormGroup,
    formControls: string[],
  ): void {
    formControls.forEach((control) =>
      formGroup.get(control).disable({ emitEvent: false }),
    );
  }

  // replaces all existing validators with the required validator, not usable if there are already validators on the form controls
  public static setRequiredValidator(
    formGroup: FormGroup,
    formControls: string[],
  ) {
    formControls.forEach((control) => {
      formGroup.get(control).setValidators(Validators.required);
      formGroup.get(control).updateValueAndValidity();
    });
  }

  public static convertToLocalDateString(date): string | null {
    if (!date) {
      return null;
    }
    const local = moment(date).local();
    return local.format(DATE_QUERY_FORMAT);
  }

  public static createFormControls(
    config: { [key: string]: any },
    formGroup: FormGroup,
  ): { [key: string]: FormControl } {
    const controls: { [key: string]: FormControl } = {};

    for (const key of Object.keys(config)) {
      controls[key] = formGroup.get(key) as FormControl;
    }
    return controls;
  }

  public static subAssessmentDisplayFunction(number: any, assessment: any) {
    const assessmentString = `${number} - ${assessment.assessmentNumber}`;
    const externalProductString = `${
      assessment.externalProduct
        ? assessment.externalProduct.name +
          ' - ' +
          assessment.externalProduct.code
        : ''
    }`;
    const internalProductString = `${
      assessment.internalProduct ? assessment.internalProduct.name : ''
    }${
      assessment.internalProduct ? ' - ' + assessment.internalProduct.code : ''
    }`;
    return `${assessmentString} ${
      externalProductString === '' ? '' : '/' + externalProductString
    } ${internalProductString ? '/' + internalProductString : ''}`;
  }

  public static subCareProgramDisplayFunction(careProgram: any): string {
    return `${careProgram?.zpmType} - ${careProgram?.client?.fullName}`;
  }

  public static sortFormArrayBy(array: any, args: string): any {
    const sorted = array?.sort((a: any, b: any) =>
      FormUtils.compareValues(a.controls[args].value, b.controls[args].value),
    );
    return sorted;
  }

  public static sortArrayBy(array: any, args: string): any {
    const sorted = array?.sort((a: any, b: any) =>
      FormUtils.compareValues(a[args], b[args]),
    );
    return sorted;
  }

  public static compareValues(aValue: any, bValue: string): any {
    if (aValue < bValue) {
      return -1;
    } else {
      return aValue > bValue ? 1 : 0;
    }
  }
  public static stringifyObjectValues<T extends Record<string, any>>(
    obj: T,
  ): Record<string, string> {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, JSON.stringify(value)]),
    );
  }

  public static destringifyObjectValues<T extends Record<string, string>>(
    obj: T,
  ): Record<string, any> {
    return Object.fromEntries(
      Object.entries(obj).map(([key, stringifyValue]) => [
        key,
        JSON.parse(stringifyValue),
      ]),
    );
  }

  public static getTrueKeys(obj: Record<string, boolean>) {
    const trueKeys = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === true) {
        trueKeys.push(key);
      }
    }
    return trueKeys;
  }
}
