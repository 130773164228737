import { FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';

const { range } = extendMoment(moment);

export function formArrayDateOverlapValidator(
  startDate = 'startDate',
  endDate = 'endDate',
): ValidatorFn {
  return (formArray: FormArray): ValidationErrors | null => {
    const docs: any[] = [...formArray.getRawValue()];

    if (docs.length === 1 || docs.length === 0) return null;

    const validRanges = docs
      .filter((s) => s.startDate !== null)
      .sort((a, b) => {
        return moment(a.startDate).diff(b.startDate);
      });

    let validatonError: ValidationErrors = null;

    for (let i = 0; i < validRanges.length - 1; i += 1) {
      const range1 = range(validRanges[i][startDate], validRanges[i][endDate]);
      const range2 = range(
        validRanges[i + 1][startDate],
        validRanges[i + 1][endDate],
      );

      if (range1.overlaps(range2, { adjacent: true }))
        validatonError = { dateOverlap: true };
    }

    return validatonError;
  };
}
