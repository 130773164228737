import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../../services';

@Pipe({
  name: 'statusTransformation',
})
export class StatusTransformationPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}
  transform(value: string): string {
    const upperCased = value[0].toUpperCase() + value.slice(1);
    return this.languageService.translation('general.status.' + upperCased);
  }
}
