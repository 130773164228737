import {SUPERSET_CONSTANTS} from 'c4p-portal-util';

export const globalDemoEnvironment = {
  production: false,
  oldDashboardEnabled: false,
  communicationManagementApiUrl: 'api/cm/contacts',
  employeeManagementApiUrl: 'api/ems/employees',
  addressManagementApiUrl: 'api/ams/addresses',
  tenantManagementApiUrl: 'api/tms/tenants',
  systemAdminApiUrl: 'api/sas',
  clientManagementApiUrl: 'api/cms/clients',
  applicationManagementApiUrl: 'api/aps/applications',
  masterdataApiUrl: 'api/mds',
  questionsetManagementApiUrl: 'api/qss',
  icommunicationApiUrl: 'api/icm/messages',
  clientNetworkManagementUrl: 'api/cns/client-networks',
  gatekeeperServiceApiUrl: 'api/gks/gatekeepers',
  gatekeeperConfigurationsServiceApiUrl: '/api/gks/configurations',
  teamManagementApiUrl: '/api/tm/teams',
  carefileManagementApiUrl: '/api/cf/carefiles',
  assessmentWidgetManagementApiUrl: '/api/pp',
  documentManagementApiUrl: '/api/dms',
  productCatalogApiUrl: '/api/pc',
  keycloakManagementApiUrl: '/api/kms',
  dashboardServiceApiUrl: '/api/dsh/dashboards',
  logbookServiceApiUrl: '/api/lgb/logbook',
  hourManagementApiUrl: '/api/hm',
  docgenPortalUrl: 'https://docgen.roc2.tuula.nl',
  careplanManagementApiUrl: '/api/cf/carefiles',
  noteManagementApiUrl: '/api/cf/notes',
  reportManagementApiUrl: '/api/rm',
  invoiceManagementApiUrl: '/api/im',
  medicationModuleUrl: '/api/mms',
  collaborationServiceWS: 'wss://roc2.tuula.nl:443/ws/',
  notificationManagementApiUrl: '/api/nm',
  topicCarefileManagementApiUrl: '/api/cm',
  commentApiUrl: '/api/cm/comments',
  docgenServiceApiUrl: '/api/dg',
  participationServiceApiUrl: '/api/pp',
  performanceTariffApiUrl: '/api/cf',
  performanceTariffTemplateApiUrl: '/api/cf',
  pharmacyManagementApiUrl: '/api/phm',
  careprogramServiceApiUrl: '/api/cf/careprogram',
  dwhServiceApiUrl: '/api/ds',
  digitalSignatureApiUrl: '/api/dgs',
  supportEmail: '',

  idleTimeout: 7200000,
  enableLangSwich: true,

  vapid:
    'BBYCTpw6CgtqB8gaKM-YDrJGhmwlfaQoxP2RZDSspg43aOsiOUe2avX4rkeeRpt_cELjI_BGgw2auIFEn3kE2Xc',
  firebase: {
    apiKey: 'AIzaSyAkCRVAoZfaPwje_BBjSqrhFav3ipDxpt0',
    authDomain: 'c4p-dev.firebaseapp.com',
    projectId: 'c4p-dev',
    storageBucket: 'c4p-dev.appspot.com',
    messagingSenderId: '184188939984',
    appId: '1:184188939984:web:1e5a04fb15c1def25df8d5',
    measurementId: 'G-1VTMWP9BQ7',
  },
  serviceDeskUrl:
    'https://supportcare4pro.atlassian.net/servicedesk/customer/portal/8',
  supersetApiUrl: 'https://bi.tuulasoft.com',
  dashboards: [
    {
      name: SUPERSET_CONSTANTS.TARIFF_AND_TRENDS,
      permissions: [],
      childs: [
        {
          name: SUPERSET_CONSTANTS.EXTERNAL_PRODUCTS,
          id: '7616b7fb-e187-4a8f-97c7-8404aea766a8',
        },
        {
          name: SUPERSET_CONSTANTS.PERFORMANCE_TARIFF,
          id: '04faec7b-2826-4e15-b330-7773d723e37a',
        },
      ],
    }
  ],

};
