import { Component, Inject, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fadeAnimation } from '../../../general-components';
export interface ExpandInfo {
  orderNum: number;
  placeholder: string;
  hint: string;
  disabled: boolean;
  required: boolean;
  isClientPortal: boolean;
  value: string;
  type: 'multiline' | 'richtext';
}
@Component({
  selector: 'app-question-expand',
  templateUrl: './question-expand.component.html',
  styleUrls: ['./question-expand.component.css'],
  animations: [fadeAnimation],
})
export class QuestionExpandComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ExpandInfo,
    public dialogRef: MatDialogRef<QuestionExpandComponent>,
  ) {}

  fullView = false;
  form: FormGroup = null!;

  private get answerControl(): FormControl {
    return this.form.get('answer') as FormControl;
  }
  ngOnInit(): void {
    this.form = this.buildForm();
    this.setAnswerControl(
      this.data.value,
      this.data.required,
      this.data.disabled,
    );
  }

  private setAnswerControl(
    value: string,
    required: boolean,
    disabled: boolean,
  ) {
    this.answerControl.setValue(!!value ? value : null);
    if (disabled) this.answerControl.disable();
    else if (required) this.answerControl.setValidators([Validators.required]);
  }
  onDismiss() {
    this.dialogRef.close(null);
  }
  onConfirm() {
    this.dialogRef.close(this.answerControl.value);
  }

  isInputValid() {
    return !this.data.disabled && this.answerControl.valid;
  }

  editorInit(quill: any) {
    quill.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
      let ops = [];
      delta.ops.forEach((op) => {
        if (op.insert && typeof op.insert === 'string') {
          ops.push({
            insert: op.insert,
          });
        }
      });
      delta.ops = ops;
      return delta;
    });
  }
  buildForm(){
    return this.formBuilder.group({
      answer: [],
    });
  }
}
