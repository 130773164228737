// https://dev.to/zhiyueyi/create-a-simple-breadcrumb-in-angular-ag5
import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  public breadcrumbs$ = new Subject<any>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((breadcrumbs: any) =>
        this.calculateBreadcrumbs(this.activatedRoute),
      );
  }

  public addBreadcrumb(customBreadcrumb: Breadcrumb){
    const createdBreacrumbs = this.createBreadcrumbs(this.activatedRoute.root);
    console.log(createdBreacrumbs);
    createdBreacrumbs.push(customBreadcrumb);
    console.log(createdBreacrumbs);
    if (createdBreacrumbs.length > 0) {
      createdBreacrumbs[createdBreacrumbs.length - 1].last = true;
    }
    this.breadcrumbs$.next(createdBreacrumbs);
  }

  public calculateBreadcrumbs(activatedRoute: ActivatedRoute) {
    const createdBreacrumbs = this.createBreadcrumbs(this.activatedRoute.root);
    // mark the last breadcrumb
    if (createdBreacrumbs.length > 0) {
      createdBreacrumbs[createdBreacrumbs.length - 1].last = true;
    }
    this.breadcrumbs$.next(createdBreacrumbs);
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Breadcrumb[] = [],
  ): Breadcrumb[] {
    // extract the label and route from configuration
    let label =
      route.routeConfig &&
      route.routeConfig.data &&
      !route.routeConfig.data.hideBreadcrumb
        ? route.routeConfig.data.breadcrumbLabel
        : '';
    let path = route.routeConfig ? route.routeConfig.path : '';

    // check for params in the route and adjust accordingly
    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');
    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      // set the label to the calculated value from the resolver
      label = route.snapshot.data.breadcrumbLabel
        ? route.snapshot.data.breadcrumbLabel
        : null;
    }

    // extend the path from previous iterations
    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: Breadcrumb = {
      label: label,
      url: nextUrl,
    };
    // only include breadcrumbs with labels
    const newBreadcrumbs = breadcrumb.label
      ? [...breadcrumbs, breadcrumb]
      : [...breadcrumbs];

    // if it is not the end of the route
    if (route.firstChild) {
      // we need to build the rest of the breadcrumbs
      return this.createBreadcrumbs(route.firstChild, nextUrl, newBreadcrumbs);
    }

    return newBreadcrumbs;
  }
}

export interface Breadcrumb {
  label: string;
  url: string;
  last?: boolean;
}
