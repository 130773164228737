import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT } from '../../../../../form-controls';
import { PaginationDataModel } from '../../../../../models';
import {
  CareProviderRoleService,
  CareProviderSpecService,
  CareProviderTypeService,
  ProfessionCareProfessionalService,
  ReferralTypeService,
} from '../../../../../services';
import { CarefileServiceModel } from '../../../models/carefile-service.model';
import { CareProgramService } from '../../../services/care-program.service';
import { CarefileServiceService } from '../../../services/carefile-service.service';
import { ClientOverviewService } from '../../../services/client-overview.service';
import { OPERATION_TYPES } from '../../../../../document/const/operation-types.const';
import { SOURCE_TYPES } from '../../../../../document/const/source-types.const';

@Component({
  selector: 'app-referral-section',
  templateUrl: './referral-section.component.html',
  styleUrls: ['./referral-section.component.scss'],
})
export class ReferralSectionComponent implements OnInit, OnDestroy {
  public carefileId: string;
  public careprogramId: string;
  public portal = SOURCE_TYPES.EMPLOYEE;
  private readonly destroyed$ = new Subject<boolean>();
  public readonly OPERATION_TYPES = OPERATION_TYPES;
  public carefileServices: CarefileServiceModel[];
  private readonly DEFAULT_CARE_PROVIDED_ROLE = '03';
  hasReferrer = false;
  public get careprogramForm(): FormGroup {
    return this.careprogramService.careProgramForm;
  }

  public get referralFormGroup(): FormGroup {
    return this.careprogramForm.get('referral') as FormGroup;
  }

  public get referrerFormGroup(): FormGroup {
    return this.careprogramForm.get('referral').get('referrer') as FormGroup;
  }

  public get careProviderRoleFormControl(): FormControl {
    return this.referrerFormGroup.get('careProviderRole') as FormControl;
  }

  public get hasReferrerFormControl(): FormControl {
    return this.careprogramForm.get('hasReferrer') as FormControl;
  }
  constructor(
    private careprogramService: CareProgramService,
    public careProviderTypeService: CareProviderTypeService,
    public careProviderSpecService: CareProviderSpecService,
    public professionCareProfessionalService: ProfessionCareProfessionalService,
    public careProviderRoleService: CareProviderRoleService,
    public clientService: ClientOverviewService,
    public referralTypeService: ReferralTypeService,
  ) {}

  ngOnInit(): void {
    this.careprogramId = this.careprogramForm.get('id').value;
    this.hasReferrer = this.hasReferrerFormControl.value;
    this.clientService.selectedCarefileId$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.carefileId = value?.carefileId;
        if (!this.carefileId) return;
      });
    this.carefileServices =
      this.careprogramService.careProgramForm.get('services').value;

    this.referrerFormGroup
      .get('agbCode')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((agbCode) => {
        if (agbCode) {
          if (agbCode.length !== 8) {
            this.referrerFormGroup.markAsDirty();
            this.referrerFormGroup.get('agbCode').markAllAsTouched();
          } else {
            this.referrerFormGroup
              .get('agbCode')
              .setValue(agbCode, { emitEvent: false });
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  addReferrer(event) {
    this.hasReferrer = event.checked;

    if (this.hasReferrer) {
      this.careProviderRoleFormControl.patchValue(
        this.DEFAULT_CARE_PROVIDED_ROLE,
        { emitEvent: false },
      );
      this.careProviderRoleFormControl.disable({ emitEvent: false });
      this.hasReferrerFormControl.patchValue(true);
      this.referralFormGroup.markAsDirty();
    } else {
      this.resetReferrerForm();
    }
  }

  private resetReferrerForm() {
    const referrer = {
      careOrgName: null,
      careProfessionalName: {
        firstName: null,
        initials: null,
        givenName: null,
        additions: null,
        lastName: null,
      },
      agbCode: null,
      careProviderType: null,
      careProviderSpec: null,
      professionCareProfessional: null,
      careProviderRole: null,
      dateOfReferral: null,
      referralType: null,
      referralFiles: null,
    };
    this.referrerFormGroup.reset(referrer, { emitEvent: false });
    this.hasReferrerFormControl.patchValue(false);
  }
}
