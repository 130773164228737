import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DEFAULT_DATE_FORMAT } from '../pipes.const';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'dayDivisionPipe' })
export class DayDivisionPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(date: Date): any {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (
      this.getDayString(date, this.translate.defaultLang) ===
      this.getDayString(today, this.translate.defaultLang)
    ) {
      return this.translate.instant('notification.labels.Today');
    } else if (
      this.getDayString(date, this.translate.defaultLang) ===
      this.getDayString(yesterday, this.translate.defaultLang)
    ) {
      return this.translate.instant('notification.labels.Yesterday');
    } else {
      return this.getDayString(date, this.translate.defaultLang);
    }
  }

  private getDayString(date: Date, defaultLang: string): string {
    return new DatePipe(defaultLang).transform(date, DEFAULT_DATE_FORMAT);
  }
}
