<app-panel type="popup">
  <app-panel-title title="socialarea.titles.GoalScoreReview"></app-panel-title>
  <div style="display: flex; margin: 16px">
    <div class="c4p-average-score c4p-font-bold">
      {{ averageScore }}
    </div>
    <div style="display: block; text-align: left">
      <div class="c4p-font-bold c4p-font-large">{{ goalName }}</div>
      <div *ngIf="averageScore" class="c4p-font-normal" style="margin-top: 8px">
        {{ 'socialarea.titles.AverageScore' | translate }}: {{ averageScore }}
      </div>
    </div>
  </div>
  <form [formGroup]="goalHistoryForm">
    <app-panel-form-row>
      <app-form-input-dropdown
        formControlName="queryType"
        [options]="queryOptions"
        [blank]="false"
        placeholder="socialarea.labels.FilterType"
      ></app-form-input-dropdown>
      <app-form-input-date
        *ngIf="queryTypeControl().value === 'timebased'"
        formControlName="fromDate"
        placeholder="application.filter.From"
      ></app-form-input-date>
      <app-form-input-date
        *ngIf="queryTypeControl().value === 'timebased'"
        formControlName="untilDate"
        placeholder="application.filter.Until"
      ></app-form-input-date>
      <app-form-input-dropdown
        *ngIf="queryTypeControl().value === 'specific'"
        formControlName="specificEvaluationId"
        placeholder="general.labels.Evaluations"
        [dropdownService]="evaluationsService"
        labelProperty="description"
        [filterObject]="evaluationFilterObject"
        [options]="evaluationsService.values"
        [objectAsValue]="true"
        [blank]="false"
      ></app-form-input-dropdown>
    </app-panel-form-row>
  </form>
  <app-panel>
    <app-table-view
      [options]="scoreHistoryTableOptions"
      (refreshRequest)="tableView = $event; refresh()"
    ></app-table-view>
  </app-panel>
  <div style="display: flex">
    <span class="c4p-button-cancel" (click)="onDismiss()" translate
      >general.labels.Cancel</span
    >
  </div>
</app-panel>
