<div [formGroup]="inputFormGroup" class="c4p-form-question-answers">
  <div [ngSwitch]="inputFormGroup.get('type').value">
    <!-- Singleline -->
    <app-single-line
      *ngSwitchCase="1"
      formControlName="answer"
      name="answer"
      [placeholder]="titleControlValue"
      [orderNum]="orderNumControlValue"
      [hint]="hintControlValue"
    ></app-single-line>

    <!-- Multiline -->
    <app-multi-line
      *ngSwitchCase="2"
      formControlName="answer"
      name="answer"
      [placeholder]="titleControlValue"
      [orderNum]="orderNumControlValue"
      [hint]="hintControlValue"
    ></app-multi-line>

    <!-- Rich text -->
    <div *ngSwitchCase="3">
      <div class="c4p-question-set-input-small" style="word-wrap: break-word">
        <span *ngIf="mandatoryControlValue" class="c4p-question-label">{{
          '* ' + orderNumControlValue + '. ' + titleControlValue
        }}</span>
        <span *ngIf="!mandatoryControlValue" class="c4p-question-label">{{
          orderNumControlValue + '. ' + titleControlValue
        }}</span>
        <span
          *ngIf="hintControlValue"
          tippy
          [tippyOptions]="{
            arrow: true,
            allowHTML: true,
            placement: 'right',
            content: hintControlValue,
            theme: 'light',
            maxWidth: '350px',
            interactive: true
          }"
        >
          <mat-icon class="c4p-hint-icons">info</mat-icon>
        </span>
      </div>
      <div
        *ngIf="!answerControlValue && !focusRichTextControlValue"
        (click)="onRichContent()"
      >
        <p>
          <i><span translate> questionset.messages.ClickHereToAnswer </span></i>
        </p>
      </div>
      <div
        *ngIf="!focusRichTextControlValue"
        (click)="onRichContent()"
        [innerHTML]="answerControlValue"
      ></div>
      <div
        *ngIf="focusRichTextControlValue && !answersFormArray.disabled"
        class="c4p-question-set-input"
      >
        <quill-editor
          placeholder=""
          formControlName="answer"
          (onBlur)="onRichContent()"
          (onEditorCreated)="onEditorCreated($event)"
        ></quill-editor>
      </div>
    </div>

    <!-- Multiple choice -->
    <app-multiple-choice
      *ngSwitchCase="4"
      formControlName="answer"
      name="answer"
      [placeholder]="titleControlValue"
      [orderNum]="orderNumControlValue"
      [hint]="hintControlValue"
      [options]="optionsForChoice[idControlValue]"
      [required]="mandatoryControlValue"
      [disable]="true"
    ></app-multiple-choice>

    <!-- Single choice -->
    <app-single-choice
      *ngSwitchCase="5"
      formControlName="answer"
      name="answer"
      [placeholder]="titleControlValue"
      [orderNum]="orderNumControlValue"
      [hint]="hintControlValue"
      [options]="optionsForChoice[idControlValue]"
    ></app-single-choice>

    <!-- Date -->
    <app-date-choice
      *ngSwitchCase="6"
      formControlName="answer"
      name="answer"
      [placeholder]="titleControlValue"
      [orderNum]="orderNumControlValue"
      [hint]="hintControlValue"
    ></app-date-choice>

    <!-- Date Time -->
    <app-date-time-choice
      *ngSwitchCase="7"
      formControlName="answer"
      name="answer"
      [placeholder]="titleControlValue"
      [orderNum]="orderNumControlValue"
      [hint]="hintControlValue"
    ></app-date-time-choice>

    <!-- Scale -->
    <app-scale-choice
      *ngSwitchCase="8"
      formControlName="answer"
      name="answer"
      [placeholder]="titleControlValue"
      [orderNum]="orderNumControlValue"
      [hint]="hintControlValue"
      [minLimit]="0"
      [maxLimit]="5"
    >
    </app-scale-choice>

    <app-additional-comment
      *ngIf="commentRequiredControlValue"
      formControlName="comment"
      name="comment"
      placeholder="clientPortal.labels.Explanation"
    >
    </app-additional-comment>
  </div>
</div>
