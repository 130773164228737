import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../../services';

export enum BillingPeriodicity {
  PER_MONTH = 'month',
  PER_4_WEEKS = '4_weeks',
}

@Pipe({
  name: 'invoicePeriod',
})
export class InvoicePeriodPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}
  transform(value: {
    invoicePeriod: {
      startDate: Date;
      endDate: Date;
      seqNo: string;
    };
    billingPeriodicity: string;
  }): string {
    const periodNumber = value.invoicePeriod.seqNo.substring(0, 2);
    const year = value.invoicePeriod.seqNo.substring(2);
    if (value.billingPeriodicity == BillingPeriodicity.PER_4_WEEKS) {
      return `${this.languageService.translation(
        'general.labels.CAKPeriod',
      )} ${+periodNumber} ${year}`;
    } else {
      //if (value.billingPeriodicity == BillingPeriodicity.PER_MONTH) { // Disabled for testing purposes due to 'unknown' periodicity values
      const language = this.languageService.value;
      let localeString = 'en-EN';
      if (language === 'nl') localeString = 'nl-NL';
      const date = new Date(Number(year), Number(periodNumber) - 1, 1);
      let dateString = date.toLocaleString(localeString, {
        month: 'long',
        year: 'numeric',
      });
      dateString = dateString.charAt(0).toUpperCase() + dateString.substring(1);
      return `${dateString}`;
    }
    // } else {
    //   return '';
    // }
  }
}
