import { Pipe, PipeTransform } from '@angular/core';
import { TenantDatePipe } from '../datetime';

@Pipe({
  name: 'carefileDisplay',
})
export class CarefileDisplayPipe
  extends TenantDatePipe
  implements PipeTransform
{
  transform(value: any, args?: any): any {
    if (!value) {
      return '';
    }
    const displayParts = [];
    if (value.carefileNumber) {
      displayParts.push(value.carefileNumber);
    }
    if (value.createdAt) {
      displayParts.push(super.transform(value.createdAt));
    }
    return displayParts.join(' - ');
  }
}
