<div class="sidesheet-overlay" *ngIf="tooltipData"></div>
<div class="sidesheet-tooltip-container" *ngIf="tooltipData">

  @if (tooltipData?.confirmAction) {
    <ng-container
      *ngTemplateOutlet="confirmActionTemplate"></ng-container>
  } @else {
    <ng-container
      *ngTemplateOutlet="tooltipData?.template"></ng-container>
  }
</div>

<ng-template #confirmActionTemplate>

  <span [ngStyle]="tooltipData.confirmAction?.contentStyle">
    <div class="sidesheet-tooltip-header" [ngStyle]="tooltipData.confirmAction?.headerStyle">
      <div class="sidesheet-tooltip-icon">
        <img src="/assets/icons/notify.svg" alt="">
      </div>
      <div class="sidesheet-tooltip-close" (click)="onClose()">
        <img src="/assets/icons/close.svg" alt="">
      </div>
    </div>
    <div class="sidesheet-tooltip-header-text"> {{ tooltipData.confirmAction?.textHeader | translate }}</div>
    <div class="sidesheet-tooltip-text">
      {{ tooltipData.confirmAction?.textContent | translate }}
    </div>

    <div class="sidesheet-tooltip-footer">
      <button class="c4p-button-cancel sidesheet-btn-cancel" *ngIf="tooltipData.confirmAction?.textCancel" type="submit"
              (click)="onExecuteButtonAction(false)">
        {{tooltipData.confirmAction?.textCancel | translate}}
      </button>
      <button class="c4p-button sidesheet-mark-button" type="submit" *ngIf="tooltipData.confirmAction?.textOk"
              (click)="onExecuteButtonAction(true)">
        <img src="/assets/icons/check-white.svg" alt="" />
        {{ tooltipData.confirmAction?.textOk | translate }}
      </button>
    </div>
  </span>
</ng-template>
