<div *ngIf="!labelValue" class="c4p-form-input{{ width }}">
  <span class="c4p-form-label">{{ label | translate }}</span>
</div>

<div *ngIf="labelValue" class="c4p-form-input{{ width }} labelValueContainer" [style.margin-left]="marginLeft">
  <mat-label
    class="labelTitle"
    matTooltip="{{ labelValue.label | translate }}"
    >{{ labelValue.label | translate }}</mat-label
  >
  <mat-label
    *ngIf="labelValue.value"
    [ngClass]="isValid ? 'labelValue' : 'labelValue invalidValue'"
    matTooltip="{{ labelValue.value | translate }}"
    >{{ labelValue.value | translate }}</mat-label
  >
  <mat-label *ngIf="!labelValue.value" class="labelValue">-</mat-label>
</div>
