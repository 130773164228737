import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ITableOption} from 'c4p-portal-util';

interface SummaryData {
  fullName: string;
  createdAt: Date;
}

@Component({
  selector: 'app-signature-action-summary',
  templateUrl: './signature-action-summary.component.html',
})
export class SignatureActionSummaryComponent implements OnInit {
  revokeLog: SummaryData[];
  resendLog: SummaryData[];
  public revokeTableOptions: ITableOption = null!;
  public resendTableOptions: ITableOption = null!;

  constructor(
    public dialogRef: MatDialogRef<SignatureActionSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
    this.getSummaryData();
    this.revokeTableOptions = this.buildRevokeTable();
    this.resendTableOptions = this.buildResendTable();
  }

  getSummaryData(): any {
    if(this.data.sharingData.length > 0) {
      const log = this.data.sharingData.find(sharingData => sharingData.userId === this.data.data.userId);
      this.revokeLog = log.revoke;
      this.resendLog = log.resend;
    }
  }

  buildRevokeTable(): ITableOption {
    return {
      name: 'document.labels.RevokeLog',
      titleIcon: '',
      data: this.revokeLog,
      columns: this.getColumns(),
      inlineActions: [],
      menuActions: [],
      showHeader: true,
      showSearch: true,
      showPagination: false,
      externPagination: false,
      defaultPageSize: 20,
      defaultSortColumn: 'createdAt',
      defaultSortDirection: 'desc',
    };
  }

  buildResendTable(): ITableOption {
    return {
      name: 'document.labels.NotifyLog',
      titleIcon: '',
      data: this.resendLog,
      columns: this.getColumns(),
      inlineActions: [],
      menuActions: [],
      showHeader: true,
      showSearch: true,
      showPagination: false,
      externPagination: false,
      defaultPageSize: 20,
      defaultSortColumn: 'createdAt',
      defaultSortDirection: 'desc',
    };
  }

  private getColumns() {
    return [
      {
        id: 'fullName',
        name: 'document.labels.fullName',
        objectProperty: 'fullName',
        type: 'string',
        width: 80,
      },
      {
        id: 'createdAt',
        name: 'document.labels.createdAt',
        type: 'date',
        format: 'datetime',
        width: 50,
      },
    ];
  }

}
