import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class GoalObjectiveService {
  public values: IFormDropdownOption[] = [];

  constructor(private masterDataService: MasterDataService) {
    this.getGoalObjectiveValues();
  }

  public getGoalObjectiveValues() {
    this.masterDataService
      .getMasterData('goal-objective')
      .subscribe((data) => (this.values = data));
  }
}
