import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  Optional,
  Self,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControlBase } from '../abstract-form-control';
import {
  DEFAULT_MIN_DATE,
  DEFAULT_MAX_DATE,
  DATE_REGEX,
  DEFAULT_DATEPICKER_ERRORS_ARRAY,
  DEFAULT_MOMENT_DATE_FORMAT,
} from '../form-controls.const';
import * as moment_ from 'moment';
import { DateInputErrorStateMatcher } from '../form-control.error-matcher';
import { NgControl } from '@angular/forms';
import { UTC_TIMEZONE } from './../form-controls.const';
const moment = moment_;

import { MatDatepicker } from '@angular/material/datepicker';
import { TENANT_PIPE_FORMAT } from '../../pipes/pipes.const';
import { CustomDateTimePipe } from '../../pipes';

@Component({
  selector: 'app-form-input-date',
  templateUrl: './form-input-date.component.html',
  styleUrls: ['./form-input-date.component.scss'],
})
export class FormInputDateComponent extends FormControlBase<FormInputDateComponent> {
  static DEFAULT_DATEPICKER_ERRORS_ARRAY = DEFAULT_DATEPICKER_ERRORS_ARRAY;
  get DEFAULT_DATEPICKER_ERRORS() {
    return FormInputDateComponent.DEFAULT_DATEPICKER_ERRORS_ARRAY;
  }
  @Input() minDate = DEFAULT_MIN_DATE;
  @Input() maxDate = DEFAULT_MAX_DATE;
  @Input() startView: string = 'month';

  // dateFilter accepts a function with date parameter and should return a boolean value to determine whether to disable or enable dates
  // it can be used in conjunction with minDate and maxDate
  @Input() dateFilter: any;
  @Input() readOnly = false;
  @Input() isDisabled: boolean = false;
  @Input() dbclickNull = false;
  @Output() openedDateStream: EventEmitter<void> = new EventEmitter<void>();
  @Output() closedDateStream: EventEmitter<void> = new EventEmitter<void>();
  @Output() changeDate: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('datePickerInput') datePickerInput: ElementRef;
  onKeydown = DATE_REGEX;

  // something is off with the material datepickers in the current cdk version 10.2.3
  // they don't work with formControls but do with ngModel
  // keeping this here until they fix it
  private _innerValue;
  get value() {
    return this._innerValue;
  }
  set value(value: any) {
    if (value !== this.value) {
      this._innerValue = value;
      this.onChangeCallback && this.onChangeCallback(value);
    }
    this.stateChanges.next();
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public dateTimePipe: CustomDateTimePipe,
  ) {
    super(ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
      this.errorStateMatcher = new DateInputErrorStateMatcher(this.ngControl);
    }
  }

  writeValue(value) {
    if (this.datePickerInput?.nativeElement && value === null) {
      // remove leftover strings when reseting the form
      this.datePickerInput.nativeElement.value = '';
      // set null value to remove default datepicker errors
      this.valueControl.setValue(null);
    }
    super.writeValue(value);
  }

  public setNow() {
    // const now = USE_UTC ? moment.utc() : moment();
    // // remove leftover strings when reseting the form
    // this.datePickerInput.nativeElement.value = now.local().format('DD/MM/YYYY HH:mm');
    // // set null value to remove default datepicker errors
    // this.value = USE_UTC ? now.utc() : now;
    const date = this.dateTimePipe
      .transform(moment(), 'noFormat')
      .format(DEFAULT_MOMENT_DATE_FORMAT);
    this.value = this.dbclickNull ? this.value : moment.tz(date, UTC_TIMEZONE);
    this.valueChangeHandler(null);
  }

  onOpenedDateStream(): void {
    this.openedDateStream.emit();
  }

  onClosedDateStream(): void {
    this.closedDateStream.emit();
  }

  chosenMonthHandler(
    normalizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>,
  ) {
    if (this.startView == 'multi-year') {
      normalizedMonth.date(1);
      datepicker.select(normalizedMonth);
      datepicker.close();
    }
  }

  onChangeDate() {
    this.changeDate.emit();
  }

  transformMaxError(value) {
    return moment(value).clone().add(1, 'day');
  }

  transformaMinError(value) {
    return moment(value).clone().subtract(1, 'day');
  }

  valueChangeHandler(event$) {
    let dbClick = false;
    if (!event$) {
      dbClick = true;
    }
    const event = event$?.target.value
      ? moment(event$.target.value, TENANT_PIPE_FORMAT)
      : this.value;
    if (!event) return;
    if (!moment(event, 'DD/MM/YYYY', true).isValid()) {
      this.ngControl.control.setErrors({ matDatepickerParse: event });
      return;
    }
    let maximumDate;
    const minimumDate = moment(this.getMinDate).clone().subtract(1, 'day');
    if (dbClick) {
      maximumDate = moment(this.getMaxDate).clone().add(1, 'day');
    } else {
      maximumDate = moment(this.getMaxDate).clone();
    }
    if (minimumDate && event.isSameOrBefore(minimumDate)) {
      this.ngControl.control.setErrors({
        matDatepickerMin: { min: minimumDate },
      });
    } else {
      if (
        this.ngControl.control?.errors &&
        this.ngControl.control?.errors['matDatepickerMin']
      ) {
        delete this.ngControl.control.errors['matDatepickerMin'];
        if (Object.keys(this.ngControl.control.errors).length === 0) {
          this.ngControl.control.setErrors(null);
        }
        this.ngControl.control.updateValueAndValidity();
      }
    }
    if (maximumDate && event.isSameOrAfter(maximumDate)) {
      this.ngControl.control.setErrors({
        matDatepickerMax: { max: maximumDate },
      });
    } else {
      if (
        this.ngControl.control?.errors &&
        this.ngControl.control?.errors['matDatepickerMax']
      ) {
        delete this.ngControl.control.errors['matDatepickerMax'];
        if (Object.keys(this.ngControl.control.errors).length === 0) {
          this.ngControl.control.setErrors(null);
        }
        this.ngControl.control.updateValueAndValidity();
      }
    }
  }
  get getMinDate() {
    if (!this.minDate) {
      this.minDate = DEFAULT_MIN_DATE;
    }
    const val = this.dateTimePipe
      .transform(this.minDate, 'noFormat')
      .format(DEFAULT_MOMENT_DATE_FORMAT);
    return moment.tz(val, UTC_TIMEZONE);
  }

  get getMaxDate() {
    if (!this.maxDate) {
      this.maxDate = DEFAULT_MAX_DATE;
    }
    const val = this.dateTimePipe
      .transform(this.maxDate, 'noFormat')
      .format(DEFAULT_MOMENT_DATE_FORMAT);
    return moment.tz(val, UTC_TIMEZONE);
  }
}
