import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IFormDropdownOption } from '../../../form-controls/form-controls.const';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyTypeService {
  public lookupValues: string[] = [];

  public get values() {
    return this.values$.value;
  }

  public values$ = new BehaviorSubject<IFormDropdownOption[]>(null);

  constructor(private masterDataService: MasterDataService) {
    this.getCompanyTypes();
  }

  public getCompanyTypes() {
    this.masterDataService.getMasterData('company-types').subscribe((data) => {
      this.values$.next(data);
      for (let item of this.values) {
        this.lookupValues[item.value] = item.label;
      }
    });
  }
}
