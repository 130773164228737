import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SocialAreaHelper } from '../social-area.helper';
import { SocialAreaService } from '../social-area.service';
import {
  MainGoalModel,
  SubGoalModel,
} from '../../../models/social-area/social-area.model';
import { ObjectiveOverviewService } from '../objective-overview.service';

@Component({
  selector: 'app-goal-config-form',
  templateUrl: './goal-config-form.component.html',
  styleUrls: ['./goal-config-form.component.scss'],
})
export class GoalsConfigFormComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<boolean>();

  socialAreaHelper: SocialAreaHelper = new SocialAreaHelper(
    this.formBuilder,
    this.destroyed$,
  );

  formArray = null!;

  public form: FormGroup = null!
  buildForm(){
    return this.formBuilder.group({
      additionalInfo: [],
    });
  }

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<GoalsConfigFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private socialAreaService: SocialAreaService,
    public objectiveOverviewService: ObjectiveOverviewService,
  ) {}

  ngOnInit(): void {
    this.form = this.buildForm();
    this.form.patchValue(this.data);
    this.formArray = this.formBuilder.array([]);
    this.socialAreaService
      .getCompleteMainGoals(this.data.refSocialAreaId)
      .subscribe((refMainGols) => {
        let existingGoals = JSON.parse(JSON.stringify(this.data.mainGoals));
        this.mergeMainGoals(existingGoals, refMainGols);
        this.formArray =
          this.socialAreaHelper.buildGoalFormArray(existingGoals);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public onDismiss(): void {
    this.dialogRef.close(null);
  }

  public onConfirm(): void {
    if (this.formArray.invalid) {
      this.formArray.markAllAsTouched();
    } else {
      let selectedMainGoals = this.filterMainGoals();
      this.dialogRef.close({
        mainGoals: selectedMainGoals,
        additionalInfo: this.form.controls['additionalInfo'].value,
      });
    }
  }

  private filterMainGoals(): MainGoalModel[] {
    let selectedMainGoals: MainGoalModel[] = this.formArray.value.filter(
      (x) => x.selected,
    );
    for (let selectedMainGoal of selectedMainGoals) {
      selectedMainGoal.subGoals = selectedMainGoal.subGoals.filter(
        (x) => x.selected,
      );
    }
    return selectedMainGoals;
  }

  private mergeMainGoals(
    mainGoals: MainGoalModel[],
    refMainGoals: MainGoalModel[],
  ): void {
    for (let refMainGoal of refMainGoals) {
      let i = mainGoals.findIndex((el) => el.refId == refMainGoal.id);
      if (i >= 0) {
        mainGoals[i].isNew = false;
        mainGoals[i].isRenamed = false;
        if (mainGoals[i].name != refMainGoal.name) {
          mainGoals[i].name = refMainGoal.name;
          mainGoals[i].isRenamed = true;
        }
        this.mergeSubGoals(mainGoals[i].subGoals, refMainGoal.subGoals);
      } else {
        refMainGoal.refId = refMainGoal.id;
        refMainGoal.isNew = true;
        refMainGoal.isRenamed = false;
        this.setSubGoalsToNew(refMainGoal.subGoals);
        mainGoals.push(refMainGoal);
      }
    }
  }

  private setSubGoalsToNew(subGoals: SubGoalModel[]): void {
    for (let subGoal of subGoals) subGoal.isNew = true;
  }

  private mergeSubGoals(
    subGoals: SubGoalModel[],
    refSubGoals: SubGoalModel[],
  ): void {
    for (let refSubGoal of refSubGoals) {
      let i = subGoals.findIndex((el) => el.refId == refSubGoal.id);
      if (i >= 0) {
        subGoals[i].isNew = false;
        subGoals[i].isRenamed = false;
        if (subGoals[i].name != refSubGoal.name) {
          subGoals[i].name = refSubGoal.name;
          subGoals[i].isRenamed = true;
        }
      } else {
        refSubGoal.refId = refSubGoal.id;
        refSubGoal.isNew = true;
        refSubGoal.isRenamed = false;
        subGoals.push(refSubGoal);
      }
    }
  }
}
