import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserInfoService } from '../../services';
import { CollaborationService } from '../../services/collaboration-service/collaboration.service';
import { CollaborationUsersService } from './collaboration-users.service';

@Component({
  selector: 'app-collaboration-users',
  templateUrl: './collaboration-users.component.html',
  styleUrls: ['./collaboration-users.component.scss'],
  providers: [CollaborationUsersService],
})
export class CollaborationUsersComponent implements OnInit, OnDestroy {
  @Input() docIdentifier: string;

  private userSubscription: Subscription;

  users: any[] = [];

  constructor(
    private collaborationService: CollaborationService,
    public userInfoService: UserInfoService,
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.collaborationService.userListSubject.subscribe(
      (data) => {
        if (data && data.docIdentifier == this.docIdentifier) {
          this.users = data.userList.slice().reverse();
        }
      },
    );
  }

  ngOnDestroy(): void {
    if (this.userSubscription) this.userSubscription.unsubscribe();
  }
}
