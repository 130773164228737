import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';

export enum DropoutReasons {
  reason1 = 'reason 1',
  reason2 = 'reason 2',
}

@Injectable({
  providedIn: 'root',
})
export class DropoutReasonService {
  public values: IFormDropdownOption[] = [
    { label: 'billing.labels.reason1', value: DropoutReasons.reason1 },
    { label: 'billing.labels.reason2', value: DropoutReasons.reason2 },
  ];

  public lookupValues: string[] = [];

  constructor() {
    for (let i = 0; i < this.values.length; i++) {
      this.lookupValues[i] = this.values[i].label;
    }
    for (const item of this.values) {
      this.lookupValues[item.value] = item.label;
    }
  }
}
