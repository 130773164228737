import { PharmacyAddressModel } from './pharmacy-address.model';
import {PharmacyTypeModel} from './pharmacyType.model';

export class PharmacyModel {
  id: string;
  pharmacyTypeId?: PharmacyTypeModel;
  pharmacyType?: string;
  name: string;
  code: string;
  visit: PharmacyAddressModel;
  deliveryAddress: PharmacyAddressModel;
  email: string;
  phone: string;
  fax: string;
  website: string;
  globalLocationNumber: string;
  distributionRequestPrint: boolean;
  distributionRequestLsp: boolean;
  lspNumber?: string;
  uraCode: string;
  agbCode: string;
  label: string;
}
