import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-value',
  templateUrl: './value.component.html',
  styleUrls: ['./value.component.scss'],
})
export class ValueComponent implements OnInit {
  @Input() label: string = '';
  @Input() value: string = '';
  @Input() labelPosition: string = 'left';

  constructor() {}

  ngOnInit(): void {}
}
