<app-panel type="popup" style="min-width: 800px">
  <app-panel-title title="carefile.titles.GoalConfiguration" titleIcon="group">
  </app-panel-title>

  <div class="c4p-form-goals-popup-container">
    <app-panel-form-row [formGroup]="form">
      <app-form-input-text-area
        formControlName="additionalInfo"
        placeholder="socialarea.labels.AdditionalInformation"
        style="width: 100%; margin-right: 16px"
      ></app-form-input-text-area>
    </app-panel-form-row>
    <div *ngFor="let control of formArray.controls; let i = index">
      <ng-container [formGroup]="control">
        <app-panel-form-row>
          <span style="flex-wrap: nowrap; display: flex">
            <mat-icon
              class="c4p-action-icons"
              *ngIf="control.value.isRenamed"
              style="position: relative; top: 16px; width: 14px"
              >autorenew</mat-icon
            >
            <span *ngIf="!control.value.isRenamed" style="width: 14px"></span>

            <app-form-input-checkbox
              [placeholder]=""
              formControlName="selected"
              width="-nowidth"
            ></app-form-input-checkbox>

            <app-label
              [label]="control.value.name"
              width="-nowidth"
            ></app-label>

            <app-form-input-dropdown
              *ngIf="control.value.selected == true"
              width="-nowidth"
              formControlName="startingLevel"
              placeholder="carefile.labels.StartingPoint"
              [options]="objectiveOverviewService.values"
              blank="true"
            ></app-form-input-dropdown>

            <app-form-input-dropdown
              *ngIf="control.value.selected == true"
              width="-nowidth"
              formControlName="objective"
              placeholder="carefile.labels.Objective"
              [options]="objectiveOverviewService.values"
              blank="true"
            ></app-form-input-dropdown>
          </span>
        </app-panel-form-row>
        <app-panel-form-row *ngIf="control.value.selected == true">
          <div [formGroup]="control.get('subGoals')">
            <div
              *ngFor="
                let subcontrol of control.get('subGoals')['controls'];
                let j = index
              "
              style="margin-left: 32px"
            >
              <ng-container [formGroup]="subcontrol">
                <app-panel-form-row>
                  <span style="flex-wrap: nowrap; display: flex">
                    <mat-icon
                      class="c4p-action-icons"
                      *ngIf="subcontrol.value.isRenamed"
                      style="position: relative; top: 16px; width: 14px"
                      >autorenew</mat-icon
                    >
                    <span
                      *ngIf="!subcontrol.value.isRenamed"
                      style="width: 14px"
                    ></span>

                    <app-form-input-checkbox
                      [placeholder]=""
                      formControlName="selected"
                      width="-nowidth"
                    ></app-form-input-checkbox>

                    <app-label
                      [label]="subcontrol.value.name"
                      width="-nowidth"
                    ></app-label>

                    <app-form-input-dropdown
                      *ngIf="subcontrol.value.selected == true"
                      width="-nowidth"
                      formControlName="startingLevel"
                      placeholder="carefile.labels.StartingPoint"
                      [options]="objectiveOverviewService.values"
                      blank="true"
                    ></app-form-input-dropdown>

                    <app-form-input-dropdown
                      *ngIf="subcontrol.value.selected == true"
                      width="-nowidth"
                      formControlName="objective"
                      placeholder="carefile.labels.Objective"
                      [options]="objectiveOverviewService.values"
                      blank="true"
                    ></app-form-input-dropdown>
                  </span>
                </app-panel-form-row>
              </ng-container>
            </div>
          </div>
        </app-panel-form-row>
      </ng-container>
    </div>
  </div>

  <div style="display: flex">
    <span class="c4p-button-cancel" (click)="onDismiss()" translate
      >general.labels.Cancel</span
    >
    <span class="c4p-button-ok" (click)="onConfirm()">
      <span>OK</span>
    </span>
  </div>
</app-panel>
