import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import {DIGITAL_SIGNATURE, ITableOption} from 'c4p-portal-util';
import { TranslateService } from '@ngx-translate/core';
import * as saveAs from 'file-saver';
import { DigitalSignatureService } from '../../service/signature/digital-signature.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { DocumentViewComponent } from '../../components/document-view/document-view.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'lib-document-signature-log-detail',
  templateUrl: './document-signature-log-detail.component.html',
})
export class DocumentSignatureLogDetailComponent {
  public tableOptions: ITableOption = null!;
  private readonly destroyed$ = new Subject<boolean>();

  @Input() data: any;
  @Input() fileData: any;
  @Output() closeDetailComp = new EventEmitter<boolean>();
  @Input() tableSize: any;

  private dataSubject: BehaviorSubject<any>;

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private digitalSignatureService: DigitalSignatureService,
  ) {
    this.dataSubject = new BehaviorSubject<any>({
      data:this.data
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.closeDetailComp.emit(true)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.data = changes.data.currentValue;
    this.dataSubject.next({
      data: this.data
    });
    this.fileData = changes.fileData.currentValue;
  }


  onCloseDialog(): void {
    this.closeDetailComp.emit(true)
  }

  viewFile(version: string){
    const signatureKey = this.data.signatureKey;
    this.digitalSignatureService.downloadDocument(signatureKey, version).subscribe((res) => {
      const blob = new Blob([res], { type: 'application/pdf' });
      const documentUrl = URL.createObjectURL(blob);
      this.dialog.open(DocumentViewComponent, {
        data: { documentUrl, document },
      });
    });
  }
  onClickButton(version: string): void {
    const file = this.data.originalVersion.documentName.replace(DIGITAL_SIGNATURE.PDF, '');
    const timestamp = new Date().getTime();
    const fileName = `${file}_${version}_${timestamp}${DIGITAL_SIGNATURE.PDF}`;
    const signatureKey = this.data.signatureKey;
    this.digitalSignatureService.downloadDocument(signatureKey, version).subscribe((res) => {
      const blob = new Blob([res], { type: 'application/pdf' });
      saveAs(blob, fileName);
    });
  }

  protected readonly DIGITAL_SIGNATURE = DIGITAL_SIGNATURE;
}
