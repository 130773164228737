import { AssignedQuestionModel } from './assigned-question.model';

export class QuestionSetModel {
  id!: number;
  title!: string;
  type!: number;
  description: string;
  autoInsert!: boolean; // default should be false
  assignedQuestions: AssignedQuestionModel[] | null | undefined;
  docGenCode: string | null | undefined;
  createdAt!: Date;
  createdBy!: number | string; // user id
  updatedAt: Date | null | undefined;
  updatedBy: number | string | null | undefined;
  roles: {
    read: string[] | [];
    readWrite: string[] | [];
  };
  services: string[];
  isAssigned?: boolean;
  externalFormat?: {
    externalFormatEnabled?: boolean;
    externalFormatClientId?: string;
  };
}
