import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-crisis-widget',
  templateUrl: './empty.widget.html',
  styleUrls: ['./empty.widget.scss'],
})
export class EmptyWidget implements OnInit {
  @Input() id: string;
  @Input() data: any;
  @Output() refreshWidget = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
