import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientShareModel, DocumentModel, DocumentService } from 'c4p-portal-util';

@Component({
  selector: 'lib-document-shared-history',
  templateUrl: './document-shared-history.component.html',
  styleUrls: ['./document-shared-history.component.css']
})
export class DocumentSharedHistoryComponent implements OnInit{
    @Input() document: DocumentModel;
    @Output() documentUpdated:EventEmitter<DocumentModel> = new EventEmitter();
    sharedPersons: ClientShareModel[];
    viaEmailPersons: any;
    internalAndExternalSharedPersons: any;
    step:number;
    constructor(private documentService: DocumentService) {

    }

  openAccordion(index) {
    this.step = index;
  }

  ngOnInit(): void {
    this.sharedPersons = this.document.sharing;
    const viaEmailList = [];
    const internalAndExternalList = [];
    this.sharedPersons?.forEach(person => {
      person.sharingType && person.sharingType === 'viaEmail' ? viaEmailList.push(person) : internalAndExternalList.push(person);
    })

    this.viaEmailPersons = viaEmailList?.map((person:ClientShareModel) => {
      return {
        title: [
          person.fullName,
          person.shareDate,
          person.identificationMethods.bsn,
          person.identificationMethods.phoneNumber,
          person.identificationMethods.birthYear,
          person.identificationMethods.withEmail,
          person.identificationMethods.withEmailCode,
          person.signatureRequired
        ],
        ...person
      }
    })

    this.internalAndExternalSharedPersons = internalAndExternalList?.map((person:ClientShareModel) => {
      return {
        title: [
          person.fullName,
          person.shareDate,
          person.signatureRequired
        ],
        ...person
      }
    })
  }

  onSelectionChange(event,person:ClientShareModel){
      const updatedPerson = this.document.sharing.find(shared => shared.userId === person.userId);
      updatedPerson.signatureRequired = event;
      if(updatedPerson){
        console.log(updatedPerson);
        this.documentUpdated.emit(this.document);
      }
  }
}
