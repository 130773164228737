export class IndividualInvolvementModel {
  id!: string;
  tenantId!: string;
  carefileId!: string;

  carefileService: any;
  employee: any;
  function: string;
  startDate: Date;
  endDate?: Date;
  maxTravelTime: any;

  type: string;
  client: { id: string; fullName: string };
  clientNetwork: { id: string; fullName: string };
  email: string;
  carefileDataFrom: Date;

  status!: string;
  createdAt!: Date;
  createdBy!: string;
  updatedAt: Date;
  updatedBy: string;
}
