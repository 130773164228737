import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { PermissionService } from '../../services/permissions/permission.service';
import { IFormDropdownOption } from '../../form-controls';
import { UserInfoService } from '../../services/user-info/user-info.service';
import { SidebarService } from '../../services/sidebar/sidebar.service';
import { HelperService } from '../../services/helper/helper.service';

const ROLES_EXCLUDED_FROM_DROPDOWN = ['uma_authorization', 'offline_access', 'default-roles-'];
@Component({
  selector: 'app-role-picker',
  templateUrl: './role-picker.component.html',
  styleUrls: ['./role-picker.component.scss'],
})
export class RolePickerComponent implements OnInit {
  public roleOptions: IFormDropdownOption[];
  public selectedRoleIndex: number;
  public hasRole: boolean;

  constructor(
    public dialogRef: MatDialogRef<RolePickerComponent>,
    private permissionService: PermissionService,
    private userInfoService: UserInfoService,
    private keycloakService: KeycloakService,
    private router: Router,
    private sidebarService: SidebarService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  ngOnInit(): void {
    this.hasRole = !!this.permissionService.currentRole;
    this.getUserRoles();
  }

  private getUserRoles(): void {
    this.permissionService.userRoles$.subscribe((userRoles: string[]) => {
      if (userRoles) {
        userRoles = userRoles.filter(
          (role) => !ROLES_EXCLUDED_FROM_DROPDOWN.some((excludedRole) => role.includes(excludedRole),
        ));
        this.roleOptions = userRoles
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map((role, index) => {
            return { label: role, value: index };
          });
      }
    });
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.keycloakService.getToken().then((tokenData) => {
      const token = HelperService.parseJwt(tokenData);
      this.permissionService.currentRole =
        this.roleOptions[this.selectedRoleIndex].label;
      this.permissionService.initRolePermissions(tokenData).subscribe(() => {
        // only then pull the info from the employee microservice
        this.userInfoService.initUserInfo(token);
        this.sidebarService.setSidebarItems(
          this.sidebarService.sidebarItems$.value,
        );
        this.dialogRef.close(true);
        this.router.navigate(['/']);
      });
    });
  }
}
