export class MedicationModel {
  medicationProfile: boolean;
  height: string;
  heightType: string;
  weight: string;
  weightType: string;
  pharmacy: any;
  action: string;
  status: string;
  errorMessage: string;
  errorCode: string;
  createdAt: Date;
  updatedAt: Date;
  displayName: string;
}
