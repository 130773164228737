import { HttpParams } from '@angular/common/http';
import * as moment_ from 'moment';
import { DocumentModel } from '../document';
import { PaginationDataModel } from '../models';
const moment = moment_;

export class HttpUtils {
  static getPaginationAndFilterParams(
    paginationData: PaginationDataModel,
    filterData: any,
    ...ignoredProperties: string[]
  ): HttpParams {
    if (ignoredProperties) {
      const filterDataCopy = { ...filterData };
      ignoredProperties.forEach((property) => {
        delete filterDataCopy[property];
      });
      filterData = filterDataCopy;
    }
    let params = HttpUtils.getPaginationParams(paginationData);
    params = HttpUtils.getFilterParams(filterData, params);
    return params;
  }

  static getPaginationParams(
    paginationData: PaginationDataModel,
    existingParams?: HttpParams,
  ): HttpParams {
    let params = existingParams ? existingParams : new HttpParams();
    params = paginationData.pageIndex
      ? params.set('page', (paginationData.pageIndex + 1).toString())
      : params.set('page', '1');
    params = paginationData.pageSize
      ? params.set('limit', paginationData.pageSize.toString())
      : params.set('limit', '10');
    params = params.set('sortColumn', paginationData.sortColumn);
    params = params.set('sortDirection', paginationData.sortDirection);
    if (paginationData.search)
      params = params.set('search', paginationData.search);
    return params;
  }

  static getFilterParams(object: any, existingParams?: HttpParams): HttpParams {
    let params = existingParams ? existingParams : new HttpParams();
    for (const property in object) {
      let value = object[property];
      if (value != null) {
        if (moment.isMoment(value)) {
          value = new Date(value.format()).toUTCString();
        }
        // encode objects
        if (typeof value === 'object' && !(value instanceof Array)) {
          // delete undefined properties
          Object.keys(value).forEach((key) => {
            if (value[key] == undefined || value[key] == null)
              delete value[key];
          });
          // only if there are properties left
          if (Object.keys(value).length > 0)
            params = params.set(
              property,
              encodeURIComponent(JSON.stringify(value)),
            );
          continue;
        } else if (value instanceof Array) {
          value = value.join(',');
        }
        if (value != null || value != '') {
          params = params.set(property, value);
        }
      }
    }
    return params;
  }

  static downloadObject(object: any) {
    var sJson = JSON.stringify(object);
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/json;charset=UTF-8,' + encodeURIComponent(sJson),
    );
    element.setAttribute('download', 'primer-server-task.json');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  static downloadDocumentFromLink(
    link: string,
    documentObject: DocumentModel | any,
  ) {
    const element = document.createElement('a');
    element.setAttribute('href', link);
    element.setAttribute('download', documentObject.fileData?.fileName);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  static isMobileAgent() {
    return window.innerWidth < 1000;

    // return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent);
  }

  static calculateFileSize(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
