<app-panel type="popup">
  <app-panel-title
    title="carefile.titles.SocialAreaConfiguration"
    titleIcon="group"
  >
  </app-panel-title>

  <div class="c4p-form-goals-popup-container">
    <div *ngFor="let control of formArray.controls; let i = index">
      <ng-container [formGroup]="control">
        <app-panel-form-row>
          <mat-icon
            class="c4p-action-icons"
            *ngIf="control.value.isRenamed"
            style="position: relative; top: 9px; width: 14px"
            >autorenew</mat-icon
          >
          <span *ngIf="!control.value.isRenamed" style="width: 14px"></span>

          <app-form-input-checkbox
            [placeholder]=""
            formControlName="selected"
            width="-nowidth"
          ></app-form-input-checkbox>
          <app-label [label]="control.value.name" width="-nowidth"></app-label>
        </app-panel-form-row>
      </ng-container>
    </div>
  </div>

  <div style="display: flex">
    <span class="c4p-button-cancel" (click)="onDismiss()" translate
      >general.labels.Cancel</span
    >
    <span class="c4p-button-ok" (click)="onConfirm()">
      <span>OK</span>
    </span>
  </div>
</app-panel>
