import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productCodes',
})
export class ProductCodePipe implements PipeTransform {
  constructor() {}
  transform(value: {
    internalProduct: {
      id: string;
      name: string;
      code: string;
      category: string;
    };
    externalProduct: {
      id: string;
      name: string;
      code: string;
      category: string;
    };
  }): string {
    const internalCode = value.internalProduct?.code;
    const externalCode = value.externalProduct?.code;
    const codes: string[] = [];
    if (externalCode) {
      codes.push(externalCode);
    }
    if (internalCode) {
      codes.push(internalCode);
    }
    return codes.join(' / ');
  }
}
