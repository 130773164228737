import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {
  // Possible values are: active, inactive, draft, deleted, open, closed.
  // Every value will be presented in a different color
  // (any new value should be added to style.sccs as .c4p-status-icon-newvalue)
  @Input() value: string = '';

  constructor() {}

  ngOnInit(): void {}
}
