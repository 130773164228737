import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  COLUMN_RADIO_DIRECTION,
  DEFAULT_RADIO_DIRECTION,
  FormControlBase,
  IFormDropdownOption,
} from '../../../form-controls';

@Component({
  selector: 'app-single-choice',
  templateUrl: './single-choice.component.html',
  styleUrls: ['./single-choice.component.css'],
})
export class SingleChoiceComponent
  extends FormControlBase<SingleChoiceComponent>
  implements OnInit
{
  @Input() options: IFormDropdownOption[];
  @Input() direction = COLUMN_RADIO_DIRECTION;
  @Input() hint: string;
  @Input() isClientPortal = false;
  @Input() orderNum: number;
  @Output() tabEvent = new EventEmitter();

  ngDoCheck(): void {
    this.adjustTouched();
  }

  onTab(el: HTMLElement) {
    this.tabEvent.emit(el.offsetHeight);
  }
}
