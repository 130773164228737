import { AbstractControl, ValidatorFn } from '@angular/forms';

export function numberModuloValidator(mod: number): ValidatorFn {
  return (
    control: AbstractControl,
  ): { [key: string]: boolean | number } | null => {
    const number = Number(control.value);
    const valid = number !== undefined && number % mod === 0;
    if (valid == false) {
      return {
        modulo: mod,
      };
    } else {
      return null;
    }
  };
}
