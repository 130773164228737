import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-notification-tooltip',
  templateUrl: './notification-tooltip.component.html',
  styleUrls: ['./notification-tooltip.component.scss'],
})
export class NotificationTooltipComponent implements OnInit {

  @Output() closeTooltip: EventEmitter<boolean> =  new EventEmitter<boolean>();
  @Output() markAllAsRead: EventEmitter<any> =  new EventEmitter<any>();
  @Input() notificationCount: number;
  @Input() notificationLimit: number;
  notificationAllMark: boolean = false;
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.closeTooltip.emit(true);
    }
  }
  constructor(private eRef: ElementRef) {}

  ngOnInit(): void {}

  onMarkAllAsRead(){
    const unReadNotification = {
      unreadNotificationOnPage: true,
      readAll: this.notificationAllMark
    }
    this.markAllAsRead.emit(unReadNotification);
  }

  onClose() {
    this.closeTooltip.emit(true);
  }

}
