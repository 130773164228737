import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tuula-table-action-button',
  templateUrl: './tuula-table-action-button.component.html',
  styleUrl: './tuula-table-action-button.component.css'
})
export class TuulaTableActionButtonComponent {

  @Input() name: string = '';
  @Input() icon: string = '';
  @Input() tooltip: string = '';
  @Input() disabled: boolean = false;
  @Input() row: any;
  @Output() click = new EventEmitter();
  @ViewChild('actionTemplate', {static : true}) template: TemplateRef<any>;
  overflow: boolean = false;

  constructor() { }

  handleClick() {
    this.click.emit(this.row);
  }

}
