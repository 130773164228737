import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PageMenuService } from '../../services/page-menu/page-menu-service.service';

export interface IMenuItems {
  icon: string;
  tooltip: string;
  method: any;
  disabled?: boolean;
  id?: string;
}

export interface IAdditionalMenu {
  name: string;
  menuItems: IMenuItems[];
}

// NEEDED A COPY SO IT USES A DIFFERENT PERMISSION FUNCTION REQUIRED FOR CAREFILES
@Component({
  selector: 'app-carefile-page-menu',
  templateUrl: './carefile-page-menu.component.html',
  styleUrls: ['./carefile-page-menu.component.scss'],
})
export class CarefilePageMenuComponent implements OnInit, OnDestroy {
  @Input() items: IMenuItems[];
  menuItemsSubscription: Subscription;
  additionalMenuSubscription: Subscription;

  menuItems: IMenuItems[] = [];
  inlineItems: IMenuItems[] = [];
  additionalMenus: IAdditionalMenu[] = [];

  constructor(private pageMenuService: PageMenuService) {}

  ngOnInit(): void {
    this.menuItemsSubscription = this.pageMenuService.menuItems$
      .pipe(debounceTime(0))
      .subscribe((menuItems) => {
        this.items = menuItems;
        this.inlineItems = [];
        this.menuItems = [];
        let i = 0;
        if (this.items)
          for (let item of this.items) {
            if (i < 3) this.inlineItems.push(item);
            else this.menuItems.push(item);
            i++;
          }
      });

    this.additionalMenuSubscription = this.pageMenuService.additionalMenus$
      .pipe(debounceTime(0))
      .subscribe((menus) => {
        this.additionalMenus = menus;
      });
  }

  ngOnDestroy(): void {
    if (this.menuItemsSubscription) this.menuItemsSubscription.unsubscribe();
    if (this.additionalMenuSubscription)
      this.additionalMenuSubscription.unsubscribe();
  }
}
