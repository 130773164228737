// export class FilterSettingsModel {
//   key!: string;
//   value!: string;
// }

// export class PageSettingsModel {
//   name!: string;
//   filters: {};
// }

export class UserSettingsModel {
  id!: string;
  employeeId!: string;
  tenantId!: string;
  pages: any;
}
