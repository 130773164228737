import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import _ from 'lodash';

@Component({
  selector: 'app-page-child-tabs',
  templateUrl: './page-child-tabs.component.html',
  styleUrls: ['./page-child-tabs.component.scss'],
})
export class PageChildTabsComponent implements OnInit, OnChanges, OnDestroy {
  navLinks = [];

  @Input() childTabsInvalid$: Observable<{ [path: string]: boolean }>;
  @Input() excludedRoutes: string[] = [];
  public validationResults: { [path: string]: boolean } = null;

  private validationSubscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.navLinks = this.buildNavItems(
      this.activatedRoute.routeConfig.children,
    );
    if (this.childTabsInvalid$) {
      this.validationSubscription = this.childTabsInvalid$.subscribe(
        (result) => {
          if (result) {
            this.validationResults = result;
          } else {
            this.validationResults = null;
          }
        },
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !_.isEqual(
        changes.excludedRoutes?.currentValue?.sort(),
        changes.excludedRoutes?.previousValue?.sort(),
      )
    ) {
      this.navLinks = this.buildNavItems(
        this.activatedRoute.routeConfig.children,
      );
    }
  }

  buildNavItems(routes: any[]) {
    return routes
      .filter((route) => route.data)
      .filter((route) => !this.excludedRoutes.includes(route.path))
      .map(({ path = '', data }) => ({
        path: path,
        breadcrumbLabel: data.breadcrumbLabel,
        hidden: data.hidden,
      }));
  }

  ngOnDestroy(): void {
    if (this.validationSubscription) this.validationSubscription.unsubscribe();
  }
}
