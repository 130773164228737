// https://www.braemoor.co.uk/software/vat.shtml
import { AbstractControl } from '@angular/forms';

export function netherlandsBsnValidator(
  control: AbstractControl,
): { [key: string]: boolean } | null {
  const valid = !control.value || checkNLBSN(control.value);
  if (valid == false) {
    return {
      bsnValidator: valid,
    };
  } else {
    return null;
  }
}

function checkNLBSN(bsnToCheck) {
  if (bsnToCheck == undefined) {
    return true;
  }
  let sum = 0;
  if (bsnToCheck.length == 8 || bsnToCheck.length == 9) {
    for (let i = 0; i < bsnToCheck.length; i++) {
      let num = Number(bsnToCheck[i]);
      if (i + 1 == bsnToCheck.length) {
        sum -= num * (bsnToCheck.length - i);
      } else {
        sum += num * (bsnToCheck.length - i);
      }
    }
    if (sum % 11 != 0) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}
