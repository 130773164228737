export class CareProgramOverviewModel {
  createCareProgram?: boolean; // Used when none careprogram is retreived and in that case clients are queried

  id: string;
  clientId: string;
  clientNumber: string;
  status: string;
  bsn: string;
  fullName: string;
  dateOfBirth: Date;
  zpmType: string;
  therapists!: TherapistModel[];
  registrationDate: Date;
  endDate?: Date;
  defaultSetting: string;
  fundingStream!: CodeNameModel;
  privacy: boolean;

  constructor(careProgram: BasicCareProgramModel) {
    this.id = careProgram.id;
    this.clientId = careProgram.client?.id;
    this.clientNumber = careProgram.client?.clientNumber;
    this.status = careProgram.status;
    this.bsn = careProgram.client?.bsn;
    this.fullName = careProgram.client?.fullName;
    this.dateOfBirth = careProgram.client?.dateOfBirth;
    this.registrationDate = careProgram.registrationDate;
    this.zpmType = careProgram.zpmType;
    this.therapists = careProgram.therapists;
    this.defaultSetting = careProgram.defaultSetting;
    this.fundingStream = careProgram.fundingStream;
    this.endDate = careProgram.endDate;
    this.privacy = careProgram.privacy;
  }
}

export class BasicCareProgramModel {
  id!: string;
  tenantId!: string;
  careProgramId!: string; // UUID
  status!: string;
  registrationDate!: Date;
  endDate: Date;
  defaultSetting: string;
  carefileId!: string;
  serviceIds!: string[];
  services?: any;
  client!: ClientCareProgramModel;
  therapists!: TherapistModel[];
  zpmType!: string;
  privacy: boolean;
  fundingStream!: CodeNameModel;
  careLabel!: CareLabelModel[];
  careTypeDeterminationGGZ!: CareTypeDeterminationModel[];
  referral: {
    referrer?: ReferrerModel;
  };
  restitutionStartDate: Date;
  restitutionEndDate: Date;
  createdAt!: Date;
  createdBy!: EmployeeInfoModel;
  updatedAt?: Date;
  updatedBy?: EmployeeInfoModel;

  hasReferrer: boolean;
}

export class CompleteCareProgramModel extends BasicCareProgramModel {
  basicGGZProfile!: BasicGGZProfileModel[];
  dSM5Diagnosis!: DSM5DiagnosisModel[];

  constructor() {
    super();
  }
}

export class ClientCareProgramModel {
  id!: string;
  clientNumber: string;
  bsn!: string;
  fullName: string;
  dateOfBirth!: Date;
}

export class TherapistModel {
  id: string;
  employeeNumber: string;
  fullName: string;
  startDate: Date;
  endDate!: Date;
}

export class EmployeeInfoModel {
  id!: string;
  employeeNumber!: string;
  fullName!: string;
}

class CodeNameModel {
  code: number;
  name: string;
}

export class CareLabelModel {
  startDate!: Date;
  endDate?: Date;
  careLabelItem!: CodeListItem;
}

export class BasicGGZProfileModel {
  startDate!: Date;
  endDate!: Date;
  profile!: CodeListItem;
}

export class CodeListItem {
  name!: string;
  code!: string;
  identifier: string;
  GDS801Code: string;
}

export class DSM5DiagnosisModel {
  startDateDiagnosis!: Date;
  endDateDiagnosis?: Date;
  diagnosis!: DiagnosisModel;
}

export class DiagnosisModel {
  startDate!: Date;
  endDate!: Date;
  code!: string;
  groupCode?: string;
  description!: string;
  hierarchyLevel!: number;
  selectable!: boolean;
  refCodeICD9CM?: string;
  refCodeICD10?: string;
  claimType?: number;
  mutations!: boolean;
  mainGroupCode: string;
  GDS801Code: string;
  identifier: string;
}

export class CareTypeDeterminationModel {
  identifier: string;
  GDS801Code: string;
  code: string;
  name: string;
  startDate: Date;
  endDate?: Date;
}

class ReferrerModel {
  careOrgName?: string;
  careProfessionalName?: {
    firstName?: string;
    initials?: string;
    givenName?: string;
    additions?: string;
    lastName: string;
  };
  agbCode?: string;
  careProviderType?: {
    key?: number;
    value?: string;
  };
  careProviderSpec?: CodeListItem;
  professionCareProfessional?: CodeListItem;
  careProviderRole?: CodeListItem;
  dateOfReferral?: Date;
  referralType!: CodeListItem;
  referralFiles?: [
    {
      filePath?: string;
    },
  ];
}
