import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class BudgetOptionsService {
  public values: IFormDropdownOption[] = [];
  public projectValues: IFormDropdownOption[] = [];
  constructor(private masterDataService: MasterDataService) {
    this.getBudgetOptions();
  }

  public getBudgetOptions() {
    this.masterDataService.getMasterData('budget-options').subscribe((data) => {
      data.sort((a, b) => {
        return a.value - b.value;
      });
      this.values = data.filter((v) => v.value != '4');
      this.projectValues = data.filter((v) => v.value != '2');
    });
  }
}
