export class CareProgramDropDownModel {
  id!: string;
  tenantId!: string;
  zpmType!: string;
  careProgramId!: string;
  defaultSetting: string;
  status!: string;
  registrationDate!: Date;
  endDate?: Date;
  carefileId!: string;
  services!: { id: string; name: string }[];
  client!: CareProgramClientModel;
  therapists!: TherapistModel[];
  fundingStream!: string;
  referral!: ReferralModel;
  restitutionStartDate: Date;
  restitutionEndDate: Date;
}

class CareProgramClientModel {
  id: string;
  clientNumber: string;
  bsn: string;
  fullName: string;
  dateOfBirth: Date;
}

class TherapistModel {
  id: string;
  employeeNumber: string;
  fullName: string;
  startDate: Date;
  endDate: Date;
}
class ReferralModel {
  referrer?: ReferrerModel;
}

class ReferrerModel {
  careOrgName?: string;
  careProfessionalName?: {
    firstName?: string;
    initials?: string;
    givenName?: string;
    additions?: string;
    lastName: string;
  };
  agbCode?: string;
  careProviderType?: string;
  careProviderSpec?: string;
  professionCareProfessional?: string;
  careProviderRole?: string;
  dateOfReferral?: Date;
  referralType!: string;
  referralFiles?: { filePath?: string }[];
}
