export class EmailModel {
  type!: string;
  email!: string;

  status?: string | null;

  createdAt?: Date;
  createdBy?: number | string;
  updatedAt?: Date | null | undefined;
  updatedBy?: number | string | null | undefined;
}
