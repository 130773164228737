
export class PharmacyAddressModel {
  id?: string;
  postcodeNumbers?: number;
  postcodeLetters?: string;
  numberMin?: number | null;
  numberMax?: number | null;
  oddEven?: string | null;
  identifier?: number | null;
  streetNameShorter?: string | null;
  cityShorter?: string | null;
  municipality?: string | null;
  municipalityShorter?: string | null;
  province?: string | null;
  addressLine?: string | null;
  country?: string;
  city?: string;
  streetName?: string | null;
}
