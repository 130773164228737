import { Component, OnInit, Inject, OnDestroy, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuestionSetRenderService } from '../question-set-render.service';
import {
  ITableOption,
  ITableView,
} from '../../../general-components/table-view/table-view.component';
import { SIDE_SHEET_CONFIG, SIDE_SHEET_CONTENT } from '../../../models/side-sheet/side-sheet-token';
import { ActionType, SideSheetButtonEvent, SideSheetConfig, SideSheetContent } from '../../../models';
import { AbstractSideSheetComponent } from '../../../general-components';
import { SideSheetService } from '../../../services';

@Component({
  selector: 'app-add-question-set',
  templateUrl: './add-question-set.component.html',
  styleUrls: ['./add-question-set.component.css'],
})
export class AddQuestionSetComponent extends AbstractSideSheetComponent implements OnInit, OnDestroy {
  public tableOptions: ITableOption = null!;

  public tableView: ITableView = {
    pageIndex: 0,
    pageSize: 100,
    sortColumn: 'title',
    sortDirection: 'asc',
    search: '',
  };

  constructor(
    @Optional() public dialogRef: MatDialogRef<AddQuestionSetComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public questionSetRenderService: QuestionSetRenderService,
    @Optional()
    @Inject(SIDE_SHEET_CONTENT)
    public sideSheetContent: SideSheetContent,
    @Optional()
    @Inject(SIDE_SHEET_CONFIG)
    public sideSheetConfig: SideSheetConfig,
    public sideSheetService: SideSheetService,
  ) {
    super(sideSheetService, sideSheetContent, sideSheetConfig);
  }

  assignedQuestionSets = [];
  questionSets = [];

  displayWarning: boolean = false;

  warningText = 'questionset.messages.InheritanceWarning ';

  ngOnInit(): void {
    this.data = this.data ? this.data : this.sideSheetContent.data;
    this.tableOptions = this.buildTable();
    this.displayWarning =
      this.data.entity === 'careplan' && !this.data.inheritingEntityId;
    this.refresh(this.tableView);
  }

  ngOnDestroy(): void {}

  getQuestionSets(search?: string): void {
    this.questionSetRenderService
      .getAvailableQuestionSets(
        this.data.entity,
        this.data.entityId,
        this.data.entityVersion,
        this.data.isCoresponsible,
        this.data.services,
        search,
      )
      .subscribe((res) => {
        this.questionSetRenderService.availableQuestionSets = res;
        for (const questionSet of this.questionSetRenderService
          .availableQuestionSets) {
          const assignedQSIndex = this.assignedQuestionSets.findIndex(
            (assignedQuestionSet) => questionSet.id === assignedQuestionSet.id,
          );
          if (assignedQSIndex > -1) {
            questionSet.isAssigned = true;
          } else {
            questionSet.isAssigned = false;
          }
        }
        this.questionSetRenderService.availableQuestionSets$.next({
          data: this.questionSetRenderService.availableQuestionSets,
          count: res.length,
        });
      });
  }

  public onConfirm(): void {
    this.filteringQuestionSets();
    if (this.sideSheetContent) {
      this.sideSheetService.closeComponent(
        this.questionSetRenderService.assignedQuestionSets.map((aq) => aq.id),
      );
    } else {
      this.dialogRef.close(
        this.questionSetRenderService.assignedQuestionSets.map((aq) => aq.id),
      );
    }
  }

  onClickButton(event: SideSheetButtonEvent): void {
    super.onClickButton(event);
    if (event.button.actionType === ActionType.OK) this.onConfirm();
  }

  public onDismiss(): void {
    if (this.sideSheetContent) {
      this.sideSheetService.closeComponent(null);
    } else {
      this.dialogRef.close(null);
    }
  }

  public refresh(data?: ITableView): void {
    this.filteringQuestionSets();
    this.tableView = data;
    this.getQuestionSets(data.search);
  }

  filteringQuestionSets() {
    for (const questionSet of this.questionSetRenderService
      .availableQuestionSets) {
      const assignedQSIndex = this.questionSets.findIndex(
        (qs) => qs.id === questionSet.id,
      );
      if (!questionSet.isAssigned && assignedQSIndex > -1) {
        this.questionSetRenderService.assignedQuestionSets.splice(
          assignedQSIndex,
          1,
        );
      } else if (questionSet.isAssigned && assignedQSIndex === -1) {
        this.questionSetRenderService.assignedQuestionSets.push(questionSet);
      }
    }
  }
  buildTable():ITableOption{
    return {
      name: 'questionset.menu.Formats',
      dataObservable: this.questionSetRenderService.availableQuestionSets$,
      columns: [
        {
          id: 'isAssigned',
          name: 'general.labels.Add',
          type: 'checkbox',
          format: '',
        },
        {
          id: 'title',
          name: 'questionset.labels.Title',
          type: 'string',
          format: '',
          width: 240,
        },
      ],
      showHeader: true,
      showPagination: false,
      showSearch: true,
      externPagination: true,
      defaultPageSize: 100,
      defaultSortColumn: 'title',
      defaultSortDirection: 'asc',
    };
  }
}
