<div
  *ngIf="!userInfoService.isClientPortal"
  style="display: flex; margin-left: 10px; flex-direction: row-reverse"
>
  <div *ngFor="let user of users; let i = index">
    <app-collaboration-user-avatar
      *ngIf="i == users.length - 1"
      [mode]="user.userIdle ? 'dimmed' : 'normal'"
      [id]="user.userId"
    ></app-collaboration-user-avatar>
    <app-collaboration-user-avatar
      *ngIf="i != users.length - 1"
      [mode]="user.userIdle ? 'dimmed' : 'normal'"
      [id]="user.userId"
    ></app-collaboration-user-avatar>
  </div>
</div>
