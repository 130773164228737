import { Component, Input, OnInit } from '@angular/core';
import { ReturnCodeService } from '../../services/referent/return-code/return-code.service';

@Component({
  selector: 'app-return-code-display',
  templateUrl: './return-code-display.component.html',
  styleUrls: ['./return-code-display.component.scss'],
})
export class ReturnCodeDisplayComponent implements OnInit {
  @Input() returnCode: string;
  returnCodes: string[];

  constructor(public returnCodeService: ReturnCodeService) {}

  ngOnInit(): void {
    this.returnCodes = this.returnCode.split(',');
  }
}
