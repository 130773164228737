import { IMainMenuItem } from 'c4p-portal-util';

const MAIN_MENU_ITEMS: IMainMenuItem[] = [
      {
        name: 'general.labels.carefiles',
        route: 'carefiles',
        icon: 'people',
        permissions: ['client:list'],
        items: []
      },
      {
        name: 'carefile.menu.Reports',
        route: 'reports',
        permissions: ['report:create'],
        icon: 'report',
        items: [],
      },
      {
        name: 'carefile.menu.Messages',
        route: 'topics',
        permissions: ['topic:create','topic:view','topic:manage'],
        icon: 'question_answer',
        permissionOperator: 'or',
        items: [],
      },
      {
        name: 'application.overview.Files',
        route: 'files',
        permissions: ['file:cp:carefile:view','file:cp:carefile:create'],
        icon: 'description',
        permissionOperator: 'or',
        items: [],
      },
      {
        name: 'clientPortal.menu.Diary',
        route: 'diary',
        icon: 'class',
        permissions: ['diary:answers:view'],
        items: [],
      },
      {
        name: 'general.menu.Hours',
        route: 'hours/hours',
        permissions: ['hourregistration:client:view'],
        icon: 'inbox',
        items: [],
      },
];

export { MAIN_MENU_ITEMS };
