class EmployeeModel {
  id: string;
  name: string;
}

class TeamModel {
  id: string;
  name: string;
}

class ServiceModel {
  id: string;
  name: string;
}

class AddressModel {
  identifier?: number;
  addressType!: string;
  postalCode!: string;
  houseNumber!: number;
  houseLetter: string | null | undefined;
  addition: string | null | undefined;
  streetName!: string;
  designation!: string;
  city!: string;
  municipality!: string;
  country!: string;
  status!: string;
  startDate!: Date;
  endDate!: Date;
}

class ClientModel {
  clientId: string;
  fullName?: string;
  bsn!: string;
  dateOfBirth!: Date;

  clientNumber!: string;
  useCustomAvatar?: boolean;
  avatar?: string;

  address?: AddressModel;

  createdAt!: Date;
  createdBy!: string;
  updatedAt: Date;
  updatedBy: string;
}

class ApplicationModel {
  id: string;
  createdAt!: Date;
  applicationNumber: number;
}

export class CareFileModel {
  id!: string;
  tenantId!: string;
  teams?: TeamModel[];
  employees?: EmployeeModel[];
  services?: ServiceModel[];

  clientId: string;
  client?: ClientModel;
  clientFullName?: string;

  description?: string;
  applications?: ApplicationModel[];
  carefileNumber: number;

  status!: string;
  createdAt!: Date;
  createdBy!: string;
  updatedAt: Date;
  updatedBy: string;
  archiveDate?: Date;
}
