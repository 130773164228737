import { Component, OnInit, Input, HostListener } from '@angular/core';
import {
  CollaborationUserAvatarService,
  DocUserModel,
} from './collaboration-user-avatar.service';

@Component({
  selector: 'app-collaboration-user-avatar',
  templateUrl: './collaboration-user-avatar.component.html',
  styleUrls: ['./collaboration-user-avatar.component.scss'],
  providers: [CollaborationUserAvatarService],
})
export class CollaborationUserAvatarComponent implements OnInit {
  @Input() id: string;
  @Input() mode: string;
  isOpen = false;
  user: DocUserModel = {};
  @HostListener('mouseenter') onMouseEnter() {
    this.isOpen = true;
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.isOpen = false;
  }

  constructor(
    private collaborationUserAvatarService: CollaborationUserAvatarService,
  ) {}

  ngOnInit(): void {
    this.collaborationUserAvatarService
      .getEmployee(this.id)
      .subscribe((data) => {
        this.user = new DocUserModel();
        this.user.id = data.id;
        this.user.name = data.name;
        this.user.avatar = data.avatar;
        this.user.phones = data.phones;
        this.user.primaryEmail = data.primaryEmail;
      });
  }
}
