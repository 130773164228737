import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls/form-controls.const';
import { ModelUtils } from '../../../utilities/model.utils';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class DeliveryFormService {
  public values: IFormDropdownOption[] = [];
  public lookupValues: string[] = [];

  public filteredValues: IFormDropdownOption[];

  constructor(private masterDataService: MasterDataService) {
    this.getDeliveryForm();
  }

  public filterDeliveryForm(search: string) {
    this.filteredValues = ModelUtils.searchInArray(
      this.values,
      search,
      'value',
    );
  }

  public getDeliveryForm() {
    this.masterDataService.getMasterData('delivery-form').subscribe((data) => {
      this.values = data;
      for (let item of this.values) {
        this.lookupValues[item.value] = item.label;
      }
      this.filteredValues = data;
    });
  }
}
