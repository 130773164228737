<ng-container>
  <app-panel type="popup">
    <app-page-content>

      <app-panel>
        <app-table-view
          [showSearch]="true"
          [options]="tableOptions"
          (refreshRequest)="tableView = $event; refresh()"
        >
        </app-table-view>
      </app-panel>
    </app-page-content>

  </app-panel>
</ng-container>

<div style="display: flex; padding-top: 10px">

  <span class="c4p-button-cancel" (click)="dialogRef.close()" translate
    >general.labels.Close</span>
</div>
