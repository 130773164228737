<ng-container>
  <app-panel type="popup">
<ng-container *ngIf="resendLog.length > 0">
  <app-panel-form-row>
  <app-table-view
    [showSearch]="false"
    [options]="resendTableOptions"
  >
  </app-table-view>
    </app-panel-form-row>

</ng-container>
    <ng-container *ngIf="revokeLog.length > 0">
    <app-panel-form-row>
      <app-table-view
        [showSearch]="false"
        [options]="revokeTableOptions"
      >
      </app-table-view>
    </app-panel-form-row>
    </ng-container>

    <div style="display: flex; padding-top: 10px; overflow: hidden">

      <span
        class="c4p-button-cancel"
        (click)="dialogRef.close()"
        translate
        >general.actions.Close</span
      >
    </div>
  </app-panel>
</ng-container>
