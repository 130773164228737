<div class="c4p-form-comment-panel" style="padding: 2px">
  <div class="c4p-form-comments">
    <ng-container *ngIf="hasCreatePermission">
      <div class="c4p-form-comment-heading">
        <button
          *ngIf="!placeCommentEnabled"
          [disabled]="isReadOnly"
          class="c4p-button-comment"
          mat-stroked-button
          color="primary"
          (click)="placeComment()"
        >
          <mat-icon style="margin-right: 10px">chat_bubble_outline</mat-icon>
          <span translate>carefile.labels.Comment</span>
        </button>

        <div *ngIf="placeCommentEnabled">
          <ng-container [formGroup]="createCommentForm">
            <quill-editor
              formControlName="comment"
              placeholder="{{ 'carefile.labels.Comment' | translate }}"
              (onBlur)="wordFormat()"
            ></quill-editor>
          </ng-container>

          <div class="c4p-form-comment-confirmation">
            <button class="c4p-button" (click)="discardComment()" translate>
              general.labels.Discard
            </button>
            <button class="c4p-button" (click)="placeComment()" translate>
              general.labels.Add
            </button>
          </div>
        </div>
      </div>
      <div class="c4p-vertical-divider"></div>
      <mat-divider></mat-divider>
      <div class="c4p-vertical-divider"></div>
    </ng-container>

    <div [formGroup]="commentsForm" [ngClass]="!isSideSheet ? 'c4p-form-comments-container' : ''">
      <div formArrayName="comment">
        <ng-container
          *ngFor="let control of commentFormArray.controls; let i = index"
          [formGroupName]="i"
        >
          <mat-card appearance="outlined" class="c4p-card-view" style="background-color: transparent">
            <div style="display: flex">
              <div class="c4p-card-view-left">
                <div>
                  <span style="display: flex; justify-content: center">
                    <ngx-avatars
                      [name]="control.get('fullName').value"
                      [src]="control.get('avatar').value"
                      size="40"
                      bgColor=" #808080"
                      fgColor="#FFFFFF"
                    ></ngx-avatars>
                  </span>

                  <div class="c4p-card-view-value c4p-ellipsis">
                    <span class="c4p-font-large">{{
                      control.get('createdBy').value['fullName']
                    }}</span>
                  </div>

                  <div class="c4p-card-view-value c4p-ellipsis">
                    <span class="c4p-font-normal">{{
                      control.get('updatedAt').value | appDateTime
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="c4p-form-comments-container-right">
                <div>
                  <div
                    *ngIf="!control.value.focusRichText"
                    style="text-align: justify; word-break: normal"
                    [innerHTML]="control.get('comment').value | html"
                  ></div>
                  <div *ngIf="control.value.focusRichText">
                    <quill-editor
                      formControlName="comment"
                      (onEditorCreated)="onEditorCreated($event)"
                    ></quill-editor>
                    <div class="c4p-form-comment-confirmation">
                      <button
                        class="c4p-button"
                        (click)="focusComment(control)"
                        translate
                      >
                        general.labels.Discard
                      </button>
                      <button
                        class="c4p-button"
                        (click)="editComment(control)"
                        translate
                      >
                        general.labels.Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="c4p-form-comment-footer"
              *ngIf="!control.value.focusRichText && control.value.isEditable"
            >
              <span>
                <a
                  type="button"
                  (click)="focusComment(control)"
                  matTooltip="{{ 'general.labels.Edit' | translate }}"
                  *ngIf="!isReadOnly"
                >
                  <mat-icon matPrefix class="c4p-action-icons">edit</mat-icon>
                </a>
                <a
                  type="button"
                  (click)="deleteComment(control.value, i)"
                  matTooltip="{{ 'general.labels.Delete' | translate }}"
                  *ngIf="!isReadOnly"
                >
                  <mat-icon matPrefix class="c4p-action-icons">delete</mat-icon>
                </a>
              </span>
            </div>
          </mat-card>
        </ng-container>
      </div>
    </div>
  </div>
</div>
