<div class="c4p-question-set-input-small" style="word-wrap: break-word">
  <span *ngIf="required" class="c4p-question-label">{{
    '* ' + orderNum + '. ' + placeholder
  }}</span>
  <span *ngIf="!required" class="c4p-question-label">{{
    orderNum + '. ' + placeholder
  }}</span>
  <span
    *ngIf="hint && !isClientPortal"
    tippy
    [tippyOptions]="{
      content: hint,
      arrow: true,
      allowHTML: true,
      placement: 'right-end',
      theme: 'light',
      maxWidth: '350px',
      interactive: true
    }"
  >
    <mat-icon class="c4p-hint-icons">info</mat-icon>
  </span>
</div>
<div class="c4p-question-set-input">
  <mat-form-field>
    <input
      type="{{ type }}"
      matInput
      #singleLine
      [formControl]="valueControl"
      [errorStateMatcher]="errorStateMatcher"
      [required]="required"
      (blur)="onTouchedCallback()"
      [pattern]="pattern"
      (keydown.Tab)="onTab(singleLine)"
    />
    <mat-error *ngFor="let error of ngControl.errors | keyvalue">
      <span *ngIf="errorLabels && errorLabels[error.key]; else defaultError">
        {{ errorLabels[error.key] | translate }}
      </span>
      <ng-container #defaultError *ngIf="DEFAULT_ERRORS.has(error.key)">
        <span>{{ DEFAULT_ERRORS.get(error.key) | translate }}&nbsp;</span>
        <span *ngIf="error.key === 'min'">{{ error.value?.min }}</span>
        <span *ngIf="error.key === 'max'">{{ error.value?.max }}</span>
        <span *ngIf="error.key === 'maxlength' || 'minlength'">{{
          error.value?.requiredLength
        }}</span>
      </ng-container>
    </mat-error>
    <span *ngIf="suffix" matSuffix>{{ suffix }}</span>
  </mat-form-field>
</div>
