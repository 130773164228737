import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MediaObserver } from '@angular/flex-layout';
import { debounceTime, map } from 'rxjs/operators';

export interface ResponsiveColumnsMap {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService implements OnDestroy {
  private static readonly DEFAULT_COLUMNS_MAP: ResponsiveColumnsMap = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
  };
  private responsiveColumns: ResponsiveColumnsMap;

  private readonly watchers: Subscription[] = [];

  private gdColumns$ = new BehaviorSubject<number>(4);
  public isMobile$ = this.gdColumns$.pipe(map((gdColumns) => gdColumns < 3));

  constructor(private readonly mediaObserver: MediaObserver) {
    this.responsiveColumns = ResponsiveService.DEFAULT_COLUMNS_MAP;

    this.initializeColsCount();

    const mediaWatcher = this.mediaObserver
      .asObservable()
      .pipe(
        debounceTime(200),
        map((changes) => {
          const matchingAliases = changes
            .map((change) => this.mapAlias(change.mqAlias))
            .sort(
              (a, b) => this.responsiveColumns[b] - this.responsiveColumns[a],
            )
            .filter((alias) =>
              Object.keys(this.responsiveColumns).includes(alias),
            )
            .filter((alias) => this.mediaObserver.isActive(alias));

          const matchedAlias =
            matchingAliases.length > 0 ? matchingAliases[0] : 'xs';

          // console.log('this.responsiveColumns[matchedAlias]', this.responsiveColumns[matchedAlias]);

          return this.responsiveColumns[matchedAlias];
        }),
      )
      .subscribe(this.gdColumns$);

    this.watchers.push(mediaWatcher);
  }

  ngOnDestroy(): void {
    this.watchers.forEach((watcher) => watcher.unsubscribe());
  }

  private initializeColsCount(): void {
    const matchingAliases = Object.keys(this.responsiveColumns)
      .sort((a, b) => this.responsiveColumns[b] - this.responsiveColumns[a])
      .filter((alias) => this.mediaObserver.isActive(alias));

    if (matchingAliases.length > 0) {
      const firstMatchingAlias = matchingAliases[0];
      this.gdColumns$.next(this.responsiveColumns[firstMatchingAlias]);
    } else {
      this.gdColumns$.next(this.responsiveColumns.xs);
    }
  }

  private mapAlias(mqAlias: string): string {
    if (!mqAlias.includes('-')) {
      return mqAlias;
    }

    const parts = mqAlias.split('-');
    const ltOrGt = parts[0];
    const alias = parts[1];

    const keys = Object.keys(this.responsiveColumns);
    const index = keys.indexOf(alias);

    return ltOrGt === 'lt' ? keys[index - 1] : keys[index + 1];
  }

  // onResize(event) {
  //   this.breakpoint = (event.target.innerWidth / 500);
  // }
}
