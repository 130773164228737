import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public value: string = '';

  private languageSubject = new BehaviorSubject<string>(null);
  public languageObservable = this.languageSubject.asObservable();

  constructor(private translate: TranslateService) {}

  public setLanguage(language: string): void {
    this.value = language;
    this.translate.setDefaultLang(language);
    this.languageSubject.next(language);
  }

  public translation(label: string): string {
    return label && this.translate.instant(label);
  }
}
