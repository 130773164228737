<span class="c4p-font-large">
  <span
    [routerLink]="breadcrumb.url"
    class="c4p-breadcrumb"
    [ngClass]="{ 'c4p-breadcrumb-active': breadcrumb.last }"
    *ngFor="let breadcrumb of breadcrumbs$ | async"
  >
    {{ breadcrumb.label | translate }}
    <span *ngIf="!breadcrumb.last">|</span>
  </span>
</span>
