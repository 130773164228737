import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DEFAULT_TIME_FORMAT } from '../pipes.const';
import { USE_UTC } from '../../form-controls/form-controls.const';

@Pipe({
  name: 'appTime',
})
export class CustomTimePipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, DEFAULT_TIME_FORMAT, USE_UTC ? 'UTC' : null);
  }
}
