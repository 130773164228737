import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ActivePeriodModel } from './active-period.model';

@Injectable()
export class ActivePeriodService {
  private readonly microserviceURL = `${this.environment.productCatalogApiUrl}`;
  public activePeriod$ = new BehaviorSubject<ActivePeriodModel>(null);
  public activePeriods$ = new BehaviorSubject<ActivePeriodModel[]>([]);

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
    @Inject('microservice') private microservice: string,
  ) {
    switch (this.microservice) {
      case 'product-catalog':
        this.microserviceURL = this.environment.productCatalogApiUrl;
        break;
      case 'hours':
        this.microserviceURL = this.environment.hourManagementApiUrl;
        break;
    }
  }

  public saveActivePeriod(
    resource: string,
    idName: string,
    id: string,
    activePeriod: ActivePeriodModel,
  ) {
    const sunEntityId = activePeriod.id ?? undefined;
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: activePeriod.id
        ? `/${resource}/:${idName}/active-periods/:active_period_id`
        : `/${resource}/:${idName}/active-periods`,
      entity_type: resource,
      entity_id: idName,
      sub_entity_type: 'ActivePeriods',
    });
    if (sunEntityId !== undefined) {
      headers['sub_entity_id'] = sunEntityId;
    }
    if (activePeriod.id) {
      headers['entity_id'] = activePeriod.id;
      return this.http.put<ActivePeriodModel>(
        `${this.microserviceURL}/${resource}/${id}/active-periods/${activePeriod.id}`,
        activePeriod,
        {
          headers,
        },
      );
    } else {
      return this.http.post<ActivePeriodModel>(
        `${this.microserviceURL}/${resource}/${id}/active-periods`,
        activePeriod,
        {
          headers,
        },
      );
    }
  }

  public deleteActivePeriod(
    resource: string,
    idName: string,
    id: string,
    activePeriodId: string,
  ) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: `/${resource}/:${idName}/active-periods/:active_period_id`,
      entity_type: resource,
      entity_id: idName,
      sub_entity_id: activePeriodId,
      sub_entity_type: 'ActivePeriods',
    });
    return this.http.delete<any>(
      `${this.microserviceURL}/${resource}/${id}/active-periods/${activePeriodId}`,
      { headers },
    );
  }
}
