import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AnswerTypeConfigurationModel } from '../../../models';
import { AnswerModel } from '../../../models/question-set-answer/answer.model';

export class QuestionRenderHelper {
  constructor(private formBuilder: FormBuilder) {}

  public buildTypeConfigurationOptionFormGroup(
    option: AnswerTypeConfigurationModel,
  ): FormGroup {
    const typeConfigurationOption = this.typeConfigurationBuildForm(option);
    typeConfigurationOption.markAsUntouched();

    return typeConfigurationOption;
  }

  public buildAnswersFormGroup(answer: AnswerModel): FormGroup {
    const formGroup = this.formBuilder.group({
      id: answer.id,
      answer: [answer.answer, answer.isMandatory ? Validators.required : null],
      title: answer.title,
      type: answer.type,
      typeConfiguration: this.formBuilder.array([]),
      hint: answer.hint,
      isMandatory: answer.isMandatory,
      order: answer.order,
      isAnswered: answer.isAnswered,
      entityId: answer.entityId,
      entityType: answer.entityType,
      entityVersion: answer.entityVersion,
      questionSetAnswerId: answer.questionSetAnswerId,
      questionId: answer.questionId,
      focusRichText: false,
      commentRequired: answer.commentRequired,
      comment: answer.comment,
    });

    if (answer.typeConfiguration) {
      for (let option of answer.typeConfiguration) {
        const typeConfigurationOptionFormGroup =
          this.buildTypeConfigurationOptionFormGroup(option);
        (formGroup.get('typeConfiguration') as FormArray).push(
          typeConfigurationOptionFormGroup,
        );
      }
    }

    formGroup.markAsUntouched();
    return formGroup;
  }
  typeConfigurationBuildForm(option: AnswerTypeConfigurationModel){
    return this.formBuilder.group({
      _id: option._id,
      displayText: option.displayText,
      order: option.order,
    });
  }
}
