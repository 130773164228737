import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {from, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {DocumentService, ITableOption, ITableView, PaginationDataModel, TableViewComponent} from 'c4p-portal-util';
import {  DocumentModel } from 'c4p-portal-util';
import { ActivatedRoute, Router } from '@angular/router';
import { getValue } from '@angular/fire/remote-config';
import { TranslateService } from '@ngx-translate/core';
import { AuditLog } from '../../models/audit-log.model';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss'],
})
export class AuditLogComponent implements OnInit {
  private document: DocumentModel;
  documentType = this.data?.document.documentType;
  tableOptions: ITableOption = null!;
  tableView: ITableView = {
    pageIndex: 0,
    pageSize: 20,
    sortColumn: 'createdAt',
    sortDirection: 'desc',
    search: '',
  };
  private _filterParams = {};
  private readonly destroyed$ = new Subject<boolean>();
  private routeControl: any;

  @ViewChild(TableViewComponent) tableViewComponent: TableViewComponent;
  constructor(
    private documentService: DocumentService,
    public dialogRef: MatDialogRef<AuditLogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private route: Router,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.tableOptions = this.buildTable();
    this.document = this.data?.document;
    this.getAuditLogs(this.document.id)
  }

  getCurrentPage(){
    this.routeControl = this.route.url;
    return this.routeControl.split("/")[1];
  }

  get filterParams() {
    return this._filterParams;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getAuditLogs(documentId:string){
    const filterData = {
      documentId: documentId
    }
   this.documentService.getAuditLogs(
     new PaginationDataModel('createdAt', 'desc', 999),
     filterData,
     this.getCurrentPage()
   ).subscribe((auditLog:any)=>{
     this.documentService.auditLogs$.next({
       data: auditLog.docs,
       count: auditLog.totalDocs,
     });
    })
  }

  refresh(): void {
    let newFilterParams = {
      ...this.filterParams,
      documentId: this.data?.document.id,
    };
    this.documentService
      .getAuditLogs(
        PaginationDataModel.fromTableView(this.tableView),
        newFilterParams,
        this.getCurrentPage(),
      )
      .subscribe((auditLog: any) => {
        this.documentService.auditLogs$.next({
          data: auditLog.docs,
          count: auditLog.totalDocs,
        });
      });
  }

  buildTable(): any {

    return {
      name: 'document.labels.auditLogs',
      titleIcon: 'file_copy',
      dataObservable: this.documentService.auditLogs$.pipe(
        map((documentPagination) => {
          if (documentPagination) {
            const modifiedData = documentPagination.data.map((item:AuditLog) => {
              item.mode = this.translate.instant(`document.labels.${item.mode}`)
              item.status = this.translate.instant(`document.labels.${item.status}`)
              item.userType = this.translate.instant(`document.labels.${item.userType}`)
              return item;
            });

            return { ...documentPagination, data: modifiedData };
          }
        }),
      ),
      columns: [
        {
          id: 'userFullName',
          name: 'document.labels.fullName',
          type: 'string',
          format: '',
          width: 140,
        },
        {
          id: 'userType',
          name: 'document.labels.userType',
          type: 'string',
          format: '',
          width: 100,
        },
        {
          id: 'mode',
          name: 'document.labels.mode',
          type: 'string',
          format: '',
          width: 65,
        },
        {
          id: 'status',
          name: 'document.labels.status',
          type: 'string',
          format: '',
          width: 65,
        },
        {
          id: 'errorText',
          name: 'document.labels.errorText',
          type: 'string',
          format: '',
        },
        {
          id: 'createdAt',
          name: 'document.labels.createdAt',
          type: 'date',
          format: 'tenantDate',
          width: 90,
        },
        {
          id: 'updatedAt',
          name: 'document.labels.updatedAt',
          type: 'date',
          format: 'tenantDate',
          width: 90,
        },
      ],
      inlineActions: [],
      menuActions: [],
      showHeader: true,
      showSearch: true,
      showPagination: true,
      externPagination: true,
      defaultPageSize: 20,
      defaultSortColumn: 'createdAt',
      defaultSortDirection: 'desc',
    };
  }

  submit() {

  }
}
