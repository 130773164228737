import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  DialogComponent,
  DocumentModel,
  DocumentService,
  ITableOption,
  ITableView,
  PermissionService,
  SING_MODE,
  UserInfoService,
} from 'c4p-portal-util';
import { DigitalSignatureService } from '../../service/signature/digital-signature.service';
import { CloseDetailEventData } from '../sharing-detail/sharing-detail.component';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentSignComponent } from '../document-sign/document-sign.component';
import { SOURCE_TYPES } from '../../const/source-types.const';
import { OPERATION_TYPES } from '../../const/operation-types.const';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-share-and-sign',
  templateUrl: './share-and-sign.component.html',
})
export class ShareAndSignComponent implements OnInit {
  public tableOptions: ITableOption = null!;
  public sharedPersons: any;
  public currentDocument: DocumentModel;
  public signatureLogs: any[] = [];
  public documentName: string;
  public disableSignButton = false;
  public paginationData: any;
  public isPdfDocument: boolean;
  public sharedPersons$ = new BehaviorSubject<{
    data: any;
    count: number;
  }>(null);
  public tableData$ = new BehaviorSubject<{
    data: any;
    count: number;
  }>(null);
  detailData: any;
  public detailFileData: DocumentModel;
  openComp = false;
  openShare = false;
  public userportal: string;
  public userInfo: any;
  private readonly destroyed$ = new Subject<boolean>();
  title: string;
  tableTotalData;
  hasSharePermission: boolean;
  hasSignPermission: boolean;
  private hideSignRelatedColumns: boolean;
  private currentUserSignatureRequired = false;
  private isRevokeColumnHide: boolean;
  public tableView: ITableView = {
    pageIndex: 0,
    pageSize: 20,
    sortColumn: 'requestDate',
    sortDirection: 'desc',
    search: '',
  };
  constructor(
    public dialogRef: MatDialogRef<ShareAndSignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private digitalSignatureService: DigitalSignatureService,
    private documentService: DocumentService,
    public userInfoService: UserInfoService,
    public translate: TranslateService,
    private toastr: ToastrService,
    private permissionService: PermissionService,
    private cdr: ChangeDetectorRef
  ) {
    this.sharedPersons$ = new BehaviorSubject<any>(null);
    this.tableData$ = new BehaviorSubject<any>(null);
    this.title = this.translate.instant('document.labels.documentSharingAndSigning')
  }
  ngOnInit(): void {
    this.isPdfDocument = this.data.document.documentType === 'pdf';
    this.documentName = this.data.document.documentName;
    this.sharedPersons = this.data.document.sharing;
    this.signatureLogs = this.data.document.signatureLogs;
    this.currentDocument = this.data.document;
    this.updateSharedPersons();
    this.subscribeSharedPersons();
    this.isRevokeColumnHide = this.getIsRevokeColumnHide(this.data.document);
    this.hideSignRelatedColumns = this.getHideSignRelatedColumns(this.data.document);
    this.tableOptions = this.buildTable();
    this.userInfoService.userInfo$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((userInfo) => {
        this.userInfo = userInfo;
      });
    this.hasSharePermission = this.permissionService.getPermission(
      'carefile:files:share',
      'or'
    );
    this.hasSignPermission = this.getSignPermission(this.data.document);
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.sharedPersons$.unsubscribe();
  }
  onButtonClick(params: any) {
    if (params.signatureLogs) {
      const userId = params.userId;
      const signatureLogs = params.signatureLogs || [];
      signatureLogs.map(log => {
        if (log?.signedBy && log?.signedBy?.id === userId) {
          const signatureKey = log.signatureKey;
          if (signatureKey) {
            this.digitalSignatureService
              .getSignatureKeys([signatureKey])
              .subscribe((res) => {
                const data = res?.[0];
                this.openDialog(data, this.data.document);
              });
          }
        }
      });
    } else {
      const signatureKey = params.signatureKey;
      if (signatureKey) {
        this.digitalSignatureService
          .getSignatureKeys([signatureKey])
          .subscribe((res) => {
            const data = res?.[0];
            this.openDialog(data, this.data.document);
          });
      }
    }
  }

  closeDetailSignature($event: boolean) {
    if ($event) {
      this.openComp = false;
      this.openComp = false;
    }
  }
  openDialog(data, fileData): void {
    this.detailData = data;
    this.detailFileData = fileData;
    this.openComp = true;
  }
  closeShare($event: CloseDetailEventData) {
    if ($event.close) {
      this.openShare = false;
      this.openShare = false;
    }
    this.documentService.updateSharing($event.document).subscribe(() => {
      this.data.document = $event.document;
      this.sharedPersons = $event.document.sharing;
      this.updateSharedPersons();
      this.buildTable();
    });
  }
  updateSharedPersons() {
    const count = this.sharedPersons?.length + this.signatureLogs?.length;
    this.sharedPersons$.next({ data: this.sharedPersons, count: count });
  }
  openShareComponent(): void {
    this.openShare = true;
  }
  submit(): void {
    this.sign(this.currentDocument);
  }
  close(){
    this.dialogRef.close();
  }
  onAllCheck(event: any) {
    if(event.columnId === 'signatureRequired'){
      if (!event.checked) {
        const dialogRef = this.openConfirmDialog('client.messages.Confirm_signatureRequired_revoke', 'client.messages.RemoveAllSignatureRequired');
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.sharedPersons?.map(person => {
              person.signatureRequired = event.checked;
            })
            this.updateSharedPersons();
            this.updateDocument();
          }else{
            this.sharedPersons?.map(person => {
              person.signatureRequired = !event.checked;
            })
            this.updateSharedPersons();
            this.updateDocument();
          }
        });
      }else{
        this.updateDocument();
      }
    }
    else{
      if(!event.checked){
        const dialogRef = this.openConfirmDialog('client.messages.Confirm_share_remove', 'client.messages.RemoveAllShare');
        dialogRef.afterClosed().subscribe((result) => {
          if(result){
            this.sharedPersons?.splice(0, this.sharedPersons?.length);
            this.updateSharedPersons();
            this.updateDocument();
          }
        })
      }
      this.updateDocument();
    }
  }

  singleCheck(event: any, person: any,columnId: any) {
    if(columnId === 'signatureRequired'){
      const index = this.sharedPersons?.findIndex(p => p.fullName === person.fullName);
      const initialSharedPerson = { ...this.sharedPersons[index] };
      this.sharedPersons[index].signatureRequired = event;
      const documentId = this.data.document.id;
      if(!event){
        const dialogRef = this.openConfirmDialog('client.messages.Confirm_signatureRequired_revoke', 'client.messages.RemoveSingleSignatureRequired');

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.documentService.revokeSignature(documentId, this.data.document.sharing[index].userId, this.userInfo)
              .subscribe({
                next: (value) => {
                  value.sharing[index].sharingType = this.data.document.sharing[index].sharingType;
                  this.sharedPersons = value.sharing;
                  this.toastr.success(this.translate.instant('document.messages.requestRevoked'));
                  this.updateSharedPersons();
                  this.updateDocument();
                }})
          }
          else {
            this.sharedPersons[index] = initialSharedPerson;
            this.updateSharedPersons();
          }
        });
      }else{
        this.updateDocument();
      }
    }else if(columnId === 'shared'){
      const index = this.sharedPersons?.findIndex(p => p.fullName === person.fullName);
      const initialSharedPerson = { ...this.sharedPersons[index] };
      if(!event) {
        const dialogRef = this.openConfirmDialog('client.messages.Confirm_share_remove', 'client.messages.RemoveSingleShare');
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.sharedPersons?.splice(index, 1);
            this.updateSharedPersons();
            this.updateDocument();
          } else {
            this.sharedPersons[index] = initialSharedPerson;
            this.updateSharedPersons();
          }
        })
      }
    }
  }
  subscribeSharedPersons(){
    this.sharedPersons$.subscribe((sharedPersonList) => {
      if (sharedPersonList && sharedPersonList?.data && sharedPersonList?.data?.length > 0) {
        sharedPersonList.data.forEach((item) => {
          item.shared = true
          item.documentId = this.data?.document?.id;
          const signedBy = this.signatureLogs?.find(log => log.signedBy?.id === item?.userId);
          if (signedBy) {
            item.signatureStatus = SING_MODE.SIGNED;
            item.signatureLogs = this.signatureLogs;
          } else if(item.signatureRequired === true) {
            item.signatureStatus = SING_MODE.PENDING;
          }else{
            item.signatureStatus = undefined;
          }
        });
      }
      this.signatureLogs.map((item) => {
        item.fullName = item.signedBy.fullName;
        item.signatureStatus = SING_MODE.SIGNED;
      })

      this.tableTotalData = [
        ...sharedPersonList?.data,
        ...this.signatureLogs?.filter(signatureLog => {
          return !sharedPersonList?.data?.some(dataItem => dataItem?.userId === signatureLog?.signedBy?.id);
        })
      ];

      if(this.paginationData){
        this.tableData$.next({
          data: this.getPaginatedData(),
          count: this.tableTotalData.length
        })
      }else{
        const pageIndex = 0;
        const pageSize = 5;
        const initialCount = pageIndex*pageSize;
        this.tableData$.next({
          data: this.tableTotalData.slice(initialCount,initialCount+ pageSize),
          count: this.tableTotalData.length
        });
      }
    })
  }

  getPaginatedData(){
    const pageIndex = this.paginationData.pageIndex;
    const pageSize = this.paginationData.pageSize;
    const initialCount = pageIndex*pageSize;
    return this.tableTotalData.slice(initialCount,initialCount+ pageSize);
  }

  updateDocument(){
    const documentId = this.data.document.id;
    this.documentService.getDocument(documentId).subscribe(document => {
      document.sharing = this.sharedPersons;
      this.documentService.updateDocument(document).subscribe();
      this.updateSharedPersons();
    });
  }
  private onNotifyButtonClick(data: any) {
    const dialogRef = this.openConfirmDialog('client.messages.Confirm_notify', 'client.messages.Confirm_notify_message');
    dialogRef.afterClosed().subscribe((result) => {
      if(result){
        this.documentService
          .notifySignature(data.documentId, data.userId, this.userInfo)
          .subscribe({
            next: (value) => {
              this.sharedPersons = value.sharing;
              this.toastr.success(this.translate.instant('document.messages.notifySuccess',{
                value: (value.sharing.find(res=>res.userId === data.userId)).resend.length }));
            }})
      }
    })
  }
  shareFile() {
    this.updateDocument();
    this.openShareComponent();
  }
  public sign(document: DocumentModel): void {
    this.disableSignButton = true;
    this.userportal = SOURCE_TYPES.CLIENT;
    const dialogRef = this.dialog.open(DocumentSignComponent, {
      data: {
        document,
        userPortal: this.userportal,
        operation: OPERATION_TYPES.APPLICATION_APPROVAL,
      },
    });
    dialogRef.afterClosed().subscribe((item) => {
      this.documentService.getDocument(this.data?.document?.id).subscribe((data) => {
        this.currentDocument = data;
        this.signatureLogs = data?.signatureLogs;
        this.updateSharedPersons();
        this.disableSignButton = false;
        this.cdr.detectChanges();
      });
    })
  }
  private openConfirmDialog(title: string, message: string) {
    return this.dialog.open(DialogComponent, {
      data: {
        title: title,
        message: message,
        confirmText: 'general.labels.Confirm'
      }
    });
  }

  onRefreshRequest(event){
    this.paginationData = event;
    this.tableData$.next({
      data: this.getPaginatedData(),
      count: this.tableTotalData.length
    })
  }

  private getTranslatedStatus(key){
    return this.translate.instant(key);
  }

  private checkViewPermission(data): boolean{
    return data.signatureStatus === 'signed' && this.permissionService.getPermission('carefile:files:digitalSignatureDetail:view','or');
  }
  buildTable(): ITableOption {
    return {
      name: `${this.title} - ${this.documentName}`,
      titleIcon: 'file_copy',
      dataObservable: this.tableData$.pipe(map((data) => {
        data.data.map(item => {
          item.sharedDate= item.shareDate;
          item.signedDate= item.signedDate ? item.signedDate : item.signatureLogs?.[0]?.signedDate;
          if(item.sharingType==="external"){
            item.external= true;
          }
        })
        return data;
      })),
      columns: [
        { id: 'external', name: 'sharingType', type: 'externalIcon', format: 'link', width: 120, iconTooltip:'document.labels.externalUser' },
        { id: 'fullName', name: 'document.labels.Name', type: 'string', format: '', width: 140 },
        { id: 'sharedDate', name: 'document.labels.sharedDate', type: 'date', format: 'nullableDate', width: 100},
        { id: 'signedDate', name: 'document.labels.SignedDate', type: 'date', format: 'nullableDate', width: 100, hideColumn: this.hideSignRelatedColumns},
        { id: 'signatureStatus', name: 'document.labels.signatureStatus', type: 'status', format: '', width: 120, hideColumn: this.hideSignRelatedColumns },
        { id: 'shared', name: 'document.labels.Shared', type: 'checkbox', format: 'allCheck', width: 90, hideColumn: this.isRevokeColumnHide, hideCondition: (data) => data.signatureStatus === 'signed'},
        { id: 'signatureRequired', name: 'document.labels.signatureRequired', type: 'checkbox', format: 'allCheck', width: 155, hideColumn: this.hideSignRelatedColumns, hideCondition: (data) => data.signatureStatus === 'signed'},
        { id: 'signatureKey', name: 'document.labels.SignatureKey', type: 'string', format: '', width: 0, hideColumn: true },
      ],
      showHeader: true,
      showPagination: true,
      externPagination: true,
      defaultPageSize: 5,
      defaultSortColumn: 'requestDate',
      defaultSortDirection: 'desc',
      menuActions: [
        {
          icon: 'local_post_office',
          tooltip: 'document.labels.Notify',
          conditionMethod: (data) => this.isNotifyClickable(data),
          method: (data) => this.onNotifyButtonClick(data)
        },
        {
          icon: 'visibility',
          tooltip: 'general.actions.View',
          conditionMethod: (data) => this.checkViewPermission(data),
          method: (data) => this.onButtonClick(data),
        }
      ],
    };
  }

  private getHideSignRelatedColumns(data: any):boolean {
    if (!this.isPdfDocument) return true;

    return !(this.permissionService.getPermission('carefile:files:digitalSignature:signatureRequired|carefile:files:digitalSignatureDetail:view|carefile:files:digitalSignature:create|carefile:files:digitalSignature:view','or'));
  }

  private getSignPermission(document:any): boolean {
    if (document?.createdBy?.id === this.userInfo?.id){
      this.currentUserSignatureRequired = true;
    } else if (document.sharing && document.sharing[0]) {
      this.currentUserSignatureRequired = document.sharing.some(sharedDoc => sharedDoc.userId === this.userInfo.id && sharedDoc?.signatureRequired)
    } else {
      this.currentUserSignatureRequired = false;
    }

    return this.permissionService.getPermission(
      'carefile:files:digitalSignature:create',
      'or',
    ) && this.currentUserSignatureRequired;
  }

  private isNotifyClickable(data:any) {
    if (!this.isPdfDocument) return false;
    const permissions = "carefile:files:digitalSignature:signatureRequired | carefile:files:share";
    return data.signatureStatus !== 'signed'
      && data.signatureRequired
      && this.permissionService.getPermission(permissions,"and");
  }

  private getIsRevokeColumnHide(data: any): boolean {
  return (data.signatureStatus === 'signed' || !this.permissionService.getPermission('carefile:files:share','or'));
  }

}
