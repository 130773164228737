import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { NgxMatMomentDateAdapterOptions } from '@angular-material-components/moment-adapter';
import { Inject, Injectable, Optional } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import * as moment_ from 'moment-timezone';
import { UserInfoService } from '../../services';

function range<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}

@Injectable()
export class TenantDateTimeAdapter extends NgxMatDateAdapter<moment.Moment> {
  private _localeData: {
    firstDayOfWeek: number;
    longMonths: string[];
    shortMonths: string[];
    dates: string[];
    longDaysOfWeek: string[];
    shortDaysOfWeek: string[];
    narrowDaysOfWeek: string[];
  };

  constructor(
    private userService: UserInfoService,
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
    @Optional()
    @Inject(MAT_MOMENT_DATE_ADAPTER_OPTIONS)
    private _options?: NgxMatMomentDateAdapterOptions,
  ) {
    super();
  }
  tenantTimezone =
    this.userService.tenantTimeZone ||
    JSON.parse(localStorage.getItem('tenantTimeZone'));
  minimumDate: moment.Moment;
  maximumDate: moment.Moment;

  setLocale(locale: string) {
    super.setLocale(locale);

    const momentLocaleData = moment.localeData(locale);
    this._localeData = {
      firstDayOfWeek: momentLocaleData.firstDayOfWeek(),
      longMonths: momentLocaleData.months(),
      shortMonths: momentLocaleData.monthsShort(),
      dates: range(31, (i) => this.createDate(2017, 0, i + 1).format('D')),
      longDaysOfWeek: momentLocaleData.weekdays(),
      shortDaysOfWeek: momentLocaleData.weekdaysShort(),
      narrowDaysOfWeek: momentLocaleData.weekdaysMin(),
    };
  }

  getYear(date: moment_.Moment): number {
    return this.clone(date).year();
  }

  getMonth(date: moment_.Moment): number {
    return this.clone(date).month();
  }

  getDate(date: moment_.Moment): number {
    return this.clone(date).date();
  }

  getDayOfWeek(date: moment_.Moment): number {
    return this.clone(date).day();
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    // Moment.js doesn't support narrow month names, so we just use short if narrow is requested.
    return style === 'long'
      ? this._localeData.longMonths
      : this._localeData.shortMonths;
  }

  getDateNames(): string[] {
    return this._localeData.dates;
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (style === 'long') {
      return this._localeData.longDaysOfWeek;
    }
    if (style === 'short') {
      return this._localeData.shortDaysOfWeek;
    }
    return this._localeData.narrowDaysOfWeek;
  }

  getYearName(date: moment_.Moment): string {
    return this.clone(date).format('YYYY');
  }

  getFirstDayOfWeek(): number {
    return this._localeData.firstDayOfWeek;
  }

  getNumDaysInMonth(date: moment_.Moment): number {
    return this.clone(date).daysInMonth();
  }

  clone(date: moment_.Moment): moment_.Moment {
    return date.clone().locale(this.locale);
  }

  createDate(year: number, month: number, date: number): moment_.Moment {
    if (month < 0 || month > 11) {
      throw Error(
        `Invalid month index "${month}". Month index has to be between 0 and 11.`,
      );
    }

    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }

    const result = this._createMoment({ year, month, date }).locale(
      this.locale,
    );
    if (!result.isValid()) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }
    return result;
  }

  today(): moment_.Moment {
    // @ts-ignore
    return this._createMoment().locale(this.locale);
  }

  parse(value: any, parseFormat: string | string[]): moment_.Moment | null {
    if (value && typeof value === 'string') {
      return this._createMoment(value, parseFormat, this.locale, true);
    }
    return value ? this._createMoment(value).locale(this.locale) : null;
  }

  format(date: moment_.Moment, displayFormat: string): string {
    date = this.clone(date);
    if (!this.isValid(date)) {
      throw Error('MomentDateAdapter: Cannot format invalid date.');
    }
    return moment(date).format(displayFormat);
  }

  addCalendarYears(date: moment_.Moment, years: number): moment_.Moment {
    return this.clone(date).add({ years });
  }

  addCalendarMonths(date: moment_.Moment, months: number): moment_.Moment {
    return this.clone(date).add({ months });
  }

  addCalendarDays(date: moment_.Moment, days: number): moment_.Moment {
    return this.clone(date).add({ days });
  }

  toIso8601(date: moment_.Moment): string {
    return this.clone(date).format();
  }

  deserialize(value: any): moment_.Moment | null {
    let date;
    if (value instanceof Date) {
      date = this._createMoment(value).locale(this.locale);
    } else if (this.isDateInstance(value)) {
      return this.clone(value);
    }
    if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      date = this._createMoment(value, moment.ISO_8601).locale(this.locale);
    }
    if (date && this.isValid(date)) {
      return this._createMoment(date).locale(this.locale);
    }
    return super.deserialize(value);
  }

  isDateInstance(obj: any): boolean {
    return moment.isMoment(obj);
  }

  isValid(date: moment_.Moment): boolean {
    return this.clone(date).isValid();
  }

  invalid(): moment_.Moment {
    return moment.invalid();
  }

  getHour(date: moment_.Moment): number {
    return moment(date).tz(this.tenantTimezone).hours();
  }
  getMinute(date: moment_.Moment): number {
    return moment(date).tz(this.tenantTimezone).minutes();
  }
  getSecond(date: moment_.Moment): number {
    return moment(date).tz(this.tenantTimezone).seconds();
  }
  setHour(date: moment_.Moment, value: number): void {
    date.hours(value);
  }
  setMinute(date: moment_.Moment, value: number): void {
    date.minutes(value);
  }
  setSecond(date: moment_.Moment, value: number): void {
    date.seconds(value);
  }

  clampDate(
    date: moment.Moment,
    min?: moment.Moment,
    max?: moment.Moment,
    format?,
    locale?,
  ): any {
    if (min) this.minimumDate = min;
    if (max) this.maximumDate = max;
    if (min && date.isBefore(min)) {
      return moment(this.minimumDate, format, locale);
    }
    if (max && date.isAfter(max)) {
      return moment(this.maximumDate, format, locale);
    }
    return moment(date, format, locale);
  }

  private _createMoment(
    date: moment_.MomentInput,
    format?: moment_.MomentFormatSpecification,
    locale?: string,
    isValueString = false,
  ): moment_.Moment {
    const { strict, useUtc }: NgxMatMomentDateAdapterOptions =
      this._options || {};
    if (isValueString) {
      return this.clampDate(
        moment(date, format),
        this.minimumDate,
        this.maximumDate,
        format,
        locale,
      );
    }
    return useUtc
      ? moment.utc(date, format, locale, strict)
      : moment(date).tz(this.tenantTimezone);
  }
}
