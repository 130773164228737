<google-chart
  #chart
  *ngIf="canDrawChart == true"
  style="opacity: 1"
  [type]="type"
  [options]="options"
  [columns]="columns"
  [data]="data"
  [dynamicResize]="true"
  [style]="isMobileView ? '' : 'width: 100%; height: 100%'"
  [width]="isMobileView ? chartWidth : undefined"
  [height]="isMobileView ? chartHeight : undefined"
  (select)="onSelect($event)"
></google-chart>
<div *ngIf="canDrawChart == false">{{ noDataAvailable | translate }}</div>
