import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DropdownServiceBase } from '../dropdown-service.abstract';
import {
  IndividualInvolvementModel,
  PaginationDataModel,
  PaginationResultModel,
} from '../../models';
import { HttpUtils } from '../../utilities';

@Injectable({
  providedIn: 'root',
})
export class IndividualInvolvementService extends DropdownServiceBase<IndividualInvolvementModel> {
  public clientPortalAccessManagements$ = new BehaviorSubject<{
    data: IndividualInvolvementModel[];
    count: number;
  }>(null);

  constructor(
    public http: HttpClient,
    @Inject('environment') private environment,
  ) {
    super();
    this.setupDropdown(this.getCarefileAccessManagements);
  }

  public getCarefileAccessManagements(
    paginationData: PaginationDataModel,
    filterData?: any,
  ): Observable<PaginationResultModel<IndividualInvolvementModel>> {
    if (!filterData.carefileId)
      throw new Error('Missing carefileId from filterData');

    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
      'carefileId',
    );
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles/:carefile_id/individual-involvements',
    });

    return this.http.get<PaginationResultModel<IndividualInvolvementModel>>(
      `${this.environment.carefileManagementApiUrl}/${filterData.carefileId}/individual-involvements`,
      { params, headers },
    );
  }
}
