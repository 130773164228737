<div style="display: flex; flex-direction: row; width: 100%">
  <div style="width: 800px; margin: 0 12px 0 12px; height: auto">
    <app-panel>
      <mat-drawer-container autosize>
        <mat-drawer position="end" [opened]="opened" mode="over">
          <lib-document-signature-log-detail
            [data]="detailData" [fileData]="detailFileData"
            (closeDetailComp)="closeDetailSignature($event)">
          </lib-document-signature-log-detail>
        </mat-drawer>
        <div>
          <app-table-view
            [showSearch]="false"
            [options]="tableOptions"
            (onRowClick)="onRowClick($event)"
          >
          </app-table-view>
        </div>
      </mat-drawer-container>
    </app-panel>


    <ng-template #detailDialog let-row>
      <button (click)="onButtonClick(row)" translate>billing.labels.Details</button>
    </ng-template>
  </div>
</div>
<div style="display: flex; padding-top: 20px">
        <span class="c4p-button-cancel" (click)="onCloseDialog()" translate
        >general.labels.Cancel</span
        >
  <span class="c4p-button-ok" (click)="sign()" translate
  >general.actions.Sign </span
  >
</div>


