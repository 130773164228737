import { PartialPdfSize } from "./pdf.size";

export interface PdfInfo {
  author: string;
  creationDate: Date;
  creator: string;
  keywords: string;
  modificationDate: Date;
  pageCount: number;
  pageSize: PartialPdfSize;
  producer: string;
  subject: string;
  title: string;
}
export type PartialPdfInfo = Partial<PdfInfo>;
