import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { DOCUMENT_STATUSES } from '../../const/dropdowns';
import { DocumentService } from '../../service/document/document.service';
import { DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT } from '../../../form-controls';
import { UserInfoService } from '../../../services';
import { ModelUtils } from '../../../utilities';
@Component({
  selector: 'app-document-filters-form',
  templateUrl: './document-filters-form.component.html',
  styleUrls: ['./document-filters-form.component.scss'],
})
export class DocumentFiltersFormComponent implements OnInit, OnDestroy {
  @Output() submit = new EventEmitter();
  public STATUSES = DOCUMENT_STATUSES;

  filtersForm: FormGroup = null!;

  public get employeeId(): string {
    return this.userInfoService.userId;
  }
  private readonly destroyed$ = new Subject<boolean>();
  constructor(
    private formBuilder: FormBuilder,
    public userInfoService: UserInfoService,
    public documentService: DocumentService,
  ) {}

  ngOnInit(): void {
    this.filtersForm= this.buildFiltersForm();
    this.filtersForm.valueChanges
      .pipe(
        debounceTime(DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT),
        map((values) => (ModelUtils.isObjectEmpty(values) ? null : values)),
        takeUntil(this.destroyed$),
      )
      .subscribe((values) => this.submit.emit(values));
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  buildFiltersForm(){
    return this.formBuilder.group({
      documentName: [],
      description: [],
      services: [],
      categories: [],
      documentType: [],
      createdByName: [],
      dateFrom: [],
      dateUntil: [],
      status: [],
    });
  }
}
