import { AnswerTypeConfigurationModel } from './answer-type-configuration.model';

export class AnswerModel {
  id!: number | string;
  tenantId!: number | string;
  entityId!: string | number;
  entityVersion: number;
  entityType: string;
  questionSetAnswerId: string | number;
  questionId: string;

  answer!: string | string[] | number | number[];
  isMandatory!: boolean;
  order: number;

  title!: string;
  type!: number | string;
  typeConfiguration: AnswerTypeConfigurationModel[] | [] | null | undefined;
  hint: string;

  commentRequired?: boolean;
  commentLabel?: string;
  comment?: string;

  createdAt!: Date;
  createdBy!: number | string; // user id
  updatedAt?: Date | null | undefined;
  updatedBy?: number | string | null | undefined;
  isAnswered: boolean;
}
