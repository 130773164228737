<app-panel [formGroup]="documentForm" type="popup" style="overflow: hidden">
  <app-panel-title
    *ngIf="!documentForm.value.id"
    title="general.actions.Create"
  ></app-panel-title>
  <app-panel-title
    *ngIf="documentForm.value.id"
    title="general.actions.Update"
  ></app-panel-title>

  <app-panel-form-row>
    <ng-container
      *ngIf="documentForm.value.id"
      [formGroup]="documentForm.get('fileData')"
    >
      <app-form-input-text
        formControlName="fileName"
        placeholder="document.labels.DocumentName"
        suffix=".{{ documentForm.value.fileData.extension }}"
      ></app-form-input-text>
    </ng-container>
    <app-form-input-dropdown
      formControlName="category"
      placeholder="document.labels.Category"
      [options]="documentService.categories"
      [objectAsValue]="true"
      labelProperty="name"
    ></app-form-input-dropdown>
    <app-form-input-dropdown
      *ngIf="checkIfServiceLabelExist"
      formControlName="services"
      placeholder="document.labels.Service"
      [search]="true"
      [multiple]="true"
      showToggleAllCheckbox="true"
      [options]="documentService.services"
      [objectAsValue]="true"
      valueProperty="id"
      labelProperty="label"
    ></app-form-input-dropdown>

    <app-form-input-dropdown
      *ngIf="!checkIfServiceLabelExist"
      formControlName="services"
      placeholder="document.labels.Service"
      [search]="true"
      [multiple]="true"
      showToggleAllCheckbox="true"
      [options]="documentService.services"
      [objectAsValue]="true"
      valueProperty="id"
      labelProperty="name"
    ></app-form-input-dropdown>
  </app-panel-form-row>
  <app-panel-form-row>
    <app-form-input-text-area
      [rows]="5"
      formControlName="description"
      placeholder="document.labels.Description"
    ></app-form-input-text-area>
  </app-panel-form-row>

  <app-panel-form-row>
    <app-file-upload
      *ngIf="!documentForm.value.id"
      [multiple]="false"
      (filesSelected$)="selectedFile = $event ? $event[0] : null"
    ></app-file-upload>
  </app-panel-form-row>
</app-panel>

<div style="display: flex">
  <span class="c4p-button-cancel" (click)="onCloseDialog()" translate
    >general.labels.Cancel</span
  >
  <span class="c4p-button-ok" (click)="submit()">
    <span *ngIf="(documentForm.value.id || selectedFile) && documentForm.valid"
      >OK</span
    >
  </span>
</div>
