import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls/form-controls.const';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class ReferrerTypeService {
  public values: IFormDropdownOption[] = [
    { value: '1', label: 'Referrer Type 1' },
    { value: '2', label: 'Referrer Type 2' },
    { value: '3', label: 'Referrer Type 3' },
  ];

  constructor(private masterDataService: MasterDataService) {
    this.getReferrerTypes();
  }

  public getReferrerTypes() {
    this.masterDataService.getMasterData('referrer-types').subscribe((data) => {
      this.values = data;
    });
  }

  public listReferrerType() {
    return this.masterDataService.getMasterData('referrer-types');
  }
}
