<ng-container [formGroup]="formGroup">
  <ng-container [formArrayName]="dynamicFormArray.formArrayName">
    <app-panel-form-row>
      <ng-container
        *ngFor="let dynamicEntry of dynamicFormArray.controls; let i = index"
      >
        <ng-container [ngSwitch]="dynamicEntry.dynamicType">
          <ng-container *ngSwitchCase="'group'">
            <app-dynamic-form-group
              [style]="getParentStyle()"
              [showParentStyle]="showParentStyle"
              [dynamicFormGroup]="dynamicEntry"
              [formGroup]="formArray.controls[i]"
              [groupLabelStyle]="groupLabelStyle"
              [showAddRemoveButtons]="showAddRemoveButtons"
              [showAddButton]="showAddButton"
              [showRemoveButton]="showRemoveButton"
              [showRemoveButtonOverride]="showRemoveButtonOverride"
              [showRemoveButtonLabel]="showRemoveButtonLabel"
              [includeAllowAllPermissions]="includeAllowAllPermissions"
            ></app-dynamic-form-group>
          </ng-container>
          <ng-container *ngSwitchCase="'control'">
            <app-dynamic-form-control
              [dynamicFormInput]="dynamicEntry"
              [formGroup]="formArray"
              [groupLabelStyle]="groupLabelStyle"
              [showAddRemoveButtons]="showAddRemoveButtons"
            ></app-dynamic-form-control>
          </ng-container>
          <button
            *ngIf="showRemoveButtonCheck(dynamicEntry)"
            class="c4p-button"
            (click)="removeArrayElement(i)"
            translate
          >
            {{ showRemoveButtonLabel }}
          </button>
        </ng-container>
      </ng-container>
      <!-- <br /> -->
      <button
        *ngIf="showAddButton"
        class="c4p-button"
        (click)="addArrayElement()"
        translate
      >
        general.actions.AddElement
      </button>
    </app-panel-form-row>
  </ng-container>
</ng-container>
