import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { HttpUtils } from '../../utilities/http.utils';
import { PaginationDataModel } from '../../models/pagination/pagination-data.model';
import { ObjectiveModel } from '../../models/social-area/objective';
import { IFormDropdownOption } from '../../form-controls/form-controls.const';

@Injectable()
export class ObjectiveOverviewService {
  public socialareas$ = new BehaviorSubject<{
    data: ObjectiveModel[];
    count: number;
  }>(null);

  public values: IFormDropdownOption[] = [];

  constructor(
    public http: HttpClient,
    @Inject('environment') public environment: any,
  ) {
    this.getObjectiveValues();
  }

  public getObjectiveValues() {
    this.getObjectives(new PaginationDataModel('value', 'asc', 999), {
      status: 'active',
    }).subscribe((data) => {
      this.values = data.docs.map((x) => {
        return { value: x.value, label: `${x.value} - ${x.name}` };
      });
    });
  }

  public getObjectives(paginationData: PaginationDataModel, filter?: any) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/social-areas',
    });
    const params = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filter,
    );
    return this.http.get<any>(
      `${this.environment.questionsetManagementApiUrl}/objectives`,
      { headers, params },
    );
  }

  public createObjective(objective: ObjectiveModel) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/social-areas',
      entity_type: 'Social-Areas',
      sub_entity_type: 'Objectives',
    });
    return this.http.post<any>(
      `${this.environment.questionsetManagementApiUrl}/objectives`,
      objective,
      {
        headers,
      },
    );
  }

  public deleteObjective(id: number | string) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/social-areas/:socialarea_id',
      entity_type: 'Social-Areas',
      sub_entity_type: 'Objectives',
      sub_entity_type_id: id.toString(),
    });
    return this.http.delete(
      `${this.environment.questionsetManagementApiUrl}/objectives/${id}`,
      {
        headers,
      },
    );
  }

  public updateObjective(objective: ObjectiveModel) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/social-areas/:socialarea_id',
      entity_type: 'Social-Areas',
      sub_entity_type: 'Objectives',
      sub_entity_type_id: objective.id,
    });
    return this.http.patch<ObjectiveModel>(
      `${this.environment.questionsetManagementApiUrl}/objectives/${objective.id}`,
      objective,
      {
        headers,
      },
    );
  }

  public isValueValid(id: number | string, value: number) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/social-areas/:socialarea_id',
      entity_type: 'Social-Areas',
      sub_entity_type: 'Objectives',
      sub_entity_type_id: id.toString(),
    });
    return this.http.get<ObjectiveModel>(
      `${this.environment.questionsetManagementApiUrl}/objectives/check/${id}/${value}`,
      {
        headers,
      },
    );
  }
}
