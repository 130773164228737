export class FunctionInfoModel {
  id: string;
  name!: string;
}
export class CategoryModel {
  id!: string;
  tenantId!: string;
  name!: string;
  funcs: Array<FunctionInfoModel>;
}
