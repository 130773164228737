import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class GuideOnCallService {
  public values: IFormDropdownOption[] = [];

  constructor(private masterDataService: MasterDataService) {
    this.getGuideOnCallValues();
  }

  public getGuideOnCallValues() {
    this.masterDataService.getMasterData('guide-on-call').subscribe((data) => {
      this.values = data.map((data) => {
        return { label: data.label, value: !!data.value };
      });
    });
  }
}
