import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT,
  DEFAULT_CONTROL_DEBOUNCE_TIME_AMOUNT,
} from '../../form-controls/form-controls.const';
import { PaginationDataModel } from '../../models/pagination/pagination-data.model';
import { ITableOption } from '../table-view/table-view.component';
import { CardGridInterface } from './card-grid.interface';

@Component({
  selector: 'app-card-grid',
  templateUrl: './card-grid.component.html',
  styleUrls: ['./card-grid.component.scss'],
  providers: [CardGridInterface],
})
export class CardGridComponent implements OnInit, OnDestroy {
  @Input() options: ITableOption = null;
  @Input() view: string = 'grid';
  @Input() tableMenu: any[];
  @Input() widgetName = '';

  @Output() refreshRequest = new EventEmitter<PaginationDataModel>();

  // This event is triggered when user clicks on Add button
  @Output() addRequest = new EventEmitter<any>();

  // This event is triggered when user clicks on Load existing button
  @Output() loadExistingRequest = new EventEmitter<any>();

  @Output() interface = new EventEmitter<CardGridInterface>();

  public totalRows = 0;
  public pageSize = 10;
  public pageIndex = 0;
  public sortColumn = '';
  public sortDirection = '';
  public search = '';
  public pageSizeOptions = [5, 10, 20, 50];
  public fromRow = 0;
  public toRow = 10;

  private readonly destroyed$ = new Subject<boolean>();
  public data: any[];

  @Input() showSearch = true;

  searchControl = new FormControl();
  itemsPerPageControl = new FormControl();

  gridForm: FormGroup = null;

  get menuActions(): any[] {
    return this.tableMenu
      ? this.tableMenu
      : this.options?.menuActions
      ? this.options.menuActions
      : [];
  }

  constructor(
    private formBuilder: FormBuilder,
    private cardGridInterface: CardGridInterface,
    private cdref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.itemsPerPageControl.patchValue(10);

    if (this.options.dataObservable) {
      this.options.dataObservable
        .pipe(takeUntil(this.destroyed$))
        .subscribe((value: any) => {
          if (value) {
            this.data = [...value.data];
            this.totalRows = value.count;
            if (this.pageIndex > 0 || this.totalRows > 0) {
              this.fromRow = this.pageIndex * this.pageSize + 1;
            } else {
              this.fromRow = 0;
            }
            this.toRow = (this.pageIndex + 1) * this.pageSize;
            if (this.toRow > this.totalRows) this.toRow = this.totalRows;
          }
        });
    }
    this.initSearch();
    this.initPagination();
    this.initInterface();
    this.interface.emit(this.cardGridInterface);
    this.setDefaultValues();
  }

  setDefaultValues() {
    if (this.options.defaultPageSize)
      this.pageSize = this.options.defaultPageSize;
    if (this.options.defaultSortColumn)
      this.sortColumn = this.options.defaultSortColumn;
    if (this.options.defaultSortDirection)
      this.sortDirection = this.options.defaultSortDirection;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private initInterface(): void {
    this.cardGridInterface.search$
      .pipe(
        debounceTime(DEFAULT_CONTROL_DEBOUNCE_TIME_AMOUNT),
        takeUntil(this.destroyed$),
      )
      .subscribe((val) => {
        this.searchControl.patchValue(val);
      });
    this.cardGridInterface.showSearch$
      .pipe(
        debounceTime(DEFAULT_CONTROL_DEBOUNCE_TIME_AMOUNT),
        takeUntil(this.destroyed$),
      )
      .subscribe((val) => {
        this.showSearch = val;
        this.cdref.detectChanges();
      });
    this.cardGridInterface.pageIndex$
      .pipe(
        debounceTime(DEFAULT_CONTROL_DEBOUNCE_TIME_AMOUNT),
        takeUntil(this.destroyed$),
      )
      .subscribe((val) => {
        this.pageIndex = val;
        this.cdref.detectChanges();
        this.refresh();
      });
  }

  private initSearch() {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => this.refresh(true));
  }

  private initPagination() {
    this.itemsPerPageControl.valueChanges
      .pipe(
        debounceTime(DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT),
        takeUntil(this.destroyed$),
      )
      .subscribe((val) => {
        this.pageSize = val;
        this.pageIndex = 0;
        this.refresh();
      });
  }

  public onAdd(): void {
    this.addRequest.emit();
  }

  public onLoadExisting(): void {
    this.loadExistingRequest.emit();
  }

  private refresh(searchControlChanged?: boolean) {
    if (searchControlChanged) this.pageIndex = 0;
    if (!this.sortDirection || !this.sortColumn) {
      this.refreshRequest.emit({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        sortColumn: this.options.defaultSortColumn,
        sortDirection: this.options.defaultSortDirection,
        search: this.searchControl.value,
      });
    } else {
      this.refreshRequest.emit({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        sortColumn: this.sortColumn,
        sortDirection: this.sortDirection,
        search: this.searchControl.value,
      });
    }
  }

  previousPage() {
    if (this.pageIndex > 0) {
      this.pageIndex--;
      this.refresh();
    }
  }

  public handlePage(e: any) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.refresh();
  }

  nextPage() {
    if ((this.pageIndex + 1) * this.pageSize < this.totalRows) {
      this.pageIndex++;
      this.refresh();
    }
  }
}
