import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appRichText',
})
export class CustomRichTextPipe implements PipeTransform {
  transform(value: string, args?: string): string {
    const parsedHint = new DOMParser().parseFromString(value, 'text/html');
    if (parsedHint.body.textContent.toString() === 'null') {
      return '';
    }
    return parsedHint.body.textContent || '';
  }
}
