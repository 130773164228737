import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, JS_DATE_TIME_FORMAT } from '../pipes.const';
import { USE_UTC_DATE } from '../../form-controls/form-controls.const';

@Pipe({
  name: 'appDate',
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(
      value,
      DEFAULT_DATE_FORMAT,
      USE_UTC_DATE ? 'UTC' : null,
    );
  }
}
