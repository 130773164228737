import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationModel } from '../../models/fcm-message/configuration.model';
import { NotificationCategoryModel } from '../../models/fcm-message/notification-category.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationSettingsService {
  private readonly FCM_CONFIGURATION_ORIGIN_PATH = '/configuration';
  private originConfigurationPath = this.FCM_CONFIGURATION_ORIGIN_PATH;

  constructor(
    @Inject('environment') public environment: any,
    public http: HttpClient,
  ) {}

  public getNotificationSubjects(): Observable<NotificationCategoryModel[]> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: this.originConfigurationPath + '/subjects',
    });
    return this.http.get<NotificationCategoryModel[]>(
      `${this.environment.notificationManagementApiUrl}${this.originConfigurationPath}/subjects`,
      {
        headers,
      },
    );
  }

  public getConfigurationsByUserId(
    userId: string,
  ): Observable<ConfigurationModel> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: this.originConfigurationPath + '/:user_id',
      entity_type: 'Notification',
      entity_id: userId,
    });
    return this.http.get<ConfigurationModel>(
      `${this.environment.notificationManagementApiUrl}${this.originConfigurationPath}/${userId}`,
      {
        headers,
      },
    );
  }

  updateConfiguration(configuration: ConfigurationModel) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: this.originConfigurationPath + '/:user_id',
      entity_type: 'Notification',
      entity_id: configuration.userId,
    });

    return this.http
      .put<ConfigurationModel>(
        `${this.environment.notificationManagementApiUrl}${this.originConfigurationPath}/${configuration.userId}`,
        configuration,
        {
          headers,
        },
      )
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }
}
