<div class="c4p-form-input">
  <mat-form-field>
    <mat-label>{{skipTranslate ? placeholder : (placeholder | translate)}}</mat-label>
    <span *ngIf="prefix" matPrefix>{{ prefix }}</span>
    <input
    *ngIf="mask"
      type="{{ type }}"
      matInput
      [formControl]="valueControl"
      [errorStateMatcher]="errorStateMatcher"
      [required]="required"
      (blur)="onTouchedCallback()"
      [readonly]="readonly"
      [mask]="mask"
      [dropSpecialCharacters]="dropSpecialCharacters"
      [step]="step"
      (keypress)="numberOnly($event)"
    />
    <input
    *ngIf="!mask"
      type="{{ type }}"
      matInput
      [formControl]="valueControl"
      [errorStateMatcher]="errorStateMatcher"
      [required]="required"
      (blur)="onTouchedCallback()"
      [readonly]="readonly"
      [dropSpecialCharacters]="dropSpecialCharacters"
      [step]="step"
      (keypress)="numberOnly($event)"
    />
    <mat-error *ngFor="let error of ngControl.errors | keyvalue">
      <span *ngIf="errorLabels && errorLabels[error.key]; else defaultError">
        {{ errorLabels[error.key] | translate }}
      </span>
      <ng-container
        #defaultError
        *ngIf="DEFAULT_ERRORS.has(error.key) && !errorLabels[error.key]"
      >
        <span>{{ DEFAULT_ERRORS.get(error.key) | translate }}&nbsp;</span>
        <span *ngIf="error.key === 'min'">{{ error.value?.min }}</span>
        <span *ngIf="error.key === 'max'">{{ error.value?.max }}</span>
        <span *ngIf="error.key === 'maxlength' || 'minlength'">{{
          error.value?.requiredLength
        }}</span>
        <span *ngIf="error.key === 'modulo'"> {{ error.value | json }}</span>
      </ng-container>
    </mat-error>
    <span *ngIf="suffix" matSuffix>{{ suffix }}</span>
  </mat-form-field>
</div>
