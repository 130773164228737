import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-panel-grid',
  templateUrl: './panel-grid.component.html',
  styleUrls: ['./panel-grid.component.scss'],
})
export class PanelGridComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
