import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IAdditionalMenu } from '../../general-components/page-menu/IAdditionalMenu';
import { IMenuItems } from '../../general-components/page-menu/IMenuItems';

@Injectable({
  providedIn: 'root',
})
export class PageMenuService {
  menuItems$: BehaviorSubject<IMenuItems[]> = new BehaviorSubject(null);
  additionalMenus$: BehaviorSubject<IAdditionalMenu[]> = new BehaviorSubject(
    null,
  );

  constructor() {}

  setMenuItems(menuItems: IMenuItems[], additionalMenus?: IAdditionalMenu[]) {
    this.menuItems$.next(menuItems);
    this.additionalMenus$.next(additionalMenus);
  }
}
