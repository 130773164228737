export class QuestionSetAnswerListModel {
  id!: string;
  title!: string;
  totalQuestions: number;
  mismatchExists: boolean;
  mandatoryQuestions: number;
  answeredQuestions: number;
  answeredMandatoryQuestions: number;
  updateAvailable: boolean;
  roles: {
    read: string[];
    readWrite: string[];
  };
  formatCompleteness: number;
  updatedAt: Date;
  updatedBy: {
    fullName: string;
  };

  constructor() {
    this.roles = {
      read: [],
      readWrite: [],
    };
  }
}
