<div class="c4p-form-input">
  <form [formGroup]="formGroup">
    <mat-form-field>
      <input
        matInput
        placeholder="{{ label | translate }}"
        formControlName="field"
        [required]="mandatory"
        onkeyup="
        var v = this.value;
        if (v.match(/^\d{2}$/) !== null) {
            this.value = v + ':';
        } else if (v.match(/^\d{2}\:\d{2}$/) !== null) {
            this.value = v + '-';
        } else if (v.match(/^\d{2}\:\d{2}\-\d{2}$/) !== null) {
            this.value = v + ':';
        }        
        "
        maxlength="11"
      />

      <mat-error *ngIf="formGroup.get('field').hasError('required')">
        Field is mandatory!
      </mat-error>
    </mat-form-field>
  </form>
</div>
