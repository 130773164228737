<app-panel type="popup" [formGroup]="avatarForm">
  <app-panel-title title="general.titles.EditAvatarImage"></app-panel-title>

  <app-panel-form-row>
    <app-form-input-checkbox
      placeholder="general.labels.UseCustomPicture"
      formControlName="useCustomImage"
    ></app-form-input-checkbox>
  </app-panel-form-row>

  <ng-container *ngIf="avatarForm.value.useCustomImage">
    <app-panel-form-row>
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        roundCropper="true"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
        [imageBase64]="data.customAvatar"
        resizeToWidth="128"
      ></image-cropper>
    </app-panel-form-row>

    <app-panel-form-row>
      <div style="height: 20px"></div>
    </app-panel-form-row>

    <app-panel-form-row mode="-center">
      <label class="c4p-button" for="filedialog" translate
        >general.actions.UploadPicture</label
      >
      <input
        id="filedialog"
        type="file"
        (change)="fileChangeEvent($event)"
        style="display: none"
      />
    </app-panel-form-row>
    <app-panel-form-row>
      <div style="height: 20px"></div>
    </app-panel-form-row>
  </ng-container>
</app-panel>

<div style="display: flex">
  <span class="c4p-button-cancel" (click)="onDismiss()" translate
    >general.labels.Cancel</span
  >
  <span class="c4p-button-ok" (click)="onConfirm()">
    <span>OK</span>
  </span>
</div>
