import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PermissionService } from '../../../../../services';
import { CareProgramService } from '../../../services/care-program.service';

@Component({
  selector: 'lib-care-program-formats-section',
  templateUrl: './care-program-formats-section.component.html',
  styleUrls: ['./care-program-formats-section.component.scss'],
})
export class CareProgramFormatsSectionComponent implements OnInit, OnDestroy {
  public viewMode: boolean = false;
  public entityId: string;
  public entityVersion = 1;
  public services = [];
  public isEditable: boolean = false;

  private readonly destroyed$ = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    public careprogramService: CareProgramService,
    private permissionService: PermissionService,
  ) {}

  ngOnInit(): void {
    this.careprogramService.selectedCareProgram$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        if (result) {
          this.viewMode = this.route.parent.snapshot.routeConfig.data.view;
          this.entityId = result.id;
          this.services = result.services?.map((svc) => svc.globalServiceId);
          this.isEditable =
            result.status === 'active' &&
            this.permissionService.getPermission(
              'careprogram:zpm:carefile:full',
            ) &&
            !this.viewMode;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
