import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CareProgramService } from '../../../services/care-program.service';
import { CarefileEmployeeService } from '../../../services/carefile-employee.service';
import { ClientOverviewService } from '../../../services/client-overview.service';
import { Moment } from 'moment';
import { DateRange } from 'moment-range';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { DateUtils } from '../../../../../utilities';

@Component({
  selector: 'lib-main-therapist-detail',
  templateUrl: './main-therapist-detail.component.html',
  styleUrls: ['./main-therapist-detail.component.css'],
})
export class MainTherapistDetailComponent implements OnInit {
  mainTherapistFilterObject: any = {};
  openedStream: string | null = null;
  currentIndex: any;
  errorMessage: string;

  public get careprogramForm(): FormGroup {
    return this.careprogramService.careProgramForm;
  }

  get mainTherapistFormArray(): FormArray {
    return this.careprogramForm.get('therapists') as FormArray;
  }

  private get registartionDateControl(): FormControl {
    return this.careprogramService.careProgramForm.get(
      'registrationDate',
    ) as FormControl;
  }

  private get endDateControl(): FormControl {
    return this.careprogramService.careProgramForm.get(
      'endDate',
    ) as FormControl;
  }

  private get servicesFormControl(): FormControl {
    return this.careprogramService.careProgramForm.get(
      'services',
    ) as FormControl;
  }

  public mainTherapistForm: FormGroup = null!;
  maxEndDate: Date | null;
  minEndDate: Date | null;
  minStartDate: Date | null;
  maxStartDate: Date | null;
  public viewOnly: boolean;
  private destroyed$ = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<MainTherapistDetailComponent>,
    private formBuilder: FormBuilder,
    public careprogramService: CareProgramService,
    private clientService: ClientOverviewService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    public carefileEmployeeService: CarefileEmployeeService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.mainTherapistForm = this.mainTherapistBuildForm()
    if (this.data.mainTherapist) {
      this.mainTherapistForm.patchValue(this.data.mainTherapist);
    }
    this.viewOnly = this.data.viewOnly;
    if (this.viewOnly) this.mainTherapistForm.disable();
    this.currentIndex = this.data.index;
    this.setMainTherapistFilters();
    this.validateDates();
    this.initialSubs();
  }

  initialSubs() {
    this.mainTherapistForm.valueChanges.subscribe((val) => {
      this.errorMessage = '';
    });
  }

  onConfirm() {
    if (this.mainTherapistForm.valid) {
      const mainTherapist = {
        id: this.mainTherapistForm.get('employee').value.id,
        fullName: this.mainTherapistForm.get('employee').value.fullName,
        employeeNumber:
          this.mainTherapistForm.get('employee').value.employeeNumber,
        startDate: this.mainTherapistForm.get('startDate').value,
        endDate: this.mainTherapistForm.get('endDate').value,
      };
      const isValidStartDate = moment(mainTherapist.startDate).isSameOrBefore(
        moment(
          mainTherapist.endDate
            ? mainTherapist.endDate
            : new Date('2999/12/31'),
        ),
      );
      if (!isValidStartDate) {
        this.errorMessage = this.translate.instant(
          'general.messages.StartDateBeforeEndDate',
        );
        return;
      }
      const isStartDateOverlapped =
        moment(mainTherapist.startDate).isBefore(moment(this.minStartDate)) ||
        moment(mainTherapist.startDate).isAfter(moment(this.maxStartDate));
      const isEndDateOverlapped =
        moment(mainTherapist.endDate).isBefore(moment(this.minEndDate)) ||
        moment(mainTherapist.endDate).isAfter(moment(this.maxEndDate));
      const isOverlapped =
        this.checkOverlapInDateLimits(mainTherapist.startDate) ||
        this.checkOverlapInDateLimits(mainTherapist.endDate);
      if (isStartDateOverlapped || isEndDateOverlapped || isOverlapped) {
        this.errorMessage = this.translate.instant(
          'careprogram.messages.OverlappingTherapists',
        );
        return;
      }
      this.dialogRef.close(mainTherapist);
    } else this.mainTherapistForm.markAllAsTouched();
  }

  onDismiss() {
    this.dialogRef.close(null);
  }

  validateDates() {
    this.getMaxEndDate();
    this.getMinStartDate();
    this.getMaxStartDate();
    this.getMinEndDate();
  }

  private setMainTherapistFilters() {
    this.clientService.selectedCarefileId$
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.mainTherapistFilterObject = {
          carefileId: value?.carefileId,
          involved: true,
          registartionStartDate: this.registartionDateControl.value,
          'carefileServices.ids': this.servicesFormControl.value.map(
            (s) => s.id,
          ),
        };

        if (this.endDateControl.value)
          this.mainTherapistFilterObject.registartionEndDate =
            this.endDateControl.value;
        if (value && !value?.initValue) {
          this.mainTherapistFormArray.clear();
        }
        this.cdr.detectChanges();
      });
  }

  onchangeDate(changeEvent) {
    if (
      this.mainTherapistFormArray.value.findIndex(
        (dateObj, index) =>
          index != this.currentIndex &&
          !!dateObj.startDate &&
          moment(dateObj.startDate).isAfter(
            this.mainTherapistForm.value.startDate,
            'days',
          ),
      ) > -1
    ) {
      this.mainTherapistForm.get('endDate').setValidators(Validators.required);
      this.mainTherapistForm
        .get('endDate')
        .updateValueAndValidity({ emitEvent: false });
    } else {
      this.mainTherapistForm.get('endDate').clearValidators();
      this.mainTherapistForm
        .get('endDate')
        .updateValueAndValidity({ emitEvent: false });
    }
    this.validateDates();
    this.mainTherapistFormArray.markAllAsTouched();
  }

  getMaxEndDate() {
    if (
      !this.mainTherapistForm.value.startDate &&
      !this.mainTherapistForm.value.endDate
    ) {
      return (this.maxEndDate = null);
    }
    let chosenDate;
    if (!!this.mainTherapistForm.value.endDate) {
      chosenDate = new Date(this.mainTherapistForm.value.endDate);
    } else if (!!this.mainTherapistForm.value.startDate) {
      chosenDate = new Date(this.mainTherapistForm.value.startDate);
    }
    const startDates = [...this.mainTherapistFormArray.value]
      .filter((dateEndDate, index) => index != this.currentIndex)
      .map((dateStartDate) => new Date(dateStartDate.startDate).getTime())
      .filter((filteredDate) => filteredDate > chosenDate?.valueOf());
    let maxEndDate: any =
      startDates.length > 0 ? moment(Math.min(...startDates)).toDate() : null;
    this.maxEndDate = maxEndDate;
  }

  getMinEndDate() {
    if (
      !this.mainTherapistForm.value.startDate &&
      !this.mainTherapistForm.value.endDate
    ) {
      return (this.minEndDate = null);
    }
    if (!!this.mainTherapistForm.value.startDate) {
      return (this.minEndDate = new Date(
        this.mainTherapistForm.value.startDate,
      ));
    } else if (!!this.mainTherapistForm.value.endDate) {
      const chosenDate = new Date(this.mainTherapistForm.value.endDate);
      const startDates = [...this.mainTherapistFormArray.value]
        .filter((dateEndDate, index) => index != this.currentIndex)
        .map((dateStartDate) => new Date(dateStartDate.startDate).getTime())
        .filter((filteredDate) => filteredDate < chosenDate?.valueOf());
      const minEndDate =
        startDates.length > 0 ? moment(Math.max(...startDates)).toDate() : null;
      this.minEndDate = minEndDate;
    }
  }

  getMinStartDate() {
    if (
      !this.mainTherapistForm.value.startDate &&
      !this.mainTherapistForm.value.endDate
    ) {
      return (this.minStartDate = null);
    }
    let chosenStartDate;
    if (!!this.mainTherapistForm.value.endDate) {
      chosenStartDate = new Date(this.mainTherapistForm.value.endDate);
    } else if (!!this.mainTherapistForm.value.startDate) {
      chosenStartDate = new Date(this.mainTherapistForm.value.startDate);
    }
    const endDates = [...this.mainTherapistFormArray.value]
      .filter(
        (dateEndDate, index) =>
          index != this.currentIndex && !!dateEndDate.endDate,
      )
      .map((dateStartDate) => new Date(dateStartDate.endDate!).getTime())
      .filter(
        (filteredStartDate) => filteredStartDate < chosenStartDate?.valueOf(),
      );
    const minStartDate =
      endDates.length > 0 ? moment(Math.max(...endDates)).toDate() : null;
    this.minStartDate = minStartDate;
  }

  getMaxStartDate() {
    if (
      !this.mainTherapistForm.value.startDate &&
      !this.mainTherapistForm.value.endDate
    ) {
      return (this.maxStartDate = null);
    }
    let chosenDate;
    if (!!this.mainTherapistForm.value.endDate) {
      return (this.maxStartDate = new Date(
        this.mainTherapistForm.value.endDate,
      ));
    } else if (!!this.mainTherapistForm.value.startDate) {
      chosenDate = new Date(this.mainTherapistForm.value.startDate);
      const startDates = [...this.mainTherapistFormArray.value]
        .filter(
          (dateStartDate, index) =>
            index != this.currentIndex && !!dateStartDate.startDate,
        )
        .map((dateStartDate) => new Date(dateStartDate.startDate!).getTime())
        .filter(
          (filteredStartDate) => filteredStartDate > chosenDate?.valueOf(),
        );
      const maxStartDate =
        startDates.length > 0 ? moment(Math.max(...startDates)).toDate() : null;
      this.maxStartDate = maxStartDate;
    }
  }

  private checkOverlapInDateLimits(chosenDateTime: number): boolean {
    const chosenMoment = moment(chosenDateTime);
    for (let [
      index,
      dateObject,
    ] of this.mainTherapistFormArray.value.entries()) {
      const endDate = dateObject.endDate ? dateObject.endDate : '2999/12/31';
      if (
        index != this.currentIndex &&
        chosenMoment.within(
          new DateRange(
            moment(dateObject.startDate).startOf('day'),
            moment(endDate).startOf('day'),
          ),
        )
      ) {
        return true;
      }
    }
    return false;
  }

  dateFilter = (d: Date | Moment | null): boolean => {
    // waiting for opening the datepicker box before filtering dates
    if (this.openedStream !== null) {
      let pickedDate = d || DateUtils.getCurrentTenantDayMoment();
      if (!moment.isMoment(d)) {
        pickedDate = moment(d);
      }

      if (pickedDate) {
        return !this.checkOverlapInDateLimits((pickedDate as Moment).valueOf());
      }
    }
    return false;
  };

  onOpened(controlName: string): void {
    this.openedStream = controlName;
  }

  onClosed(): void {
    this.openedStream = null;
  }
  mainTherapistBuildForm(){
    return  this.formBuilder.group({
      employee: [null, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null],
    });
  }
}
