import { PermissionService } from './../../services/permissions/permission.service';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  AnswerModel,
  PaginationDataModel,
  QuestionSetAnswerListModel,
  QuestionSetAnswerModel,
} from '../../models';
import { AvailableQuestionSetModel } from '../../models/question-set-answer/available-questionset.model';
import { HttpUtils } from '../../utilities';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaginationResultModel } from '../../models/pagination/pagination-result.model';

@Injectable({
  providedIn: 'root',
})
export class QuestionSetRenderService {
  public questionSetAnswerModel: QuestionSetAnswerModel =
    new QuestionSetAnswerModel();

  constructor(
    private http: HttpClient,
    @Inject('environment') public environment: any,
    private permissionService: PermissionService,
  ) {}

  public availableQuestionSets$ = new BehaviorSubject<{
    data: AvailableQuestionSetModel[];
    count: number;
  }>(null);

  public availableQuestionSets: AvailableQuestionSetModel[] = [];
  public assignedQuestionSets: AvailableQuestionSetModel[] = [];

  public getQuestionSets(
    entity: string,
    entityId: string | number,
    entityVersion: number,
    paginationData: PaginationDataModel,
    filterData?: any,
  ): Observable<PaginationResultModel<QuestionSetAnswerListModel>> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/questionset-answers/:entity/:entity_id/:entity_version',
      current_role: this.permissionService.currentRole,
      entity_type: entity,
      entity_id: entityId.toString(),
    });
    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );

    return this.http.get<any>(
      `${this.environment.questionsetManagementApiUrl}/questionset-answers/${entity}/${entityId}/${entityVersion}`,
      {
        params,
        headers,
      },
    );
  }

  public getAvailableQuestionSets(
    entity: string,
    entityId: string | number,
    entityVersion: number,
    isCoresponsible: boolean,
    services: string[],
    search?: string,
  ) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path:
        '/questionset-answers/:entity/:entity_id/:entity_version/available-questionsets',
      current_role: this.permissionService.currentRole,
      entity_type: entity,
      entity_id: entityId.toString(),
      sub_entity_type: 'Questions-Sets',
    });

    let params: HttpParams = new HttpParams();

    if (search) params = params.append('search', search);
    if (isCoresponsible)
      params = params.append('coresponsible', isCoresponsible.toString());
    if (services) params = params.append('services', services.toString());

    return this.http.get<AvailableQuestionSetModel[]>(
      `${this.environment.questionsetManagementApiUrl}/questionset-answers/${entity}/${entityId}/${entityVersion}/available-questionsets`,
      {
        params,
        headers,
      },
    );
  }

  public addQuestionSets(
    entity: string,
    entityId: string | number,
    entityVersion: number,
    questionSetIds: string[],
    inheritingEntityType?: string,
    inheritingEntityId?: string,
    inheritingEntityVersion?: number,
  ) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/questionset-answers/:entity/:entity_id/:entity_version',
      entity_type: entity,
      entity_id: entityId.toString(),
      sub_entity_type: 'QuestionAnswers',
    });

    let body: any = { questionSetIds };

    if (inheritingEntityId && inheritingEntityType) {
      body.inheritingEntityId = inheritingEntityId;
      body.inheritingEntityType = inheritingEntityType;
      body.inheritingEntityVersion = inheritingEntityVersion
        ? inheritingEntityVersion
        : 1;
    }

    return this.http.post<any>(
      `${this.environment.questionsetManagementApiUrl}/questionset-answers/${entity}/${entityId}/${entityVersion}`,
      body,
      {
        headers,
      },
    );
  }

  public getAnswers(
    entity: string,
    entityId: string | number,
    entityVersion: number,
    questionSetAnswerId: string | number,
    filter: any,
  ) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path:
        '/question-answers/:entity/:entity_id/:entity_version/:question_set_answer_id',
      entity_type: entity,
      entity_id: entityId ? entityId.toString() : '',
      sub_entity_id: questionSetAnswerId ? questionSetAnswerId.toString() : '',
      sub_entity_type: 'QuestionAnswers',
    });
    return this.http.get<any>(
      `${this.environment.questionsetManagementApiUrl}/question-answers/${entity}/${entityId}/${entityVersion}/${questionSetAnswerId}?mode=${filter.answeredFilter}&mandatory=${filter.mandatoryFilter}`,
      {
        headers,
      },
    );
  }

  public updateAnswer(answer: AnswerModel) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path:
        '/question-answers/:entity/:entity_id/:entity_version/:question_set_answer_id',
      entity_type: answer.entityType,
      entity_id: answer ? answer?.entityId.toString() : '',
      sub_entity_id: answer ? answer?.questionSetAnswerId.toString() : '',
      sub_entity_type: 'QuestionAnswers',
    });
    return this.http.put<any>(
      `${this.environment.questionsetManagementApiUrl}/question-answers/${answer.entityType}/${answer.entityId}/${answer.entityVersion}/${answer.questionSetAnswerId}`,
      answer,
      {
        headers,
      },
    );
  }

  public deleteQuestionSet(
    questionSetAnswerId: string,
    entity: string,
    entityId: string,
    entityVersion: number,
    bypassConfirmation: string=  ""
  ) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path:
        '/questionset-answers/:entity/:entity_id/:entity_version/:question_set_answer_id',
      entity_type: entity,
      entity_id: entityId.toString(),
      sub_entity_id: questionSetAnswerId.toString(),
      sub_entity_type: 'QuestionAnswers',
      bypassConfirmation: bypassConfirmation,
    });
    return this.http.delete<any>(
      `${this.environment.questionsetManagementApiUrl}/questionset-answers/${entity}/${entityId}/${entityVersion}/${questionSetAnswerId}`,
      {
        headers,
      },
    );
  }

  public getAvailableUpdateForQuestionSetAnswer(
    entity: string,
    entityId: string | number,
    entityVersion: number,
    questionSetAnswerId: string | number,
  ) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path:
        '/questionset-answers/:entity/:entity_id/:entity_version/update/:question_set_answer_id',
      entity_type: entity,
      entity_id: entityId.toString(),
      sub_entity_id: questionSetAnswerId.toString(),
      sub_entity_type: 'QuestionAnswers',
    });
    return this.http.get<any>(
      `${this.environment.questionsetManagementApiUrl}/questionset-answers/${entity}/${entityId}/${entityVersion}/update/${questionSetAnswerId}`,
      {
        headers,
      },
    );
  }

  public applyAvailableUpdateForQuestionSetAnswer(
    entity: string,
    entityId: string | number,
    entityVersion: number,
    questionSetAnswerId: string | number,
    questionSetAnswer: any,
  ) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path:
        '/questionset-answers/:entity/:entity_id/:entity_version/update/:question_set_answer_id',
      entity_type: entity,
      entity_id: entityId.toString(),
      sub_entity_id: questionSetAnswerId.toString(),
      sub_entity_type: 'QuestionAnswers',
    });
    return this.http.patch<any>(
      `${this.environment.questionsetManagementApiUrl}/questionset-answers/${entity}/${entityId}/${entityVersion}/update/${questionSetAnswerId}`,
      questionSetAnswer,
      {
        headers,
      },
    );
  }
}
