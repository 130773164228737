import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../../services';
import { STATUS } from '../pipes.const';

@Pipe({
  name: 'invoiceStatusTransformation',
})
export class InvoiceStatusTransformationPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}
  transform(value: string): string {
    if (value === STATUS.DECLARED || value === STATUS.INVOICED || value === STATUS.CORRECTION_DECLARED) {
      value = STATUS.SUBMITTED;
    } else if (value === STATUS.APPROVED) {
      value = STATUS.ACCEPTED;
    }
    const upperCased = value[0].toUpperCase() + value.slice(1);
    return this.languageService.translation('general.status.' + upperCased);
  }
}
