<app-page>
  <ng-container *appTopBar>
    <app-page-tab>
      <div class="c4p-tab" translate>document.labels.Categories</div>
    </app-page-tab>

    <app-page-menu></app-page-menu>
  </ng-container>

  <app-page-content>
    <app-panel>
      <app-table-view
        [showSearch]="!toggleFilters"
        [options]="tableOptions"
        (addRequest)="create()"
        (refreshRequest)="tableView = $event; refresh()"
        width="-nowidth"
        style="width: 500px; margin: 0px 0px 11px 10px"
      >
      </app-table-view>
    </app-panel>
  </app-page-content>
</app-page>
