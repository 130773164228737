export enum StatusEnum {
  Unknown = 'unknown',
  Active = 'active',
  Inactive = 'inactive',
  Deleted = 'deleted',
  Draft = 'draft',
  ZpmDraft = 'zpm-draft',
  Open = 'open',
  Closed = 'closed',
  Sent = 'sent',
  Approved = 'approved', // STILL USED BY ICOM, NOT BY REST OF C4P
  Rejected = 'rejected',
  Processed = 'processed',
  Final = 'final',
  Ready = 'ready',
  Processing = 'processing',
  Compiling = 'compiling',
  Compiled = 'compiled',
  Failed = 'failed',
  //  Invoiced = 'invoiced', // DEPRECATED
  //  Declared = 'declared', // DEPRECATED
  Archived = 'archived',
  Accepted = 'accepted',
  Correction = 'correction',
  Proposed = 'proposed',
  Declined = 'declined',
  Credited = 'credited',
  // Partially_approved = 'partially_approved', // DEPRECATED
  Partially_credited = 'partially_credited',
  Partially_declined_credited = 'partially_declined_credited',
  Partially_accepted = 'partially_accepted',

  Submitted = 'submitted',
}
