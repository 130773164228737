import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class DriversLicenceService {
  public values: IFormDropdownOption[] = [];
  public lookupValues: string[] = [];

  constructor(private masterDataService: MasterDataService) {
    this.getDriversLicenseValues();
  }

  public getDriversLicenseValues() {
    this.masterDataService
      .getMasterData('drivers-license')
      .subscribe((data) => {
        this.values = data.map((data) => {
          return { label: data.label, value: !!data.value };
        });
        for (let item of this.values) {
          this.lookupValues[item.value] = item.label;
        }
      });
  }
}
