import { Inject, Injectable, Optional } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { UserInfoService } from './../../services/user-info/user-info.service';
import * as moment_ from 'moment-timezone';

const moment = moment_;

@Injectable()
export class CustomMomentDateAdapter extends MomentDateAdapter {
  tenantTimezone: string;

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
    private userService: UserInfoService,
  ) {
    super(dateLocale);
    this.tenantTimezone =
      this.userService.tenantTimeZone ||
      JSON.parse(localStorage.getItem('tenantTimeZone'));
  }
  minimumDate: moment.Moment;
  maximumDate: moment.Moment;

  static TIMEZONE = 'Africa/Accra';

  createDate(year: number, month: number, date: number): moment.Moment {
    if (month < 0 || month > 11) {
      throw Error(
        `Invalid month index "${month}". Month index has to be between 0 and 11.`,
      );
    }

    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }

    const monthString = ('0' + (month + 1)).slice(-2);
    const yearSting = ('0' + date).slice(-2);
    const dateString = `${year}-${monthString}-${yearSting} 00:00`;
    const result = moment.tz(dateString, CustomMomentDateAdapter.TIMEZONE);

    if (!result.isValid()) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }
    return result;
  }

  deserialize(value: any): moment.Moment | null {
    let date;
    if (value instanceof Date) {
      date = this._createMoment2(value).locale(this.locale);
    } else if (this.isDateInstance(value)) {
      return this.clone(value);
    }
    if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      date = this._createMoment2(value, moment.ISO_8601).locale(this.locale);
    }
    if (date && this.isValid(date)) {
      return this._createMoment2(date).locale(this.locale);
    }
    return super.deserialize(value);
  }

  parse(value: any, parseFormat: string | string[]): moment.Moment | null {
    if (value && typeof value === 'string') {
      if (moment(value, 'DD/MM/YYYY', true).isValid()) {
        return this._createMoment2(value, parseFormat, this.locale, true);
      }
      return null;
    }
    return value ? this._createMoment2(value).locale(this.locale) : null;
  }

  today(): moment.Moment {
    return moment().tz(this.tenantTimezone).local(this.locale);
  }

  clampDate(
    date: moment.Moment,
    min?: moment.Moment,
    max?: moment.Moment,
    format?,
    locale?,
  ): moment.Moment {
    if (min) this.minimumDate = min;
    if (max) this.maximumDate = max;
    return moment(date, format, locale);
  }

  private _createMoment2(
    date: moment.MomentInput,
    format?: moment.MomentFormatSpecification,
    locale?: string,
    isStringValue = false,
  ): moment.Moment {
    let date2;
    if (isStringValue) {
      date2 = this.clampDate(
        moment(date, format, locale),
        this.minimumDate,
        this.maximumDate,
      ).format('YYYY-MM-DD');
    } else {
      date2 = moment(date, format, locale);
    }
    return moment.tz(date2, CustomMomentDateAdapter.TIMEZONE);
  }
}
