import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FormInputTextComponent } from '../../form-controls/form-input-text/form-input-text.component';

@Component({
  selector: 'app-employee-link',
  templateUrl: './employee-link.component.html',
  styleUrls: ['./employee-link.component.scss'],
})
export class EmployeeLinkComponent implements OnInit, OnChanges {
  @Input() employee: any;
  @Input() placeholder: string;
  @ViewChild(FormInputTextComponent) formInputText: FormInputTextComponent;
  employeeControl = new FormControl({ value: null, disabled: true });

  constructor(
    private el: ElementRef,
    private router: Router,
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const employee = changes.employee?.currentValue;
    if (employee) {
      this.employeeControl.setValue(employee.fullName);
      const nativeInput =
        this.el.nativeElement.querySelector('input[matinput]');
      const employeeLink = document.createElement('a');
      if (employee) {
        employeeLink.innerHTML = employee.fullName;
        employeeLink.href = 'employees/' + employee.id;
        employeeLink.className = 'c4p-link';
        employeeLink.onclick = ($event) =>
          this.directToEmployee($event, employee.id);
        nativeInput && nativeInput.replaceWith(employeeLink);
      }
    }
  }

  directToEmployee(event: any, employeeId: string): any {
    event.preventDefault();
    this.router.navigate(['employees', employeeId]);
  }
}
