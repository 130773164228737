import { AbstractDynamicElement } from './abstract-dynamic.model';
import { DynamicFormInputOrGroup } from './dynamic-form-types';

export class DynamicFormArray extends AbstractDynamicElement {
  controls: Array<DynamicFormInputOrGroup>;
  formArrayName: string;

  constructor(formArrayName: string) {
    super();
    this.formArrayName = formArrayName;
    this._dynamicType = 'array';
    this.controls = [];
  }
}
