import { EmployeePhoneModel } from './employee-phone.model';
import { EmployeeBigNumberModel } from './employee-big-number.model';

export class EmployeeModel {
  id!: string;
  employeeNumber!: number | string;
  avatar: string | null;
  tenantId!: number | string;
  teams: Array<String> | null;
  isEmployed!: boolean;
  isDeleted!: boolean;
  gender!: number;
  initials: string | null;
  prefix: string | null;
  firstName!: string;
  lastName!: string;
  name!: string;
  dateOfBirth!: Date;
  professionalTitle: string | null;
  primaryEmail: string;
  secondaryEmail: string | null;
  phones: Array<EmployeePhoneModel> | null;
  bigNumbers: Array<EmployeeBigNumberModel> | null;
  preferredLanguage: string | null | undefined;
  status: string | null;
  function?: string;
  functions?: string[];

  // frontend calculated
  fullName: string;

  createdAt!: Date;
  createdBy!: number | string;
  updatedAt: Date | null | undefined;
  updatedBy: number | string | null | undefined;

  constructor(employeeData?: any) {
    if (employeeData) {
      Object.assign(this, employeeData);
      this.fullName = this.calculateFullName();
    }
  }

  private calculateFullName(): string {
    if (this.prefix && this.prefix != '') {
      return this.firstName + ' ' + this.prefix + ' ' + this.lastName;
    } else {
      return this.firstName + ' ' + this.lastName;
    }
  }
}
