@if(!sideSheetContent){
  <app-panel type="popup" class="addFormatsPopup">
  <div *ngIf="displayWarning" class="c4p-popup-content-top">
    <div class="c4p-popup-content-left-part">
      <mat-icon matPrefix class="c4p-indicator-icon c4p-indicator-icon-false"
        >error_outline</mat-icon
      >
    </div>
    <div class="c4p-popup-content-right-part">
      <p class="c4p-font-medium">
        <span translate>{{ warningText }}</span>
      </p>
    </div>
  </div>
  <app-table-view
    [options]="tableOptions"
    (refreshRequest)="refresh($event)"
  ></app-table-view>

  <div style="display: flex">
    <span class="c4p-button-cancel" (click)="onDismiss()" translate
      >general.labels.Cancel</span
    >
    <span class="c4p-button-ok" (click)="onConfirm()">
      <span>OK</span>
    </span>
  </div>
</app-panel>
} @else {
  <app-panel>
    <div *ngIf="displayWarning" class="c4p-popup-content-top">
      <div class="c4p-popup-content-left-part">
        <mat-icon matPrefix class="c4p-indicator-icon c4p-indicator-icon-false"
        >error_outline</mat-icon
        >
      </div>
      <div class="c4p-popup-content-right-part">
        <p class="c4p-font-medium">
          <span translate>{{ warningText }}</span>
        </p>
      </div>
    </div>
    <app-table-view
      [options]="tableOptions"
      (refreshRequest)="refresh($event)"
    ></app-table-view>
  </app-panel>
}
