<div style="width: 500px; margin-left: auto;">
  <app-panel
  [type]="'popup-share-and-sign'"
  style="height: -webkit-fill-available;
  overflow-x: hidden;
  display: flex;
  justify-content: flex-end">
    <app-panel-title
      title="document.labels.SignatureDetails"
    >
      <ng-container panelButtons>
        <div style="cursor: pointer; margin-right: -10px" (click)="onCloseDialog()">
          <mat-icon style="height: 50%; font-size: medium;">close</mat-icon>
        </div>
      </ng-container>
    </app-panel-title>
    <app-panel-form-row>
      <app-label
        *ngIf="this.data"
        [labelValue]="{
                  label: 'document.labels.RequestedBy',
                  value: data?.signedVersion?.signatureInfo?.createdBy?.fullName
                }"
      >
      </app-label>
      <app-label
        *ngIf="this.data"
        [labelValue]="{
                  label: 'document.labels.Signatory',
                  value: data.signedVersion?.signatureInfo?.signedBy?.fullName
                }"
      >
      </app-label>
    </app-panel-form-row>
    <app-panel-form-row>
      <app-label
        *ngIf="this.data"
        [labelValue]="{
                  label: 'document.labels.SignatureKey',
                  value: data.signatureKey
                }"
      >
      </app-label>
      <app-label
        *ngIf="this.data"
        [labelValue]="{
                  label: 'document.labels.SignedOn',
                  value: data.signedVersion?.signatureInfo?.signedDate | appDate
                }"
      >
      </app-label>
    </app-panel-form-row>
    <app-panel-form-row>
      <app-label
        *ngIf="this.data"
        [labelValue]="{
                  label: 'document.labels.SignerRole',
                  value: data?.signedVersion?.signatureInfo?.signerRole
                }"
      >
      </app-label>
    </app-panel-form-row>
    <app-panel-form-row *ngIf="this.data">
      <div style="margin-left: 20px;display: flex; flex-direction: column; align-items: center; margin-top: 10px;">
        <div style="font-size: 12px;">{{ 'document.labels.OriginalDocument' | translate }}</div>
        <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
          <mat-icon matPrefix class="c4p-action-badge" [tooltip]="'general.actions.View'| translate" (click)="viewFile(DIGITAL_SIGNATURE.ORIGINAL)" style="margin-right: 20px; font-size: 24px;">visibility</mat-icon>
          <mat-icon aria-hidden='false' class="c4p-action-badge" [tooltip]="'general.actions.Download'| translate" (click)="onClickButton(DIGITAL_SIGNATURE.ORIGINAL)" style="font-size: 24px;">cloud_download</mat-icon>
        </div>
      </div>
      <div style="margin-left: 130px;display: flex; flex-direction: column; align-items: center; margin-top: 10px;">
        <div style="font-size: 12px;">{{ 'document.labels.SignedDocument' | translate }}</div>
        <div style="display: flex; align-items: center; margin-bottom: 8px;">
          <mat-icon matPrefix class="c4p-action-badge" [tooltip]="'general.actions.View'| translate" (click)="viewFile(DIGITAL_SIGNATURE.SIGNED)" style="margin-right: 20px; font-size: 24px;">visibility</mat-icon>
          <mat-icon aria-hidden='false' class="c4p-action-badge" [tooltip]="'general.actions.Download'| translate" (click)="onClickButton(DIGITAL_SIGNATURE.SIGNED)" style="font-size: 24px;">cloud_download</mat-icon>
        </div>
        </div>
    </app-panel-form-row>
  </app-panel>
</div>
