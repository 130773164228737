<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  style="
    width: auto;
    margin: 0px 4px;
    line-height: 0px;
    height: 24px;
    font-size: 12px;
    font-weight: 400;
    border: solid;
    border-width: 1px;
    border-radius: 3px;
    padding: 0px 4px;
    background-color: var(--sidebar-bg-color);
    color: var(--sidebar-font-color);
    border-color: var(--sidebar-line-color);
  "
>
  {{ name | translate }}
</button>

<mat-menu #menu="matMenu" class="c4p-menu">
  <div *ngFor="let item of items">
    <div
      mat-menu-item
      class="c4p-menu-item"
      [disabled]="!item.hasPermission"
      (click)="item.method(item.tooltip)"
    >
      <mat-icon class="c4p-action-menu-icon" *ngIf="item.icon.length > 0">{{
        item.icon
      }}</mat-icon>
      <span class="c4p-menu-title">{{ item.tooltip | translate }}</span>
    </div>
  </div>
</mat-menu>
