import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-service-desk-form',
  templateUrl: './service-desk.component.html',
  styleUrls: ['./service-desk.component.scss'],
})
export class ServiceDeskComponent implements OnInit {

  emailAddress: string = this.environment.supportEmail;

  constructor(
    public dialogRef: MatDialogRef<ServiceDeskComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    @Inject('environment') public environment,
  ) {}

  ngOnInit(): void {}

  onConfirm(): void {
    this.dialogRef.close(false);
  }
}
