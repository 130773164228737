import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MismatchHelper } from './mismatch.helper';
import { MismatchAnswer } from '../../../models/mismatch-answer/mismatch-answer.model';

@Component({
  selector: 'app-mismatch-answers',
  templateUrl: './mismatch-answers.component.html',
  styleUrls: ['./mismatch-answers.component.scss'],
})
export class MismatchAnswersComponent implements OnInit {
  mismatchAnswersForm: FormGroup;
  mismatchQuestionsArr: FormArray;
  mismatchQuestionSetAnswer: FormGroup;
  mismatchQuestion: FormGroup;

  mismatchHelper: MismatchHelper;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<MismatchAnswersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.mismatchHelper = new MismatchHelper(this.fb);
  }

  public get mismatchedQuestionSetAnswersFormArray(): FormArray {
    return this.mismatchAnswersForm.get(
      'mismatchedQuestionSetAnswers',
    ) as FormArray;
  }

  ngOnInit(): void {
    this.mismatchAnswersForm = this.mismatchHelper.buildMismatchAnswerFormGroup(
      this.data,
    );
  }

  public onConfirm(): void {
    if (this.mismatchAnswersForm.invalid) {
      this.mismatchAnswersForm.markAllAsTouched();
    } else {
      const mismatchAnswer: MismatchAnswer =
        this.mismatchAnswersForm.getRawValue();
      this.dialogRef.close(mismatchAnswer);
    }
  }
}
