import { Inject, Optional } from '@angular/core';
import { Injectable } from '@angular/core';
import {
  MatMomentDateAdapterOptions,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment_ from 'moment';
const moment = moment_;

@Injectable()
export class FormDateAdapter extends MomentDateAdapter {
  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
    @Optional()
    @Inject(MAT_MOMENT_DATE_ADAPTER_OPTIONS)
    private options?: MatMomentDateAdapterOptions,
  ) {
    super(dateLocale);
  }

  parse(value: any): any {
    if (value) {
      const date_regex =
        /(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/; // Can you own regex or remove it.
      if (date_regex.test(value)) {
        return this.options?.useUtc
          ? moment.utc(value, 'DD/MM/YYYY')
          : moment(value, 'DD/MM/YYYY');
      } else {
        return;
      }
    } else {
      return;
    }
  }

  format(date: moment_.Moment, displayFormat: string): string {
    return moment(date).format(displayFormat);
  }
}
