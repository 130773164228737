<div class="c4p-form-row">
  <div class="c4p-vertical-divider"></div>
  <div *ngFor="let item of checkList; let i = index">
    <app-input-checkbox
      [(value)]="item.value"
      (valueChange)="onChange(i)"
      [label]="item.name"
      [readonly]="readonly"
      [ngModelOptions]="{ standalone: true }"
    ></app-input-checkbox>
  </div>
  <div class="c4p-vertical-divider"></div>
</div>
