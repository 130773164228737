import {
  Directive,
  ElementRef,
  OnDestroy,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import tippy, { Instance, Props } from 'tippy.js';

@Directive({
  selector: '[tippy]',
})
export class TippyDirective implements OnDestroy, AfterViewInit, OnChanges {
  @Input() public tippyOptions: Partial<Props>;

  private instance: Instance<Props> = null;

  constructor(private readonly element: ElementRef) {
    this.element = element;
  }

  ngAfterViewInit() {
    this.instance = tippy(
      this.element.nativeElement as Element,
      this.tippyOptions || {},
    );
    this.updateProps({ ...(this.tippyOptions ?? {}) });
  }

  ngOnChanges(changes: SimpleChanges) {
    let props = { ...(this.tippyOptions ?? {}) };

    if (changes.tippyOptions) {
      props = { ...(changes.tippyOptions.currentValue ?? {}) };
    }

    this.updateProps(props);
  }

  ngOnDestroy() {
    this.instance?.destroy();
    this.instance = null;
  }

  private updateProps(props: Partial<Props>) {
    if (
      this.instance &&
      JSON.stringify(props) !== JSON.stringify(this.instance.props)
    ) {
      this.instance.setProps(this.normalizeOptions(props));
      if (!props.content) {
        this.instance.disable();
      } else {
        this.instance.enable();
      }
    }
  }

  private normalizeOptions = (props: Partial<Props>): Partial<Props> => ({
    ...(props || {}),
    duration: props?.duration ?? [50, 50],
  });
}
