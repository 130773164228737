<mat-card appearance="outlined" class="c4p-card-view">
  <div class="c4p-card-view-header">
    <!-- For future use
    <div *ngIf="options.menuActions" style="text-align: right">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon class="c4p-action-icons">more_horiz</mat-icon>
      </button>
    </div> -->

    <ng-container *ngFor="let field of firstPart">
      <div
        *ngIf="!field.hideLabel"
        class="c4p-font-normal c4p-dimmed c4p-ellipsis c4p-card-view-label"
        translate
      >
        {{ field.name }}
      </div>

      <div *ngIf="field.type == 'status'" class="c4p-card-view-value">
        <span
          class="c4p-status-icon c4p-status-icon-{{
            data[field.id]
          }} c4p-ellipsis"
          translate
          >general.status.{{ data[field.id] | titlecase }}</span
        >
      </div>

      <div
        *ngIf="field.type == 'string' && field.format == 'title'"
        class="c4p-card-view-value"
      >
        <span class="c4p-font-xlarge c4p-ellipsis">{{ data[field.id] }}</span>
      </div>

      <div
        *ngIf="field.type == 'string' && field.format != 'title'"
        class="c4p-card-view-value"
      >
        <span class="c4p-font-normal c4p-ellipsis">{{ data[field.id] }}</span>
      </div>

      <div *ngIf="field.type == 'boolean'" class="c4p-card-view-value">
        <mat-icon
          matPrefix
          class="c4p-indicator-icon c4p-indicator-icon-{{ data[field.id] }}"
        >
          {{ field.format }}</mat-icon
        >
      </div>

      <div *ngIf="field.type == 'date'" class="c4p-card-view-value">
        <span class="c4p-font-normal c4p-ellipsis">{{
          data[field.id] | date: field.format
        }}</span>
      </div>

      <div *ngIf="field.type == 'objectList'" class="c4p-card-view-value-list">
        <span class="c4p-font-normal c4p-ellipsis">
          <app-object-list [data]="data[field.id]"></app-object-list>
        </span>
      </div>

      <div *ngIf="field.type == 'avatar'">
        <span style="display: flex; justify-content: center">
          <ngx-avatars
            [name]="data[field.avatarName]"
            [src]="data[field.id]"
            size="80"
            bgColor="#FFFFFF"
            fgColor="#808080"
          ></ngx-avatars>
        </span>
      </div>
    </ng-container>
  </div>

  <div class="c4p-card-view-content">
    <ng-container *ngFor="let field of secondPart" style="padding-top: 12px">
      <div
        *ngIf="!field.hideLabel"
        class="c4p-font-normal c4p-dimmed c4p-ellipsis c4p-card-view-label"
        translate
      >
        {{ field.name }}
      </div>

      <div *ngIf="field.type == 'status'" class="c4p-card-view-value">
        <span
          class="c4p-status-icon c4p-status-icon-{{
            data[field.id]
          }} c4p-ellipsis"
          translate
          >general.status.{{ data[field.id] | titlecase }}</span
        >
      </div>

      <div
        *ngIf="field.type == 'string' && field.format == 'title'"
        class="c4p-card-view-value"
      >
        <span class="c4p-font-xlarge c4p-ellipsis">{{ data[field.id] }}</span>
      </div>

      <div
        *ngIf="field.type == 'string' && field.format != 'title'"
        class="c4p-card-view-value"
      >
        <span class="c4p-font-normal c4p-ellipsis">{{ data[field.id] }}</span>
      </div>

      <div *ngIf="field.type == 'boolean'" class="c4p-card-view-value">
        <mat-icon
          matPrefix
          class="c4p-indicator-icon c4p-indicator-icon-{{ data[field.id] }}"
        >
          {{ field.format }}</mat-icon
        >
      </div>

      <div *ngIf="field.type == 'date'" class="c4p-card-view-value">
        <span class="c4p-font-normal c4p-ellipsis">{{
          data[field.id] | date: field.format
        }}</span>
      </div>

      <div *ngIf="field.type == 'objectList'" class="c4p-card-view-value-list">
        <span class="c4p-font-normal c4p-ellipsis">
          <app-object-list [data]="data[field.id]"></app-object-list>
        </span>
      </div>

      <div *ngIf="field.type == 'avatar'">
        <span style="display: flex; justify-content: center">
          <ngx-avatars
            [name]="data[field.avatarName]"
            [src]="data[field.id]"
            size="80"
            bgColor="#FFFFFF"
            fgColor="#808080"
          ></ngx-avatars>
        </span>
      </div>
    </ng-container>
  </div>

  <!-- For now we will use menuActions so we can share same configuration for table or card -->
  <div
    class="c4p-card-view-footer"
    *ngIf="options.menuActions && options.menuActions.length > 0"
  >
    <span *ngFor="let action of options.menuActions">
      <a
        (click)="action.method(options.data)"
        matTooltip="{{ action.tooltip | translate }}"
      >
        <mat-icon matPrefix class="c4p-action-icons">{{
          action.icon
        }}</mat-icon>
      </a>
    </span>
  </div>
</mat-card>

<mat-menu #menu="matMenu" class="c4p-menu">
  <ng-template matMenuContent let-name="name" let-rowIndex="rowIndex">
    <div *ngFor="let action of options.menuActions">
      <div
        mat-menu-item
        class="c4p-menu-item"
        (click)="action.method(options.data)"
      >
        <mat-icon class="c4p-action-menu-icon">{{ action.icon }}</mat-icon>
        <span class="c4p-menu-title">{{ action.tooltip | translate }}</span>
      </div>
    </div>
  </ng-template>
</mat-menu>
