import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CategoryModel, FunctionInfoModel } from '../../models/category.model';
import { FunctionService } from '../../../services';
import { EXCLUDED_CATEGORY_NAME } from '../../../utilities';
@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',
  styleUrls: ['./category-detail.component.scss'],
})
export class CategoryDetailComponent implements OnInit {
  public categoryForm: FormGroup = null!;

  private readonly destroyed$ = new Subject<boolean>();

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CategoryDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private functionService: FunctionService,
  ) {}

  ngOnInit(): void {
    this.categoryForm = this.categoryBuildForm();
    const category: CategoryModel = this.data?.category;
    this.disableNameInForm(category);
    if (category) this.categoryForm.patchValue(category);
    this.populateFunctions(category?.funcs || []);
  }

  private disableNameInForm(category: CategoryModel) {
    if (category.name === EXCLUDED_CATEGORY_NAME)
      this.categoryForm.get('name').disable();
  }

  get functionsFormArray(): FormArray {
    return this.categoryForm.get('funcs') as FormArray;
  }

  populateFunctions(selectedFunctions: FunctionInfoModel[]): void {
    this.functionService.getFunctions().subscribe((data) => {
      this.functionsFormArray.clear();
      this.functionService.functions.forEach((fn) =>
        this.functionsFormArray.push(
          this.buildFunctionFormGroup(fn, selectedFunctions),
        ),
      );
    });
  }

  private buildFunctionFormGroup(
    functionModel: FunctionInfoModel,
    selectedFunctions: FunctionInfoModel[],
  ) {
    const functionModelFormGroup = this.fb.group({
      id: [functionModel?.id],
      name: [functionModel?.name, Validators.required],
      checked: [
        selectedFunctions.filter((sf) => sf.name == functionModel.name).length >
        0
          ? true
          : false,
      ],
    });

    if (this.categoryForm.disabled) functionModelFormGroup.disable();
    return functionModelFormGroup;
  }

  submit() {
    if (this.categoryForm.valid) {
      const category: CategoryModel = this.categoryForm.getRawValue();
      this.dialogRef.close(category);
    } else {
      this.categoryForm.markAllAsTouched();
    }
  }
  categoryBuildForm() {
    return this.fb.group({
      id: [],
      name: [null, Validators.required],
      funcs: this.fb.array([]),
    });
  }
}
