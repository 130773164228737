<app-panel [formGroup]="changePasswordForm" type="popup">
  <app-panel-title title="general.labels.ChangePassword" titleIcon="lock">
  </app-panel-title>

  <app-panel-form-row>
    <app-form-input-text
      formControlName="newPassword"
      placeholder="clientPortal.labels.NewPassword"
      [type]="newPasswordType"
      pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
      [errorLabels]="{ pattern: 'general.labels.InvalidPassword' }"
    ></app-form-input-text>
    <button (click)="onNewPasswordVisibility()" mat-icon-button>
      <mat-icon class="c4p-action-icons" matSuffix>{{
        hideNewPassword ? 'visibility_off' : 'visibility'
      }}</mat-icon>
    </button>
  </app-panel-form-row>
  <app-panel-form-row>
    <app-form-input-text
      formControlName="confirmPassword"
      placeholder="clientPortal.labels.ConfirmPassword"
      [type]="confirmPasswordType"
      [errorLabels]="{ noMatch: 'general.labels.PasswordsDoNotMatch' }"
    ></app-form-input-text>
    <button (click)="onConfirmPasswordVisibility()" mat-icon-button>
      <mat-icon class="c4p-action-icons" matSuffix>{{
        hideConfirmPassword ? 'visibility_off' : 'visibility'
      }}</mat-icon>
    </button>
  </app-panel-form-row>

  <div style="display: flex">
    <span class="c4p-button-cancel" (click)="onDismiss()" translate
      >general.labels.Cancel</span
    >
    <span class="c4p-button-ok" (click)="onConfirm()">
      <span>OK</span>
    </span>
  </div>
</app-panel>
