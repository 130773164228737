import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  ITableOption,
  ITableView,
} from '../../../general-components/table-view/table-view.component';
import { PaginationDataModel } from '../../../models/pagination/pagination-data.model';
import { QuestionSetAnswerListModel } from '../../../models/question-set-answer/question-set-answer-list.model';
import { QuestionSetRenderService } from '../question-set-render.service';

@Component({
  selector: 'app-question-set-select-versioning',
  templateUrl: './question-set-select-versioning.component.html',
  styleUrls: ['./question-set-select-versioning.component.css'],
})
export class QuestionSetSelectVersioningComponent implements OnInit {
  @Input() entityId: string | number;
  @Input() entity: string;
  @Input() entityVersion: number;
  @Input() services: string[];
  @Input() inheritingEntityId: string;
  @Input() inheritingEntityType: string;
  @Input() inheritingEntityVersion: number;

  questionSet$ = new BehaviorSubject<{
    data: QuestionSetAnswerListModel[];
    count: number;
  }>(null);

  public tableOptions: ITableOption = null!;

  public tableView: ITableView = {
    pageIndex: 0,
    pageSize: 100,
    sortColumn: 'title',
    sortDirection: 'asc',
    search: '',
  };

  assignedQuestionSets: any[] = [];
  questionSets: any[] = [];

  displayWarning: boolean = false;

  warningText = 'questionset.messages.InheritanceWarning ';

  constructor(public questionSetRenderService: QuestionSetRenderService) {}

  ngOnInit(): void {
    this.tableOptions = this.buildTable();
    this.getQuestionSets(true);
  }

  ngOnDestroy(): void {}

  getQuestionSets(initialized?: boolean): void {
    // TODO: add flow for CARE-667 for coresponsible
    this.questionSetRenderService
      .getQuestionSets(
        this.entity,
        this.entityId,
        this.entityVersion,
        PaginationDataModel.fromTableView(this.tableView),
        {
          coresponsible: false,
        },
      )
      .subscribe((data) => {
        this.questionSets = data.docs;
        if (initialized) {
          this.questionSets = this.questionSets.map((qs) => ({
            ...qs,
            isAssigned: true,
          }));
          this.assignedQuestionSets = this.questionSets;
        }
        this.questionSet$.next({
          data: this.questionSets,
          count: data.totalDocs,
        });
      });
  }
  buildTable():ITableOption{
    return {
      name: 'questionset.menu.Formats',
      dataObservable: this.questionSet$,
      columns: [
        {
          id: 'isAssigned',
          name: 'general.labels.Add',
          type: 'checkbox',
          format: '',
        },
        {
          id: 'title',
          name: 'questionset.labels.Title',
          type: 'string',
          format: '',
          width: 240,
        },
      ],
      showHeader: true,
      showPagination: false,
      showSearch: false,
      externPagination: true,
      defaultPageSize: 100,
      defaultSortColumn: 'title',
      defaultSortDirection: 'asc',
    };
  }
}
