import {
  Component,
  OnChanges,
  Input,
  SimpleChanges,
  ViewChild,
  HostListener,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GoogleChartComponent } from 'angular-google-charts';
import { ResponsiveService } from '../../..';
import { LanguageService } from '../../services';
@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnChanges, OnInit {
  // Chart data
  @Input() data: any = {};

  // Provide value as app.labels.Title
  @Input() label = '';

  // Google chart options
  @Input() options: any = {};

  // Google chart type
  @Input() type = '';

  // Google chart column names
  @Input() columns: string[] = [];
  @Output() select = new EventEmitter();

  @ViewChild('chart ', { static: false }) chart: GoogleChartComponent;

  chartWidth = 200;
  chartHeight = 300;
  sideBarWidth = 295;
  canDrawChart = false;
  noDataAvailable = 'hours.labels.NoData';
  isMobileView = false;
  private columnTemplates = [];

  constructor(
    private responsiveService: ResponsiveService,
    private translate: TranslateService,
    private languageService: LanguageService,
  ) {}

  ngOnInit(): void {
    this.columnTemplates = this.columns;
    this.mobileViewSubscription();
    this.subscribeOnLanguageChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data.currentValue == null) {
      this.canDrawChart = false;
    }
    if (changes.data.currentValue !== null) {
      if (changes.data.currentValue.length !== 0) {
        this.drawChart();
      } else {
        this.canDrawChart = false;
      }
    } else {
      this.canDrawChart = false;
    }
  }

  mobileViewSubscription() {
    this.responsiveService.isMobile$.subscribe((value) => {
      this.isMobileView = value;
      if (value) {
        this.sideBarWidth = 10;
      } else {
        this.sideBarWidth = 295;
      }
    });
  }

  drawChart() {
    this.data = Object.assign([], this.data);
    this.chartWidth = window.innerWidth - this.sideBarWidth;
    this.chartHeight = this.options.height ? this.options.height : 300;
    this.canDrawChart = true;
  }

  subscribeOnLanguageChange() {
    this.languageService.languageObservable.subscribe(() => {
      this.columns = this.columnTemplates.map((column) => {
        this.options = {
          ...this.options,
          title: this.translate.instant(this.label),
        };
        if (column && typeof column === 'string')
          return this.translate.instant(column);
        return column;
      });
    });
  }

  onSelect(event) {
    const selectedRow = event?.selection[0]?.row;
    this.select.emit(selectedRow);
  }
}
