// animations.ts
import {
  trigger,
  style,
  transition,
  animate,
  query,
  animateChild,
  group,
} from '@angular/animations';

// we prepare the pages by moving them away in the opposite direction from where they should be coming from
// then we animate both pages at the same time by moving them in the desired direction
export const verticalRouteTransition = trigger('verticalIndex', [
  transition(':increment', [
    // set values for all occurences
    // notice it is the same for increment
    query(':enter, :leave', [
      style({
        position: 'absolute',
        height: '100%',
        width: '100%',
      }),
    ]),
    // this is going for the page that is entering the screen,
    // it hides it and moves it to the right
    // no need to modify the page that is going away
    query(':enter', [style({ transform: 'translateY(100%)' })]),
    // don't see any differences from this line
    query(':leave', animateChild(), { optional: true }),
    // makes the animations run at the same time
    group([
      // leaves child components visible while animating
      query('router-outlet ~ *', [style({}), animate(1, style({}))], {
        optional: true,
      }),
      // goes for the page that is going away
      query(
        ':leave',
        [animate('0.4s ease-in', style({ transform: 'translateY(-100%)' }))],
        { optional: true },
      ),
      // goes for the page that is entering the screen
      query(':enter', [
        animate('0.4s ease-in', style({ transform: 'translateY(0%)' })),
      ]),
    ]),
    // don't see any differences from this line
    query(':enter', animateChild()),
  ]),
  transition(':decrement', [
    query(':enter, :leave', [
      style({
        position: 'absolute',
        height: '100%',
        width: '100%',
      }),
    ]),
    query(':enter', [style({ transform: 'translateY(-100%)' })]),
    query(':leave', animateChild(), { optional: true }),
    group([
      query('router-outlet ~ *', [style({}), animate(1, style({}))], {
        optional: true,
      }),
      query(
        ':leave',
        [animate('0.4s ease-in', style({ transform: 'translateY(100%)' }))],
        { optional: true },
      ),
      query(':enter', [
        animate('0.4s ease-in', style({ transform: 'translateY(0%)' })),
      ]),
    ]),
    query(':enter', animateChild()),
  ]),
]);

export const horizontalRouteTransition = trigger('horizontalIndex', [
  transition(':increment', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
        }),
      ],
      { optional: true },
    ),
    query(':enter', [style({ right: '-100%', opacity: 0 })], {
      optional: true,
    }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(
        ':leave',
        [animate('0.4s ease-out', style({ right: '100%', opacity: 0 }))],
        { optional: true },
      ),
      query(
        ':enter',
        [animate('0.4s ease-out', style({ right: '0%', opacity: 1 }))],
        { optional: true },
      ),
    ]),
    query(':enter', animateChild(), { optional: true }),
  ]),
  transition(':decrement', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ],
      { optional: true },
    ),
    query(':enter', [style({ left: '-100%', opacity: 0 })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(
        ':leave',
        [animate('0.4s ease-out', style({ left: '100%', opacity: 0 }))],
        { optional: true },
      ),
      query(
        ':enter',
        [animate('0.4s ease-out', style({ left: '0%', opacity: 1 }))],
        { optional: true },
      ),
    ]),
    query(':enter', animateChild(), { optional: true }),
  ]),
]);

export const routeAnimations = [
  verticalRouteTransition,
  horizontalRouteTransition,
];

export const inOutAnimation = trigger('inOutAnimation', [
  transition(':enter', [
    style({ height: 0, opacity: 0 }),
    animate('2s ease-out', style({ height: 300, opacity: 1 })),
  ]),
  transition(':leave', [
    style({ height: 300, opacity: 1 }),
    animate('2s ease-in', style({ height: 0, opacity: 0 })),
  ]),
]);

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('200ms', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('200ms', style({ opacity: 0.4 })),
  ]),
]);

export const slideFromTop = trigger('slideFromTop', [
  transition(':enter', [
    style({ transform: 'translateY(-100%)' }),
    animate('400ms ease-in', style({ transform: 'translateY(0%)' })),
  ]),
  transition(':leave', [
    animate('400ms ease-in', style({ transform: 'translateY(-100%)' })),
  ]),
]);

export const slideFromBottom = trigger('slideFromBottom', [
  transition(':enter', [
    style({ transform: 'translateY(100%)' }),
    animate('400ms ease-in', style({ transform: 'translateY(0%)' })),
  ]),
  transition(':leave', [
    animate('400ms ease-in', style({ transform: 'translateY(100%)' })),
  ]),
]);
