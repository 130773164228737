  <div class="page-info-bar">
    <ng-container *ngIf="pageInfoService.isEmployeePortal" [formGroup]="form">
      <div class="c4p-pageinfo">
        <app-form-input-text
          class="c4p-tablet-screen"
          formControlName="createdBy"
          placeholder="general.labels.CreatedBy"
          *ngIf="form.controls['createdBy'].value"
        ></app-form-input-text>
        <app-form-input-text
          formControlName="createdAt"
          class="c4p-tablet-screen"
          placeholder="general.labels.CreatedAt"
          *ngIf="form.controls['createdAt'].value"
        ></app-form-input-text>
        <app-form-input-text
          formControlName="updatedBy"
          class="c4p-mobile-screen"
          placeholder="general.labels.UpdatedBy"
          *ngIf="form.controls['updatedBy'].value"
        ></app-form-input-text>
        <app-form-input-text
          formControlName="updatedAt"
          class="c4p-mobile-screen"
          placeholder="general.labels.UpdatedAt"
          *ngIf="form.controls['updatedAt'].value"
        ></app-form-input-text>
      </div>
    </ng-container>

    <span
      *ngIf="
        (pageInfoService.version$ | async) != '' &&
        pageInfoService.isEmployeePortal
      "
      class="c4p-status-icon c4p-mobile-screen"
      >v{{ pageInfoService.version$ | async }}</span
    >

    <span
      *ngIf="
        (pageInfoService.status$ | async) != '' &&
        pageInfoService.isEmployeePortal
      "
      class="c4p-status-icon c4p-status-icon-{{
        pageInfoService.status$ | async
      }} c4p-ellipsis c4p-mobile-screen"
      >{{ pageInfoService.statusString$ | async }}</span
    >

    <div
      class="c4p-mobile-screen"
      style="padding-bottom: 10px"
      *ngIf="
        (pageInfoService.clientName$ | async) != '' &&
        !pageInfoService.isEmployeePortal &&
        pageInfoService.isClientSet
      "
    >
      <span class="c4p-form-label" translate
        >clientPortal.labels.CareFileOf</span
      >

      <span class="c4p-form-label">
        {{ pageInfoService.clientName$ | async }}</span
      >
    </div>

    <div
      class="c4p-horizontal-divider c4p-mobile-screen"
      *ngIf="
        (pageInfoService.careStatus$ | async) != '' &&
        !pageInfoService.isEmployeePortal &&
        pageInfoService.isClientSet
      "
    >
      <span class="c4p-form-label" translate>clientPortal.labels.Care</span>
      <span class="c4p-form-label">: </span>
      <span
        class="c4p-status-icon c4p-status-icon-{{
          pageInfoService.careStatus$ | async
        }} c4p-ellipsis"
      >
        {{ pageInfoService.careStatusString$ | async }}</span
      >
    </div>

    <div
      class="c4p-horizontal-divider c4p-mobile-screen"
      *ngIf="
        (pageInfoService.clientNumber$ | async) != '' &&
        !pageInfoService.isEmployeePortal &&
        pageInfoService.isClientSet
      "
    >
      <span class="c4p-form-label" translate
        >clientPortal.labels.ClientNumber</span
      >
      <span class="c4p-form-label">: </span>
      <span class="c4p-form-label">{{
        pageInfoService.clientNumber$ | async
      }}</span>
    </div>

    <div
      class="c4p-horizontal-divider c4p-mobile-screen"
      *ngIf="
        (pageInfoService.clientDateOfBirth$ | async) != '' &&
        !pageInfoService.isEmployeePortal &&
        pageInfoService.isClientSet
      "
    >
      <span class="c4p-form-label" translate
        >clientPortal.labels.DateOfBirth</span
      >
      <span class="c4p-form-label">: </span>
      <span class="c4p-form-label">{{
        pageInfoService.clientDateOfBirth$ | async | appDate
      }}</span>
    </div>

    <div
      class="c4p-horizontal-divider c4p-mobile-screen"
      *ngIf="
        (pageInfoService.clientAssignedTo$ | async) != '' &&
        !pageInfoService.isEmployeePortal &&
        pageInfoService.isClientSet
      "
    >
      <span class="c4p-form-label" translate
        >clientPortal.labels.AssignedTo</span
      >
      <span class="c4p-form-label">: </span>
      <span class="c4p-form-label">{{
        pageInfoService.clientAssignedTo$ | async
      }}</span>
    </div>
  </div>
