import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../../../general-components/dialog/dialog.component';
import { CareLabelDetailComponent } from '../../../components/care-label-detail/care-label-detail.component';
import { BasicCareProgramModel, CareLabelModel } from '../../../models';
import { CareProgramService } from '../../../services/care-program.service';

@Component({
  selector: 'app-care-label-section',
  templateUrl: './care-label-section.component.html',
  styleUrls: ['./care-label-section.component.scss'],
})
export class CareLabelSectionComponent implements OnInit {
  public get careprogramForm(): FormGroup {
    return this.careprogramService.careProgramForm;
  }

  public get careLabelFormArray(): FormArray {
    return this.careprogramForm.get('careLabel') as FormArray;
  }

  public displayedColumns = [
    'startDate',
    'endDate',
    'code',
    'name',
    'operations',
  ];

  constructor(
    private careprogramService: CareProgramService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.initCareLabelData();
  }

  private initCareLabelData() {
    const selectedCareProgram =
      this.careprogramService.selectedCareProgram$.value;
    if (
      selectedCareProgram &&
      selectedCareProgram.careLabel?.length > 0 &&
      this.careLabelFormArray.length === 0
    ) {
      selectedCareProgram.careLabel.forEach((careLabel) => {
        this.careLabelFormArray.push(this.buildCareLabelFormGroup(careLabel));
      });
    }
  }

  private buildCareLabelFormGroup(careLabel: CareLabelModel): FormGroup {
    const careLabelFormGroup = this.careLabelBuildForm(careLabel);

    if (this.careLabelFormArray.disabled) careLabelFormGroup.disable();
    return careLabelFormGroup;
  }
  public createCareLabel() {
    const dialogRef = this.dialog.open(CareLabelDetailComponent, {
      data: {
        careLabel: null,
        viewOnly: false,
      },
    });

    dialogRef.afterClosed().subscribe((careLabel: CareLabelModel) => {
      if (careLabel) {
        const carelabelFormGroup = this.buildCareLabelFormGroup(careLabel);
        this.careLabelFormArray.push(carelabelFormGroup);
        carelabelFormGroup.markAsDirty();
        this.careLabelFormArray.markAsTouched();
      }
    });
  }

  public editCareLabel(careLabel: CareLabelModel, index: number) {
    const dialogRef = this.dialog.open(CareLabelDetailComponent, {
      data: {
        careLabel,
        viewOnly: false,
      },
    });

    dialogRef.afterClosed().subscribe((careLabel) => {
      if (careLabel) {
        const careLabelFormGroup = this.careLabelFormArray.get([index]);
        careLabelFormGroup.patchValue(careLabel);
        careLabelFormGroup.markAsDirty();
      }
    });
  }

  public deleteCareLabel(index: number) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'general.messages.DeleteItem',
        message: 'careprogram.messages.AreYouSureYouWantToDeleteCareLabel',
        confirmText: 'general.actions.delete',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.careLabelFormArray.markAsDirty();
        this.careLabelFormArray.removeAt(index);
        this.careLabelFormArray.markAsTouched();
      }
    });
  }

  public viewCareLabel(index: number) {
    const careLabel = this.careLabelFormArray.get([index]).value;
    const dialogRef = this.dialog.open(CareLabelDetailComponent, {
      data: {
        careLabel,
        viewOnly: true,
      },
    });

    dialogRef.afterClosed().subscribe(() => {});
  }
  careLabelBuildForm(careLabel: CareLabelModel){
    return  this.formBuilder.group({
      startDate: [careLabel.startDate, Validators.required],
      endDate: [careLabel.endDate],
      careLabelItem: this.formBuilder.group({
        name: [careLabel.careLabelItem.name, Validators.required],
        code: [careLabel.careLabelItem.code, Validators.required],
      }),
    });
  }
}
