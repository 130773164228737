<app-panel [formGroup]="dSM5DiagnosisForm" type="popup">
  <app-panel-title
    title="careprogram.titles.DSM5Diagnosis"
    titleIcon="new_label"
  >
  </app-panel-title>
  <app-panel-form-row>
    <app-form-input-date
      formControlName="startDateDiagnosis"
      [readonly]="viewOnly"
      [maxDate]="getMaxDate(endDateDiagnosisControl.value)"
      placeholder="careprogram.labels.StartDateDiagnosis"
    ></app-form-input-date>
    <app-form-input-date
      formControlName="endDateDiagnosis"
      [readonly]="viewOnly"
      [minDate]="getMinDate(startDateDiagnosisControl.value)"
      placeholder="careprogram.labels.EndDateDiagnosis"
    ></app-form-input-date>
  </app-panel-form-row>
  <app-panel>
    <app-panel-title title="careprogram.labels.Diagnosis"> </app-panel-title>
    <app-panel-form-row>
      <app-form-input-dropdown
        [options]="dsm5DiagnosisService.filteredDsm5Values"
        formControlName="diagnosis"
        [readonly]="viewOnly"
        placeholder="general.labels.Description"
        frontendFiltering="true"
        labelProperty="description"
        frontendFilteringProperty="description"
        search="true"
        valueProperty="code"
        objectAsValue="true"
        [startOptions]="dsm5DiagnosisService.filteredDsm5Values"
        [maxContentSelectionPanel]="true"
      ></app-form-input-dropdown>
      <app-form-input-dropdown
        [options]="dsm5DiagnosisService.filteredDsm5Values"
        formControlName="code"
        [readonly]="viewOnly"
        placeholder="general.labels.Code"
        frontendFiltering="true"
        labelProperty="code"
        frontendFilteringProperty="code"
        search="true"
        valueProperty="code"
        objectAsValue="true"
        [startOptions]="dsm5DiagnosisService.filteredDsm5Values"
      ></app-form-input-dropdown>
    </app-panel-form-row>
    <app-panel-form-row>
      <app-label
        [labelValue]="{
          label: 'general.labels.StartDate',
          value: descriptionControl.value?.startDate | appDate
        }"
      ></app-label>
      <app-label
        [labelValue]="{
          label: 'general.labels.EndDate',
          value: descriptionControl.value?.endDate | appDate
        }"
      ></app-label>
      <app-label
        [labelValue]="{
          label: 'careprogram.labels.GroupCode',
          value: descriptionControl.value?.groupCode
        }"
      ></app-label>
      <app-label
        [labelValue]="{
          label: 'careprogram.labels.MainGroupCode',
          value: descriptionControl.value?.mainGroupCode
        }"
      ></app-label>
    </app-panel-form-row>
    <app-panel-form-row>
      <app-label
        [labelValue]="{
          label: 'careprogram.labels.HierarchyLevel',
          value: descriptionControl.value?.hierarchyLevel
        }"
      ></app-label>
      <app-label
        [labelValue]="{
          label: 'careprogram.labels.ReferenceCodeICD9CM',
          value: descriptionControl.value?.refCodeICD9CM
        }"
      ></app-label>
      <app-label
        [labelValue]="{
          label: 'careprogram.labels.ReferenceCodeICD10',
          value: descriptionControl.value?.refCodeICD10
        }"
      ></app-label>

      <app-label
        [labelValue]="{
          label: 'careprogram.labels.ClaimType',
          value: descriptionControl.value?.claimType
        }"
      ></app-label>
    </app-panel-form-row>

    <app-panel-form-row>
      <mat-checkbox
        style="margin-left: 25px"
        [checked]="descriptionControl.value?.selectable"
        disabled="true"
        >{{ 'careprogram.labels.Selectable' | translate }}</mat-checkbox
      >
      <mat-checkbox
        style="margin-left: 25px"
        [checked]="descriptionControl.value?.mutations"
        disabled="true"
        >{{ 'careprogram.labels.Mutation' | translate }}</mat-checkbox
      >
    </app-panel-form-row>
  </app-panel>
  <app-panel-form-row> </app-panel-form-row>
</app-panel>
<div style="display: flex">
  <span class="c4p-button-cancel" (click)="onDismiss()" translate
    >general.labels.Cancel</span
  >
  <span class="c4p-button-ok" *ngIf="!viewOnly" (click)="onConfirm()">
    <span>OK</span>
  </span>
</div>
