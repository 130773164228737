import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  MainGoalModel,
  SocialAreaModel,
  SubGoalModel,
} from '../../models/social-area/social-area.model';

export class SocialAreaHelper {
  constructor(
    public formBuilder: FormBuilder,
    private destroyed$: Subject<any>,
    private scoringEnabled?: boolean,
    private viewMode?: boolean,
  ) {}

  public buildSubGoalsFormGroup(
    subgoals: SubGoalModel,
    isEvaluation?: boolean,
  ): FormGroup {
    const formGroup: FormGroup = this.formBuilder.group({
      id: subgoals.id,
      refId: subgoals.refId,
      tenantId: subgoals.tenantId,
      name: subgoals.name,
      selected: subgoals.selected,
      startingLevel: subgoals.startingLevel,
      objective: subgoals.objective,
      score: subgoals.score,
      isNew: subgoals.isNew,
      isRenamed: subgoals.isRenamed,
      manuallyMarked: subgoals.manuallyMarked,
      achieved: subgoals.achieved,
    });

    const achievedControl = formGroup.get('achieved') as FormControl;
    achievedControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (achievedControl.dirty)
          formGroup.get('manuallyMarked').setValue(true);
      });

    if (this.scoringEnabled)
      formGroup.get('score').setValidators([Validators.required]);
    if (isEvaluation) {
      formGroup.get('score').disable();
      formGroup.addControl(
        'displayScore',
        this.formBuilder.control(Math.round(subgoals.score)),
        { emitEvent: false },
      );
      formGroup.get('displayScore').disable();
    }
    if (this.viewMode) formGroup.disable();
    formGroup.markAsUntouched();
    return formGroup;
  }
  buildFormGroup(goal: MainGoalModel){
    return this.formBuilder.group({
      id: goal.id,
      refId: goal.refId,
      tenantId: goal.tenantId,
      name: goal.name,
      selected: goal.selected,
      startingLevel: goal.startingLevel,
      objective: goal.objective,
      subGoals: this.formBuilder.array([]),
      isNew: goal.isNew,
      isRenamed: goal.isRenamed,
      summary: goal.summary,
      score: goal.score,
      achieved: goal.achieved,
      manuallyMarked: goal.manuallyMarked,
    });
  }
  public buildGoalFormGroup(
    goal: MainGoalModel,
    isEvaluation?: boolean,
  ): FormGroup {
    const formGroup = this.buildFormGroup(goal);

    if (goal.manualMarkedEnabled !== undefined) {
      goal.manualMarkedEnabled
        ? formGroup.get('achieved').enable()
        : formGroup.get('achieved').disable();
    }

    const achievedControl = formGroup.get('achieved') as FormControl;
    achievedControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (achievedControl.dirty)
          formGroup.get('manuallyMarked').setValue(true);
      });

    if (this.scoringEnabled) {
      isEvaluation
        ? formGroup.get('score').setValidators([Validators.required])
        : formGroup.get('summary').setValidators([Validators.required]);
    }
    if (goal.subGoals) {
      for (let subgoal of goal.subGoals) {
        const subgoalFormGroup = this.buildSubGoalsFormGroup(
          subgoal,
          isEvaluation,
        );
        (formGroup.get('subGoals') as FormArray).push(subgoalFormGroup);
      }
    }
    if (this.viewMode) formGroup.disable();
    formGroup.markAsUntouched();
    return formGroup;
  }

  public buildGoalFormArray(
    goals: MainGoalModel[],
    isEvaluation?: boolean,
  ): FormArray {
    let formArray: FormArray = this.formBuilder.array([]);
    for (let goal of goals) {
      const goalFormGroup = this.buildGoalFormGroup(goal, !!isEvaluation);
      formArray.push(goalFormGroup);
    }
    return formArray;
  }

  public buildSocialAreaFormGroup(
    socialArea: SocialAreaModel,
    isEvaluation?: boolean,
  ): FormGroup {
    const formGroup = this.formBuilder.group({
      id: socialArea.id,
      refId: socialArea.refId,
      entityId: socialArea.entityId,
      entityType: socialArea.entityType,
      tenantId: socialArea.tenantId,
      name: socialArea.name,
      selected: socialArea.selected,
      additionalInfo: socialArea.additionalInfo,
      mainGoals: this.formBuilder.array([]),
      isNew: socialArea.isNew,
      isRenamed: socialArea.isRenamed,
      scoringAnswered: socialArea.scoringAnswered,
    });
    if (socialArea.mainGoals) {
      if (socialArea.mainGoals.length == 0)
        formGroup.get('scoringAnswered').patchValue(true);
      for (let goal of socialArea.mainGoals) {
        const goalFormGroup = this.buildGoalFormGroup(goal, !!isEvaluation);
        if (this.viewMode) goalFormGroup.disable();
        (formGroup.get('mainGoals') as FormArray).push(goalFormGroup);
      }
    }

    formGroup.markAsUntouched();
    return formGroup;
  }

  public buildSocialAreaFormArray(
    socialAreas: SocialAreaModel[],
    isEvaluation?: boolean,
  ): FormArray {
    let formArray: FormArray = this.formBuilder.array([]);

    for (let socialArea of socialAreas) {
      const formGroup = this.buildSocialAreaFormGroup(
        socialArea,
        !!isEvaluation,
      );
      if (this.viewMode) formGroup.disable();
      formArray.push(formGroup);
    }
    return formArray;
  }
}
