  <span
    [matMenuTriggerFor]="menu"
    class="languge-button">
    <img
      alt="image"
      src="{{ image }}"
    />
</span>

<mat-menu #menu="matMenu" class="c4p-menu lang-menu">
  <ng-container *ngFor="let action of menuActions">

    <div
    mat-menu-item
    class="lang-menu-item"
    (click)="setLanguage(action.image, action.language)">
    <mat-icon svgIcon="{{action.icon}}" aria-hidden="false"></mat-icon>
    <span class="lang-menu-label">{{ action.tooltip }}</span>
  </div>

  </ng-container>

</mat-menu>
