import {
  Directive,
  Input,
  ElementRef,
  HostListener,
  Renderer2,
  OnDestroy,
  OnInit,
} from '@angular/core';

// Lets keep this directive for future use

@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective implements OnDestroy, OnInit {
  @Input('tooltip') objects: string;
  @Input() placement: string = 'bottom';
  @Input() delay: number = 500;
  tooltip: HTMLElement = null;
  offset = -15;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip) {
      this.show();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) {
      this.hide();
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  show() {
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, 'c4p-tooltip-show');
  }

  hide() {
    window.setTimeout(() => {
      this.renderer.removeClass(this.tooltip, 'c4p-tooltip-show');
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null;
    }, this.delay);
  }

  create() {
    this.tooltip = this.renderer.createElement('div');

    this.renderer.addClass(this.tooltip, 'c4p-popup');
    // for (let obj of this.objects) {
    let el = this.renderer.createElement('div');
    // this.renderer.addClass(el, 'c4p-card-view-list-item');
    this.renderer.setStyle(el, 'margin', `4px 16px`);
    this.renderer.appendChild(el, this.renderer.createText(this.objects));
    this.renderer.appendChild(this.tooltip, el);
    // }

    this.renderer.appendChild(document.body, this.tooltip);

    this.renderer.addClass(this.tooltip, 'c4p-tooltip');
    this.renderer.addClass(this.tooltip, `c4p-tooltip-${this.placement}`);
  }

  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltip.getBoundingClientRect();

    const scrollPos =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    let top, left;

    if (this.placement === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'bottom') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.placement === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }
}
