import { IFormDropdownOption } from '../../form-controls/form-controls.const';

export const DOCUMENT_STATUSES: IFormDropdownOption[] = [
  { label: 'general.status.Draft', value: 'draft' },
  { label: 'general.status.Approved', value: 'approved' },
  { label: 'general.status.Rejected', value: 'rejected' },
];

export const DOCUMENT_SHARING_TYPE: IFormDropdownOption[] = [
  { label: 'document.labels.externalShare', value: 'external' },
  { label: 'document.labels.internalShare', value: 'internal' },
];

export const DOCUMENT_EMAIL_SHARING_TYPE: IFormDropdownOption[] = [
  { label: 'document.labels.clientEmail', value: 'clientEmail' },
  { label: 'document.labels.clientNetworkEmail', value: 'clientNetwork' }
];
