import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';

export enum DebitCredit {
  DEBIT = 'debit',
  CREDIT = 'credit',
}

@Injectable({
  providedIn: 'root',
})
export class DebitCreditService {
  public values: IFormDropdownOption[] = [
    { label: 'billing.enum.invoiceType.credit', value: DebitCredit.CREDIT },
    { label: 'billing.enum.invoiceType.debit', value: DebitCredit.DEBIT },
  ];

  public lookupValues: string[] = [];

  constructor() {
    for (let i = 0; i < this.values.length; i++) {
      this.lookupValues[i] = this.values[i].label;
    }
    for (const item of this.values) {
      this.lookupValues[item.value] = item.label;
    }
  }
}
