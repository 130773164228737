import { Inject, Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { MatDialog } from '@angular/material/dialog';
import { PermissionService } from '../permissions/permission.service';
import { UserInfoService } from '../user-info/user-info.service';
import { SidebarService } from '../sidebar/sidebar.service';
import { GatekeeperService } from '../gatekeeper/gatekeeper.service';
import { IMainMenuItem, UserTypeEnum } from '../../models';
import { CollaborationService } from '../collaboration-service/collaboration.service';
import { FcmMessageService } from '../fcm-message/fcm-message.service';
import { NotificationService } from '../notification/notification.service';
import { HelperService } from '../helper/helper.service';
import { map } from 'rxjs/operators';

const LOCAL_STORAGE_GATEKEEPER_KEY = 'gatekeepers';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(
    private keycloakService: KeycloakService,
    private matDialog: MatDialog,
    private permissionService: PermissionService,
    private userInfoService: UserInfoService,
    private sidebarService: SidebarService,
    private gatekeeperService: GatekeeperService,
    private collaborationService: CollaborationService,
    public dialog: MatDialog,
    private fcmMessageService: FcmMessageService,
    private notificationService: NotificationService,
    @Inject('environment') public environment: any
  ) {}

  public initializeApp(mainMenuItems: IMainMenuItem[]): void {
    this.initializeUser(mainMenuItems);

    // this.languageService.setLanguage('en');
    this.sidebarService.setSidebarItems(mainMenuItems);
  }

  private initializeUser(mainMenuItems: IMainMenuItem[]) {
    // fetch the token
    this.keycloakService.getToken().then((tokenData) => {
      const token = HelperService.parseJwt(tokenData);
      this.permissionService.initRolePermissions(token);
      if (
        token.user_type === UserTypeEnum.Client ||
        token.user_type === UserTypeEnum.ClientNetwork
      ) {
        // Mark that user is logged in Client Portal
        this.userInfoService.isClientPortal = true;
        this.permissionService.initUserPermissions(token);
        if (!this.permissionService.currentRole){
          this.permissionService.setClientRole(token.realm_access.roles);
        }
        this.permissionService.initRolePermissions(tokenData).subscribe(() => {
          this.editSidebarMenu(token, mainMenuItems);
          this.userInfoService.initUserInfo(token);
        });
        this.gatekeeperService.getAllGatekeepers().subscribe((data) => {//! move this under this.initializeNotifications(token);
          sessionStorage.setItem(
            LOCAL_STORAGE_GATEKEEPER_KEY,
            JSON.stringify(data),
          );
        });
        this.initializeNotifications(token);

      } else {
        if (this.permissionService.currentRole) {
          this.permissionService
            .initRolePermissions(tokenData)
            .subscribe(() => {
              this.userInfoService.initUserInfo(token);
              this.collaborationService.connect(
                this.userInfoService.userId,
                token,
              );
            });
            this.gatekeeperService.getAllGatekeepers().subscribe((data) => {
              sessionStorage.setItem(
                LOCAL_STORAGE_GATEKEEPER_KEY,
                JSON.stringify(data),
              );
            });
          this.initializeNotifications(token);
        } else {
          this.gatekeeperService.getAllGatekeepers().subscribe((data) => {
            sessionStorage.setItem(
              LOCAL_STORAGE_GATEKEEPER_KEY,
              JSON.stringify(data),
            );
          });
          this.initializeNotifications(token);
          this.userInfoService.initUserInfo(token);
          this.collaborationService.connect(this.userInfoService.userId, token);
        }
      }
    });
  }
  private initializeNotifications(token: any) {
    // if (firebase.messaging.isSupported()) {
      // Ask user permission, in case permission is not granted by the user earlier, for FCM (Firebase) message notifications
      this.fcmMessageService.requestPermission(token.user_id);
      // Receive push notifications
      this.notificationService.receiveMessage();
    //}
    // If a user have permission, retrieve count of unread ntfs and set bell counter
    if(this.permissionService.getPermission('notification:manage', 'or')){
    this.notificationService.updateUnreadMessageCount(token.user_id);
    }
  }

  private editSidebarMenu(token: any, mainMenuItems: IMainMenuItem[]) {
    //If there's only one carefile, remove Carefiles sidebar item
    if (token.user_type === UserTypeEnum.ClientNetwork) {
      this.userInfoService
        .getUserCarefiles(token.user_id)
        .subscribe((carefiles) => {
          if (carefiles.totalDocs < 2) {
            for (const sidebarItem of mainMenuItems) {
              sidebarItem.items = sidebarItem.items.filter(
                (it) => it.name !== 'general.labels.carefiles',
              );
              this.sidebarService.setSidebarItems(mainMenuItems);
            }
          }
        });
    } else if (token.user_type === UserTypeEnum.Client) {
      this.userInfoService
        .getClientCarefiles(token.user_id)
        .pipe(
          map((response: any) => {
            return response.docs;
          }),
        )
        .subscribe((careFiles: any[]) => {
          if (careFiles.length < 2) {
            for (const sidebarItem of mainMenuItems) {
              sidebarItem.items = sidebarItem.items.filter(
                (it) => it.name !== 'general.labels.carefiles',
              );
              this.sidebarService.setSidebarItems(mainMenuItems);
            }
          }
        });
    } else {
      this.sidebarService.setSidebarItems(mainMenuItems);
    }
  }
}
