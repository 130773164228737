import { SimpleSignOutput } from './simple.sign.output';

export class SignatureLogDTO {
  id?: string;
  tenantId!: string;
  signatureKey?: string;
  documentId?: string;
  documentUrl!: string;
  originalDocumentUrl!: string;
  originalVersion!: SimpleSignOutput;
  signedDocumentUrl?: string;
  signedVersion?: SimpleSignOutput;
}
