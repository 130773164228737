import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DEFAULT_INPUT_TYPE, FormControlBase } from '../../../form-controls';

@Component({
  selector: 'app-single-line',
  templateUrl: './single-line.component.html',
  styleUrls: ['./single-line.component.css'],
})
export class SingleLineComponent extends FormControlBase<SingleLineComponent> {
  @Input() type = DEFAULT_INPUT_TYPE;
  @Input() pattern: string;
  @Input() hint: string;
  @Input() orderNum: number;
  @Input() isClientPortal = false;
  @Output() tabEvent = new EventEmitter();

  ngDoCheck(): void {
    this.adjustTouched();
  }

  onTab(el: HTMLElement) {
    this.tabEvent.emit(el.offsetHeight);
  }
}
