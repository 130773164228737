<ng-container>
  <app-panel [formGroup]="sharingForm" style="width: auto; margin-left: auto;display: flex; justify-content: flex-end" [type]="'popup-share-and-sign'">
    <app-panel-title *ngIf="sharedPersons" title="document.labels.documentSharing" titleIcon="person_add_alt_1">
      <ng-container panelButtons>
        <div style="cursor: pointer;margin-right: -10px" (click)="onCloseDialog()">
          <mat-icon style="height: 50%; font-size: medium;">close</mat-icon>
        </div>
      </ng-container>
    </app-panel-title>
    <app-panel *ngIf="sharedPersons" style='margin-top: -5px'>
    <app-panel-form-row>
      <app-panel-form-row style='margin-left: 10px;'>
        <app-form-input-dropdown
          showToggleAllCheckbox="true"
          formControlName="clientNetwork"
          placeholder="document.labels.externalShare"
          [search]="true"
          blank="false"
          labelProperty="fullName"
          [multiple]="true"
          [objectAsValue]="true"
          [options]="externalValues"
          [startOptions]="externalValues"
          frontendFilteringProperty="fullName"
          [frontendFiltering]="true"
          style='padding-bottom: 50px;padding-left: 25px;'
        ></app-form-input-dropdown>
        <app-form-input-dropdown
          showToggleAllCheckbox="true"
          formControlName="individualInvolments"
          placeholder="document.labels.internalShare"
          [search]="true"
          blank="false"
          labelProperty="fullName"
          [objectAsValue]="true"
          [multiple]="true"
          [options]="individualValues"
          [startOptions]="individualValues"
          frontendFilteringProperty="fullName"
          [frontendFiltering]="true"
          style='padding-bottom: 50px; padding-left: 13px;'
        ></app-form-input-dropdown>
      </app-panel-form-row>
    </app-panel-form-row>
    </app-panel>
    <app-panel *ngIf="sharedPersons" [ngClass]="{'custom-panel-height': tempCountSharedPerson === 0 && signatureLogs?.length === 0, 'custom-panel': true}" style='overflow-x: scroll; flex-grow: 1; display: flex' [type]="'popup-share-and-sign'">
    <app-table-view [options]="tableOptions" (checkboxChanged)='singleCheck($event.event, $event.element)'>
    </app-table-view>
      <span *ngIf='section !== "application" && sharedPersons?.length < 1' class="custom-span-notAvailableForSharing c4p-ellipsis" translate>document.labels.sharedWithNoOne</span>
      <span *ngIf='!sharedPersons && section === "application"' class="custom-span-notAvailableForSharing c4p-ellipsis" translate>document.labels.notAvailableForSharing</span>
    </app-panel>
    <div style="display: flex; padding-top: -5px;">
      <span *ngIf='section !== "application"' class="c4p-button-cancel" (click)="onCloseDialog()" translate>general.labels.Cancel</span>
      <span *ngIf='section === "application"' class="c4p-button-cancel" (click)="onCloseDialog()" translate>general.labels.Close</span>
      <span *ngIf='section !== "application"' class="c4p-button-ok" (click)="submit()">
      <span translate>general.actions.Submit</span>
      </span>
    </div>
  </app-panel>
</ng-container>
