import { Component, Input, Optional, Self } from '@angular/core';
import { FormInputDateComponent } from '../form-input-date/form-input-date.component';
import {
  DATE_TIME_REGEX,
  DEFAULT_DATE_TIME_SHOW_SPINNERS,
  DEFAULT_DATE_TIME_SHOW_SECONDS,
  DEFAULT_DATE_TIME_HOUR_STEP,
  DEFAULT_DATE_TIME_MINUTE_STEP,
  DEFAULT_MAX_DATE,
  DEFAULT_MIN_DATE,
} from '../form-controls.const';
import * as moment_ from 'moment';
import { CustomDateTimePipe } from '../../pipes';
import { NgControl } from '@angular/forms';
import { DEFAULT_DATE_TIME_FORMAT } from '../../pipes/pipes.const';
const moment = moment_;

@Component({
  selector: 'app-form-input-date-time',
  templateUrl: './form-input-date-time.component.html',
  styleUrls: ['./form-input-date-time.component.scss'],
})
export class FormInputDateTimeComponent extends FormInputDateComponent {
  @Input() showSpinners = DEFAULT_DATE_TIME_SHOW_SPINNERS;
  @Input() showSeconds = DEFAULT_DATE_TIME_SHOW_SECONDS;
  @Input() stepHour = DEFAULT_DATE_TIME_HOUR_STEP;
  @Input() stepMinute = DEFAULT_DATE_TIME_MINUTE_STEP;
  @Input() defaultTime: number[];
  @Input() minDate = DEFAULT_MIN_DATE;
  @Input() maxDate = DEFAULT_MAX_DATE;
  onKeydown = DATE_TIME_REGEX;
  tenantTimezone: string;

  constructor(
    public dateTimePipe: CustomDateTimePipe,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    super(ngControl, dateTimePipe);
  }

  public setNow() {
    // const now = USE_UTC ? moment.utc() : moment();
    // // remove leftover strings when reseting the form
    // this.datePickerInput.nativeElement.value = now.local().format('DD/MM/YYYY HH:mm');
    // // set null value to remove default datepicker errors
    // this.value = USE_UTC ? now.utc() : now;
    this.value = this.dateTimePipe.transform(moment(), 'noFormat');
  }

  valueChangeHandler(event$) {
    if (!event$.target.value) return;
    const event = moment(event$.target.value, DEFAULT_DATE_TIME_FORMAT);
    const minimumDate = this.dateTimePipe.transform(this.minDate);
    const maximumDate = this.dateTimePipe.transform(this.maxDate);
    if (
      minimumDate &&
      event.isSameOrBefore(moment(minimumDate, DEFAULT_DATE_TIME_FORMAT))
    ) {
      this.value = moment(minimumDate, DEFAULT_DATE_TIME_FORMAT);
    }
    if (
      maximumDate &&
      event.isSameOrAfter(moment(maximumDate, DEFAULT_DATE_TIME_FORMAT))
    ) {
      this.value = moment(maximumDate, DEFAULT_DATE_TIME_FORMAT);
    }
  }
}
