import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FcmMessageModel } from '../../models/fcm-message/fcm-message.model';
import { BehaviorSubject } from 'rxjs';
import { map,  takeUntil } from 'rxjs/operators';
import { PaginationResultModel } from '../../models/pagination/pagination-result.model';
import { Subject } from 'rxjs';
import { ConfigurationModel } from '../../models/fcm-message/configuration.model';
import { getToken, Messaging, onMessage } from '@angular/fire/messaging';
@Injectable({
  providedIn: 'root',
})
export class FcmMessageService {
  private fcmMessage = new BehaviorSubject<
    PaginationResultModel<FcmMessageModel>
  >(null);
  public fcmMessage$ = this.fcmMessage.asObservable();

  public newMessageEvent = new EventEmitter();

  private readonly destroyed$ = new Subject<boolean>();

  private originConfigurationPath = '/configuration';

  constructor(
    public http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
    public messaging: Messaging,
    @Inject('environment') public environment: any,
  ) {}

  requestPermission(userId: string) {
    navigator.serviceWorker.register("./firebase-messaging-sw.js").then(async (serviceWorkerRegistration: ServiceWorkerRegistration) => {
      await this.waitForServiceWorkerActivation(serviceWorkerRegistration);
      getToken(this.messaging, { serviceWorkerRegistration: serviceWorkerRegistration })
        .then(token => {
          if (!token) {
            this.translate.setDefaultLang(navigator.language.slice(0, 2));
            this.translate
              .get('general.labels.notificationDeclined')
              .subscribe((translated: string) => {
                this.toastr.warning(
                  this.translate.instant(
                    'general.labels.notificationDeclined',
                    'Warning',
                  ),
                );
              });
          } else {
            this.upsertRegistrationToken({
              userId: userId,
              registrationToken: token,
            })
              .pipe(takeUntil(this.destroyed$))
              .subscribe();


            onMessage(this.messaging, (msg) => {
              const notificationTitle = msg.data.subject;
              const notificationOptions = {
                body: msg.data.message || '',
                // icon: '',
              };
              this.newMessageEvent.emit(true);
              serviceWorkerRegistration.showNotification(notificationTitle,notificationOptions);
            })
          }
        })
    })
  }

  async waitForServiceWorkerActivation(registration: ServiceWorkerRegistration): Promise<void> {
    const serviceWorker = registration.installing || registration.waiting || registration.active;

    if (serviceWorker?.state === 'activated') {
      return;
    }

    return new Promise((resolve, reject) => {
      const stateChangeListener = (event: Event) => {
        if (serviceWorker?.state === 'activated') {
          serviceWorker.removeEventListener('statechange', stateChangeListener);
          resolve();
        }
      };

      serviceWorker?.addEventListener('statechange', stateChangeListener);

      if (serviceWorker?.state === 'activated') {
        serviceWorker.removeEventListener('statechange', stateChangeListener);
        resolve();
      }
    });
  }

  upsertRegistrationToken(configurationModel: ConfigurationModel) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: this.originConfigurationPath + '/:user_id/registration',
    });

    return this.http
      .post<ConfigurationModel>(
        `${this.environment.notificationManagementApiUrl}${this.originConfigurationPath}/${configurationModel.userId}/registration`,
        configurationModel,
        {
          headers,
        },
      )
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }



  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
