import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ComponentFactoryResolver,
  HostListener,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { startWith, pairwise } from 'rxjs/operators';
import { IWidget } from '../../../models';
import { ResponsiveService } from '../../../services/responsive/responsive.service';
import { DashboardInterface } from '../dashboard.interface';
import { WidgetDirective } from './widget.directive';

@Component({
  selector: 'app-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss'],
})
export class DashboardWidgetComponent implements OnInit {
  @Input() widget: IWidget;

  @Output() onResize = new EventEmitter<string>();
  @Output() onRefresh = new EventEmitter();
  @Output() onRecreate = new EventEmitter();

  @ViewChild(WidgetDirective, { static: true }) widgetHost: WidgetDirective;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  width: number;
  height: number = null;
  previousSize: string = 'Small';
  sizes: string[] = ['Small', 'Medium', 'Large', 'Extra', 'Fit'];
  sizeNames = {
    Small: 'general.labels.Small',
    Medium: 'general.labels.Medium',
    Large: 'general.labels.Large',
    Extra: 'general.labels.Extra',
    Fit: 'general.labels.Fit',
  };
  sizeCoeff: number[] = [1, 2, 2, 3, 3];
  sideBarWidth: number = 220;

  visibleTitle: boolean = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private responsiveService: ResponsiveService,
    private dashbaordInterface: DashboardInterface,
  ) {}

  @HostListener('window:resize', ['$event'])
  onWindowResize(event?) {
    this.calculateWidth();
  }

  ngOnInit() {
    this.calculateWidth();
    this.heightSubscription();
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        this.widget.implementation,
      );

    const viewContainerRef = this.widgetHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef =
      viewContainerRef.createComponent<any>(componentFactory);
    componentRef.instance.data = this.widget.data;
    componentRef.instance.id = this.widget.id;

    if (componentRef.instance.refreshWidget) {
      componentRef.instance.refreshWidget.subscribe(() => {
        this.onRefresh.emit();
      });
    }

    this.resize(this.widget.size);

    this.responsiveService.isMobile$.subscribe((value) => {
      this.sideBarWidth = 220;
      if (value) this.sideBarWidth = 0;
      this.calculateWidth();
    });
  }

  heightSubscription() {
    this.dashbaordInterface.widgetHeightChanged$
      .pipe(startWith(null as string), pairwise())
      .subscribe(([prevHeight, height]) => {
        if (
          prevHeight != height &&
          this.widget.id === 'assessmentUtilization'
        ) {
          this.onRecreate.emit();
        }
      });
  }

  public resize(size: string): void {
    this.previousSize = this.widget.size;
    this.widget.size = size;

    if (this.previousSize != this.widget.size) {
      this.calculateWidth();
      this.onResize.emit(size);
    }
  }

  private calculateWidth(): void {
    const minimumWidth = 315;
    const coeff = this.sizeCoeff[this.sizes.indexOf(this.widget.size)];
    const viewWidth = window.innerWidth - this.sideBarWidth - 60 - 20;

    const numberOfHorizontalPlaces = Math.floor(viewWidth / minimumWidth);

    this.width =
      Math.floor(viewWidth / numberOfHorizontalPlaces) * coeff -
      4 * (2 * coeff) -
      numberOfHorizontalPlaces * 2;

    if (this.width < minimumWidth * coeff)
      this.width =
        minimumWidth * coeff - 4 * (2 * coeff) - numberOfHorizontalPlaces;

    if (this.widget.size == 'Full') {
      if (viewWidth <= 920) this.width = viewWidth + 80;
      else this.width = viewWidth + 10;
    }

    if (
      this.widget.id === 'totalHours' ||
      this.widget.id === 'totalHoursLine'
    ) {
      this.height = 355;
    }
  }

  showTitle() {
    // disable this functionality for now
    // this.visibleTitle = true;
  }

  hideTitle() {
    // disable this functionality for now
    // if (!this.trigger.menuOpen) this.visibleTitle = false;
  }
}
