import { Subject} from 'rxjs';

export class SidesheetRef {
    
    private sidesheetCloseSubject = new Subject<any>();

    constructor(){}

    public afterClosed() {
        return this.sidesheetCloseSubject.asObservable();
    }

    close(data: any): void {
        this.sidesheetCloseSubject.next(data);
        this.sidesheetCloseSubject.complete();
    }
}