import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Role } from '../../../models/system-administration/role.model';
@Injectable({
  providedIn: 'root',
})
export class FunctionService {
  public values: string[] = [];
  public functions: Role[] = [];

  constructor(
    private http: HttpClient,
    @Inject('environment') public environment: any,
  ) {}

  public getFunctions(type = '2') {
    const httpHeaders: HttpHeaders = new HttpHeaders({
      origin_path: '/function',
    });
    return this.http
      .get<any[]>(
        `${this.environment.systemAdminApiUrl}/function?type=${type}`,
        {
          headers: httpHeaders,
        },
      )
      .pipe(
        map((data) => {
          this.functions = data;
          this.values = data.map((x) => (x = x.name));
        }),
      );
  }
}
