import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { DynamicFormArray } from '../../models/dynamic-form-array.model';
import { DynamicFormService } from '../../services/dynamic-form.service';

@Component({
  selector: 'app-dynamic-form-array',
  templateUrl: './dynamic-form-array.component.html',
  styleUrls: ['./dynamic-form-array.component.scss'],
})
export class DynamicFormArrayComponent implements OnInit {
  @Input() dynamicFormArray: DynamicFormArray;
  @Input() formGroup: FormGroup;
  @Input() groupLabelStyle: string = 'normal';
  @Input() showAddRemoveButtons: boolean = true;
  @Input() showAddButton: boolean = true;
  @Input() showRemoveButton: boolean = true;
  @Input() showRemoveButtonOverride: string = '';
  @Input() showRemoveButtonLabel: string = 'general.actions.RemoveElement';
  @Input() includeAllowAllPermissions: boolean = true;
  @Input() showParentStyle: boolean = false;
  formArray: FormArray;

  // we copy the first element so we can use it later to add new elements to the array
  // kind of a bad solution, rethink this at some point if needed
  private firstDynamicArrayElement: any;

  constructor(private dynamicFormService: DynamicFormService) {}

  ngOnInit(): void {
    this.formArray = this.formGroup.get(
      this.dynamicFormArray.formArrayName,
    ) as FormArray;
    this.firstDynamicArrayElement = this.dynamicFormArray.controls[0];
  }

  removeArrayElement(index: number): void {
    this.dynamicFormArray.controls.splice(index, 1);
    this.formArray.removeAt(index);
  }

  addArrayElement() {
    if (this.firstDynamicArrayElement.dynamicType === 'group') {
      this.removeControlValues(this.firstDynamicArrayElement);
      this.firstDynamicArrayElement.formGroupName =
        this.formArray.controls.length.toString();
      this.formArray.push(
        this.dynamicFormService.generateFormGroup(
          this.firstDynamicArrayElement,
        ),
      );
    } else if (this.firstDynamicArrayElement.dynamicType === 'control') {
      this.firstDynamicArrayElement.value = null;
      this.firstDynamicArrayElement.key =
        this.formArray.controls.length.toString();
      this.formArray.push(new FormControl(null));
    }
    this.dynamicFormArray.controls.push(this.firstDynamicArrayElement);
  }

  removeControlValues(control: any) {
    if (control.controls) {
      control.controls.forEach((ctrl) => {
        this.removeControlValues(ctrl);
      });
    } else {
      control.value = null;
    }
  }

  showRemoveButtonCheck(dynamicEntry) {
    if (
      dynamicEntry.controls != null &&
      dynamicEntry.controls.get(this.showRemoveButtonOverride)
    ) {
      return false;
    }
    return this.showRemoveButton;
  }

  getParentStyle() {
    if (this.showParentStyle) {
      return null;
    } else {
      return {
        display: 'flex',
      };
    }
  }
}
