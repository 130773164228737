import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IFormDropdownOption } from '../../../../form-controls';
import { MasterDataService } from '../../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class CareTypeDeterminationService {
  public values: IFormDropdownOption[];
  public nameOptions = new BehaviorSubject<IFormDropdownOption[]>([]);
  public codeOptions = new BehaviorSubject<IFormDropdownOption[]>([]);

  constructor(private masterDataService: MasterDataService) {
    this.getCareTypeDeterminations();
  }

  private getCareTypeDeterminations() {
    this.masterDataService
      .getMasterData('care-type-determination-ggz', true)
      .subscribe((data) => {
        this.values = data;
        this.nameOptions.next(
          data.map((option) => {
            return { value: option.label, label: option.label };
          }),
        );
        this.codeOptions.next(
          data.map((option) => {
            return { value: option.value.code, label: option.value.code };
          }),
        );
      });
  }
}
