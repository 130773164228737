import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  AfterContentInit,
  AfterViewInit,
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomDateTimePipe } from '../../pipes';
import { PageInfoService } from '../../services/pageinfo/page-info.service';

@Component({
  selector: 'app-page-info',
  templateUrl: './page-info.component.html',
  styleUrls: ['./page-info.component.scss'],
})
export class PageInfoComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<boolean>();

  public form: FormGroup = null!;

  public status: string = '';

  constructor(
    public pageInfoService: PageInfoService,
    public formBuilder: FormBuilder,
    private cdref: ChangeDetectorRef,
    private dateTimePipe: CustomDateTimePipe,
  ) {}

  ngOnInit(): void {
    this.form = this.buildForm();
    this.form.disable();

    this.pageInfoService.updatedAt$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((updateDate) => {
        const value = updateDate
          ? this.dateTimePipe.transform(updateDate)
          : null;
        this.form.controls['updatedAt'].patchValue(value);
        this.cdref.detectChanges();
      });

    this.pageInfoService.createdAt$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((createdDate) => {
        const value = createdDate
          ? this.dateTimePipe.transform(createdDate)
          : null;
        this.form.controls['createdAt'].patchValue(value);
        this.cdref.detectChanges();
      });

    this.pageInfoService.createdBy$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((createdBy) => {
        const value = createdBy ? createdBy : null;
        this.form.controls['createdBy'].patchValue(value);
        this.cdref.detectChanges();
      });

    this.pageInfoService.updatedBy$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((updatedBy) => {
        const value = updatedBy ? updatedBy : null;
        this.form.controls['updatedBy'].patchValue(value);
        this.cdref.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  buildForm(){
      return this.formBuilder.group({
          updatedAt: [],
          updatedBy: [],
          createdAt: [],
          createdBy: [],
          dummy: [],
      });
  }
}
