<div class="c4p-question-set-input">
  <mat-form-field>
    <input
      type="{{ type }}"
      matInput
      [formControl]="valueControl"
      placeholder="{{ placeholder | translate }}"
      #comment
      (keydown.Tab)="onTab(comment)"
    />
    <span *ngIf="suffix" matSuffix>{{ suffix }}</span>
  </mat-form-field>
</div>
