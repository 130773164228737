import { AnswerTypeConfigurationModel } from './answer-type-configuration.model';

export class QuestionSetAnswerCompare {
  id: string;
  title: string;
  totalQuestions: number;
  answeredQuestions: number;
  answeredMandatoryQuestions: number;
  mandatoryQuestions: number;
  roles: {
    read: string[];
    readWrite: string[];
  };
  formatCompleteness: number;
  mismatchExists: boolean;
  createdBy: { id: string; fullName: string };
  createdAt: Date;
}

export class QuestionAnswerCompare {
  id: string;
  createdAt: Date;
  hint: string;
  tenantId: string;
  title: string;
  type: number;
  order: number;
  answer: string;
  isMandatory: boolean;
  isAnswered: boolean;
  entityId: string;
  entityType: string;
  entityVersion: number;
  commentRequired: boolean;
  questionSetAnswerId: string;
  questionId: string;
  typeConfiguration: AnswerTypeConfigurationModel[];
  updatedAt: Date;
  createdBy: { id: string; fullName: string };
  updatedBy: { id: string; fullName: string };
}

export class QuestionSetCompareModel {
  questionSetAnswerChanges: {
    oldQuestionSetAnswer?: QuestionSetAnswerCompare;
    newQuestionSetAnswer?: QuestionSetAnswerCompare;
  };
  questionChanges: {
    oldQuestionAnswers?: QuestionAnswerCompare[];
    newQuestionAnswers?: QuestionAnswerCompare[];
  };
}
