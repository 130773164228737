import { AssignedQuestionUpdateModel } from './assigned-question-update.model';

export class QuestionSetUpdateModel {
  id!: string | number;
  title!: string;
  description: string;
  type!: number;
  autoInsert!: boolean;
  docGenCode: string | null | undefined;
  createdAt: Date;
  createdBy!: string | number;
  assignedQuestions: AssignedQuestionUpdateModel[];
  roles: {
    read: string[] | [];
    readWrite: string[] | [];
  };
  services: string[];
  updatedAt: Date;
}
