<div [formGroup]="identificationMethods">
  <app-panel-form-row>
    <app-form-input-checkbox
      formControlName="validateWithBsn"
      placeholder="document.labels.Bsn"
    ></app-form-input-checkbox>
    <app-form-input-checkbox
      formControlName="validateWithBirthYear"
      placeholder="document.labels.birthYear"
    ></app-form-input-checkbox>
    <app-form-input-checkbox
      formControlName="validateWithPhoneNumber"
      placeholder="document.labels.phoneNumber"
    ></app-form-input-checkbox>
  </app-panel-form-row>
  <app-panel-form-row>
    <app-form-input-checkbox
      formControlName="validateWithEmail"
      placeholder="document.labels.validateWithEmail"
    ></app-form-input-checkbox>
    <app-form-input-checkbox
      formControlName="validateWithEmailCode"
      placeholder="document.labels.validateWithEmailCode"
    ></app-form-input-checkbox>
  </app-panel-form-row>
</div>
