import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatTooltip } from '@angular/material/tooltip';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as moment from 'moment';
import { CustomDateTimePipe } from '../../pipes';
export class IComError {
  resultType: string;
  result: string;
  error: any;
}

export interface IComErrorData {
  error: IComError;
  message: any;
  isOverviewState: boolean;
}

@Component({
  selector: 'lib-error-panel',
  templateUrl: './error-panel.component.html',
  styleUrls: ['./error-panel.component.scss'],
})
export class ErrorPanelComponent implements OnInit {
  public errorMessage$ = new BehaviorSubject<string>(null);
  public codeOnly = false;
  public isOverviewState = true;

  errorForm: FormGroup = null!;



  constructor(
    public dialogRef: MatDialogRef<ErrorPanelComponent>,
    @Inject(MAT_DIALOG_DATA) public errorData: IComErrorData,
    private clipboard: Clipboard,
    private translate: TranslateService,
    private fb: FormBuilder,
    private tenantDateTime: CustomDateTimePipe,
  ) {}

  ngOnInit(): void {
    this.errorForm = this.errorBuildForm();
    this.isOverviewState = this.errorData.isOverviewState;

    let serverError: string[];
    let errorCode: string;
    if ((serverError = this.errorData.error.error.error)) {
      let errorString: string = '';
      // is the message actually an object
      if (serverError[0].charAt(0) == '{') {
        errorString = serverError[0];
      } else {
        serverError.forEach((error) => {
          errorString += this.translate.instant(error) + '\n';
        });
      }

      this.errorMessage$.next(errorString);
    } else if ((errorCode = this.errorData.error.error.code)) {
      this.errorMessage$.next(errorCode);
      this.codeOnly = true;
    }

    const createdAtString: string = this.errorData.message.createdAt;
    let createdDate;
    if (createdAtString) {
      if (createdAtString.split('-')[0].length > 2) {
        createdDate = this.tenantDateTime.transform(
          moment(this.errorData.message.createdAt, 'YYYY-MM-DD'),
        );
      } else {
        createdDate = this.tenantDateTime.transform(
          moment(this.errorData.message.createdAt, 'DD-MM-YYYY hh:mm'),
        );
      }
    }

    const message = {
      clientName: this.errorData.message.clientName,
      id: this.errorData.message.id,
      messageType: this.errorData.message.messageType,
      createdAt: createdDate,
    };
    this.errorForm.patchValue(message);
  }

  public copyToClipboard(successTooltip: MatTooltip) {
    this.clipboard.copy(this.errorMessage$.value);
    successTooltip.disabled = false;
    successTooltip.toggle();

    setTimeout(() => {
      successTooltip.disabled = true;
      successTooltip.toggle();
    }, 500);
  }
  errorBuildForm(){
    return this.fb.group({
      clientName: [{ value: null, disabled: true }],
      id: [{ value: null, disabled: true }],
      messageType: [{ value: null, disabled: true }],
      createdAt: [{ value: null, disabled: true }],
    });
  }
}
