import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginationDataModel, PaginationResultModel } from '../../models';
import { DropdownServiceBase } from '../../services';
import { HttpUtils } from '../../utilities';
import { EvaluationModel } from '../../models/evaluation/evaluation.model';

@Injectable()
export class EvaluationsService extends DropdownServiceBase<EvaluationModel> {
  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
  ) {
    super();

    this.setupDropdown(this.getEvaluations);
  }

  public getEvaluations(
    paginationData: PaginationDataModel,
    filterData: any,
  ): Observable<PaginationResultModel<EvaluationModel>> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles/:carefile_id/evaluations',
      entity_type: 'Carefile',
      entity_id: filterData.carefileId,
    });

    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );

    return this.http.get<PaginationResultModel<EvaluationModel>>(
      `${this.environment.carefileManagementApiUrl}/${filterData.carefileId}/evaluations`,
      { params, headers },
    );
  }
}
