<app-panel type="popup-medium">
  <app-panel-title title="icomErrorPanel.ErrorOccured" titleIcon="warning">
  </app-panel-title>
  <ng-container [formGroup]="errorForm">
    <app-panel-form-row mode="-center-borderless">
      <app-form-input-text
        formControlName="clientName"
        placeholder="icomErrorPanel.ClientName"
      >
      </app-form-input-text>
      <app-form-input-text
        formControlName="id"
        placeholder="icomErrorPanel.MessageId"
      >
      </app-form-input-text>
    </app-panel-form-row>
    <app-panel-form-row mode="-center-borderless">
      <app-form-input-text
        formControlName="messageType"
        placeholder="icomErrorPanel.MessageType"
      >
      </app-form-input-text>
      <app-form-input-text
        formControlName="createdAt"
        placeholder="icomErrorPanel.CreationDate"
      >
      </app-form-input-text>
    </app-panel-form-row>
  </ng-container>
  <mat-card appearance="outlined">
    <span *ngIf="codeOnly">{{
      'icommunication.error.supportContact' | translate
    }}</span>
    <pre class="c4p-pre">{{ errorMessage$ | async }}</pre>
    <button
      mat-icon-button
      class="copy-icon"
      [matTooltip]="'icomErrorPanel.CopiedToClipboard' | translate"
      [matTooltipDisabled]="true"
      #successTooltip="matTooltip"
      (click)="copyToClipboard(successTooltip)"
    >
      <mat-icon
        [matTooltip]="'icomErrorPanel.CopyToClipboard' | translate"
        matTooltipPosition="below"
        >content_copy</mat-icon
      >
    </button>
  </mat-card>
</app-panel>

<div style="display: flex">
  <span class="c4p-button-cancel" (click)="dialogRef.close()" translate
    >general.actions.Close</span
  >
  <span
    *ngIf="isOverviewState"
    class="c4p-button-ok"
    (click)="dialogRef.close(errorData.message.id)"
  >
    <span translate>icomErrorPanel.SeeMessage</span>
  </span>
</div>
