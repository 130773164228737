import { NotificationUserInfoDTO } from './notification-user-info.dto';

export class EmailAttachment {
  filename: string;
  content: string;
}
export class ExternalNotificationDto {
  tenantId: string;
  templateKey: string;
  replaceItems: NotificationReplaceDTO[];
  receiver: NotificationUserInfoDTO;
  emailAttachments?: EmailAttachment[];
  secondaryReceivers?: NotificationUserInfoDTO[];
}

export class NotificationReplaceDTO {
  key: string;
  value: string;
}

export class NotificationDTO {
  id: string;
  tenantId: string;
  from: string;
  users: NotificationUser[];
  subject: string;
  message: string;
  url: string;
  metadata: any;
  emailAddresses: string[];
  emailAttachment: EmailAttachment;
  createdAt: Date;

  currentUserRead?: boolean;
  currentUserStarred?: boolean;
  readUnreadFlag?: boolean;
}

export class NotificationUser{
  userId: string;
  userRead: boolean;
  readAt?: Date;
  isStarred?: boolean;
}
