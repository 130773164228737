import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

export class DocUserModel {
  id?: string;
  name?: string;
  avatar?: string;
  primaryEmail?: string;
  phones?: any[];
}

@Injectable()
export class CollaborationUserAvatarService {
  constructor(
    private http: HttpClient,
    @Inject('environment') public environment: any,
  ) {}

  public getEmployee(id: number | string) {
    const httpHeaders: HttpHeaders = new HttpHeaders({
      origin_path: '/employees/:employee_id',
      entity_id: id.toString(),
    });
    return this.http.get<DocUserModel>(
      `${this.environment.employeeManagementApiUrl}/${id}`,
      {
        headers: httpHeaders,
      },
    );
  }
}
