import { ToastrService } from 'ngx-toastr';
import { KeycloakManagementService } from './../../services/keycloak-management/keycloak-management.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PasswordMatchValidator } from '../../validators/passwords-match.validator';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  newPasswordType = 'password';
  hideNewPassword = true;
  confirmPasswordType = 'password';
  hideConfirmPassword = true;
  public changePasswordForm: FormGroup = null!;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private keycloakManagementService: KeycloakManagementService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.changePasswordForm = this.changePasswordBuildForm();
  }

  passwordMatchValidator(frm: FormGroup) {
    return frm.controls['newPassword'].value ===
      frm.controls['confirmPassword'].value
      ? null
      : { mismatch: true };
  }

  onNewPasswordVisibility() {
    this.hideNewPassword = !this.hideNewPassword;

    if (this.hideNewPassword) this.newPasswordType = 'password';
    else this.newPasswordType = 'text';
  }

  onConfirmPasswordVisibility() {
    this.hideConfirmPassword = !this.hideConfirmPassword;

    if (this.hideConfirmPassword) this.confirmPasswordType = 'password';
    else this.confirmPasswordType = 'text';
  }

  onConfirm() {
    if (this.changePasswordForm.valid) {
      const password = this.changePasswordForm.get('newPassword').value;
      this.keycloakManagementService.passwordReset(password).subscribe(
        () => {
          this.toastr.success(
            this.translate.instant(
              'general.labels.PasswordChangedSuccessfully',
            ),
          );
          this.dialogRef.close();
        },
        () => {
          this.toastr.error(
            this.translate.instant('general.labels.PasswordChangeFail'),
          );
          this.dialogRef.close();
        },
      );
    }
  }

  onDismiss() {
    this.dialogRef.close();
  }
  changePasswordBuildForm(){
    return this.formBuilder.group(
        {
          newPassword: [null, [Validators.required]],
          confirmPassword: [null, [Validators.required]],
        },
        { validator: PasswordMatchValidator('newPassword', 'confirmPassword') },
    );
  }
}
