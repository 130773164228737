import {
  Component,
  OnInit,
  AfterContentInit,
  ContentChild,
} from '@angular/core';
import { PageContentComponent } from '../page-content/page-content.component';
import { PageInfoComponent } from '../page-info/page-info.component';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit, AfterContentInit {
  // @ContentChild(PageTabComponent) pageTabComponent: PageTabComponent;
  // @ContentChild(PageMenuComponent) pageMenuComponent: PageMenuComponent;
  @ContentChild(PageContentComponent)
  pageContentComponent: PageContentComponent;
  @ContentChild(PageInfoComponent) pageInfoComponent: PageInfoComponent;

  constructor() {}

  ngOnInit(): void {}

  ngAfterContentInit() {}
}
