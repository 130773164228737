import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { CareFileModel } from '../../models/carefile/carefile.model';
import {
  GoalHistoryModel,
  ScoreHistoryModel,
  SocialAreaModel,
} from '../../models/social-area/social-area.model';
import { HttpUtils } from '../../utilities/http.utils';
import { PaginationDataModel } from '../../models/pagination/pagination-data.model';

@Injectable()
export class SocialAreaService {
  public carefiles$ = new BehaviorSubject<{
    docs: CareFileModel[];
    count: number;
  }>(null);

  public goalHistory$ = new BehaviorSubject<ScoreHistoryModel[]>(null);

  constructor(
    private http: HttpClient,
    @Inject('environment') public environment: any,
  ) {}

  public getSocialAreas(
    paginationData: PaginationDataModel,
    filterData: any,
    entity: string,
    entityId: string,
    entityVersion: number,
  ) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: this.setOriginPath(entity),
    });
    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );

    return this.http.get<any>(
      `${this.environment.questionsetManagementApiUrl}/selected-social-areas/${entity}/${entityId}/${entityVersion}`,
      {
        headers,
        params,
      },
    );
  }

  public createSocialAreas(
    entityId: string,
    entityVersion: number,
    entity: string,
    socialArea: any,
  ) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: this.setOriginPath(entity),
      entity_type: entity,
      entity_id: entityId,
      sub_entity_type: 'Social-Areas',
    });
    return this.http.post<SocialAreaModel[]>(
      `${this.environment.questionsetManagementApiUrl}/selected-social-areas/${entity}/${entityId}/${entityVersion}`,
      socialArea,
      {
        headers,
      },
    );
  }

  public updateSocialAreas(
    entityId: string,
    entityVersion: number,
    entity: string,
    socialArea: any,
  ) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: this.setOriginPath(entity),
      entity_type: entity,
      entity_id: entityId,
      sub_entity_type: 'Social-Areas',
    });
    return this.http.patch<SocialAreaModel[]>(
      `${this.environment.questionsetManagementApiUrl}/selected-social-areas/${entity}/${entityId}/${entityVersion}`,
      socialArea,
      {
        headers,
      },
    );
  }

  public getCompleteMainGoals(socialareaId: string) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path:
        '/selected-social-areas/careplan/:careplan_id/:entity_version',
    });
    let params: HttpParams = new HttpParams();
    params = params.append('socialarea_id', socialareaId);

    return this.http.get<any>(
      `${this.environment.questionsetManagementApiUrl}/main-goals/complete-goals`,
      { headers, params },
    );
  }

  private setOriginPath(entity: string) {
    if (entity === 'report') {
      return `/selected-social-areas/report/:report_id/:entity_version`;
    }
    // In future there will be more entity types;
    return `/selected-social-areas/careplan/:careplan_id/:entity_version`;
  }

  public getGoalHistory(
    entity: string,
    entityId: string,
    entityVersion: number,
    goalType: string,
    filterParams?: any,
  ): Observable<GoalHistoryModel> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: `/selected-social-areas/evaluation/:evaluation_id/:entity_version/history/${goalType}`,
      entity_id: entityId,
      entity_type: entity,
    });
    let params: HttpParams = HttpUtils.getFilterParams(filterParams);

    return this.http.get<any>(
      `${this.environment.questionsetManagementApiUrl}/selected-social-areas/${entity}/${entityId}/${entityVersion}/history/${goalType}`,
      { headers, params },
    );
  }
}
