import { ModelUtils } from '../../utilities/model.utils';
import { ClientContactInformationModel } from './client-contact-information.model';
import { ClientEducationModel } from './client-education.model';
import { ClientEmploymentModel } from './client-employement.model';

export class ClientModel {
  id!: string;
  tenantId!: number | string;
  customAvatar: string | null | undefined;
  providerAvatar: string | null | undefined;
  useCustomAvatar: boolean | null | undefined;
  avatar!: string | null | undefined;
  clientNumber!: string; // future tenants might want different numbering
  fullName: string | null | undefined;
  namePrefix: string | null | undefined;
  givenName: string | null | undefined;
  initials: string | null | undefined;
  lastName: string | null | undefined;
  firstNames: string | null | undefined;
  nameUse: string | null | undefined;
  dateOfBirth: Date | null | undefined;
  dateUse: string | null | undefined;
  gender: string | null | undefined;
  bsn: string | null | undefined;
  communicationForm: string | null | undefined;
  language: string | null | undefined;
  juridicalStatus: string | null | undefined;
  legalRepresentative: string | null | undefined;
  isConfirmed!: boolean; // default is false
  contactInfo: ClientContactInformationModel | null | undefined;
  educations: ClientEducationModel[] | null | undefined;
  employments: ClientEmploymentModel[] | null | undefined;
  preferredLanguage: string | null | undefined;
  status!: string;
  assessmentStatus?: string;
  createdAt!: Date;
  createdBy!: number | string;
  updatedAt: Date | null | undefined;
  updatedBy: number | string | null | undefined;
  email?: string;

  constructor(client?: any) {
    if (client) {
      Object.assign(this, client);
      this.fullName = ModelUtils.getFullName(
        client.givenName,
        client.namePrefix,
        client.lastName,
      );
    } else {
      this.fullName = 'n / a';
    }
  }
}
