<app-page-content [formGroup]="referralFormGroup">
  <ng-container formGroupName="referrer">
    <app-panel>
      <app-panel-title title="careprogram.titles.GeneralInformation">
      </app-panel-title>
      <app-panel-form-row>
        <app-form-input-date
          formControlName="dateOfReferral"
          placeholder="careprogram.labels.DateOfReferral"
        >
        </app-form-input-date>
        <app-form-input-dropdown
          formControlName="referralType"
          placeholder="careprogram.labels.TypeOfReferral"
          [options]="referralTypeService.values"
          width="-nowidth"
          style="width: 610px; margin: 0px 0px 11px 10px"
        >
        </app-form-input-dropdown>

        <span style="margin-left: 15px" class="c4p-ellipsis" translate>
          careprogram.labels.AddReferrer
        </span>
        <mat-slide-toggle
          style="margin-left: 10px"
          (change)="addReferrer($event)"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="hasReferrer"
        ></mat-slide-toggle>
      </app-panel-form-row>
    </app-panel>
    <app-panel *ngIf="hasReferrer">
      <app-panel-title title="careprogram.titles.Referrer"> </app-panel-title>
      <app-panel-form-row formGroupName="careProfessionalName">
        <app-form-input-text
          formControlName="firstName"
          placeholder="careprogram.labels.FirstName"
        >
        </app-form-input-text>
        <app-form-input-text
          formControlName="initials"
          placeholder="careprogram.labels.Initials"
        >
        </app-form-input-text>
        <app-form-input-text
          formControlName="givenName"
          placeholder="careprogram.labels.GivenName"
        >
        </app-form-input-text>
        <app-form-input-text
          formControlName="additions"
          placeholder="careprogram.labels.Additions"
        >
        </app-form-input-text>
        <app-form-input-text
          formControlName="lastName"
          placeholder="careprogram.labels.LastName"
        >
        </app-form-input-text>
      </app-panel-form-row>
      <app-panel-form-row>
        <app-form-input-text
          formControlName="careOrgName"
          placeholder="careprogram.labels.CareOrgName"
        >
        </app-form-input-text>
        <app-form-input-text
          formControlName="agbCode"
          placeholder="careprogram.labels.AgbCode"
        >
        </app-form-input-text>
      </app-panel-form-row>
      <app-panel-form-row>
        <app-form-input-dropdown
          formControlName="careProviderType"
          placeholder="careprogram.labels.CareProviderType"
          [options]="careProviderTypeService.values"
        >
        </app-form-input-dropdown>
        <app-form-input-dropdown
          formControlName="careProviderSpec"
          placeholder="careprogram.labels.CareProviderSpecification"
          [options]="careProviderSpecService.values"
          [maxContentSelectionPanel]="true"
        >
        </app-form-input-dropdown>
        <app-form-input-dropdown
          formControlName="professionCareProfessional"
          placeholder="careprogram.labels.ProfessionCareProfessional"
          [options]="professionCareProfessionalService.values"
          [maxContentSelectionPanel]="true"
        >
        </app-form-input-dropdown>
        <app-form-input-dropdown
          formControlName="careProviderRole"
          placeholder="careprogram.labels.CareProviderRole"
          [options]="careProviderRoleService.values"
        >
        </app-form-input-dropdown>
      </app-panel-form-row>
    </app-panel>
    <app-documents
      class="refferal-doc"
      section="careprogram"
      [carefileId]="carefileId"
      [careprogramId]="careprogramId"
      [services]="carefileServices"
      [userportal]="portal"
      [applicationViewMode]="referralFormGroup?.disabled"
      careprogramViewMode="true"
      [operation]="OPERATION_TYPES.REFERRAL_APPROVAL"
    >
    </app-documents>
  </ng-container>
</app-page-content>
