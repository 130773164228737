import { Component, Input, DoCheck } from '@angular/core';
import { FormControlBase } from '../abstract-form-control';
import {
  IFormDropdownOption,
  DEFAULT_RADIO_DIRECTION,
} from '../form-controls.const';

@Component({
  selector: 'app-form-input-radio',
  templateUrl: './form-input-radio.component.html',
  styleUrls: ['./form-input-radio.component.scss'],
})
export class FormInputRadioComponent
  extends FormControlBase<FormInputRadioComponent>
  implements DoCheck
{
  @Input() options: IFormDropdownOption[] = [];
  @Input() direction = DEFAULT_RADIO_DIRECTION;
  @Input() showCustomOption = false;
  @Input() showLabelHorizontal = true;

  ngDoCheck(): void {
    this.adjustTouched();
  }
}
