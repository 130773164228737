import { Injectable } from '@angular/core';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class InterestExperienceService {
  public values = [];

  constructor(private masterDataService: MasterDataService) {
    this.getGroupTypes();
  }

  public getGroupTypes() {
    this.masterDataService.getMasterData('group-types').subscribe((data) => {
      this.values = data.map((type) => type.label);
    });
  }
}
