import { ErrorStateMatcher } from '@angular/material/core';
import {
  NgControl,
  FormControl,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';

export class FormErrorStateMatcher implements ErrorStateMatcher {
  ngControl: NgControl;
  constructor(ngControl: NgControl) {
    this.ngControl = ngControl;
  }
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    return !!(
      this.ngControl &&
      (this.ngControl.invalid || control.invalid) &&
      this.ngControl.touched
    );
  }
}

// solves the issue of valid dates having a red line
// needed for now until they fix formControl on dateinputs
export class DateInputErrorStateMatcher implements ErrorStateMatcher {
  ngControl: NgControl;
  constructor(ngControl: NgControl) {
    this.ngControl = ngControl;
  }
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    return !!(
      this.ngControl &&
      this.ngControl.invalid &&
      this.ngControl.touched
    );
  }
}
