import { DateChoiceComponent } from './../date-choice/date-choice.component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DEFAULT_DATE_TIME_SHOW_SECONDS,
  DEFAULT_DATE_TIME_SHOW_SPINNERS,
  DEFAULT_DATE_TIME_HOUR_STEP,
  DEFAULT_DATE_TIME_MINUTE_STEP,
  DATE_TIME_REGEX,
} from '../../../form-controls/form-controls.const';
import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'app-date-time-choice',
  templateUrl: './date-time-choice.component.html',
  styleUrls: ['./date-time-choice.component.css'],
})
export class DateTimeChoiceComponent extends DateChoiceComponent {
  @Input() showSpinners = DEFAULT_DATE_TIME_SHOW_SPINNERS;
  @Input() showSeconds = DEFAULT_DATE_TIME_SHOW_SECONDS;
  @Input() stepHour = DEFAULT_DATE_TIME_HOUR_STEP;
  @Input() stepMinute = DEFAULT_DATE_TIME_MINUTE_STEP;
  @Input() defaultTime: number[];
  @Input() hint: string;
  @Input() isClientPortal = false;
  @Input() orderNum: number;
  @Output() tabEvent = new EventEmitter();

  onKeydown = DATE_TIME_REGEX;

  onTab(el: HTMLElement) {
    this.tabEvent.emit(el.offsetHeight);
  }
}
