<div id="maincontainer" class="main-container">
  <app-navbar [navBarMode]="navBarMode" (navbarModeChanged)="onNavbarModeChanged($event)"></app-navbar>

  <div class="main-content-container" class="{{navBarMode}}">
    <app-top-bar [navBarMode]="navBarMode" (navbarModeChanged)="onNavbarModeChanged($event)"></app-top-bar>

    <div class="main-content-page">
      <div class="page-top-bar">
        <app-breadcrumb></app-breadcrumb>

        <app-page-info></app-page-info>
  
        <div class="main-content-page-menu">
          <ng-container [ngTemplateOutlet]="topBarTemplate$ | async"></ng-container>
        </div>
        
        <mat-progress-bar
        mode="indeterminate"
        class="c4p-progress-bar"
        [ngStyle]="{ visibility: (isLoading$ | async) ? 'visible' : 'hidden' }"></mat-progress-bar>
      </div>

      <div class="main-content">

        <router-outlet #outlet="outlet"></router-outlet>
      </div>

    </div>



  </div>
</div>

<app-side-sheet> </app-side-sheet>
