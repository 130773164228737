import { SignatureLogDTO, SignatureLogSummary } from '../../models';
import { b } from '@fullcalendar/core/internal-common';

export enum DocumentStatus {
  DRAFT = 'draft',
  CLOSED = 'closed',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  ACTIVE = 'active',
}

export enum DocumentType {
  PDF = 'pdf',
}

class UserModel {
  id: string;
  type: string;
  fullName: string;
}

class ClientModel {
  id: string;
  fullName: string;
}

export class ClientShareModel {
  id?: string;
  userId: string;
  fullName: string;
  sharingType?: string;
  shareDate: Date;
  signatureRequired?: boolean;
  signatureStatus?: string;
  email?: string;
  disabled?: boolean; // frontend-only
  checked?: boolean; // frontend-only
  identificationMethods?: IdentificationMethods
}
export class IdentificationMethods{
  bsn?: boolean = false;
  phoneNumber?: boolean = false;
  birthYear?: boolean = false;
  withEmail?: boolean = false;
  withEmailCode?: boolean = false;
}
export class FileDataModel {
  pathToBucket?: string;
  link?: string;
  fileName: string;
  extension: string;
  fileSize: string;
  base64file?: string | ArrayBuffer;
}

class CategoryInfoModel {
  id: string;
  name: string;
}

class ServiceInfoModel {
  id: string;
  name: string;
  globalServiceId: string;
}

class OriginalVersion {
  documentName: string;
}

export class DocumentModel {
  id!: string;
  tenantId!: string;
  description?: string;
  category!: CategoryInfoModel;
  status!: DocumentStatus;
  fileData?: FileDataModel;
  client?: ClientModel;
  applicationId?: string;
  carefileId?: string;
  careprogramId?: string;
  sharing: Array<ClientShareModel>;
  type?: string;
  contentType?: string;
  services?: Array<ServiceInfoModel>;
  createdAt?: Date;
  createdBy!: UserModel;
  updatedAt?: Date;
  updatedBy?: UserModel;
  readFlag: boolean;
  lastSignature?: SignatureLogDTO;
  signatureLogs?: SignatureLogSummary[];
  originalVersion?: OriginalVersion;
  isSigned: boolean;
  documentName: string;
  isArchived?: boolean;
}
