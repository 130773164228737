<li [routerLink]="menuItem?.route" routerLinkActive="navbar-menu-item-selected" class="navbar-menu-item"
    (click)="onMenuItemClicked($event)">
    <span class="navbar-navbar-menu-item-icon-wrapper">
        <mat-icon matPrefix class="navbar-menu-item-icon">{{ menuItem.icon }}</mat-icon>
    </span>
    <span class="navbar-menu-item-text">{{menuItem.name | translate}}</span>
</li>

<ng-container *ngIf="selectedMenuItemName===menuItem.name">
    <ng-container *ngIf="menuItem && menuItem.items && menuItem.items.length>0">
        <div class="navbar-sub-menu-container">
            <ng-container *ngFor="let item of menuItem.items">
                <ng-container *ngIf="item.hasPermission && !item?.route?.startsWith('http')">
                    <div 
                    [routerLink]="item.route" 
                    routerLinkActive="navbar-sub-menu-item-selected"
                        class="navbar-sub-menu-item">
                        <span class="navbar-sub-menu-item-text">{{ item.name | translate}}</span>
                    </div>
                </ng-container>

                <ng-container *ngIf="item.hasPermission && item?.route?.startsWith('http')">
                    <div class="navbar-sub-menu-item" (click)="onExternalLink(item)">
                        <span class="navbar-sub-menu-item-text">{{ item.name | translate}}</span>
                    </div>
                </ng-container>

            </ng-container>
        </div>
    </ng-container>
</ng-container>