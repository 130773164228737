import { Subject } from 'rxjs';

export class CardGridInterface {
  private _showSearch: boolean;
  showSearch$ = new Subject<boolean>();

  public showSearch(): void {
    this._showSearch = true;
    this.showSearch$.next(true);
  }

  public hideSearch(): void {
    this._showSearch = false;
    this.showSearch$.next(false);
  }

  private _search: string;
  search$ = new Subject<string>();

  public resetSearch(): void {
    this._search = '';
    this.search$.next('');
  }

  private _pageIndex: number;
  pageIndex$ = new Subject<number>();

  public set pageIndex(val: number) {
    this._pageIndex = val;
    this.pageIndex$.next(val);
  }

  public get pageIndex(): number {
    return this._pageIndex;
  }

  private _pageSizeIndex: any;
  pageSizeIndex$ = new Subject<{ pageIndex: number; pageSize: number }>();

  public set pageSizeIndex(val: any) {
    this._pageSizeIndex = val;
    this.pageSizeIndex$.next(val);
  }

  public get pageSizeIndex(): any {
    return this._pageSizeIndex;
  }

  constructor() {}
}
