import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class ApplierTypeService {
  public values: IFormDropdownOption[] = [
    { value: '1', label: 'Applier Type 1' },
    { value: '2', label: 'Applier Type 2' },
    { value: '3', label: 'Applier Type 3' },
  ];

  constructor(private masterDataService: MasterDataService) {
    this.getApplierTypes();
  }

  public getApplierTypes() {
    this.masterDataService.getMasterData('applier-types').subscribe((data) => {
      this.values = data;
    });
  }
}
