import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';

export const CLIENT_PORTAL_USER_TYPE: IFormDropdownOption[] = [
  { label: 'general.labels.Client', value: 'client' },
  { label: 'general.labels.ClientNetwork', value: 'client_network' },
];

@Injectable({
  providedIn: 'root',
})
export class ClientPortalUserTypeService {
  public values: IFormDropdownOption[] = CLIENT_PORTAL_USER_TYPE;
  public lookupValues: string[] = [];

  constructor() {
    this.clientPortalUserTypeValues();
  }

  public clientPortalUserTypeValues() {
    for (const item of this.values) {
      this.lookupValues[item.value] = item.label;
    }
  }
}
