import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientNetworkModel } from '../../models/client-network/client-network.model';
import { PaginationDataModel } from '../../models/pagination/pagination-data.model';
import { PaginationResultModel } from '../../models/pagination/pagination-result.model';
import { HttpUtils } from '../../utilities/http.utils';
import { DropdownServiceBase } from '../dropdown-service.abstract';

@Injectable({
  providedIn: 'root',
})
export class ClientNetworkService extends DropdownServiceBase<ClientNetworkModel> {
  constructor(
    private http: HttpClient,
    @Inject('environment') public environment: any,
  ) {
    super();
    this.setupDropdown(this.getClientNetworks);
  }

  public getClientNetworks(
    paginationData: PaginationDataModel,
    filterData?: any,
  ): Observable<PaginationResultModel<ClientNetworkModel>> {
    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/client-networks',
    });
    return this.http.get<PaginationResultModel<ClientNetworkModel>>(
      this.environment.clientNetworkManagementUrl,
      { params, headers },
    );
  }
}
