import { Component, OnInit, OnDestroy } from '@angular/core';
import { LanguageService } from '../../services/language/language.service';
import { Subscription } from 'rxjs';
import * as moment_ from 'moment';
import { UserInfoService } from '../../services/user-info/user-info.service';

const moment = moment_;
@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
})
export class LanguagePickerComponent implements OnInit, OnDestroy {
  public image: string = 'assets/svg/nl.svg';

  private subscription: Subscription;

  public menuActions = [
    {
      icon: 'flag-nl',
      image: 'assets/svg/nl.svg',
      tooltip: 'NL',
      language: 'nl'
    },
    {
      icon: 'flag-en',
      image: 'assets/svg/gb.svg',
      tooltip: 'EN',
      language: 'en'
    },
  ];

  constructor(
    private languageService: LanguageService,
    private userInfoService: UserInfoService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.languageService.languageObservable.subscribe(
      (value: string) => {
        this.image = this.menuActions.find(action => action.language === value)?.image;
      },
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setLanguage(img, language) {
    this.languageService.setLanguage(language);
    this.image = img;
    this.userInfoService.updatePreferredLanguage(language);
  }
}
