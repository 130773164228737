import { FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';

export function formArrayUniqueValueValidator(
  uniqueProperty: string,
): ValidatorFn {
  return (formArray: FormArray): ValidationErrors | null => {
    const values: any[] = [...formArray.value];

    let allValues = [];
    values.forEach((value) => {
      if (
        valueProperty(value, uniqueProperty)?.length > 1 &&
        valueProperty(value, uniqueProperty) instanceof Array
      ) {
        valueProperty(value, uniqueProperty)?.forEach((element) => {
          allValues.push({ [uniqueProperty]: [element] });
        });
      }
      allValues.push(value);
    });

    let validatonError: ValidationErrors = null;

    const uniqueItems = allValues
      .filter((value) => valueProperty(value, uniqueProperty) != undefined)
      .map((value) => {
        return { count: 1, value: valueProperty(value, uniqueProperty) };
      })
      .reduce((a, b) => {
        a[b.value] = (a[b.value] || 0) + b.count;
        return a;
      }, {});

    const duplicates = Object.keys(uniqueItems).filter(
      (a) => uniqueItems[a] > 1,
    );

    if (duplicates.length > 0) {
      formArray.controls
        .map((formGroup) => formGroup.get(formGroupProperty(uniqueProperty)))
        // find all the controls with the duplicated value
        .filter((control) => duplicates.includes(control.value))
        // set an error on them
        .forEach((control) => {
          control.setErrors({ notUnique: true });
          control.markAsTouched();
        });

      validatonError = { notUnique: duplicates.join(', ') };
    } else {
      formArray.controls
        // reset the validation when it is valid
        .forEach((formGroup) => {
          const control = formGroup.get(formGroupProperty(uniqueProperty));
          if (control?.errors?.notUnique) {
            control.setErrors({ notUnique: null });
            control.updateValueAndValidity({
              emitEvent: false,
              onlySelf: true,
            });
            formGroup.updateValueAndValidity({
              emitEvent: false,
              onlySelf: true,
            });
          }
        });
    }

    return validatonError;
  };
}
function formGroupProperty(uniqueProperty: string) {
  if (uniqueProperty.split('.').length == 2) {
    const properties = uniqueProperty.split('.');
    return properties[0];
  } else return uniqueProperty;
}
function valueProperty(value: any, uniqueProperty: string) {
  // Check if uniqueProperty is nested.
  if (uniqueProperty.split('.').length == 2) {
    const properties = uniqueProperty.split('.');

    // Parent object can be null, so child properties are not populated at all.
    if (!value[properties[0]]) return value[properties[0]];
    return value[properties[0]][properties[1]];
  } else return value[uniqueProperty];
}
