// https://www.braemoor.co.uk/software/vat.shtml
import { AbstractControl } from '@angular/forms';

export function netherlandsKvkValidator(
  control: AbstractControl,
): { [key: string]: boolean } | null {
  const valid = control.value && checkVATNumber(control.value);
  if (valid == false) {
    return {
      vatNumber: valid,
    };
  } else {
    return null;
  }
}

function checkVATNumber(toCheck) {
  // Array holds the regular expressions for the valid VAT number
  var vatexp = new Array();

  // To change the default country (e.g. from the UK to Germany - DE):
  //    1.  Change the country code in the defCCode variable below to "DE".
  //    2.  Remove the question mark from the regular expressions associated with the UK VAT number:
  //        i.e. "(GB)?" -> "(GB)"
  //    3.  Add a question mark into the regular expression associated with Germany's number
  //        following the country code: i.e. "(DE)" -> "(DE)?"

  var defCCode = 'NL';

  // Note - VAT codes without the "**" in the comment do not have check digit checking.

  vatexp.push(/^(NL)?(\d{9}B\d{2})$/); //** Netherlands
  vatexp.push(/^(NL)?([A-Z0-9\*\+]{10}\d{2})$/); //** Netherlands sole proprietor

  // Load up the string to check
  var VATNumber = toCheck.toUpperCase();

  // Remove spaces etc. from the VAT number to help validation
  VATNumber = VATNumber.replace(/(\s|-|\.)+/g, '');

  // Assume we're not going to find a valid VAT number
  var valid = false;

  // Check the string against the regular expressions for all types of VAT numbers
  for (let i = 0; i < vatexp.length; i++) {
    // Have we recognised the VAT number?
    if (vatexp[i].test(VATNumber)) {
      // Yes - we have
      var cCode = RegExp.$1; // Isolate country code
      var cNumber = RegExp.$2; // Isolate the number
      if (cCode.length == 0) cCode = defCCode; // Set up default country code

      // Call the appropriate country VAT validation routine depending on the country code
      if (NLVATCheckDigit(cNumber)) valid = VATNumber;

      // Having processed the number, we break from the loop
      break;
    }
  }

  // Return with either an error or the reformatted VAT number
  return valid;
}

function NLVATCheckDigit(vatnumber) {
  // Function to turn a Dutch VAT number in its entirety to a numeric equivalent
  function convert_string_tonumeric(vatstring) {
    // Add 'NL' back to front of string
    let vat = 'NL' + vatstring;

    // Each character in the string is looked at one at a time
    let nextchar: any = '';

    // The result of the conversion goes here
    let result = '';

    for (let i = 0; i < vat.length; i++) {
      // Pick up the next character from the vat string as Unicode
      nextchar = vat.charCodeAt(i);

      // If it a '+' or a '*' convert to numeric 36 and 37 respectively
      if (nextchar > 41 && nextchar < 44) nextchar = nextchar - 6;
      // Convert 0 to 9 to 0 to 9 characters
      else if (nextchar > 47 && nextchar < 58) nextchar = nextchar - 48;
      // Convert A-Z to 10 to 35
      else if (nextchar > 64 && nextchar < 91) nextchar = nextchar - 55;

      // Add to convert test string
      result = result + nextchar;
    }
    return result;
  }

  // Function to calculate the modulus of a long number
  function modulo(divident, divisor) {
    var partLength = 7;

    while (divident.length > partLength) {
      var part = divident.substring(0, partLength);
      divident = (part % divisor) + divident.substring(partLength);
    }

    return divident % divisor;
  }

  function standardNo(vatnumber) {
    // Checks the check digits of a Dutch VAT number.

    var total = 0;
    var multipliers = [9, 8, 7, 6, 5, 4, 3, 2];

    // Extract the next digit and multiply by the counter.
    for (var i = 0; i < 8; i++)
      total += Number(vatnumber.charAt(i)) * multipliers[i];

    // Establish check digits by getting modulus 11.
    total = total % 11;
    if (total > 9) total = 0;

    // Compare it with the last character of the VAT number. If it's the same, then it's valid.
    if (total == vatnumber.slice(8, 9)) return true;
    else return false;
  }

  function soleproprietorNo(vatnumber) {
    // Checks the check digit of a sole proprietor Dutch VAT number
    var numericvat = convert_string_tonumeric(vatnumber);
    if (modulo(numericvat, 97) == 1) return true;
    else return false;
  }

  // First check whether the VAT number is a valid standard one
  if (standardNo(vatnumber)) return true;
  // If not, check whether it is a valid sole proprietor vat number
  else if (soleproprietorNo(vatnumber)) return true;
  // Neither - it must be invalid
  else return false;
}
