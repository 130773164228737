import { AfterViewInit, Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import {
  DEFAULT_CHECKBOX_LABEL_POSITION,
  IFormDropdownOption,
} from '../../../form-controls';

@Component({
  selector: 'app-multiple-choice',
  templateUrl: './multiple-choice.component.html',
  styleUrls: ['./multiple-choice.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleChoiceComponent),
      multi: true,
    },
  ],
})
export class MultipleChoiceComponent
  implements AfterViewInit, ControlValueAccessor
{
  @Input() labelPosition = DEFAULT_CHECKBOX_LABEL_POSITION;
  @Input() options: IFormDropdownOption[];
  @Input() hint: string;
  @Input() isClientPortal = false;
  @Input() orderNum: number;
  @Input() required: boolean;
  @Input() placeholder: string;

  private _disabled = false;

  @Input()
  get disable(): boolean {
    return this._disabled;
  }
  set disable(value: boolean) {
    this._disabled = value;
    this._disabled ? this.formArray.disable() : this.formArray.enable();
    this.stateChanges.next();
  }

  formBuilder: FormBuilder = new FormBuilder();
  formArray: FormArray = this.formBuilder.array([]);

  valueControl = new FormControl();
  stateChanges = new Subject<void>();
  subscription = new Subscription();

  checkBoxValues: boolean[] = [];

  @Input()
  get value(): any {
    return JSON.stringify(this.valueControl.value);
  }
  set value(value: any) {
    if (value !== this.value) {
      this.valueControl.setValue(value, { emitEvent: false });
    }
    this.stateChanges.next();
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched() {}
  writeValue(value: any): void {
    if (value) this.checkBoxValues = JSON.parse(value);
    this.valueControl.setValue(value);
  }

  public buildFormGroup(label: string, value: boolean): FormGroup {
    const formGroup = this.buildForm(value,label);
    formGroup.markAsUntouched();
    return formGroup;
  }

  public addFormGroup(label: string, value: boolean) {
    const answerFormGroup = this.buildFormGroup(label, value);
    answerFormGroup.markAsDirty();
    this.formArray.push(answerFormGroup);
  }

  ngAfterViewInit(): void {
    let i = 0;
    for (let option of this.options) {
      this.addFormGroup(option.label, this.checkBoxValues[i]);
      i++;
    }
    if (this.disable) this.formArray.disable();
    else this.formArray.enable();
    this.subscription = this.formArray.valueChanges.subscribe((data) => {
      this.valueControl.setValue(
        JSON.stringify(
          data.map((a) => {
            return a.value;
          }),
        ),
      );
      this.propagateChange(this.valueControl.value);
    });
  }
  buildForm(value: boolean, label: string){
    return this.formBuilder.group({
      value,
      label,
    });
  }
}
