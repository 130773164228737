export class QuestionSetCreateModel {
  title!: string;
  type!: number;
  description: string;
  autoInsert!: boolean; // default should be false
  roles: {
    read: Array<string>;
    readWrite: Array<string>;
  };
  services: string[];
  formatCompleteness: number;
}
