import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DropdownServiceBase } from '../dropdown-service.abstract';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationModel } from '../../models/application/application.model';
import { CareFileModel } from '../../models/carefile/carefile.model';
import { ClientNetworkModel } from '../../models/client-network/client-network.model';
import { ClientModel } from '../../models/client/client.model';
import { PaginationDataModel } from '../../models/pagination/pagination-data.model';
import { PaginationResultModel } from '../../models/pagination/pagination-result.model';
import { HttpUtils } from '../../utilities/http.utils';
import { LanguageService } from '../language/language.service';

export const LOCAL_STORAGE_CLIENT_KEY = 'client';

@Injectable({
  providedIn: 'root',
})
export class ClientInfoService extends DropdownServiceBase<ClientModel> {
  constructor(
    private http: HttpClient,
    @Inject('environment') public environment: any,
    public languageService: LanguageService,
  ) {
    super();

    this.setupDropdown(this.getClients);
  }

  public getClientDetail(clientId: number | string): Observable<ClientModel> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/clients/:client_id',
      entity_type: 'Client',
      entity_id: clientId.toString(),
    });
    return this.http
      .get<ClientModel>(
        `${this.environment.clientManagementApiUrl}/${clientId}`,
        { headers },
      )
      .pipe(map((client) => new ClientModel(client)));
  }

  public getClients(
    paginationData: PaginationDataModel,
    filterData?: any,
  ): Observable<PaginationResultModel<ClientModel>> {
    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/clients',
    });
    return this.http.get<PaginationResultModel<ClientModel>>(
      this.environment.clientManagementApiUrl,
      { params, headers },
    );
  }

  public getCarefileRelatedClients(
    clients: string[],
  ): Observable<ClientModel[]> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/clients',
      clients: clients,
      entity_type: 'Client',
    });
    return this.http.get<ClientModel[]>(
      `${this.environment.clientManagementApiUrl}`,
      { headers },
    );
  }

  public getClientNetworkDetail(
    clientNetworkId: number | string,
  ): Observable<ClientNetworkModel> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/client-networks/:client_network_id',
      portal: 'client_portal',
      entity_type: 'ClientNetwork',
      entity_id: clientNetworkId.toString(),
    });
    return this.http.get<ClientNetworkModel>(
      `${this.environment.clientNetworkManagementUrl}/${clientNetworkId}`,
      {
        headers,
      },
    );
  }

  public getCarefileDetail(
    clientId: number | string,
  ): Observable<CareFileModel> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles',
      client_id: clientId.toString(),
      entity_type: 'Carefile',
      entity_id: clientId.toString(),
    });

    return this.http.get<CareFileModel>(
      `${this.environment.carefileManagementApiUrl}`,
      { headers },
    );
  }

  public getApplicationsDetail(
    clientNetworkId: number | string,
  ): Observable<ApplicationModel[]> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/applications',
      client_network_id: clientNetworkId.toString(),
    });
    return this.http.get<ApplicationModel[]>(
      `${this.environment.applicationManagementApiUrl}`,
      {
        headers,
      },
    );
  }

  public updateClientNetworkLanguage(
    preferredLanguage: string,
    clientNetworkId: string | number,
  ): Observable<ClientNetworkModel> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/client-networks/:client_network_id/update-language',
      entity_type: 'ClientNetwork',
      entity_id: clientNetworkId.toString(),
    });
    return this.http
      .patch<any>(
        `${this.environment.clientNetworkManagementUrl}/${clientNetworkId}/update-language`,
        { preferredLanguage },
        { headers },
      )
      .pipe(map((clientNetwork) => new ClientNetworkModel(clientNetwork)));
  }

  public updateClientLanguage(
    preferredLanguage: string,
    clientId: string | number,
  ): Observable<ClientModel> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/clients/:client_id/update-language',
      entity_type: 'Client',
      entity_id: clientId.toString(),
    });
    return this.http
      .patch<any>(
        `${this.environment.clientManagementApiUrl}/${clientId}/update-language`,
        { preferredLanguage },
        { headers },
      )
      .pipe(map((client) => new ClientModel(client)));
  }

  // SESSION STORAGE MGMT
  public storeClientSession(
    clientId: string | number,
    carefileId: string | number,
  ) {
    const client = {
      client_id: clientId,
      carefile_id: carefileId,
    };
    sessionStorage.setItem(LOCAL_STORAGE_CLIENT_KEY, JSON.stringify(client));
  }

  public getClientSession() {
    return JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_CLIENT_KEY));
  }

  public getClientNetworks(
    paginationData: PaginationDataModel,
    filterData?: any,
  ): Observable<PaginationResultModel<ClientNetworkModel>> {
    let params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/client-networks',
    });
    return this.http.get<PaginationResultModel<ClientNetworkModel>>(
      this.environment.clientNetworkManagementUrl,
      { params, headers },
    );
  }
}
