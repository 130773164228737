import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserInfoService } from '../user-info/user-info.service';

@Injectable({
  providedIn: 'root',
})
export class KeycloakManagementService {
  constructor(
    private http: HttpClient,
    @Inject('environment') public environment: any,
    public userInfoService: UserInfoService,
  ) {}

  public passwordReset(password: string) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/users/password',
      entity_type: 'User',
      entity_id: this.userInfoService.sub,
    });
    const data = {
      password: password,
      keycloakId: this.userInfoService.sub,
    };

    return this.http.post<any>(
      `${this.environment.keycloakManagementApiUrl}/users/password`,
      data,
      { headers },
    );
  }
}
