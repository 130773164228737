import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { ITableOption } from '../../../general-components/table-view/table-view.component';
import {
  QuestionAnswerCompare,
  QuestionSetAnswerCompare,
  QuestionSetCompareModel,
} from '../../../models/question-set-answer/question-set-compare.model';
import { QuestionTypeService } from '../../../services/referent/question-type/question-type.service';
import { QuestionSetRenderService } from '../question-set-render.service';

@Component({
  selector: 'app-question-set-compare',
  templateUrl: './question-set-compare.component.html',
  styleUrls: ['./question-set-compare.component.scss'],
})
export class QuestionSetCompareComponent implements OnInit {
  questionSetCompareData: QuestionSetCompareModel =
    new QuestionSetCompareModel();

  oldQuestionSet: QuestionSetAnswerCompare;
  oldQuestions: QuestionAnswerCompare[];

  newQuestionSet: QuestionSetAnswerCompare;
  newQuestions: QuestionAnswerCompare[];

  public questions$ = new BehaviorSubject<QuestionAnswerCompare[]>(null);
  private questions: any[];

  public viewNew: boolean = false;

  public questionsTable: ITableOption = {
    name: 'questionset.titles.Questions',
    dataObservable: this.questions$,
    columns: [
      {
        id: 'status',
        name: 'general.labels.Status',
        type: 'status',
        width: 120,
      },
      {
        id: 'oldOrder',
        name: 'questionset.labels.Order',
        type: 'string',
        width: 40,
      },
      { id: 'oldTitle', name: 'questionset.labels.Title', type: 'string' },
      {
        id: 'oldType',
        name: 'questionset.labels.Type',
        type: 'lookup',
        lookupValues: this.questionTypeService.lookupValues,
      },
      {
        id: 'newOrder',
        name: 'questionset.labels.Order',
        type: 'string',
        width: 40,
      },
      { id: 'newTitle', name: 'questionset.labels.Title', type: 'string' },
      {
        id: 'newType',
        name: 'questionset.labels.Type',
        type: 'lookup',
        lookupValues: this.questionTypeService.lookupValues,
      },
    ],
    inlineActions: [],
    showAdd: false,
    showHeader: true,
    showPagination: false,
    showSearch: false,
    externPagination: false,
    defaultPageSize: 10,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<QuestionSetCompareComponent>,
    private questionSetRenderService: QuestionSetRenderService,
    public questionTypeService: QuestionTypeService,
  ) {}

  ngOnInit(): void {
    this.getCompareData();
  }

  onConfirm(): void {
    this.dialogRef.close({
      questionSetAnswer:
        this.questionSetCompareData.questionSetAnswerChanges
          .newQuestionSetAnswer,
      questionAnswers:
        this.questionSetCompareData.questionChanges.newQuestionAnswers,
    });
  }

  private getCompareData() {
    this.questionSetRenderService
      .getAvailableUpdateForQuestionSetAnswer(
        this.data.entity,
        this.data.entityId,
        this.data.entityVersion,
        this.data.id,
      )
      .subscribe((data: QuestionSetCompareModel) => {
        this.questionSetCompareData = data;

        this.oldQuestionSet =
          data.questionSetAnswerChanges?.oldQuestionSetAnswer;
        this.oldQuestions = data.questionChanges?.oldQuestionAnswers;

        this.newQuestionSet =
          data.questionSetAnswerChanges?.newQuestionSetAnswer;
        this.newQuestions = [...data.questionChanges?.newQuestionAnswers];

        this.questions = [];
        for (let q of this.oldQuestions) {
          let status = 'deleted';

          let matchingIndex = this.newQuestions.findIndex(
            (x) => x.questionId == q.questionId,
          );
          let matchingQuestion = { ...this.newQuestions[matchingIndex] };

          if (matchingIndex != -1) {
            this.newQuestions.splice(matchingIndex, 1);
            status = 'unchanged';
            if (q.title != matchingQuestion.title) status = 'titlechanged';
            if (q.type != matchingQuestion.type) status = 'typechanged';
          }

          this.questions.push({
            status: status,
            oldOrder: q.order,
            oldTitle: q.title,
            oldType: q.type,
            newOrder: matchingQuestion?.order,
            newTitle: matchingQuestion?.title,
            newType: matchingQuestion?.type,
          });
        }

        for (let q of this.newQuestions) {
          this.questions.push({
            status: 'new',
            newOrder: q.order,
            newTitle: q.title,
            newType: q.type,
          });
        }

        this.questions$.next(this.questions);
      });
  }
}
