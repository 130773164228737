<mat-drawer-container
  class="{{sideSheetConfig.backdropType}}"
  [hasBackdrop]="sideSheetConfig?.hasBackdrop"
  (backdropClick)="onBackdropClicked()"
>
  <mat-drawer
    #sideSheetElement
    mode="over"
    [ngClass]="[
      sideSheetConfig?.size,
      sideSheetConfig?.position === 'start'
        ? 'sidesheet-position-left'
        : 'sidesheet-position-right',
      sideSheetConfig?.fullscreen ? 'sidesheet-fullscreen' : ''
    ]"
    [style]="sideSheetConfig?.style?.drawer"
    class="side-sheet-container"
    [position]="sideSheetConfig?.position"
    autosize
    [disableClose]="sideSheetConfig?.disableCloseOnBackdropClick"
  >
    <div
      class="sidesheet-main-container"
      [ngStyle]="{
        opacity: sideSheetConfig?.opacity
      }"
    >
      <app-sidesheet-loading
        *ngIf="sideSheetConfig?.loadingVisible === true"
        [sideSheetConfig]="sideSheetConfig"
        [sideSheetContent]="sideSheetContent"
      ></app-sidesheet-loading>
      <div class="sidesheet-top-container">
        <app-sidesheet-title-bar
          *ngIf="sideSheetConfig?.titleBarVisible === true"
          [sideSheetConfig]="sideSheetConfig"
          [sideSheetContent]="sideSheetContent"
          [titleBarcrumbActionsTemplate]="activeComponent?.componentRef?.instance?.sidesheetTitleBarActionsTemplate"
          (buttonClicked)="executeButtonClick($event)"
        ></app-sidesheet-title-bar>

        <app-sidesheet-breadcrumb
          *ngIf="sideSheetConfig?.breadcrumbVisible === true"
          [sideSheetConfig]="sideSheetConfig"
          [sideSheetContent]="sideSheetContent"
          [breadcrumbActionsTemplate]="activeComponent?.componentRef?.instance?.sidesheetBreadcrumbActionsTemplate"
          [formerSidesheets]="formerSidesheets"
          (buttonClicked)="executeButtonClick($event)"
        ></app-sidesheet-breadcrumb>

        <ng-container *ngIf="sideSheetConfig?.commandLinePosition === 'top'">
          <app-sidesheet-commandline
            *ngIf="sideSheetConfig?.commandLineVisible === true"
            [sideSheetConfig]="sideSheetConfig"
            [sideSheetContent]="sideSheetContent"
            [commandLineLeftTemplate]="activeComponent?.componentRef?.instance?.sidesheetCommandLineLeftTemplate"
            [commandLineRightTemplate]="activeComponent?.componentRef?.instance?.sidesheetCommandLineRightTemplate"
            (buttonClicked)="executeButtonClick($event)"
            (checkboxSelected)="executeCheckboxSelection($event)"
            (dropdownSelected)="executeDropdownSelection($event)"
          ></app-sidesheet-commandline>

          <app-sidesheet-tooltip
            *ngIf="sideSheetConfig?.tooltipVisible === true"
            [sideSheetConfig]="sideSheetConfig"
            [sideSheetContent]="sideSheetContent"
            (tooltipButtonClicked)="executeTooltipButtonClick($event)"
          ></app-sidesheet-tooltip>
        </ng-container>

        <ng-container
          *ngTemplateOutlet="
            activeComponent?.componentRef?.instance
              ?.sidesheetContentHeaderTemplate
          "
        ></ng-container>
      </div>

      <div
        class="sidesheet-middle-container"
        [ngStyle]="sideSheetConfig?.style?.body"
      >
        <ng-template sideSheetHost></ng-template>
      </div>

      <div class="sidesheet-bottom-container">
        <ng-container
          *ngTemplateOutlet="
            activeComponent?.componentRef?.instance
              ?.sidesheetContentFooterTemplate
          "
        ></ng-container>

        <app-sidesheet-tooltip
          *ngIf="
            sideSheetConfig?.tooltipVisible === true &&
            sideSheetConfig?.commandLinePosition !== 'top'
          "
          [sideSheetConfig]="sideSheetConfig"
          [sideSheetContent]="sideSheetContent"
          (tooltipButtonClicked)="executeTooltipButtonClick($event)"
        ></app-sidesheet-tooltip>

        <app-sidesheet-commandline
          *ngIf="
            sideSheetConfig?.commandLineVisible === true &&
            sideSheetConfig?.commandLinePosition === 'bottom'
          "
          [sideSheetConfig]="sideSheetConfig"
          [sideSheetContent]="sideSheetContent"
          [commandLineLeftTemplate]="activeComponent?.componentRef?.instance?.sidesheetCommandLineLeftTemplate"
          [commandLineRightTemplate]="activeComponent?.componentRef?.instance?.sidesheetCommandLineRightTemplate"
          (buttonClicked)="executeButtonClick($event)"
          (checkboxSelected)="executeCheckboxSelection($event)"
          (dropdownSelected)="executeDropdownSelection($event)"
        ></app-sidesheet-commandline>
      </div>
    </div>
  </mat-drawer>
</mat-drawer-container>
