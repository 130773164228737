import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { IWidget } from '../../../models';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-dashboard-settings',
  templateUrl: './dashboard-settings.component.html',
  styleUrls: ['./dashboard-settings.component.scss'],
})
export class DashboardSettingsComponent implements OnInit, OnDestroy {
  formArray: FormArray = this.formBuilder.array([]);

  constructor(
    public dialogRef: MatDialogRef<DashboardSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public widgets: IWidget[],
    public formBuilder: FormBuilder,
    private dashboardService: DashboardService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {}

  public buildForm(widget: IWidget): FormGroup {
    const formGroup = this.formBuilder.group({
      id: widget.id,
      name: widget.name,
      visible: widget.visible,
    });
    formGroup.markAsUntouched();
    return formGroup;
  }

  public addControl(widget: IWidget) {
    const formGroup = this.buildForm(widget);
    formGroup.markAsDirty();
    formGroup.valueChanges.subscribe((x) => {});
    this.formArray.push(formGroup);
  }

  initForm(): void {
    for (let widget of this.widgets) {
      this.addControl(widget);
    }
  }

  loadDefault() {
    this.dialogRef.close({ mode: 'default', data: null });
  }

  public onConfirm(): void {
    this.dialogRef.close({ mode: 'update', data: this.formArray.value });
  }

  public onDismiss(): void {
    this.dialogRef.close(null);
  }
}
