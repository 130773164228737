import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { Button, CheckBox, Option, SelectBoxes, SideSheetConfig, SideSheetContent } from '../../../models/side-sheet';
import { SideSheetService } from '../../../services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidesheet-commandline',
  templateUrl: './sidesheet-commandline.component.html',
  styleUrls: ['./sidesheet-commandline.component.scss']
})
export class SidesheetCommandlineComponent implements OnInit, OnDestroy {

  @Input() sideSheetConfig: SideSheetConfig;
  @Input() sideSheetContent: SideSheetContent;
  @Input() commandLineLeftTemplate: TemplateRef<any>;
  @Input() commandLineRightTemplate: TemplateRef<any>;

  @Output() buttonClicked = new EventEmitter<Button>();
  @Output() checkboxSelected = new EventEmitter<CheckBox>();
  @Output() dropdownSelected = new EventEmitter<{dropdown: SelectBoxes, option: Option}>();

  selectedItems: string[] = [];

  private subscription: Subscription = new Subscription();


  constructor(private sideSheetService: SideSheetService) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onCommanlineButtonClicked(button: Button) {
    this.onExecuteButtonAction(button);
  }

  onExecuteButtonAction(button: Button) {
    this.sideSheetService.closeTooltip();
    this.buttonClicked.emit(button);
  }

  onExecuteDropdownAction(item: SelectBoxes, option: Option) {
    this.sideSheetService.closeTooltip();
    if (!item.selectedItem) item.selectedItem = [];
    item.selectedItem.push(option);
    item.selectedItem = this.convertUniqueArray(item.selectedItem, 'key');
    item.options.map((data) => (data.selected = false));
    
    this.dropdownSelected.emit({dropdown: item, option});
  }

  onExecuteCheckboxAction(checked: any,item: CheckBox) {
    this.sideSheetService.closeTooltip();
    item.checked = checked;

    this.checkboxSelected.emit(item);    
  }
 
  convertUniqueArray(arr: any[], filter: string) {
    return [...new Map(arr.map((item) => [item[filter], item])).values()];
  }

}
