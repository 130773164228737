import { KeycloakService } from 'keycloak-angular';
import { KeycloakConfig } from 'keycloak-js';
import { TenantConfigurationService } from './tenant-configuration.service';
import { MAIN_MENU_ITEMS } from './values.const';
import { AppInitService } from 'c4p-portal-util';

export function initializeKeycloak(
  keycloak: KeycloakService,
  configService: TenantConfigurationService,
  appInitService: AppInitService,
) {
  return async () => {
    const configuration = await configService.getKeycloakConfiguration();

    let keycloakConfig: KeycloakConfig = {
      url: configuration.keycloakHost,
      realm: configuration.keycloakRealmName,
      clientId: 'c4p-client-portal',
    };

    await keycloak.init({
      config: keycloakConfig,
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
      bearerExcludedUrls: [],
    });

    return appInitService.initializeUser(MAIN_MENU_ITEMS);
  };
}
