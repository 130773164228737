import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SocialAreaHelper } from '../social-area.helper';
import { SocialAreaModel } from '../../../models/social-area/social-area.model';
import { SocialAreaOverviewService } from '../social-area-overview.service';
import { PaginationDataModel } from '../../../models/pagination/pagination-data.model';
import { GoalObjectiveService } from '../../../services/referent/goal-objective/goal-objective.service';

@Component({
  selector: 'app-social-area-config-form',
  templateUrl: './social-area-config-form.component.html',
  styleUrls: ['./social-area-config-form.component.scss'],
})
export class SocialAreaConfigFormComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<boolean>();

  socialAreaHelper: SocialAreaHelper = new SocialAreaHelper(
    this.formBuilder,
    this.destroyed$,
  );

  formArray = this.formBuilder.array([]);

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SocialAreaConfigFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = [],
    public goalObjectiveService: GoalObjectiveService,
    private socialAreaService: SocialAreaOverviewService,
  ) {}

  ngOnInit(): void {
    this.socialAreaService
      .getSocialAreas(new PaginationDataModel('createdAt', 'asc', 100, ''), {
        status: 'active',
      })
      .subscribe((refData) => {
        let existingArea = JSON.parse(JSON.stringify(this.data.socialAreas));
        this.mergeSocialAreas(existingArea, refData.docs);
        this.formArray =
          this.socialAreaHelper.buildSocialAreaFormArray(existingArea);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public onDismiss(): void {
    this.dialogRef.close(null);
  }

  public onConfirm(): void {
    this.dialogRef.close(this.formArray.value);
  }

  private mergeSocialAreas(
    socialAreas: SocialAreaModel[],
    refSocialAreas: SocialAreaModel[],
  ): void {
    for (let refSocialArea of refSocialAreas) {
      let i = socialAreas.findIndex((el) => el.refId == refSocialArea.id);
      if (i >= 0) {
        socialAreas[i].isNew = false;
        socialAreas[i].isRenamed = false;
        if (socialAreas[i].name != refSocialArea.name) {
          socialAreas[i].name = refSocialArea.name;
          socialAreas[i].isRenamed = true;
        }
      } else {
        refSocialArea.refId = refSocialArea.id;
        refSocialArea.entityType = this.data.entity;
        refSocialArea.entityId = this.data.entityId;
        refSocialArea.selected = false;
        refSocialArea.id = null;
        refSocialArea.mainGoals = [];
        refSocialArea.isNew = true;
        refSocialArea.isRenamed = false;
        socialAreas.push(refSocialArea);
      }
    }
  }
}
