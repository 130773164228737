import { Directive, ElementRef, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appMaskDate]',
})
export class DateMaskDirective implements OnDestroy {
  iMask: any;

  constructor(private element: ElementRef) {}

  ngOnDestroy() {}
}
