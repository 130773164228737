import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-panel-form-row',
  templateUrl: './panel-form-row.component.html',
  styleUrls: ['./panel-form-row.component.scss'],
})
export class PanelFormRowComponent implements OnInit {
  @Input() mode: string = '';
  @Input() title: string = '';

  @Input() rowClass: string;

  constructor() {}

  ngOnInit(): void {}
}
