import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CustomDateTimePipe,
  CustomTimePipe,
  CustomDatePipe,
  DurationPipe,
  DurationMinutePipe,
  TenantDatePipe,
  LocalizedDatePipe,
  NotificationDatePipe,
  DayDivisionPipe,
} from './datetime/';
import { CustomRichTextPipe } from './rich-text';
import { FilterPipe } from './utility/filter.pipe';
import { CustomJoinPipe } from './join';
import { HtmlSanitizerPipe } from './html-sanitizer';
import {
  InvoicePeriodPipe,
  MoneyPipe,
  RoundPipe,
  ProductCodePipe,
  BillingPeriodicityPipe,
  BillingAndInvoiceMethodPipe,
  HourSourcePipe,
  HourStatusPipe,
  StatusTransformationPipe,
  InvoiceStatusTransformationPipe,
} from './billing';
import { HourPipe } from './utility/hour.pipe';
import { CarefileDisplayPipe } from './carefile';

const moduleComponents = [
  CustomDatePipe,
  TenantDatePipe,
  CustomDateTimePipe,
  CustomTimePipe,
  CustomRichTextPipe,
  FilterPipe,
  CustomJoinPipe,
  DurationPipe,
  HtmlSanitizerPipe,
  MoneyPipe,
  RoundPipe,
  InvoicePeriodPipe,
  ProductCodePipe,
  BillingPeriodicityPipe,
  BillingAndInvoiceMethodPipe,
  HourPipe,
  DurationMinutePipe,
  HourSourcePipe,
  HourStatusPipe,
  CarefileDisplayPipe,
  StatusTransformationPipe,
  InvoiceStatusTransformationPipe,
  LocalizedDatePipe,
  NotificationDatePipe,
  DayDivisionPipe
];

@NgModule({
  imports: [CommonModule],
  declarations: [...moduleComponents],
  exports: [...moduleComponents],
  providers: [],
})
export class PipesModule {}
