import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { UserInfoService } from '..';
import { FcmMessageService } from '../fcm-message/fcm-message.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ExternalNotificationDto, NotificationDTO, PaginationDataModel, PaginationResultModel } from '../../models';
import { HttpUtils } from '../../utilities';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public count$ = new BehaviorSubject<number>(0);
  public resetListbox: boolean = true;
  private readonly destroyed$ = new Subject<boolean>();


  constructor(
    private fcmMessageService: FcmMessageService,
    private userInfoService: UserInfoService,
    public http: HttpClient,
    @Inject('environment') private environment,
  ) {
  }

  updateUnreadMessageCount(userId: string) {
    this.getUnreadMessageCount(userId)
      .pipe(take(1))
      .subscribe((count) => {
        this.count$.next(count);
      });
  }

  receiveMessage() {
    this.fcmMessageService.newMessageEvent.pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
      this.updateUnreadMessageCount(this.userInfoService?.userId);
    })
  }

  public sendEmail(notification: ExternalNotificationDto) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: `/notifications`,
    });
    return this.http.post<void>(
      `${this.environment.notificationManagementApiUrl}/notifications`,
      notification,
      { headers },
    );
  }

  getNotifications(
    paginationData: PaginationDataModel,
    filterData?: any,
  ): Observable<PaginationResultModel<NotificationDTO>> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: "/notifications",
    });

    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );
    return this.http.get<PaginationResultModel<NotificationDTO>>(
      `${this.environment.notificationManagementApiUrl}/notifications`,
      { params, headers },
    ).pipe(
      map(response => {
        response.docs.map(doc => {
          const isUser = doc?.users.find(n=>this.userInfoService.userId === n.userId );
          doc.currentUserRead = isUser?.userRead ?? false;
          doc.currentUserStarred = isUser?.isStarred ?? false;
        } )
        return response;
      } )
    );
  }

  readNotifications(notificationIds: string[], readUnreadFlag: boolean, readAll?: boolean) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/notifications/read',
      entity_type: 'Notification',
    });

    return this.http
      .patch<any>(
        `${this.environment.notificationManagementApiUrl}/notifications/read`,
        {
          read: readUnreadFlag,
          notificationIds: notificationIds,
          readAll
        },
        {
          headers,
        },
      )
      .pipe(map((res: any) => res || {}));
  }

  starNotification(notificationId: string, star: boolean) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/notifications/:notification_id/star',
      entity_type: 'Notification',
      entity_id: notificationId,
    });

    return this.http
      .patch<any>(
        `${this.environment.notificationManagementApiUrl}/notifications/${notificationId}/star`,
        {star},
        {
          headers,
        },
      ).pipe(
        map(response => {
            const isUser = response?.users.find(n=>this.userInfoService.userId === n.userId );
            response.currentUserRead = isUser?.userRead ?? false;
            response.currentUserStarred = isUser?.isStarred ?? false;
          return response;
        })
      )
  }

  getUnreadMessageCount(
    userId: string,
  ): Observable<number> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: "/notifications/:userId/unreadNotificationsCount",
    });

    return this.http.get<number>(
      `${this.environment.notificationManagementApiUrl}/notifications/${userId}/unreadNotificationsCount`,
      { headers },
    );
  }

}
