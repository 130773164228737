<app-page>
  <ng-container *appTopBar>
    <app-page-tab>
      <mat-icon (click)="back()" *ngIf="carefileView" class="c4p-back-icons"
        >chevron_left</mat-icon
      >
      <div class="c4p-tab" translate>carefile.titles.Overview</div>
    </app-page-tab>

    <app-page-menu></app-page-menu>
  </ng-container>

  <app-page-content>
    <app-panel [@slideFromTop] *ngIf="toggleFilters">
      <app-panel-title title="application.overview.Filters" titleIcon="search">
      </app-panel-title>
      <app-care-program-overview-filter-form
        [carefileView]="carefileView"
        [careprogramsLength]="careprogramsLength"
        (submit)="filterParams = $event; refresh()"
      ></app-care-program-overview-filter-form>
    </app-panel>

    <app-panel>
      <app-table-view
        [showSearch]="!toggleFilters"
        [options]="tableOptions"
        (refreshRequest)="tableView = $event; refresh()"
        (addRequest)="create()"
      >
      </app-table-view>
    </app-panel>
  </app-page-content>
</app-page>
