<div class="calculateWrapper">
  <div>
    <span translate class="title">billing.labels.Total</span>
    <span>{{ calculation.total | currency : 'EUR' : 'symbol' : '1.2-2' : 'nl' }}</span>
  </div>
  <hr />
  <div>
    <span translate class="title">billing.labels.VatAmount</span>
    <span>{{ calculation.vatAmount | currency : 'EUR' : 'symbol' : '1.2-2' : 'nl' }}</span>
  </div>
  <hr />
  <div class="total">
    <span translate class="title">billing.labels.IncludedVat</span>
    <span>{{ calculation.totalIncludedVat | currency : 'EUR' : 'symbol' : '1.2-2' : 'nl' }}</span>
  </div>
</div>
