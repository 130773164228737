import { TypeConfigurationModel } from './type-configuration.model';

export class QuestionModel {
  id!: number | string;
  title!: string;
  tenantId!: number | string;
  // If type is SingleLine or MultiLine, typeConfiguration should be null.
  type!: number | string;
  typeConfiguration: TypeConfigurationModel[] | null | undefined;
  hint: string;
  docGenCode: string;
  isDiaryQuestion: boolean;
  commentRequired: boolean;
  createdAt!: Date;
  createdBy!: number | string; // user id
  updatedAt: Date | null | undefined;
  updatedBy: number | string | null | undefined;
  isAssigned = false;
}
