import {
  OnDestroy,
  HostBinding,
  Input,
  Optional,
  Self,
  Component,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { takeUntil, debounceTime, map, filter } from 'rxjs/operators';
import {
  DEFAULT_PLACEHOLDER,
  DEFAULT_CONTROL_DEBOUNCE_TIME_AMOUNT,
  DEFAULT_ERRORS_MAP,
} from './form-controls.const';
import { FormErrorStateMatcher } from './form-control.error-matcher';

@Component({
  template: '',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: FormControlBase,
    },
  ],
})
export abstract class FormControlBase<T = any>
  implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<T>
{
  static DEFAULT_ERRORS_MAP = DEFAULT_ERRORS_MAP;
  get DEFAULT_ERRORS() {
    return FormControlBase.DEFAULT_ERRORS_MAP;
  }
  static nextId = 0;
  @HostBinding()
  id = `c4p-material-input-${FormControlBase.nextId++}`;

  private _disabled: boolean = false;
  private _focused: boolean = false;
  private _placeholder: string = DEFAULT_PLACEHOLDER;
  private _required: boolean = false;
  protected destroy: Subject<void> = new Subject();
  stateChanges = new Subject<void>();
  empty: boolean;
  shouldLabelFloat: boolean;
  valueControl = new FormControl();

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  get required(): boolean {
    if (this._required) {
      return this._required;
    }
    if (
      this.ngControl &&
      this.ngControl.control &&
      this.ngControl.control.validator
    ) {
      const emptyValueControl = Object.assign({}, this.ngControl.control);
      (emptyValueControl as any).value = null;
      return (
        'required' in
        (this.ngControl.control.validator(emptyValueControl) || {})
      );
    }
    return false;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get value(): any {
    return this.valueControl.value;
  }
  set value(value: any) {
    if (value !== this.value) {
      this.valueControl.setValue(value, { emitEvent: false });
    }
    this.stateChanges.next();
  }

  get errorState() {
    return !!this.ngControl.errors;
  }

  get focused(): boolean {
    return this._focused;
  }
  set focused(value: boolean) {
    this._focused = value;
    this.stateChanges.next();
  }

  // C4P-PROPS
  @Input() errorLabels = {};
  @Input() suffix: string;
  @Input() prefix: string;
  @Input() uppercase = false;
  @Input() addresscase = false;
  @Input() readonly = false;
  public errorStateMatcher: FormErrorStateMatcher;
  // C4P-PROPS

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
      this.errorStateMatcher = new FormErrorStateMatcher(this.ngControl);
    }
  }

  ngOnInit(): void {
    if (this.ngControl) {
      this.valueControl.valueChanges
        .pipe(
          debounceTime(DEFAULT_CONTROL_DEBOUNCE_TIME_AMOUNT),
          map((value) => this.upperCaseMap(value)),
          takeUntil(this.destroy),
        )
        .subscribe((value) => this.onChangeCallback(value));
    }
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    this.stateChanges.complete();
  }

  // MatFormFieldControl implementation
  setDescribedByIds(ids: string[]): void {}
  onContainerClick(event: MouseEvent): void {}

  // ControlValueAccessor implementation

  // reference to changeCallback, initialized in registerOnChange
  public onChangeCallback: (_: any) => void;
  // reference to touchedCallback, initialized in registerOnTouched
  public onTouchedCallback: () => void;

  // save the onChange callback from ControlValueAccessor
  registerOnChange(onChange: any) {
    this.onChangeCallback = onChange;
  }
  // save the onTouched callback from ControlValueAccessor
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  // called when using setValue or patchValue
  writeValue(value: any): void {
    this.value = value;
  }
  // called when using disable
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.valueControl.disable({ emitEvent: false });
    } else {
      this.valueControl.enable({ emitEvent: false });
    }
    this.disabled = isDisabled;
  }

  // called when using markAsTouched
  // only used for controls that cannot use errorStateMatcher
  protected adjustTouched() {
    if (this.ngControl.touched !== this.valueControl.touched) {
      if (this.ngControl.touched) {
        this.valueControl.markAsTouched();
      } else {
        this.valueControl.markAsUntouched();
      }
    }
  }

  private upperCaseMap = (value: string) => {
    if (this.uppercase && value) {
      this.valueControl.setValue(value.toUpperCase(), { emitEvent: false });
      return value.toUpperCase();
    } else if (this.addresscase && value) {
      const newValue = value.toUpperCase().replace(/\s/g, '');
      this.valueControl.setValue(newValue, { emitEvent: false });
      return newValue;
    }
    return value;
  };

  // propagate validators set in the outer FormControl
  // protected inheritValidators(): void {
  //   const validators = this.ngControl.control.validator;
  //   const asyncValidators = this.ngControl.control.asyncValidator;
  //   this.valueControl.setValidators(validators ? validators : null);
  //   this.valueControl.setAsyncValidators(
  //     asyncValidators ? asyncValidators : null
  //   );
  //   this.valueControl.updateValueAndValidity();
  // }
}
