import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DEFAULT_INPUT_TYPE, FormControlBase } from '../../../form-controls';

@Component({
  selector: 'app-additional-comment',
  templateUrl: './additional-comment.component.html',
  styleUrls: ['./additional-comment.component.css'],
})
export class AdditionalCommentComponent extends FormControlBase<AdditionalCommentComponent> {
  @Input() type = DEFAULT_INPUT_TYPE;
  @Input() pattern: string;
  @Output() tabEvent = new EventEmitter();

  ngDoCheck(): void {
    this.adjustTouched();
  }

  onTab(el: HTMLElement) {
    this.tabEvent.emit(el.offsetHeight);
  }
}
