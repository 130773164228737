import { Component, Input, AfterViewInit, Injectable } from '@angular/core';
import {
  DEFAULT_MIN_DATE,
  DEFAULT_MAX_DATE,
  DATE_REGEX,
  DEFAULT_START_DATE_PLACEHOLDER,
  DEFAULT_END_DATE_PLACEHOLDER,
  DEFAULT_MOMENT_DATE_FORMAT,
  UTC_TIMEZONE,
} from '../form-controls.const';
import * as moment_ from 'moment';
import { ControlContainer } from '@angular/forms';
import { CustomDateTimePipe } from '../../pipes';
import {
  DateRange,
  MatDateRangeSelectionStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
} from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';
const moment = moment_;

@Injectable()
export class SevenDayRangeSelectionStrategy<D>
  implements MatDateRangeSelectionStrategy<D>
{
  constructor(private _dateAdapter: DateAdapter<D>) {}

  selectionFinished(date: D | null): DateRange<D> {
    return this._createSevenDayRange(date);
  }

  createPreview(activeDate: D | null): DateRange<D> {
    return this._createSevenDayRange(activeDate);
  }

  private _createSevenDayRange(date: D | null): DateRange<D> {
    if (date) {
      const start = this._dateAdapter.addCalendarDays(date, 0);
      const end = this._dateAdapter.addCalendarDays(date, 6);
      return new DateRange<D>(start, end);
    }

    return new DateRange<D>(null, null);
  }
}

@Component({
  selector: 'app-form-input-week-range',
  templateUrl: './form-input-week-range.component.html',
  styleUrls: ['./form-input-week-range.component.css'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: SevenDayRangeSelectionStrategy,
    },
  ],
})
export class FormInputWeekRangeComponent implements AfterViewInit {
  @Input() placeholder;
  @Input() required;
  @Input() startDatePlaceholder = DEFAULT_START_DATE_PLACEHOLDER;
  @Input() endDatePlaceholder = DEFAULT_END_DATE_PLACEHOLDER;
  @Input() minDate = DEFAULT_MIN_DATE;
  @Input() maxDate = DEFAULT_MAX_DATE;
  onKeydown = DATE_REGEX;

  constructor(
    public controlContainer: ControlContainer,
    public dateTimePipe: CustomDateTimePipe,
  ) {}
  public setNow(controlName: string) {
    const date = this.dateTimePipe
      .transform(moment(), 'noFormat')
      .format(DEFAULT_MOMENT_DATE_FORMAT);
    this.controlContainer.control
      .get(controlName)
      .setValue(moment.tz(date, UTC_TIMEZONE));
  }

  ngAfterViewInit(): void {}
}
