import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PaginationDataModel } from '../../models/pagination/pagination-data.model';
import { QuestionSetCreateModel } from '../../models/question-set/question-set-create.model';
import { QuestionSetUpdateModel } from '../../models/question-set/question-set-update.model';
import { QuestionSetModel } from '../../models/question-set/question-set.model';
import { HttpUtils } from '../../utilities';
import { DropdownServiceBase } from '../dropdown-service.abstract';

@Injectable({
  providedIn: 'root',
})
export class QuestionSetBaseService extends DropdownServiceBase<QuestionSetModel> {
  public questionSet: QuestionSetModel = new QuestionSetModel();
  public questionSets: QuestionSetModel[] = [];
  public assignedQuestionSets: { subjectOrder: number; subject: string }[] = [];
  public questionSets$ = new BehaviorSubject<{
    data: QuestionSetModel[];
    count: number;
  }>(null);

  public selectedQuestionSet$ = new BehaviorSubject<QuestionSetModel>(null);

  private originPath = '';
  private originPathId = '';
  private readonly QUESTION_SETS_ORIGIN_PATH = '/questionsets';
  private readonly DIARY_SUBJECTS_ORIGIN_PATH = '/diary-subjects';
  private readonly QUESTION_SET_ORIGIN_PATH = '/questionsets/:question_set_id';
  private readonly DIARY_SUBJECT_ORIGIN_PATH =
    '/diary-subjects/:diary_subject_id';

  constructor(
    public http: HttpClient,
    @Inject('environment') public environment: any,
  ) {
    super();
    this.setupOrigin();
    this.setupDropdown(this.getQuestionSets);
  }

  isDiarySubject(value: boolean) {
    if (value) {
      this.originPath = this.DIARY_SUBJECTS_ORIGIN_PATH;
      this.originPathId = this.DIARY_SUBJECT_ORIGIN_PATH;
    } else {
      this.originPath = this.QUESTION_SETS_ORIGIN_PATH;
      this.originPathId = this.QUESTION_SET_ORIGIN_PATH;
    }
  }

  setupOrigin() {
    this.originPath = this.DIARY_SUBJECTS_ORIGIN_PATH;
    this.originPathId = this.DIARY_SUBJECT_ORIGIN_PATH;
  }

  public getQuestionSets(
    paginationData: PaginationDataModel,
    filterData?: any,
  ) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: this.originPath,
    });

    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );
    return this.http.get<any>(
      `${this.environment.questionsetManagementApiUrl}${this.originPath}`,
      { params, headers },
    );
  }

  public getUnAssignedDiaryQuestionSets(
    paginationData: PaginationDataModel,
    filterData?: any,
  ) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: this.originPath,
    });
    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );
    return this.http
      .get<any>(
        `${this.environment.questionsetManagementApiUrl}${this.originPath}`,
        { params, headers },
      )
      .pipe(
        tap((data) => {
          this.questionSets = data.docs;
          for (const questionSet of this.questionSets) {
            const aqIndex = this.assignedQuestionSets.findIndex(
              (assignedQuestionSet) =>
                assignedQuestionSet.subject === questionSet.id.toString(),
            );
            if (aqIndex > -1) {
              questionSet.isAssigned = true;
            } else {
              questionSet.isAssigned = false;
            }
          }
          this.questionSets$.next({
            data: this.questionSets,
            count: data.totalDocs,
          });
        }),
      );
  }

  public getQuestionSet(id: number | string) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: this.originPathId,
      entity_type: 'QuestionSet',
      entity_id: id.toString(),
    });

    return this.http
      .get<QuestionSetModel>(
        `${this.environment.questionsetManagementApiUrl}${this.originPath}/${id}`,
        { headers },
      )
      .pipe(
        tap((data) => {
          this.questionSet = data;
        }),
      );
  }

  public createQuestionSet(questionSet: QuestionSetCreateModel) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: this.originPath,
      entity_type: 'QuestionSet',
    });

    return this.http
      .post<any>(
        `${this.environment.questionsetManagementApiUrl}${this.originPath}`,
        questionSet,
        { headers },
      )
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  public deleteQuestionSet(questionSetId: number | string) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: this.originPathId,
      entity_type: 'QuestionSet',
      entity_id: questionSetId.toString(),
    });

    return this.http.delete<any>(
      `${this.environment.questionsetManagementApiUrl}${this.originPath}/${questionSetId}`,
      { headers },
    );
  }

  public updateQuestionSet(questionSetUpdate: QuestionSetUpdateModel) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: this.originPathId,
      entity_type: 'QuestionSet',
      entity_id: this.questionSet.id.toString(),
    });

    return this.http.put<QuestionSetUpdateModel>(
      `${this.environment.questionsetManagementApiUrl}${this.originPath}/${this.questionSet.id}`,
      questionSetUpdate,
      {
        headers,
      },
    );
  }
}
