import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-long-label',
  templateUrl: './long-label.component.html',
  styleUrls: ['./long-label.component.scss'],
})
export class LongLabelComponent implements OnInit {
  @Input() label: string = '';

  constructor() {}

  ngOnInit(): void {}
}
