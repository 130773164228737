import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';
import { QuestionSetType } from '../../../models/question-set/question-set-type.enum';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class QuestionsetTypeService {
  public values: IFormDropdownOption[] = [];
  public filteredValues: IFormDropdownOption[];
  public lookupValues: string[] = [];

  constructor(private masterDataService: MasterDataService) {
    this.getQuestionSetTypesValues();
  }

  public filterTypes() {
    this.filteredValues = this.values.filter(
      (values) => values.value != QuestionSetType.Diary,
    );
  }
  public getQuestionSetTypesValues() {
    this.masterDataService
      .getMasterData('questionset-types')
      .subscribe((data) => {
        this.values = data;
        this.filteredValues = data.filter(
          (values) => values.value != QuestionSetType.Diary,
        );
        for (let item of this.values) {
          this.lookupValues[item.value] = item.label;
        }
      });
  }
}
