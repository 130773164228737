import { AnswerModel } from '../question-set-answer/answer.model';

export class MismatchQuestion {
  entityQuestionAnswer: AnswerModel;
  inheritingQuestionAnswer: AnswerModel;
  inheritAnswer?: boolean;
}

export class MismatchQuestionSetAnswer {
  questionSetAnswerId: string;
  questionSetAnswerTitle: string;
  mismatchedQuestions: MismatchQuestion[];
}

export class MismatchAnswer {
  id: string;
  entityId: string;
  entityVersion: number;
  entityType: number;
  inheritingEntityId: string;
  inheritingEntityVersion: number;
  inheritingEntityType: number;
  tenantId: string;
  mismatchedQuestionSetAnswers: MismatchQuestionSetAnswer[];
}
