import { FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';
import { ModelUtils } from '../utilities/model.utils';

const { range } = extendMoment(moment);

export function hourOverlapValidator({
  idProperty = 'id',
  displayProperty,
  startDate = 'startDate',
  endDate = 'endDate',
}): ValidatorFn {
  return (formArray: FormArray): ValidationErrors | null => {
    const docs: any[] = [...formArray.value];

    if (docs.length === 1 || docs.length === 0) return null;

    const validRanges = docs
      .filter((s) => s.from !== null)
      .sort((a, b) => {
        if (a < b) return -1;
        return 1;
      });

    let validatonError: ValidationErrors = null;

    for (let i = 0; i < validRanges.length - 1; i += 1) {
      const entity1 = validRanges[i];

      for (let j = i + 1; j < validRanges.length; j += 1) {
        const entity2 = validRanges[j];
        const entity1Id = ModelUtils.getObjectPropertyWithString(
          idProperty,
          entity1,
        );
        const entity2Id = ModelUtils.getObjectPropertyWithString(
          idProperty,
          entity2,
        );
        const entityOverlaps = entity1Id === entity2Id;

        if (
          entityOverlaps &&
          calculateHourOverlap(
            entity1.from,
            entity1.to,
            entity2.from,
            entity2.to,
          )
        ) {
          validatonError = {
            hourOverlap: displayProperty
              ? ModelUtils.getObjectPropertyWithString(displayProperty, entity1)
              : true,
          };
        }
      }
    }

    return validatonError;
  };
}

export const calculateHourOverlap = (
  stringExistingFrom: string,
  stringExistingTo: string,
  stringFrom: string,
  stringTo: string,
) => {
  const from = convertTimeToNumber(stringFrom);
  const to = convertTimeToNumber(stringTo);
  const existingFrom = convertTimeToNumber(stringExistingFrom);
  const existingTo = convertTimeToNumber(stringExistingTo);
  if (existingFrom <= from && existingTo > from) return true;
  if (existingFrom < to && existingTo > to) return true;
  if (existingFrom >= from && existingFrom < to) return true;
  if (existingTo > from && existingTo <= to) return true;
  return false;
};

export const convertTimeToNumber = (time: string) => {
  return parseInt(time.replace(':', ''));
};
