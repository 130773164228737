<div class="c4p-form-row">
  <h1>Side Sheet Component</h1>
  <app-panel-form-row [formGroup]="form">
    <app-form-input-text formControlName="name" placeholder="tttt"></app-form-input-text>
    <app-form-input-text formControlName="name" placeholder="tttt"></app-form-input-text>
    <app-form-input-text formControlName="testName" placeholder="Change Input data"></app-form-input-text>

  </app-panel-form-row>
  <button class="c4p-button" (click)="loadExample2Component()">
    Load Example Component 2
  </button>
</div>

<div>
  {{sideSheetContent.data ? (sideSheetContent.data | json) : "No data"}}
</div>

<div style="margin-top: 40px;">
  <h1>{{eventText}}</h1>
</div>

<div style="margin-top: 40px;">
  <h1>Heading Division1</h1>
  <h2>I'm content component1</h2>
</div>


<div style="margin-top: 40px;">
  <h1>Heading Division2</h1>
  <h2>I'm content component2</h2>
</div>


<div style="margin-top: 40px;">
  <h1>Heading Division3</h1>
  <h2>I'm content component3</h2>
</div>

<div style="margin-top: 40px;">
  <h1>Heading Division4</h1>
  <h2>I'm content component4</h2>
</div>

<div style="margin-top: 40px;">
  <h1>Heading Division5</h1>
  <h2>I'm content component5</h2>
</div>

<div style="margin-top: 40px;">
  <h1>Heading Division6</h1>
  <h2>I'm content component6</h2>
</div>

<div style="margin-top: 40px;">
  <h1>Heading Division7</h1>
  <h2>I'm content component7</h2>
</div>

<ng-template #sidesheetContentFooterTemplate>
  <mat-paginator [length]="10" [pageSize]="5"
    [pageIndex]="0" [pageSizeOptions]="[1,2,10]">
  </mat-paginator>
</ng-template>