export interface IWidget {
  id?: string;
  name?: string;
  component: string;
  implementation?: any;
  visible?: boolean;
  size: string;
  position?: number;
  permissions?: string[];
  data?: any;
}
