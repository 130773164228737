import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round',
})
export class RoundPipe implements PipeTransform {
  transform(value: number): string {
    return value ? this.c4pRounding(value) : '0.00';
  }

  private c4pRounding(value: number): string {
    const ret = value.toFixed(2);
    return ret;
  }
}
