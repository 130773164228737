import { DiaryService } from './diary.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DialogComponent } from '../../general-components/dialog/dialog.component';
import { AnswerTypeConfigurationModel } from '../../models/question-set-answer/answer-type-configuration.model';
import { AnswerModel } from '../../models/question-set-answer/answer.model';
import { FormatCleanerService } from '../../services/format-cleaner/format-cleaner.service';
import {
  DiaryAnswerModel,
  DiarySubjectModel,
} from '../../models/diary/diary.model';
import { UserInfoService } from '../../services/user-info/user-info.service';
import { DateUtils } from '../../utilities';

export const CHART_SERIES_COLOR = [
  '#F70000',
  '#1DB0E8',
  '#F1CA3A',
  '#6F9654',
  '#4B0082',
  '#f5f5dc',
  '#F5F5DC',
  '#FFA500',
];

export class AnswerCategory {
  categoryName: string;
  numOfQuestions: number;
  icon: string;
  answers: AnswerModel[];
}

export enum CompletenessStatusEnum {
  New = 1,
  InProgress,
  Completed,
  Closed,
}

@Component({
  selector: 'app-question-set-stepper',
  templateUrl: './question-set-stepper.component.html',
  styleUrls: ['./question-set-stepper.component.scss'],
})
export class QuestionSetStepperComponent implements OnInit {
  @Input('carefileId') carefileId: string;
  @Input('employeeComponent') employeeComponent: boolean; // This input indicates from which portal is component called.

  // QuestionSet component stuff
  isLinear = false;
  isLeftVisible = true;

  diaryFormGroup: FormGroup;
  diarySubjects: FormArray = this.formBuilder.array([]);
  optionsForChoice: [options: AnswerTypeConfigurationModel[]];
  completenessStatus: number;
  // Calendar stuff
  selectedDate = DateUtils.getCurrentTenantDayDate();
  maxDate = DateUtils.getCurrentTenantDayDate();

  // Chart stuff
  public chartOptions = {
    legend: { position: 'top' },
    colors: CHART_SERIES_COLOR,
    fontSize: 11,
    fontName: 'Ubuntu',
    height: 310,
    width: 550,
    vAxis: {
      minValue: 1,
      maxValue: 5,
    },
    focusTarget: 'category',
  };
  public columns = [''];
  public chartData = {
    title: '',
    weekData: null,
  };
  public previewDate = DateUtils.getCurrentTenantDayDate();

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    public formatCleanerService: FormatCleanerService,
    public dialog: MatDialog,
    public diaryService: DiaryService,
    public userInfoService: UserInfoService,
  ) {}

  ngOnInit() {
    this.initWeeklyPreview();
    this.diaryFormGroup = this.formBuilder.group({
      id: [null],
      title: [null],
      carefileId: [null],
      createdAt: [null],
      updatedAt: [null],
      tenantId: [null],
      diaryPreviewDate: [null],
      completenessStatus: [null],
      diaryQuestionSetId: [null],
      diarySubjects: this.diarySubjects,
    });
    this.initDiaryQuestionSet();
    this.optionsForChoice = [[]];
  }

  submitQuestionSet() {
    if (this.diarySubjects.valid) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'general.actions.Submit',
          message: 'clientPortal.messages.AreYouSureSubmit',
          confirmText: 'general.labels.Yes',
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.diaryService
            .updateDiaryQuestionSetAnswer(this.diaryFormGroup.getRawValue())
            .subscribe((diary) => {
              this.updateDiaryForm(diary);
              this.initWeeklyPreview();
              this.toastr.success(
                this.translate.instant('clientPortal.messages.FormSubmitted'),
              );
            });
        }
      });
    } else {
      this.diarySubjects.controls.forEach((control) => {
        control.markAllAsTouched();
      });
    }
  }

  buildAnswersFormGroups(answers: DiaryAnswerModel[]): FormGroup[] {
    let answerFormGroupArray: FormGroup[] = [];
    answers.forEach((obj) => {
      let answer = obj.answer;

      const formGroup = this.buildFormGroup(answer, obj);

      if (this.completenessStatus === CompletenessStatusEnum.Completed) {
        formGroup.disable();
      }

      let options = [];
      for (let option of answer.typeConfiguration) {
        options.push({ label: option.displayText, value: option._id });
      }
      this.optionsForChoice[answer.id] = options;

      formGroup.markAsUntouched();
      answerFormGroupArray.push(formGroup);
    });

    return answerFormGroupArray;
  }
  buildFormGroup(answer: AnswerModel, obj: DiaryAnswerModel){
    return this.formBuilder.group({
      id: [answer.id],
      answer: [answer.answer, obj.isMandatory ? Validators.required : null],
      title: [answer.title],
      type: [answer.type],
      typeConfiguration: [answer.typeConfiguration],
      hint: [answer.hint],
      isMandatory: [obj.isMandatory],
      order: [obj.order],
      isAnswered: [answer.isAnswered],
      entityId: [answer.entityId],
      entityType: [answer.entityType],
      questionSetAnswerId: [answer.questionSetAnswerId],
      commentRequired: [answer.commentRequired],
      comment: [answer.comment],
      commentLabel: [answer.commentLabel],
      focusRichText: [false],
    });
  }
  initDiaryQuestionSet() {
    this.diaryService
      .getDiaryByPreviewDate(this.carefileId, this.selectedDate)
      .subscribe(
        (diary) => {
          this.updateDiaryForm(diary);
        },
        (error) => {},
      );
  }

  updateDiaryForm(diary) {
    this.diarySubjects.clear();
    if (this.employeeComponent) {
      this.completenessStatus = 3;
    } else {
      this.completenessStatus = diary.completenessStatus;
    }

    if (diary.diarySubjects && diary.diarySubjects.length > 0) {
      diary.diarySubjects.forEach((diarySubject) => {
        this.addCategory(diarySubject);
      });
    }

    this.diaryFormGroup = this.formBuilder.group({
      id: diary.id,
      title: diary.title,
      carefileId: this.userInfoService.currentCareFile.id,
      createdAt: diary.createdAt,
      updatedAt: diary.updatedAt,
      tenantId: diary.tenantId,
      diaryPreviewDate: diary.diaryPreviewDate,
      completenessStatus: diary.completenessStatus,
      diaryQuestionSetId: diary.diaryQuestionSetId,
      diarySubjects: this.diarySubjects,
    });
  }

  buildCategoryFormGroup(subject: DiarySubjectModel): FormGroup {
    const formGroup = this.buildForm(subject)
    formGroup.markAsUntouched();
    return formGroup;
  }

  addCategory(subject?: DiarySubjectModel) {
    const categoryFormGroup = this.buildCategoryFormGroup(subject);
    this.diarySubjects.push(categoryFormGroup);
  }

  onEditorCreated(event) {
    event.root.focus();
  }

  onRichContent(answerGroup: FormGroup, index: number) {
    answerGroup
      .get('answer')
      .patchValue(
        this.formatCleanerService.cleanUpHtml(answerGroup.value.answer),
      );
    if (this.diarySubjects.at(index).get('answers').disabled)
      answerGroup.controls.focusRichText.patchValue(false);
    else
      answerGroup.controls.focusRichText.patchValue(
        !answerGroup.value.focusRichText,
      );
  }

  validateStep(control: FormGroup) {
    if (!control.valid) control.markAllAsTouched();
  }

  onDateSelect(event: Moment) {
    this.diarySubjects.clear();
    this.selectedDate = event.toDate();
    this.initDiaryQuestionSet();
    this.isLeftVisible = !this.isLeftVisible;
  }

  public initWeeklyPreview() {
    this.diaryService
      .getDiaryWeeklyPreview(this.carefileId, this.previewDate)
      .subscribe((res) => {
        this.chartData.weekData = res.weekData;
        this.chartData.title = res.title;
        this.columns = [''];
        this.columns =
          res.columns.length === 0
            ? this.columns.concat([''])
            : this.columns.concat(res.columns);
        this.chartOptions['series'] = this.getSeriesColors(
          this.columns.length - 1,
        );
      });
  }

  getPreviousWeek() {
    this.sevenDays(false);
    this.initWeeklyPreview();
  }

  getNextWeek() {
    this.sevenDays(true);
    this.initWeeklyPreview();
  }

  private getSeriesColors(length: number): any {
    let result = {};
    CHART_SERIES_COLOR.slice(0, length).forEach((value, ind) => {
      result[Number(ind)] = { color: value };
    });
    return result;
  }

  private sevenDays(isNextWeek: boolean) {
    if (isNextWeek) this.previewDate.setDate(this.previewDate.getDate() + 7);
    else this.previewDate.setDate(this.previewDate.getDate() - 7);
  }
  buildForm(subject: DiarySubjectModel){
    return this.formBuilder.group({
      title: subject.title,
      subjectOrder: subject.subjectOrder,
      questionSetId: subject.questionSetId,
      answers: this.formBuilder.array(
          this.buildAnswersFormGroups(subject.answers),
      ),
      focusRichText: false,
    });
  }
}
