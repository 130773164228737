import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { IFormDropdownOption } from '../../../form-controls/form-controls.const';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class ProductCategoryService {
  public values: IFormDropdownOption[] = [];
  public assessmentValues: IFormDropdownOption[] = [];
  public lookupValues: string[] = [];

  public filteredValues$ = new BehaviorSubject<IFormDropdownOption[]>(null);

  // needed so we know that the request has been made
  // talk about this with the team
  public values$ = new ReplaySubject<IFormDropdownOption[]>(1);

  constructor(private masterDataService: MasterDataService) {
    this.getProductCategories();
  }

  public filterCategories(lawCode: string): void {
    this.values$.pipe(take(1)).subscribe((values) => {
      if (lawCode) {
        const filteredCategories = values.filter(
          (category) => category.type === lawCode,
        );
        this.filteredValues$.next(filteredCategories);
      } else {
        this.filteredValues$.next([]);
      }
    });
  }

  public getProductCategories() {
    this.masterDataService
      .getMasterData('product-categories')
      .subscribe((data) => {
        // sort by value
        data.sort((a, b) => {
          return a.value - b.value;
        });
        this.assessmentValues = data;
        this.values = data;
        for (let item of this.values) {
          this.lookupValues[item.value] = item.label;
        }

        this.values$.next(data);
      });
  }
}
