<div class="c4p-question-set-input-small" style="word-wrap: break-word">
  <span *ngIf="required" class="c4p-question-label">{{
    '* ' + orderNum + '. ' + placeholder
  }}</span>
  <span *ngIf="!required" class="c4p-question-label">{{
    orderNum + '. ' + placeholder
  }}</span>
  <span
    *ngIf="hint && !isClientPortal"
    tippy
    [tippyOptions]="{
      content: hint,
      arrow: true,
      allowHTML: true,
      placement: 'right-end',
      theme: 'light',
      maxWidth: '350px',
      interactive: true
    }"
  >
    <mat-icon class="c4p-hint-icons">info</mat-icon>
  </span>
</div>
<div class="c4p-question-set-input">
  <div [formGroup]="formArray" style="margin-left: -10px; margin-top: 4px">
    <app-panel-form-row
      mode="-questions-no-border"
      *ngFor="let control of formArray.controls; let i = index"
    >
      <ng-container [formGroupName]="i">
        <app-form-input-checkbox
          [placeholder]="control.value.label"
          formControlName="value"
          width="-nowidth"
        ></app-form-input-checkbox>
      </ng-container>
    </app-panel-form-row>
  </div>
</div>
