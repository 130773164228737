import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mismatch-answers-title',
  templateUrl: './mismatch-answers-title.component.html',
  styleUrls: ['./mismatch-answers-title.component.scss'],
})
export class MismatchAnswersTitleComponent implements OnInit {
  @Input() title: string;

  constructor() {}

  ngOnInit(): void {}
}
