import { PartialPdfInfo } from "./pdf.info";
import { PartialSignatureInfo } from "./signature.info";

export class SimpleSignOutput {
  tenantId: string;
  documentName!: string;
  url!: string;
  signerIdentificationMethod: string;
  source!: string;
  signatureInfo: PartialSignatureInfo;
  inputFileMetadata: any;
  outputFileMetadata: any;
  pdfInfo: PartialPdfInfo;
  createdAt?: Date;
}
