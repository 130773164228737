import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentModel } from '../../models/document.model';
import { PartialSignatureInfo } from '../../../models';
import { DocumentService } from '../..';
import { UserInfoService } from '../../../services';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getToken } from '@angular/fire/app-check';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-document-sign',
  templateUrl: './document-sign.component.html',
})
export class DocumentSignComponent implements OnInit, OnDestroy {
  private document: DocumentModel;
  private userPortal: string;
  private operation: string;
  public documentName: string;
  public signatureConfirmation: boolean;
  public signatureIncludeRole: boolean;
  public defaultIdentificationMethod: string = "Tuula 2FA"
  public errorOccurred = false;
  public errorMessage: string;
  showSpinner = false;
  signatureStatus = false;
  isSignedDocument = false;
  isEmployeePortal: boolean;
  private destroyed$ = new Subject<boolean>();
  private errorCounter = 0;
  private maxErrors = 2;

  constructor(
    private userInfoService: UserInfoService,
    private keycloakService: KeycloakService,
    private documentService: DocumentService,
    public dialogRef: MatDialogRef<DocumentSignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.document = this.data?.document;
    this.userPortal = this.data?.userPortal;
    this.operation = this.data?.operation;
    this.documentName = this.document?.fileData?.fileName;
    this.checkSignedUserInfo();
    this.isEmployeePortal = this.documentService.isEmployeePortal();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  checkSignedUserInfo(){
    this.showSpinner = true;
    this.userInfoService.userInfo$.pipe(takeUntil(this.destroyed$)).subscribe((userInfo) => {
      const isSigned = this.document.signatureLogs.filter(signedLog => signedLog.signedBy.id === userInfo.id )
      if(isSigned.length > 0) this.isSignedDocument= true;
      this.showSpinner = false;
    })
    this.showSpinner = false;
  }
  close(){
    if(this.showSpinner) return;
    this.dialogRef.close();
  }
  submit() {
    if(this.showSpinner) return;
    this.showSpinner = true;
    try {
      if(!this.signatureIncludeRole || !this.isEmployeePortal) {this.signatureIncludeRole=false;}
      this.userInfoService.userInfo$.pipe(takeUntil(this.destroyed$)).subscribe((userInfo) => {
        const simpleSignInput = this.convertDocumentToSignatureInfo(
          this.document,
          `${userInfo?.id}`,
          `${userInfo?.fullName}`,
          this.signatureIncludeRole,
        );
        this.documentService
          .signPdf(this.document.id, simpleSignInput).pipe(takeUntil(this.destroyed$))
          .subscribe((res) => {
            this.showSpinner = false;
            this.signatureStatus = true;
            this.toastr.success(this.translate.instant('general.labels.Success'));
          },error => {
            this.errorCounter++;
            if (this.errorCounter >= this.maxErrors) {
              this.dialogRef.close();
              this.toastr.error(this.translate.instant('general.labels.Unsuitable'));
            } else {
              this.errorMessage = error.error.message;
              this.errorOccurred = true;
              this.showSpinner = false;
            }
          });
      });
    } catch (error) {
      this.errorMessage = error.message;
      this.errorOccurred = true;
      this.showSpinner = false;
    }
  }

  convertDocumentToSignatureInfo(
    document: DocumentModel,
    userId: string,
    fullName: string,
    signatureIncludeRole: boolean,
  ): PartialSignatureInfo {

    return {
      operation: this.operation,
      documentId: document.id,
      signerIdentificationMethod: this.keycloakService.getKeycloakInstance().tokenParsed?.x_auth_method || this.defaultIdentificationMethod,
      signedBy: { fullName: fullName, id: userId },
      signatureIncludeRole: signatureIncludeRole,
    };
  }
}
