
<app-accordion [title]="'document.labels.ViaEmailSharedPersonList'" *ngIf="viaEmailPersons?.length > 0">
  <app-accordion
    hideToggle
    [hideShadow]="true"
    [doCollapse]="false"
    [decoratedHeader]="true"
    [title]="[
    'document.labels.FullName',
    'document.labels.shareDate',
    'document.labels.Bsn',
    'document.labels.phoneNumber',
    'document.labels.birthYear',
    'document.labels.validateWithEmail',
    'document.labels.validateWithEmailCode',
  ]">
  </app-accordion>
  <ng-container *ngFor="let externalPerson of viaEmailPersons; let i = index">
    <app-accordion
      [selected]="externalPerson.signatureRequired"
      (changeValue)="onSelectionChange($event,externalPerson)"
      [title]="externalPerson.title"
      (click)="openAccordion(i)"
      [hideToggle]="true"
      [panelOpenState]="step === i"
    >
      <div style="width: '98%'; margin: auto">
        <app-panel style="margin-top: 15px">
          <app-panel-title title="billing.labels.General"></app-panel-title>
          <app-panel-form-row>
            <app-label
              [labelValue]="{
                    label: 'document.labels.fullName',
                    value: externalPerson.fullName
                  }"
            >
            </app-label>
            <app-label
              [labelValue]="{
                    label: 'document.labels.shareDate',
                    value: externalPerson.shareDate | appDate
                  }"
            >
            </app-label>
            <app-label
              [labelValue]="{
                    label: 'document.labels.Bsn',
                    value: externalPerson.identificationMethods?.bsn
                  }"
            >
            </app-label>
            <app-label
              [labelValue]="{
                    label: 'document.labels.phoneNumber',
                    value: externalPerson.identificationMethods?.phoneNumber
                  }"
            >
            </app-label>
            <app-label
              [labelValue]="{
                    label: 'document.labels.birthYear',
                    value: externalPerson.identificationMethods?.birthYear
                  }"
            >
            </app-label>
            <app-label
              [labelValue]="{
                    label: 'document.labels.validateWithEmail',
                    value: externalPerson.identificationMethods?.validateWithEmail
                  }"
            >
            </app-label>
            <app-label
              [labelValue]="{
                    label: 'document.labels.validateWithEmailCode',
                    value: externalPerson.identificationMethods?.validateWithEmailCode
                  }"
            >
            </app-label>
          </app-panel-form-row>
        </app-panel>
      </div>
    </app-accordion>
  </ng-container>
</app-accordion>

<app-accordion [title]="'document.labels.InternalAndExternalSharedPersonHistory' | translate">
      <app-accordion
        hideToggle
        [hideShadow]="true"
        [doCollapse]="false"
        [decoratedHeader]="true"
        [title]="[
      'document.labels.fullName',
      'document.labels.shareDate',
      'document.labels.signatureRequired'
    ]">
      </app-accordion>
        <ng-container *ngFor="let person of internalAndExternalSharedPersons; let i = index">
          <app-accordion
            [title]="person.title"
            [selected]="person.signatureRequired"
            [hideToggle]="true"
            (changeValue)="onSelectionChange($event,person)">
            <div style="width: '98%'; margin: auto">
              <app-panel style="margin-top: 15px">
                <app-panel-title title="billing.labels.General"></app-panel-title>
                <app-panel-form-row>
                  <app-label
                    [labelValue]="{
                    label: 'document.labels.FullName',
                    value: person.fullName
                  }"
                  >
                  </app-label>
                  <app-label
                    [labelValue]="{
                    label: 'document.labels.shareDate',
                    value: person.shareDate | appDate
                  }"
                  >
                  </app-label>
                  <app-label
                    [labelValue]="{
                    label: 'document.labels.signatureRequired',
                    value: person.signatureRequired
                  }"
                  >
                  </app-label>
                </app-panel-form-row>
              </app-panel>
            </div>
          </app-accordion>
        </ng-container>
</app-accordion>


