import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { HttpUtils } from '../../utilities/http.utils';
import { PaginationDataModel } from '../../models/pagination/pagination-data.model';
import { SocialAreaModel } from '../../models/social-area/social-area.model';

@Injectable()
export class SocialAreaOverviewService {
  public socialareas$ = new BehaviorSubject<{
    data: SocialAreaModel[];
    count: number;
  }>(null);

  constructor(
    private http: HttpClient,
    @Inject('environment') public environment: any,
  ) {}

  public getSocialAreas(paginationData: PaginationDataModel, filter?: any) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/social-areas',
    });
    const params = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filter,
    );
    return this.http.get<any>(
      `${this.environment.questionsetManagementApiUrl}/social-areas`,
      { headers, params },
    );
  }

  public createSocialArea(socialArea: SocialAreaModel) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/social-areas',
      entity_type: 'Social-Areas',
    });
    return this.http.post<any>(
      `${this.environment.questionsetManagementApiUrl}/social-areas`,
      socialArea,
      {
        headers,
      },
    );
  }

  public deleteSocialArea(id: number | string) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/social-areas/:socialarea_id',
      entity_type: 'Social-Areas',
      entity_id: id.toString(),
    });
    return this.http.delete(
      `${this.environment.questionsetManagementApiUrl}/social-areas/${id}`,
      {
        headers,
      },
    );
  }
}
