<div class="c4p-form-input-full-width" [ngClass]="large ? 'w-large' : xLarge ? 'x-large' : ''">
  <mat-form-field>
    <mat-label>{{placeholder | translate}}</mat-label>

    <textarea
      type="{{ type }}"
      matInput
      [errorStateMatcher]="errorStateMatcher"
      [formControl]="valueControl"
      [required]="required"
      (blur)="onTouchedCallback()"
      [cols]="cols"
      [rows]="rows"
      [ngStyle]="{ resize: disableResize ? 'none' : '' }"
      cdkTextareaAutosize="autoResize"
      [readonly]="readonly"
      [ngClass]=" xLarge ? 'x-large-overflow' : '' "
    ></textarea>
    <mat-error *ngFor="let error of ngControl.errors | keyvalue">
      <span *ngIf="errorLabels && errorLabels[error.key]; else defaultError">
        {{ errorLabels[error.key] | translate }}
      </span>
      <ng-container #defaultError *ngIf="DEFAULT_ERRORS.has(error.key)">
        <span>{{ DEFAULT_ERRORS.get(error.key) | translate }}&nbsp;</span>
        <span *ngIf="error.key === 'maxlength' || 'minlength'">{{
          error.value?.requiredLength
        }}</span>
      </ng-container>
    </mat-error>
    <span *ngIf="suffix" matSuffix>{{ suffix }}</span>
  </mat-form-field>
</div>
