import { AbstractDynamicElement } from './abstract-dynamic.model';

export class DynamicFormInput<T> extends AbstractDynamicElement {
  value: T;
  key: string;
  placeholder: string;
  controlType: string;
  required?: boolean;
  order?: number;
  type?: string;
  options?: { key: string; value: string }[];

  constructor(
    options: {
      value?: T;
      key?: string;
      placeholder?: string;
      required?: boolean;
      order?: number;
      controlType?: string;
      type?: string;
      options?: { key: string; value: string }[];
    } = {},
  ) {
    super();
    this._dynamicType = 'control';
    this.value = options.value;
    this.key = options.key || '';
    this.placeholder = options.placeholder || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.options = options.options || [];
  }
}
