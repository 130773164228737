import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { CategoryService } from '../../service/category/category.service';
import { DocumentService } from '../../service/document/document.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit, OnChanges {
  @Input() clientId: string;
  @Input() applicationId: string;
  @Input() carefileId: string;
  @Input() careprogramId: string;
  @Input() userportal: string;
  @Input() section: string;
  @Input() services: any[];
  @Input() applicationViewMode: boolean = false;
  @Input() careprogramViewMode: boolean = false;
  @Input() carefileStatus: any;
  @Input() operation: string;

  private readonly destroyed$ = new Subject<boolean>();
  constructor(
    private documentService: DocumentService,
    private categoryService: CategoryService,
  ) {}

  ngOnInit(): void {
    this.getCategories();

    this.documentService.documentParams$.next({
      clientId: this.clientId,
      applicationId: this.applicationId,
      carefileId: this.carefileId,
      userportal: this.userportal,
      section: this.section,
      careprogramId: this.careprogramId,
      carefileStatus: this.carefileStatus,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.clientId ||
      changes.applicationId ||
      changes.carefileId ||
      changes.portal
    ) {
      this.documentService.documentParams$.next({
        clientId: this.clientId,
        applicationId: this.applicationId,
        carefileId: this.carefileId,
        userportal: this.userportal,
        section: this.section,
        careprogramId: this.careprogramId,
      });
    }
    if (changes.services) {
      this.documentService.services = changes.services.currentValue;
    }
  }

  private getCategories() {
    this.categoryService.getCategories().subscribe((categories) => {
      this.documentService.categories = categories;
    });
  }
}
