<app-panel [formGroup]="careTypeDeterminationForm" type="popup">
  <app-panel-title
    title="careprogram.titles.CareTypeDeterminationGGZ"
    titleIcon="new_label"
  ></app-panel-title>

  <app-panel-form-row>
    <app-form-input-dropdown
      formControlName="name"
      placeholder="general.labels.Name"
      [options$]="careTypeDeterminationService.nameOptions"
      style="width: 550px; margin: 0px 0px 11px 10px"
      width="-nowidth"
    ></app-form-input-dropdown>
    <app-form-input-dropdown
      formControlName="code"
      placeholder="general.labels.Code"
      [options$]="careTypeDeterminationService.codeOptions"
    ></app-form-input-dropdown>
    <app-form-input-date
      formControlName="startDate"
      placeholder="general.labels.StartDate"
    ></app-form-input-date>
    <app-form-input-date
      formControlName="endDate"
      placeholder="general.labels.EndDate"
    ></app-form-input-date>
  </app-panel-form-row>
</app-panel>
<div style="display: flex">
  <span class="c4p-button-cancel" (click)="onDismiss()" translate
    >general.labels.Cancel</span
  >
  <span class="c4p-button-ok" *ngIf="!viewOnly" (click)="onConfirm()">
    <span>OK</span>
  </span>
</div>
