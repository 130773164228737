import {
  Component,
  Input,
  Optional,
  Output,
  Self,
  EventEmitter,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  DateInputErrorStateMatcher,
  DEFAULT_TEXT_AREA_COLUMNS,
  DEFAULT_TEXT_AREA_DISABLE_RESIZE,
  DEFAULT_TEXT_AREA_TYPE,
  FormControlBase,
} from '../../../form-controls';
import {
  ExpandInfo,
  QuestionExpandComponent,
} from '../question-expand/question-expand.component';

@Component({
  selector: 'app-multi-line',
  templateUrl: './multi-line.component.html',
  styleUrls: ['./multi-line.component.css'],
})
export class MultiLineComponent extends FormControlBase<MultiLineComponent> {
  @Input() type = DEFAULT_TEXT_AREA_TYPE;
  @Input() cols = DEFAULT_TEXT_AREA_COLUMNS;
  @Input() rows = DEFAULT_TEXT_AREA_TYPE;
  @Input() disableResize = DEFAULT_TEXT_AREA_DISABLE_RESIZE;
  @Input() hint: string;
  @Input() isClientPortal = false;
  @Input() orderNum: number;
  @Input() fullView = true;
  @Output() tabEvent = new EventEmitter();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private dialog: MatDialog,
  ) {
    super(ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
      this.errorStateMatcher = new DateInputErrorStateMatcher(this.ngControl);
    }
  }
  expand() {
    const data: ExpandInfo = {
      hint: this.hint,
      orderNum: this.orderNum,
      placeholder: this.placeholder,
      disabled: this.disabled,
      required: this.required,
      isClientPortal: this.isClientPortal,
      value: this.value,
      type: 'multiline',
    };
    const dialogRef = this.dialog.open(QuestionExpandComponent, { data });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.valueControl.setValue(result);
      }
    });
  }

  onTab(el: HTMLElement) {
    this.tabEvent.emit(el.offsetHeight);
  }
}
