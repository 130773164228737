import { AnswerModel } from '../question-set-answer/answer.model';
import { StatusEnum } from './../enumerators';

export class DiaryAnswerModel {
  order: number;
  isMandatory: boolean;
  answer: AnswerModel;
}

export class DiarySubjectModel {
  subjectOrder: number;
  questionSetId: string;
  title: string;
  answers: DiaryAnswerModel[];
}

export class DiaryModel {
  id!: string;
  tenantId!: string;
  title: string;
  diaryPreviewDate: Date;
  status: StatusEnum;
  carefileId: string;
  diaryQuestionSetId: string;
  completenessStatus: number;
  diarySubjects: DiarySubjectModel[];
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: any;
  updatedBy?: any;
}
