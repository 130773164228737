import { ActivatedRouteSnapshot } from '@angular/router';

export abstract class BaseResolver {
  // sets the breadcrumbLabel accordingly
  protected setBreadCrumb(route: ActivatedRouteSnapshot, data: any): void {
    if (data) {
      let breadcrumbLabel = data;
      route.data = { ...route.data, breadcrumbLabel };
    }
  }
}
