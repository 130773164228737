<div class="sidesheet-breadcrumb-container">
    <div class="sidesheet-breadcrumb-menu-container">
        <ol class="sidesheet-breadcrumb">
            <li *ngFor="let item of formerSidesheets; let isLast = last; let i = index"
                [class.active]="isLast" class="sidesheet-breadcrumb-item" >

                <ng-container *ngIf="isLast">
                    <span>{{item?.content?.title | translate}}</span>
                    <span class="sidesheet-breadcrumb-item-subtitle" *ngIf="sideSheetContent?.subTitle">{{"   (" + (sideSheetContent?.subTitle | translate) + ")"}}</span>
                </ng-container>

                <ng-container *ngIf="!isLast">
                    <a *ngIf="!isLast" href="javascript:void(0)"
                        (click)="loadComponent(item?.componentRef?.componentType, item?.content, item?.config)">{{item?.content?.title | translate}}</a>
                </ng-container>
            </li>
        </ol>
    </div>

    <div class="sidesheet-breadcrumb-buttons-container">
        @if (breadcrumbActionsTemplate) {
            <ng-container *ngTemplateOutlet="breadcrumbActionsTemplate"></ng-container>
        } @else {
            <ng-container *ngTemplateOutlet="sidesheetBreadCrumbDefaultActionsTemplate"></ng-container>
        }
    </div>

</div>

<ng-template #sidesheetBreadCrumbDefaultActionsTemplate>
    <button mat-icon-button class="sidesheet-breadcrumb-button" (click)="toggleFullScreen()"
        *ngIf="sideSheetConfig?.breadcrumbFullscreenVisible">
        <mat-icon> {{sideSheetConfig?.fullscreen ? "fullscreen_exit": "fullscreen"}}</mat-icon>
    </button>
    <button mat-icon-button class="sidesheet-breadcrumb-button" (click)="onCloseButton()"
        [disabled]="closeButton.disabled" *ngIf="sideSheetConfig?.breadcrumbCloseVisible">
        <mat-icon>close</mat-icon>
    </button>
</ng-template>
