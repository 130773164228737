<app-panel type="popup">
  <mat-slide-toggle
    style="margin-left: 10px"
    [(ngModel)]="viewNew"
  ></mat-slide-toggle>
</app-panel>
<app-panel type="popup">
  <ng-container *ngIf="viewNew">
    <app-panel-title title="questionset.titles.NewFormat"> </app-panel-title>
    <app-panel-form-row>
      <app-label
        [labelValue]="{
          label: 'questionset.labels.CreateFormatTitle',
          value: newQuestionSet?.title
        }"
      >
      </app-label>
      <app-label
        [labelValue]="{
          label: 'questionset.labels.TotalQuestions',
          value: newQuestionSet?.totalQuestions
        }"
      >
      </app-label>
      <app-label
        [labelValue]="{
          label: 'questionset.labels.MandatoryQuestions',
          value: newQuestionSet?.mandatoryQuestions
        }"
      >
      </app-label>
    </app-panel-form-row>
  </ng-container>

  <ng-container *ngIf="!viewNew">
    <app-panel-title title="questionset.titles.OldFormat"> </app-panel-title>
    <app-panel-form-row>
      <app-label
        [labelValue]="{
          label: 'questionset.labels.CreateFormatTitle',
          value: oldQuestionSet?.title
        }"
      >
      </app-label>
      <app-label
        [labelValue]="{
          label: 'questionset.labels.TotalQuestions',
          value: oldQuestionSet?.totalQuestions
        }"
      >
      </app-label>
      <app-label
        [labelValue]="{
          label: 'questionset.labels.MandatoryQuestions',
          value: oldQuestionSet?.mandatoryQuestions
        }"
      >
      </app-label>
    </app-panel-form-row>
  </ng-container>

  <app-table-view [options]="questionsTable"></app-table-view>

  <div style="display: flex">
    <span class="c4p-button-cancel" (click)="dialogRef.close()" translate
      >general.labels.Cancel</span
    >
    <span class="c4p-button-ok" (click)="onConfirm()" translate
      >general.labels.Confirm</span
    >
  </div>
</app-panel>
