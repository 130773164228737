import { Component, OnInit } from '@angular/core';
import { AppInitializerService, HelperService } from 'c4p-portal-util';
import { KeycloakService } from 'keycloak-angular';
import { MAIN_MENU_ITEMS } from './const/values.const';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private appInitializerService: AppInitializerService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private keycloak: KeycloakService,
  ) {
    this.registerIcons();
  }

  async ngOnInit() {
    this.logoutWrongUser();
    this.appInitializerService.initializeApp(MAIN_MENU_ITEMS);
  }

  async logoutWrongUser() {
    const tokenData = await this.keycloak.getToken();
    const token = HelperService.parseJwt(tokenData);
    if (token.user_type === 'employee') this.keycloak.logout();
  }
  registerIcons(){
    this.matIconRegistry.addSvgIcon('mySignatureGray', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/mySignatureGray.svg'));
    this.matIconRegistry.addSvgIcon('mySignatureYellow', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/mySignatureYellow.svg'));
    this.matIconRegistry.addSvgIcon('mySignatureGreen', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/mySignatureGreen.svg'));
    this.matIconRegistry.addSvgIcon('mySignatureRed', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/mySignatureRed.svg'));

    this.matIconRegistry.addSvgIcon('internalGray', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/internalGray.svg'));
    this.matIconRegistry.addSvgIcon('internalYellow', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/internalYellow.svg'));
    this.matIconRegistry.addSvgIcon('internalGreen', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/internalGreen.svg'));
    this.matIconRegistry.addSvgIcon('internalRed', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/internalRed.svg'));

    this.matIconRegistry.addSvgIcon('externalGray', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/externalGray.svg'));
    this.matIconRegistry.addSvgIcon('externalYellow', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/externalYellow.svg'));
    this.matIconRegistry.addSvgIcon('externalGreen', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/externalGreen.svg'));
    this.matIconRegistry.addSvgIcon('externalRed', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/externalRed.svg'));

    this.matIconRegistry.addSvgIcon('flag-en', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/gb.svg'));
    this.matIconRegistry.addSvgIcon('flag-nl', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/nl.svg'));
  }
}
