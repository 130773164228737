<app-panel type="popup">
  <app-panel-title [title]="title"></app-panel-title>

  <form #phoneForm="ngForm">
    <app-panel-form-row>
      <app-form-input-dropdown
        [(ngModel)]="phone.type"
        name="type"
        placeholder="general.labels.PhoneType"
        [options]="phoneTypeService.values"
        required="true"
      ></app-form-input-dropdown>
    </app-panel-form-row>
    <app-panel-form-row>
      <app-form-input-dropdown
        [(ngModel)]="phone.countryCode"
        name="countryCode"
        placeholder="general.labels.CountryCode"
        [options]="countryCodeService.values"
        required="true"
      ></app-form-input-dropdown>
      <app-form-input-text
        [(ngModel)]="phone.number"
        name="number"
        placeholder="general.labels.Phone"
        pattern="^[0-9\s]*$"
        required="true"
        [errorLabels]="{ pattern: 'general.form.InvalidPhone' }"
      >
      </app-form-input-text>
    </app-panel-form-row>
  </form>
</app-panel>

<div style="display: flex">
  <span class="c4p-button-cancel" (click)="onDismiss()" translate
    >general.labels.Cancel</span
  >
  <span class="c4p-button-ok" (click)="onConfirm()">
    <span>OK</span>
  </span>
</div>
