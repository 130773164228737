import { Input, Output } from '@angular/core';
import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpUtils } from '../../utilities/http.utils';
import { MAX_ALLOWED_FILE_SIZE } from '../../utilities';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  MAX_ALLOWED_FILE_SIZE = `${MAX_ALLOWED_FILE_SIZE / (1024 * 1024)}MB`;
  files: any[] = [];
  isFileBiggerThanMaxAllowedSize: boolean = false;
  fileExtensionValid: boolean = true;
  allowedFileExtensions = [
    'doc',
    'docx',
    'odt',
    'ods',
    'xls',
    'xlsx',
    'ppt',
    'pttx',
    'txt',
    'pdf',
    'bmp',
    'jpg',
    'jpeg',
    'png',
    'gif',
    'mp3',
    'aac',
    'flac',
    'mp4',
    'avi',
    'mkv',
    'eml',
    'emlx',
    'msg',
    'json',
    'mpg',
    'mpeg',
  ];

  @Input() multiple = true;

  @Output() filesSelected$ = new BehaviorSubject<any[]>(null);

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.filesSelected$.next(this.files);
  }

  prepareFilesList(files: Array<any>) {
    this.isFileBiggerThanMaxAllowedSize = false;
    this.fileExtensionValid = true;
    for (let file of files) {
      let fileNameExtension = file.name.split('.').pop().toLowerCase();
      if (!this.allowedFileExtensions.includes(fileNameExtension)) {
        this.fileExtensionValid = false;
        break;
      }
    }
    if (this.fileExtensionValid == false) {
      return;
    }
    if (!this.multiple) {
      if (files[0].size > MAX_ALLOWED_FILE_SIZE) {
        this.isFileBiggerThanMaxAllowedSize = true;
        return;
      }
      this.files = [];
      files = files && [files[0]];
    }

    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }

    this.filesSelected$.next(this.files);
  }

  formatBytes(bytes) {
    return HttpUtils.calculateFileSize(bytes);
  }
}
