import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class GradeService {
  public values: IFormDropdownOption[] = [];

  constructor(private masterDataService: MasterDataService) {
    this.getGrades();
  }

  public getGrades() {
    this.masterDataService
      .getMasterData('grades')
      .subscribe((data) => (this.values = data));
  }
}
