export class SidebarConfiguration {
  // unique SidebarConfiguration id
  sidebarConfigurationId!: string;

  // unique Tenant id
  tenantId!: number | string;

  // Frontend menuItem-label
  collectionLabel: string;

  // Frontend submenuItem-label
  itemLabel: string;

  // order number
  order: number;
  route: string; // over-ride route from tenant-management db sidebar_configuration coll
  permission: string; // over-ride route from tenant-management db sidebar_configuration coll
}
