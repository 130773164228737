export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';
export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/yyyy HH:mm';
export const JS_DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';
export const DEFAULT_TIME_FORMAT = 'HH:mm';
export const TENANT_PIPE_FORMAT = 'DD/MM/yyyy';

export enum STATUS {
  SUBMITTED = 'submitted',
  DRAFT = 'draft',
  APPROVED = 'approved',
  DECLARED = 'declared',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  INVOICED = 'invoiced',
  CORRECTION_DECLARED = 'correction_declared',
  PARTIALLY_ACCEPTED = 'partially_accepted',
}
