<app-panel [formGroup]="careLabelForm" type="popup">
  <app-panel-title title="careprogram.titles.CareLabel" titleIcon="new_label">
  </app-panel-title>
  <app-panel-form-row>
    <ng-container formGroupName="careLabelItem">
      <app-form-input-dropdown
        formControlName="name"
        placeholder="general.labels.Name"
        [options$]="carelabelService.labelOptions"
      ></app-form-input-dropdown>
      <app-form-input-dropdown
        formControlName="code"
        placeholder="general.labels.Code"
        [options$]="carelabelService.codeOptions"
      ></app-form-input-dropdown>
    </ng-container>
  </app-panel-form-row>
  <app-panel-form-row>
    <app-form-input-date
      formControlName="startDate"
      [maxDate]="getMaxDate(endDateControl.value)"
      placeholder="general.labels.StartDate"
    ></app-form-input-date>
    <app-form-input-date
      formControlName="endDate"
      [minDate]="getMinDate(startDateControl.value)"
      placeholder="general.labels.EndDate"
    ></app-form-input-date>
  </app-panel-form-row>
</app-panel>
<div style="display: flex">
  <span class="c4p-button-cancel" (click)="onDismiss()" translate
    >general.labels.Cancel</span
  >
  <span class="c4p-button-ok" *ngIf="!viewOnly" (click)="onConfirm()">
    <span>OK</span>
  </span>
</div>
