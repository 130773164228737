<!-- <app-panel> -->

<div class="c4p-form-questions">
  <div class="c4p-form-questions-title" [formGroup]="filterForm">
    <app-form-input-radio
      *ngIf="!isClientPortal"
      placeholder=""
      direction="row"
      [options]="dropdownOptions"
      formControlName="answeredFilter"
    ></app-form-input-radio>
    <app-form-input-checkbox
      *ngIf="!isClientPortal"
      placeholder="questionset.labels.ShowOnlyMandatory"
      formControlName="mandatoryFilter"
    ></app-form-input-checkbox>
  </div>
  <div *ngIf="!hasPermission" style="margin-left: 8px">
    <p class="c4p-font-medium c4p-font-bold" translate>
      questionset.labels.FormatNotAllowed
    </p>
  </div>
  <div
    [ngClass]='isSideSheet ? "c4p-form-question-answers-container-side" : "c4p-form-question-answers-container"'
    *ngIf="hasPermission"
    (scroll)="onScroll($event)"
    #scroll
  >
    <div [formGroup]="answersFormArray" class="c4p-form-question-answers">
      <app-panel-form-row
        mode="-questions"
        *ngFor="let control of answersFormArray.controls; let i = index"
      >
        <ng-container [formGroupName]="i" [ngSwitch]="control.value.type">
          <!-- Singleline -->
          <app-single-line
            *ngSwitchCase="1"
            formControlName="answer"
            name="answer"
            (tabEvent)="onTabHandler($event)"
            [placeholder]="control.value.title"
            [orderNum]="control.value.order"
            [hint]="control.value.hint"
            [isClientPortal]="isClientPortal"
          ></app-single-line>

          <!-- Multiline -->
          <app-multi-line
            class="question-text-area"
            *ngSwitchCase="2"
            (tabEvent)="onTabHandler($event)"
            formControlName="answer"
            name="answer"
            [placeholder]="control.value.title"
            [orderNum]="control.value.order"
            [hint]="control.value.hint"
            [isClientPortal]="isClientPortal"
          ></app-multi-line>

          <!-- Rich text -->
          <div
            *ngSwitchCase="3"
            tabindex="0"
            #richEditor
            (keydown.Tab)="onTabRichEditor(richEditor)"
          >
            <div
              class="c4p-question-set-input-small"
              style="word-wrap: break-word"
            >
              <span
                *ngIf="control.value.isMandatory"
                class="c4p-question-label"
                >{{
                  '* ' + control.value.order + '. ' + control.value.title
                }}</span
              >
              <span
                *ngIf="!control.value.isMandatory"
                class="c4p-question-label"
                >{{ control.value.order + '. ' + control.value.title }}</span
              >
              <span
                *ngIf="control.value.hint && !isClientPortal"
                tippy
                [tippyOptions]="{
                  arrow: true,
                  allowHTML: true,
                  placement: 'right',
                  content: control.value.hint,
                  theme: 'light',
                  maxWidth: '350px',
                  interactive: true
                }"
              >
                <mat-icon class="c4p-hint-icons">info</mat-icon>
              </span>
              <span style="margin-left: auto" (click)="expand(i)">
                <mat-icon class="c4p-hint-icons">open_in_full</mat-icon>
              </span>
            </div>
            <div
              *ngIf="!control.value.answer && !control.value.focusRichText"
              (click)="onRichContent($event, control)"
            >
              <p>
                <i
                  ><span translate>
                    questionset.messages.ClickHereToAnswer
                  </span></i
                >
              </p>
            </div>
            <quill-view-html
              *ngIf="control.value.answer && !control.value.focusRichText"
              class="quill-view-mode"
              (click)="onRichContent($event, control)"
              [content]="control.value.answer"
            ></quill-view-html>
            <div
              *ngIf="control.value.focusRichText && !answersFormArray.disabled"
              class="c4p-question-set-input"
            >
              <quill-editor
                placeholder=""
                formControlName="answer"
                (onSelectionChanged)="onRichContent($event, control)"
                (onEditorCreated)="onEditorCreated($event)"
              ></quill-editor>
            </div>
          </div>

          <!-- Multiple choice -->
          <app-multiple-choice
            *ngSwitchCase="4"
            formControlName="answer"
            name="answer"
            [placeholder]="control.value.title"
            [orderNum]="control.value.order"
            [options]="optionsForChoice[control.value.id]"
            [hint]="control.value.hint"
            [isClientPortal]="isClientPortal"
            [required]="control.value.isMandatory"
            [disable]="!this.isEditable"
          ></app-multiple-choice>

          <!-- Single choice -->
          <app-single-choice
            *ngSwitchCase="5"
            formControlName="answer"
            name="answer"
            [placeholder]="control.value.title"
            [orderNum]="control.value.order"
            [options]="optionsForChoice[control.value.id]"
            [hint]="control.value.hint"
            [isClientPortal]="isClientPortal"
            (tabEvent)="onTabHandler($event)"
          ></app-single-choice>

          <!-- Date -->
          <app-date-choice
            *ngSwitchCase="6"
            formControlName="answer"
            name="answer"
            [placeholder]="control.value.title"
            [orderNum]="control.value.order"
            [hint]="control.value.hint"
            [isClientPortal]="isClientPortal"
            (tabEvent)="onTabHandler($event)"
          ></app-date-choice>

          <!-- Date Time -->
          <app-date-time-choice
            *ngSwitchCase="7"
            formControlName="answer"
            name="answer"
            [placeholder]="control.value.title"
            [orderNum]="control.value.order"
            [hint]="control.value.hint"
            [isClientPortal]="isClientPortal"
            (tabEvent)="onTabHandler($event)"
          ></app-date-time-choice>

          <!-- Scale -->
          <app-scale-choice
            *ngSwitchCase="8"
            formControlName="answer"
            name="answer"
            [placeholder]="control.value.title"
            [orderNum]="control.value.order"
            [hint]="control.value.hint"
            [isClientPortal]="isClientPortal"
            [minLimit]="0"
            [maxLimit]="5"
            (tabEvent)="onTabHandler($event)"
          >
          </app-scale-choice>

          <app-additional-comment
            *ngIf="control.value.commentRequired"
            formControlName="comment"
            name="comment"
            placeholder="clientPortal.labels.Explanation"
            (tabEvent)="onTabHandler($event)"
          >
          </app-additional-comment>
        </ng-container>
      </app-panel-form-row>
    </div>
  </div>
</div>
<!-- </app-panel> -->
