import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IFormDropdownOption } from '../../../../form-controls';
import { CareLabelService } from '../../../../services/referent/care-program/care-label/care-label.service';
import { CareLabelModel } from '../../models/care-program.model';
import { CareProgramService } from '../../services/care-program.service';

interface InputData {
  careLabel: CareLabelModel;
  viewOnly: boolean;
}

@Component({
  selector: 'app-care-label-detail',
  templateUrl: './care-label-detail.component.html',
  styleUrls: ['./care-label-detail.component.scss'],
})
export class CareLabelDetailComponent implements OnInit, OnDestroy {
  public careLabelForm: FormGroup = null!;

  public get startDateControl() {
    return this.careLabelForm.get('startDate');
  }

  public get endDateControl() {
    return this.careLabelForm.get('endDate');
  }

  private get nameControl() {
    return this.careLabelForm.get('careLabelItem').get('name');
  }

  private get codeControl() {
    return this.careLabelForm.get('careLabelItem').get('code');
  }

  private get registrationDate() {
    return this.careprogramService.careProgramForm.get('registrationDate')
      .value;
  }

  public viewOnly: boolean;
  public LABEL_OPTIONS: IFormDropdownOption[] = [];
  private destroyed$ = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: InputData,
    private dialogRef: MatDialogRef<CareLabelDetailComponent>,
    private formBuilder: FormBuilder,
    public careprogramService: CareProgramService,
    public carelabelService: CareLabelService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.careLabelForm = this.careLabelBuildForm();
    if (this.data.careLabel) {
      this.careLabelForm.patchValue(this.data.careLabel);
    }
    if (this.viewOnly) this.careLabelForm.disable();
    this.codeSubscription();
    this.nameSubscription();
    this.dateSubscription();
    this.carelabelService.filterCareLabelValue(this.registrationDate);

    this.viewOnly = this.data.viewOnly;
  }

  dateSubscription() {
    this.careprogramService.careProgramForm
      .get('registrationDate')
      .valueChanges.subscribe((date) => {
        this.carelabelService.filterCareLabelValue(date);
        this.careLabelForm.reset();
      });
  }

  private nameSubscription() {
    this.nameControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((name) => {
        if (name) {
          const careLabelOption = this.carelabelService.careLabels.find(
            (carelabel) => carelabel.label === name,
          );
          this.codeControl.setValue(careLabelOption.value, {
            emitEvent: false,
          });
          this.startDateControl.setValue(careLabelOption.startDate, {
            emitEvent: false,
          });
          this.endDateControl.setValue(careLabelOption.endDate, {
            emitEvent: false,
          });
        } else {
          this.startDateControl.setValue(null, { emitEvent: false });
          this.endDateControl.setValue(null, { emitEvent: false });
          this.codeControl.setValue(null, { emitEvent: false });
          this.careLabelForm.markAsTouched();
        }
      });
  }

  private codeSubscription() {
    this.codeControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((code) => {
        if (code) {
          const careLabelOption = this.carelabelService.careLabels.find(
            (carelabel) => carelabel.value === code,
          );
          this.nameControl.setValue(careLabelOption.label, {
            emitEvent: false,
          });
          this.startDateControl.setValue(careLabelOption.startDate, {
            emitEvent: false,
          });
          this.endDateControl.setValue(careLabelOption.endDate, {
            emitEvent: false,
          });
        } else {
          this.startDateControl.setValue(null, { emitEvent: false });
          this.endDateControl.setValue(null, { emitEvent: false });
          this.nameControl.setValue(null, { emitEvent: false });
          this.careLabelForm.markAsTouched();
        }
      });
  }

  onConfirm() {
    if (this.careLabelForm.valid) {
      this.dialogRef.close(this.careLabelForm.getRawValue());
    } else this.careLabelForm.markAllAsTouched();
  }

  onDismiss() {
    this.dialogRef.close(null);
  }

  getMaxDate(date?: moment.Moment): moment.Moment | null {
    if (date) {
      return moment(date).clone().subtract(1, 'days');
    }
  }

  getMinDate(date?: moment.Moment): moment.Moment {
    if (date) return moment(date).clone().add(1, 'days');
    else return null;
  }
  careLabelBuildForm(){
    return this.formBuilder.group({
      startDate: [{ value: null, disabled: true }, Validators.required],
      endDate: [{ value: null, disabled: true }],
      careLabelItem: this.formBuilder.group({
        name: [null, Validators.required],
        code: [null, Validators.required],
      }),
    });
  }
}
