import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
  Optional,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AddressTypeService } from '../../services/referent/address-type/address-type.service';
import { NumberDesignationService } from '../../services/referent/number-designation/number-designation.service';
import {Validators, FormBuilder, FormGroup, Form} from '@angular/forms';
import {
  DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT,
  PATTERN,
} from '../../form-controls';
import { AddressModel } from '../../models/address/address.model';
import { ModelUtils } from '../../utilities/model.utils';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css'],
})
export class AddressComponent implements OnInit, OnDestroy {


  addressForm: FormGroup= null!;
  private readonly destroyed$ = new Subject<boolean>();
  autofilledFields = [
    'city',
    'country',
    'streetName',
    'municipality',
    'addition',
    'identifier',
  ];

  @Input() inline = false;
  @Output() addressSelected$ = new EventEmitter<AddressModel>();
  @Input() parentFormGroup: FormGroup;

  constructor(
    public addressTypeService: AddressTypeService,
    public numberDesignationService: NumberDesignationService,
    @Optional() public dialogRef: MatDialogRef<AddressComponent>,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('environment') public environment: any,
  ) {}

  ngOnInit(): void {
    this.addressForm = this.addressBuildForm();
    if (this.data && this.data.address) {
      this.addressForm.patchValue(this.data.address);
    }
    if (this.parentFormGroup) {
      this.parentFormGroup.registerControl('address', this.addressForm);
    }
    this.initAutofillSubscription();
    if (this.inline) this.initAddressSelectedSubscription();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public onConfirm(): void {
    if (this.addressForm.invalid) {
      this.addressForm.markAllAsTouched();
    } else {
      const address: AddressModel = { ...this.addressForm.value };
      address.fullStreetName = ModelUtils.getCompleteAddresss(address);

      this.dialogRef.close(address);
    }
  }

  public onDismiss(): void {
    this.dialogRef.close(null);
  }

  public initAddressSelectedSubscription() {
    this.addressForm.valueChanges
      .pipe(
        debounceTime(DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT),
        takeUntil(this.destroyed$),
      )
      .subscribe((address) => {
        if (this.addressForm.valid) this.addressSelected$.emit(address);
      });
  }

  public initAutofillSubscription() {
    const postalCodeControl = this.addressForm.get('postalCode');
    const houseNumberControl = this.addressForm.get('houseNumber');
    combineLatest([
      postalCodeControl.valueChanges,
      houseNumberControl.valueChanges,
    ])
      .pipe(
        debounceTime(DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT),
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
      )
      .subscribe(([postCode, houseNumber]) => {
        if (this.addressForm.get('postalCode').valid) {
          this.getAddressFromPostCode(postCode, houseNumber).subscribe(
            (data) => {
              this.autofilledFields.forEach((field) => {
                if (data) this.addressForm.get(field).setValue(data[field]);
              });
              // different key in response, for now we will skip this
              // this.addressForm.get('addition').setValue(data.identifier);
            },
            (error) => {
              this.autofilledFields.forEach((field) => {
                this.addressForm.get(field).reset();
              });
            },
          );
        }
      });
  }

  public getAddressFromPostCode(postalCode: string, houseNumber: number) {
    // Why do we have HTTP call out of component instead of service?
    const httpHeaders: HttpHeaders = new HttpHeaders({
      origin_path: '/addresses',
    });
    return this.http.get<any>(
      `${this.environment.addressManagementApiUrl}?postalCode=${postalCode}&houseNumber=${houseNumber}`,
      {
        headers: httpHeaders,
      },
    );
  }
  addressBuildForm(){
    return  this.formBuilder.group({
      id: [],
      addressType: [null, Validators.required],
      startDate: [],
      endDate: [],
      identifier: [],
      postalCode: [
        null,
        [Validators.required, Validators.pattern(PATTERN.postalCode)],
      ],
      houseNumber: [
        null,
        [Validators.required, Validators.pattern(PATTERN.numbersOnly)],
      ],
      streetName: [
        null,
        [Validators.required, Validators.pattern(PATTERN.stringDot)],
      ],
      houseLetter: [null, Validators.pattern(PATTERN.string)],
      addition: [null, Validators.pattern(PATTERN.stringAndNumbers)],
      designation: [],
      city: [null, [Validators.required, Validators.pattern(PATTERN.string)]],
      municipality: [
        null,
        [Validators.required, Validators.pattern(PATTERN.string)],
      ],
      country: [null, [Validators.required, Validators.pattern(PATTERN.string)]],
    });
  }
}
