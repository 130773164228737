import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PageMenuService } from '../../services/page-menu/page-menu-service.service';
import { IMenuItems } from './IMenuItems';
import { IAdditionalMenu } from './IAdditionalMenu';

@Component({
  selector: 'app-page-menu',
  templateUrl: './page-menu.component.html',
  styleUrls: ['./page-menu.component.scss'],
})
export class PageMenuComponent implements OnInit, OnDestroy {
  @Input() items: IMenuItems[];
  menuItemsSubscription: Subscription;
  additionalMenuSubscription: Subscription;

  menuItems: IMenuItems[] = [];
  inlineItems: IMenuItems[] = [];
  additionalMenus: IAdditionalMenu[] = [];

  constructor(private pageMenuService: PageMenuService) {}

  ngOnInit(): void {
    this.menuItemsSubscription = this.pageMenuService.menuItems$
      .pipe(debounceTime(0))
      .subscribe((menuItems) => {
        this.items = menuItems;
        this.inlineItems = [];
        this.menuItems = [];
        let i = 0;
        if (this.items)
          for (let item of this.items) {
            if (i < 3) this.inlineItems.push(item);
            else this.menuItems.push(item);
            i++;
          }
      });

    this.additionalMenuSubscription = this.pageMenuService.additionalMenus$
      .pipe(debounceTime(0))
      .subscribe((menus) => {
        this.additionalMenus = menus;
      });
  }

  ngOnDestroy(): void {
    if (this.menuItemsSubscription) this.menuItemsSubscription.unsubscribe();
    if (this.additionalMenuSubscription)
      this.additionalMenuSubscription.unsubscribe();
  }
}
