import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls/form-controls.const';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceTypeService {
  public values: IFormDropdownOption[] = [];
  public lookupValues: string[] = [];

  constructor(private masterDataService: MasterDataService) {
    this.getLaws();
  }

  public getLaws() {
    this.masterDataService.getMasterData('invoice-types').subscribe((data) => {
      this.values = data;
      for (let item of this.values) {
        this.lookupValues[item.value] = item.label;
      }
    });
  }
}
