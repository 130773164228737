import { OnInit, TemplateRef } from '@angular/core';
import { Directive, OnDestroy } from '@angular/core';
import { TopBarService } from '../services/top-bar/top-bar.service';

@Directive({
  selector: '[appTopBar]',
})
export class TopBarDirective implements OnInit, OnDestroy {
  constructor(
    public templateRef: TemplateRef<any>,
    private topBarService: TopBarService,
  ) {}

  ngOnInit(): void {
    this.topBarService.setTemplate(this.templateRef);
  }

  ngOnDestroy(): void {
    this.topBarService.setTemplate(null);
  }
}
