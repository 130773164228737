<div class="c4p-question-set-input-small" style="word-wrap: break-word">
  <span *ngIf="required" class="c4p-question-label">{{
    '* ' + orderNum + '. ' + placeholder
  }}</span>
  <span *ngIf="!required" class="c4p-question-label">{{
    orderNum + '. ' + placeholder
  }}</span>
  <span
    *ngIf="hint && !isClientPortal"
    tippy
    [tippyOptions]="{
      content: hint,
      arrow: true,
      allowHTML: true,
      placement: 'right-end',
      theme: 'light',
      maxWidth: '350px',
      interactive: true
    }"
  >
    <mat-icon class="c4p-hint-icons">info</mat-icon>
  </span>
</div>
<div class="c4p-question-set-input" style="width: 160px">
  <mat-form-field>
    <input
      [errorStateMatcher]="errorStateMatcher"
      [ngxMatDatetimePicker]="dateTimePicker"
      matInput
      [min]="minDate"
      [max]="maxDate"
      [(ngModel)]="value"
      placeholder=""
      [required]="required"
      (blur)="onTouchedCallback()"
      (keydown)="onKeydown($event)"
      maxlength="16"
      (dblclick)="setNow()"
      #datePickerInput
      #valueInput="ngModel"
      [disabled]="disabled"
      (keydown.Tab)="onTab(datePickerInput)"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="dateTimePicker"
    ></mat-datepicker-toggle>
    <ngx-mat-datetime-picker
      [showSeconds]="showSeconds"
      [stepHour]="stepHour"
      [stepMinute]="stepMinute"
      [defaultTime]="defaultTime"
      #dateTimePicker
    >
    </ngx-mat-datetime-picker>
    <!--mat-error *ngFor="let error of valueInput.errors | keyvalue">
      <span
        *ngIf="
          errorLabels && errorLabels[error.key];
          else defaultValueControlErrors
        "
      >
        {{ errorLabels[error.key] | translate }}
      </span>
      <ng-container
        #defaultValueControlErrors
        *ngIf="DEFAULT_DATEPICKER_ERRORS.includes(error.key)"
      >
        <span>{{ DEFAULT_ERRORS.get(error.key) | translate }}&nbsp;</span>
        <span *ngIf="error.key === 'matDatetimePickerMin'">{{
          error.value?.min | appDate
        }}</span>
        <span *ngIf="error.key === 'matDatetimePickerMax'">{{
          error.value?.max | appDate
        }}</span>
      </ng-container>
    </mat-error-->
    <mat-error *ngFor="let error of ngControl.errors | keyvalue">
      <span
        *ngIf="
          errorLabels && errorLabels[error.key];
          else defaultNgControlErrors
        "
      >
        {{ errorLabels[error.key] | translate }}
      </span>
      <ng-container
        #defaultNgControlErrors
        *ngIf="DEFAULT_ERRORS.has(error.key)"
      >
        <span>{{ DEFAULT_ERRORS.get(error.key) | translate }}&nbsp;</span>
      </ng-container>
    </mat-error>
  </mat-form-field>
</div>
