import * as moment from 'moment';

//@dynamic
export class DateUtils {
  public static UTC_LIKE_TIMEZONE = 'Africa/Accra';
  public static  DATE_TIME_FORMAT_WITHOUT_TZ = "yyyy-MM-DD HH:mm:ss";
  public static dateRegex =
    /^(?:(\d{2})\/(\d{2})\/(\d{4})|(\d{2})\/(\d{2})|(\d{2})|(\d{2})\/(\d{4}))$/;

  public static getCurrentUTCDayMoment(): moment.Moment {
    return moment().tz(DateUtils.UTC_LIKE_TIMEZONE).startOf('day');
  }
  public static getCurrentUTCDayDate(): Date {
    return DateUtils.getCurrentUTCDayMoment().toDate();
  }

  public static getCurrentTenantDayMoment(): moment.Moment {
    let tenantTimeZone = JSON.parse(localStorage.getItem('tenantTimeZone'));
    const timeDiff = moment().tz(tenantTimeZone).utcOffset() / 60;
    return moment()
      .tz(DateUtils.UTC_LIKE_TIMEZONE)
      .add(timeDiff, 'hours')
      .startOf('day');
  }
  public static getCurrentTenantDayDate(): Date {
    return DateUtils.getCurrentTenantDayMoment().toDate();
  }

  public static getCurrentTimestampMoment(): moment.Moment {
    return moment();
  }
  public static getCurrentTimestampDate(): Date {
    return new Date();
  }
  public static filterByDate(
    startDate: Date | string,
    endDate: Date | string,
    date: Date | string,
    startTimeWithDate?: Date,
    endTimeWithDate?: Date,
  ): boolean {
    if (!date) return true;
    if (typeof startDate === 'string') startDate = new Date(startDate);
    if (typeof endDate === 'string') endDate = new Date(endDate);
    if (typeof date === 'string') date = new Date(date);
    if (startTimeWithDate && endTimeWithDate)
      return (
        (startDate <= startTimeWithDate && !endDate) ||
        (startDate <= startTimeWithDate && endTimeWithDate <= endDate)
      );
    return (
      (startDate <= date && !endDate) || (startDate <= date && date <= endDate)
    );
  }

  public static getTenantTimeZone() {
    return JSON.parse(localStorage.getItem('tenantTimeZone'));
  }

  public static updateTimeToTenantTimeZone(
    date: moment.Moment,
    time: string,
  ): moment.Moment {
    const [startHour, startMinute] = time.split(':');

    return moment(date)
      .tz(DateUtils.getTenantTimeZone())
      .set({
        hour: parseInt(startHour),
        minute: parseInt(startMinute),
      });
  }

  public static isValidDateFormat(dateString: string): boolean {
    return this.dateRegex.test(dateString);
  }

  public static transformDateForSearch(dateString: string): string {
    return dateString?.split('/')?.reverse()?.join('.');
  }


  public static changeDateToBrowserTimezone(momentDate) {
    // Date picker is using CustomMomentDateAdapter and it is converting the date wrong way, we will use this function to fix it
    if (!momentDate) {
      return null;
    }

    if (moment.isMoment(momentDate)) {
      const temp = momentDate.format(this.DATE_TIME_FORMAT_WITHOUT_TZ);
      return new Date(temp).toString(); // it is converting according to Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    return momentDate;
  }
}
