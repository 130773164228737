export class CilCalculation {
  tariff: number;
  totalIncludedVat: number;
  vatAmount: number;
  vatPercentage: number;
  total: number;
}

export class VatTariff {
  id: string;
  name: string;
  value: string;
  percentage: number;
}
