import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IFormDropdownOption } from '../../../form-controls/form-controls.const';
import { MasterDataService } from '../../master-data/master-data.service';
import { DEPRECATED_FREQUENCY_4_WEEKS } from '../../../utilities';

@Injectable({
  providedIn: 'root',
})
export class FrequencyService {
  // public values: IFormDropdownOption[] = [];
  public lookupValues: string[] = [];

  public get values() {
    return this.values$.value;
  }
  public get filteredValues() {

    return this.values$?.value?.filter(
      (item) => item?.value !== DEPRECATED_FREQUENCY_4_WEEKS,
    );
  }

  public values$ = new BehaviorSubject<IFormDropdownOption[]>(null);

  constructor(private masterDataService: MasterDataService) {
    this.getFrequencies();
  }

  public getFrequencies() {
    this.masterDataService.getMasterData('frequency').subscribe((data) => {
      data.sort((a, b) => {
        return a.value - b.value;
      });
      this.values$.next(data);
      for (let item of this.values) {
        this.lookupValues[item.value] = item.label;
      }
    });
  }
}
