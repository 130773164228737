import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DiaryWeeklyPreviewModel } from '../../models/diary/diary-weekly-preview.model';
import { DiaryModel } from './../../models/diary/diary.model';

@Injectable({
  providedIn: 'root',
})
export class DiaryService {
  constructor(
    private http: HttpClient,
    @Inject('environment') public environment: any,
  ) {}

  public getDiaryByPreviewDate(
    carefileId: string,
    previewDate: Date,
  ): Observable<DiaryModel> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/diary/answers/:carefile_id',
      diary_preview_date: previewDate.toString(),
    });
    const params = new HttpParams().set('carefileId', carefileId);
    return this.http.get<DiaryModel>(
      `${this.environment.questionsetManagementApiUrl}/diary/answers/${carefileId}`,
      { headers, params },
    );
  }

  public updateDiaryQuestionSetAnswer(
    diaryAnswers: DiaryModel,
  ): Observable<DiaryModel> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/diary/answers/:diary_id',
    });

    let params = new HttpParams();
    if (diaryAnswers.carefileId)
      params = params
        .set('carefile_id', diaryAnswers.carefileId)
        .set('carefileId', diaryAnswers.carefileId);

    return this.http.put<DiaryModel>(
      `${this.environment.questionsetManagementApiUrl}/diary/answers/${diaryAnswers.id}`,
      diaryAnswers,
      {
        headers,
        params,
      },
    );
  }

  public getDiaryWeeklyPreview(carefileId: string, previewDate: Date) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/diary/week-preview/:carefile_id',
      preview_date: previewDate.toString(),
    });
    const params = new HttpParams().set('carefileId', carefileId);
    return this.http.get<DiaryWeeklyPreviewModel>(
      `${this.environment.questionsetManagementApiUrl}/diary/week-preview/${carefileId}`,
      {
        headers,
        params,
      },
    );
  }
}
