import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DashboardInterface } from './dashboard.interface';
import * as Muuri from 'muuri';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DEFAULT_CONTROL_DEBOUNCE_TIME_AMOUNT } from '../../form-controls/form-controls.const';

export class DashboardModel {
  id: string;
  name: string;
  description: string;
  dashboard_type: string;
  default: boolean;
  owner: number;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DashboardInterface],
})
export class DashboardComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  @Input() page: string;
  @Output() interface = new EventEmitter<DashboardInterface>();

  private readonly destroyed$ = new Subject<boolean>();

  widgets: any[] = [];
  private grid: any;
  private needRefresh = false;
  private needRecreate = false;
  private startDragPosition: number;
  private endDragPosition: number;

  constructor(
    private dashboardInterface: DashboardInterface,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.initInterface();
    this.interface.emit(this.dashboardInterface);
  }

  ngAfterViewInit(): void {
    this.createGrid();
  }

  ngAfterViewChecked() {
    if (this.needRefresh) {
      this.grid.refreshItems().layout(() => {
        this.needRefresh = false;
      });
    }

    if (this.needRecreate) {
      this.needRecreate = false;
      this.grid.destroy();
      this.createGrid();
      this.needRefresh = true;
    }
  }

  ngOnDestroy() {
    this.grid.destroy();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private initInterface(): void {
    this.dashboardInterface.widgets$
      .pipe(
        debounceTime(DEFAULT_CONTROL_DEBOUNCE_TIME_AMOUNT),
        takeUntil(this.destroyed$),
      )
      .subscribe((val) => {
        this.widgets = val;
        this.needRecreate = true;
        this.dashboardInterface.save();
      });
  }

  private createGrid(): void {
    this.grid = new Muuri.default('.grid', {
      items: '.item',
      dragEnabled: false,
      layoutOnResize: true,
      layoutDuration: 0,
      showDuration: 0,
      layout: {
        fillGaps: false,
        rounding: false,
      },
      layoutOnInit: true,
    });
    this.grid
      .on('dragStart', (item) => {
        const items = this.grid.getItems();
        this.startDragPosition = item._element.attributes['widget-index'].value;
      })
      .on('dragEnd', (item) => {
        const items = this.grid.getItems();
        this.endDragPosition = items.indexOf(item);

        if (this.startDragPosition != this.endDragPosition)
          this.reorderWidgets();
      });
  }

  private reorderWidgets(): void {
    const tmpWidget = this.widgets[this.startDragPosition];
    this.widgets.splice(this.startDragPosition, 1);
    this.widgets.splice(this.endDragPosition, 0, tmpWidget);

    for (let i = 0; i < this.widgets.length; i++) this.widgets[i].position = i;

    this.dashboardInterface.save();
  }

  onWidgetResize(size: string): void {
    this.needRefresh = true;
    this.dashboardInterface.save();
  }

  onWidgetRecreate() {
    this.needRecreate = true;
  }

  onWidgetRefresh(): void {
    this.needRefresh = true;
  }
}
