import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PermissionService } from '../../services';
import { ObjectListComponent } from '../object-list/object-list.component';
import { ITableOption } from '../table-view/table-view.component';

@Component({
  selector: 'app-card-view-note',
  templateUrl: './card-view-note.component.html',
  styleUrls: ['./card-view-note.component.scss'],
})
export class CardViewNoteComponent implements OnInit {
  @Input() options: ITableOption = null;
  @Input() data: any;

  firstPart: any[] = [];
  secondPart: any[] = [];
  thirdPart: any[] = [];
  get menuActions(): any[] {
    return this.options?.menuActions ? this.options.menuActions : [];
  }
  constructor(
    private dialog: MatDialog,
    private permissionsService: PermissionService,
  ) {}

  ngOnInit(): void {
    for (let field of this.options.columns) {
      if (field.position && field.position == 'second') {
        this.secondPart.push(field);
      } else if (field.position === 'third') {
        this.thirdPart.push(field);
      } else {
        this.firstPart.push(field);
      }
    }
    this.initMenuItems();
  }

  showObjects(data: any[]) {
    const dialogRef = this.dialog.open(ObjectListComponent, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
      }
    });
  }

  private initMenuItems() {
    if (this.menuActions) {
      for (let action of this.menuActions) {
        if (action.permission) {
          action.hasPermission = this.permissionsService.getPermission(
            action.permission,
            action.permissionOperator,
          );
        } else {
          action.hasPermission = true;
        }
      }
    }
  }
}
