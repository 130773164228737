export interface IMainSubMenuItem {
    name: string;
    route: string;
    permission?: string;
    hasPermission?: boolean;
    icon?: string;
    order?: number;
    permissionOperator?: 'or' | 'and';
}

export interface IMainMenuItem {
    portal?: string;
    name: string;
    icon: string;
    items: IMainSubMenuItem[];
    permissions?: Array<string>;
    hasPermission?: boolean;
    permissionOperator?: 'or' | 'and';
    route?: string;
}
