import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls/form-controls.const';
import { ModelUtils } from '../../../utilities/model.utils';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class AssessmentTypeService {
  public values: IFormDropdownOption[] = [];
  public lookupValues: string[] = [];

  public filteredValues: IFormDropdownOption[];

  constructor(private masterDataService: MasterDataService) {
    this.getAssessmentTypes();
  }

  public filterAssessmentTypes(search: string) {
    this.filteredValues = ModelUtils.searchInArray(
      this.values,
      search,
      'value',
    );
  }

  public getAssessmentTypes() {
    this.masterDataService
      .getMasterData('assessment-type')
      .subscribe((data) => {
        this.values = data;
        for (let item of this.values) {
          this.lookupValues[item.value] = item.label;
        }
        this.filteredValues = data;
      });
  }
}
