import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SideSheetButtonEvent, SideSheetButtonListener } from '../../../models';
import { SideSheetService } from '../../../services';

@Component({
  selector: 'app-side-sheet-example2',
  templateUrl: './side-sheet-example2.component.html',
  styleUrls: ['./side-sheet-example2.component.scss']
})
export class SideSheetExample2Component implements OnInit, OnDestroy, SideSheetButtonListener {
  form: FormGroup = this.fb.group({
    name: new FormControl(''),
    testName: new FormControl(''),
  });

  eventText = "No event";

  constructor(
    private fb: FormBuilder,
    private sideSheetService : SideSheetService
  ) {
    console.log("ExampleComponent constructor");
  }
 
  ngOnInit() {
    console.log("ExampleComponent ngOnInit");
  }


  ngOnDestroy(): void {
    console.log("ExampleComponent ngOnDestroy");
  }

  onClickButton(event: SideSheetButtonEvent): void {
    this.eventText = event?.button?.name + " is clicked"
    this.sideSheetService.closeComponent({name: 'Example 2 send action type = ' + event?.button?.actionType});
  }
}
