import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
import { ToastrService } from 'ngx-toastr';
import { LanguageService } from '../language/language.service';
import { PermissionService } from '../permissions/permission.service';
import { LoadingProgressService } from '../loading-progress/loading-progress.service';

@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(
    private keycloakService: KeycloakService,
    private toastr: ToastrService,
    private languageService: LanguageService,
    private permissionService: PermissionService,
    private loadingProgressService: LoadingProgressService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let currentRole = this.permissionService.currentRole || '';

    const clonedRequest = request.clone({
      headers: request.headers
        .set('CurrentRole', currentRole)
        .set('X-C4P-CID', this.generateRandomValue())
        .set('Accept-Language', this.languageService.value)
        .set(
          'local_time_zone',
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        )
        .set(
          'Cache-Control',
          'no-store, no-cache, must-revalidate, proxy-revalidate',
        )
        .set('Pragma', 'no-cache')
        .set('Expires', '0')
        .set('Surrogate-Control', 'no-store'),
      withCredentials: true,
    });

    let loading = false;
    return next.handle(clonedRequest).pipe(
      tap(() => {
        if (!loading) {
          this.loadingProgressService.start();
          loading = true;
        }
      }),
      catchError((err) => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.keycloakService.logout();

          const error = err.error.message || err.statusText;
        } else {
          if (err.error instanceof Blob) {
            err.error.text().then((res) => {
              const blobError = JSON.parse(res);
              this.toastr.error(blobError.message, 'Error');
            });
          } else if (err.error?.message)
            this.toastr.error(err.error.message, 'Error');
          else if (err.error) this.toastr.error(err.error, 'Error');
          else if (err.message) this.toastr.error(err.message, 'Error');
        }
        return throwError(err);
      }),
      finalize(() => loading && this.loadingProgressService.stop()),
    );
  }

  private generateRandomValue(): string {
    const alphaNums =
      '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';

    for (let i = 0; i < 16; i++) {
      const rand = Math.random();
      const index = Math.floor(rand * alphaNums.length);
      result += alphaNums.substr(index, 1);
    }

    return result;
  }
}
