import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { MasterDataService } from '../../..';

@Injectable({
  providedIn: 'root',
})
export class Dsm5DiagnosisService {
  public filteredDsm5 = new BehaviorSubject<any[]>([]);
  public dSM5Diagnosis = [];

  get filteredDsm5Values() {
    return this.filteredDsm5.value;
  }

  constructor(private masterDataService: MasterDataService) {
    this.getDsm5Diagnosis();
  }

  public getDsm5Diagnosis() {
    this.masterDataService
      .getMasterData('dsm5-diagnosis', true)
      .subscribe((data) => {
        this.dSM5Diagnosis = data.map((dSM5Diagnosis) => dSM5Diagnosis.value);
        this.filteredDsm5.next(this.dSM5Diagnosis);
      });
  }

  public filterDsm5DiagnosisValue(startDiagnosisDate, endDiagnosisDate) {
    if (!endDiagnosisDate) {
      endDiagnosisDate = '9999-12-31';
    }
    const filteredOptions = this.dSM5Diagnosis.filter(
      (value) =>
        moment(startDiagnosisDate).isSameOrAfter(moment(value.startDate)) &&
        moment(startDiagnosisDate).isSameOrBefore(
          moment(value.endDate ? value.endDate : '9999-12-31'),
        ) &&
        moment(endDiagnosisDate).isSameOrAfter(moment(value.startDate)) &&
        moment(endDiagnosisDate).isSameOrBefore(
          moment(value.endDate ? value.endDate : '9999-12-31'),
        ),
    );
    this.filteredDsm5.next(filteredOptions);
  }
}
