import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CategoryModel } from '../../models/category.model';
import { BehaviorSubject } from 'rxjs';
import { Inject } from '@angular/core';

@Injectable()
export class CategoryService {
  public categories$ = new BehaviorSubject<CategoryModel[]>(null);
  public selectedCategory$ = new BehaviorSubject<CategoryModel>(null);

  private readonly categoryUrl =
    this.environment.documentManagementApiUrl + '/categories';

  constructor(
    public http: HttpClient,
    @Inject('environment') private environment,
  ) {}

  public getCategories(search?: string) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/categories',
    });
    let params = new HttpParams();
    if (search) params = params.set('search', search);
    return this.http.get<CategoryModel[]>(this.categoryUrl, {
      headers,
      params,
    });
  }

  public deleteCategory(categoryNumber: number | string) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/categories/:category_id',
      entity_id: categoryNumber.toString(),
    });
    return this.http.delete<any>(`${this.categoryUrl}/${categoryNumber}`, {
      headers,
    });
  }

  public getCategory(id: number | string) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/categories/:category_id',
      entity_id: id.toString(),
    });
    return this.http.get<CategoryModel>(`${this.categoryUrl}/${id}`, {
      headers,
    });
  }

  public updateCategory(category: CategoryModel) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/categories/:category_id',
      entity_id: category.id,
    });
    return this.http.put<CategoryModel>(
      `${this.categoryUrl}/${category.id}`,
      category,
      { headers },
    );
  }

  public createCategory(category: CategoryModel) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/categories',
    });
    return this.http.post<CategoryModel>(`${this.categoryUrl}`, category, {
      headers,
    });
  }
}
