import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-object-list',
  templateUrl: './object-list.component.html',
  styleUrls: ['./object-list.component.scss'],
})
export class ObjectListComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() title: string;
  @Input() objectProperty = 'name';
  @Output() selectItem = new EventEmitter();
  values: string[] = [];
  constructor(private eRef: ElementRef, private translate: TranslateService) {}

  ngOnInit(): void {
    if (this.data) {
      if (this.objectProperty === 'none') this.values = this.data;
      else
        this.values = this.data?.map((x) => {
          return x[this.objectProperty ? this.objectProperty : 'name'];
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      if (this.objectProperty === 'none') this.values = this.data;
      else
        this.values = changes.data.currentValue.map((x) => {
          return x[this.objectProperty ? this.objectProperty : 'name'];
        });
    }
  }

  selectItemHandler() {
    this.selectItem.emit();
  }
}
