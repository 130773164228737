import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls/form-controls.const';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({ providedIn: 'root' })
export class OccupationCategoryService {
  public values: IFormDropdownOption[] = [];
  public lookupValues: string[] = [];
  public occupationCategories = [];

  constructor(private masterDataService: MasterDataService) {
    this.getOccupationCategories();
  }

  public getOccupationCategories() {
    this.masterDataService
      .getMasterData('occupation-categories', true)
      .subscribe((data) => {
        this.occupationCategories = data.map((occupationCategory) => {
          return {
            code: occupationCategory.value.value,
            ...occupationCategory.value,
          };
        });
        this.values = data.map((occupationCategory) => {
          return {
            value: {
              code: occupationCategory.value.value,
              ...occupationCategory.value,
            },
            label: occupationCategory.label,
          };
        });
        for (let item of this.values) {
          this.lookupValues[item.value.id] = item.label;
        }
      });
  }
}
