import { SignatureInfo } from './signature.info';

export class SimpleSignInput {
  tenantId: string;
  documentName!: string;
  url!: string;
  source!: string;
  signatureInfo: SignatureInfo;
  addHeader: boolean;
  addFooter: boolean;
}
