import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { CommentService } from './comment.service';
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter, SimpleChanges,
} from '@angular/core';
import { FormatCleanerService } from 'c4p-portal-util';
import { CommentModel } from '../../models/comment/comment.model';
import { UserInfoService } from 'c4p-portal-util';
import { UpdateTimeByUtils } from '../../utilities';
import {PermissionService} from 'c4p-portal-util';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit, OnDestroy {
  @Input() entity: string;
  @Input() entityId: string;
  @Input() isReadOnly = false;
  @Input() isSideSheet = false;
  @Input() refreshPage: boolean = false;
  @Output() noComments = new EventEmitter();
  @Output() updateTime = new EventEmitter<any>();
  @Output() sideSheetCommentFormat = new EventEmitter<any>();


  createCommentForm: FormGroup;
  commentsForm: FormGroup = null!;
  get commentFormArray(): FormArray {
    return this.commentsForm.get('comment') as FormArray;
  }
  placeCommentEnabled: boolean = false;

  constructor(
    public formatCleanerService: FormatCleanerService,
    private commentService: CommentService,
    private fb: FormBuilder,
    private userInfoService: UserInfoService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.commentsForm = this.commentsBuildForm();
    this.createCommentForm = this.fb.group({
      comment: [null, Validators.required],
      entity: [this.entity],
      entityId: [this.entityId],
    });
    this.initComments();
  }
  ngOnDestroy(): void {}


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.refreshPage) {
      this.initComments();
    }
  }

  initComments() {
    this.commentService
      .getComments(this.entity, this.entityId)
      .subscribe((res: CommentModel[]) => {
        const updateAt = res.map((item) => ({
          updatedAt: new Date(item?.updatedAt) ?? null,
          updatedBy: item?.updatedBy?.fullName ?? null,
        }));

        this.updateTime.emit(UpdateTimeByUtils.getLatestObj(updateAt));

        res.length > 0 && this.noComments.emit(true);
        this.commentFormArray.clear();
        res.forEach((comment, ind) => {
          if (
            ((this.hasCreatePermission() &&
              comment.createdBy.id === this.userInfoService.userId) ||
              this.hasManagePermission()) &&
            ind === 0
          ) {
            comment.isEditable = true;
          } else {
            comment.isEditable = false;
          }
          this.commentFormArray.push(this.buildCommentFormGroup(comment));
        });
      });
  }

  placeComment() {
    if (this.placeCommentEnabled) {
      let comment = { ...this.createCommentForm.getRawValue() };
      if (this.userInfoService.avatar) {
        comment.avatar = this.userInfoService.avatar;
      }
      if (this.entity === 'topic') {
        comment.readBy = this.commentService.prepareReadBy(this.entity);
      }
      this.commentService.createComment(comment).subscribe((comment) => {
        const comments = this.commentFormArray.controls.reverse();
        if (comments.length > 0)
          this.commentFormArray
            .at(comments.length - 1)
            .get('isEditable')
            .patchValue(false);
        this.commentFormArray.push(this.buildCommentFormGroup(comment));
        this.commentFormArray.controls.reverse();
        this.initComments();
      });
    }
    this.placeCommentEnabled = !this.placeCommentEnabled;
    this.createCommentForm.get('comment').reset();
  }

  focusComment(control: FormGroup) {
    let comment = control.get('comment').value;
    let isRichTextText = !control.get('focusRichText').value;

    control.get('focusRichText').patchValue(isRichTextText);

    if (!isRichTextText) {
      control.get('comment').patchValue(control.get('previousComment').value);
    } else control.get('previousComment').patchValue(comment);
  }

  editComment(control: FormGroup) {
    this.commentService
      .updateComment(control.getRawValue())
      .subscribe((comment) => {
        control.patchValue({ ...comment, focusRichText: false });
      });
    this.initComments();
  }

  deleteComment(comment: CommentModel, index: any): void {
    if (this.entity === 'report') {

      if (this.isSideSheet) {
        this.sideSheetCommentFormat.emit({
          data: comment.id,
          entity: this.entity,
          entityId: this.entityId.toString(),
          entityVersion: '1',
          isSideSheet: this.isSideSheet ? 'true' : '',
        });
      }

    } else {
      this.deleteCommentNoneSheet(comment, index);
    }

  }
  deleteCommentNoneSheet(comment: CommentModel, index: any) {
    this.commentService
      .deleteComment(comment.id, comment.entity, comment.entityId, this.isSideSheet ? 'true' : '')
      .subscribe(() => {
        this.commentFormArray.removeAt(index);
        const comments = this.commentFormArray.getRawValue();
        if (comments.length > 0) {
          const previousComment = comments[0];
          if (
            (this.hasCreatePermission() &&
              previousComment.createdBy.id === this.userInfoService.userId) ||
            this.hasManagePermission()
          ) {
            this.commentFormArray.at(0).get('isEditable').patchValue(true);
          }
        }
      });
    this.initComments();
  }

  private buildCommentFormGroup(comment: CommentModel) {
    const formGroup = this.fb.group({
      id: [comment.id],
      fullName: [comment.createdBy.fullName],
      createdBy: [comment.createdBy],
      updatedAt: [comment.updatedAt ? comment.updatedAt : comment.createdAt],
      comment: [comment.comment ? comment.comment : ''],
      entity: [comment.entity],
      entityId: [comment.entityId],
      status: [comment.status],
      avatar: [comment.avatar],
      isEditable: [comment.isEditable],
      previousComment: [comment.comment],
      focusRichText: [false],
    });
    return formGroup;
  }
  discardComment() {
    this.placeCommentEnabled = false;
    this.createCommentForm.get('comment').reset();
  }

  wordFormat() {
    this.createCommentForm
      .get('comment')
      .setValue(
        this.formatCleanerService.cleanUpHtml(
          this.createCommentForm.get('comment').value,
        ),
        { emitEvent: false },
      );
  }
  onEditorCreated(event) {
    event.root.focus();
  }
  commentsBuildForm(){
    return this.fb.group({
      comment: this.fb.array([]) as FormArray,
    });
  }
  hasManagePermission() {
    return this.permissionService.getPermission('comment:manage','or');
  }
  hasCreatePermission() {
    return this.permissionService.getPermission('comment:create','or');
  }
}
