import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-time-period',
  templateUrl: './time-period.component.html',
  styleUrls: ['./time-period.component.scss'],
})
export class TimePeriodComponent implements OnInit {
  // Label for time period input
  @Input() label: string;

  // Start time, should be in hh:mm format
  @Input() startTime: string;

  // End time, should be in hh:mm format
  @Input() endTime: string;

  // Mandatory or not, false by default
  @Input() mandatory: boolean = false;

  // This event emits new value for start time
  @Output() startTimeChange = new EventEmitter();

  // This event emits new value for end time
  @Output() endTimeChange = new EventEmitter();

  // Event emits on every value change; it is true if the value is not valid
  @Output() onInvalid = new EventEmitter();

  // Validate observable in case we want to initiate validation from extern compoent
  @Input() validate: any;

  public value: string;

  formGroup: FormGroup;
  subscription: Subscription;
  validationSubscription: Subscription;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    if (this.startTime && this.endTime)
      this.value = this.startTime + '-' + this.endTime;
    let validations = [];

    if (this.mandatory) {
      validations.push(Validators.required);
    }

    this.formGroup = this.buildForm(validations);

    this.subscription = this.formGroup
      .get('field')
      .valueChanges.subscribe((val) => {
        this.onChange(val);
        this.onInvalid.emit(this.formGroup.controls['field'].invalid);
      });

    this.formGroup.controls['field'].setValue(this.value);

    if (this.validate) {
      this.validationSubscription = this.validate.subscribe((val) => {
        if (val) {
          this.formGroup.controls['field'].markAsTouched();
          this.formGroup.controls['field'].updateValueAndValidity();
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.validationSubscription) this.validationSubscription.unsubscribe();
  }

  onChange(value) {
    this.value = value;
    if (value) {
      let fromTime = value.slice(0, 5);
      let toTime = value.slice(6, 11);

      this.startTimeChange.emit(fromTime);
      this.endTimeChange.emit(toTime);
    }
  }
  buildForm(validations: any){
    return  this.formBuilder.group({
      field: [null, validations],
    });
  }
}
