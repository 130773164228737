import { InstructionModel } from './instruction.model';

export class GatekeeperModel {
  id!: string;
  tenantId!: string;
  title!: string;
  resource!: string;
  action!: string;
  services: { id: string; name: string }[];
  method: string;
  endpoint: string;
  requireConfirmation!: boolean;
  requireReason: boolean;
  detail: InstructionModel[];
  status!: string | boolean;
  updatedAt: Date;
}
