import { Injectable, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TopBarService {
  private topBarTemplate$$ = new Subject<TemplateRef<any> | null>();
  public topBarTemplate$ = this.topBarTemplate$$.pipe(debounceTime(0));

  constructor() {}

  setTemplate(template?: TemplateRef<any>) {
    this.topBarTemplate$$.next(template);
  }
}
