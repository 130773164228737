import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IMainMenuItem } from '../../models';
import { ClientInfoService, SidebarService, UserInfoService } from '../../services';
import { Subscription } from 'rxjs';

export enum NavbarMode {
  EXPANDED = 'navbar-expanded',
  COLLAPSED = 'navbar-collapsed',
  OPENED = 'navbar-mobile-opened',
  CLOSED = 'navbar-mobile-closed',
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent implements OnInit, OnDestroy {

  @Output() navbarModeChanged = new EventEmitter<NavbarMode>();
  @Input() navBarMode!: NavbarMode;

  private subscription: Subscription = new Subscription();

  previousNavbarModeBeforeMobile: NavbarMode | null = null;

  mainMenuItems;
  selectedMenuItem: IMainMenuItem | null = null;

  userInfo;

  media: any;

  constructor(
    private sidebarService: SidebarService,
    private clientInfoService: ClientInfoService,
    private userInfoService: UserInfoService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.sidebarService.mainMenuItems$.subscribe((mainMenuItems) => {
          this.mainMenuItems = mainMenuItems;
        },
      )
    );

    this.subscription.add(
      this.userInfoService.userInfo$.subscribe((userInfo) => {
          this.userInfo = userInfo;
        },
      )
    );

    var style = getComputedStyle(document.body)
    const mediaVal = style.getPropertyValue('--navbar-mobile-breakpoint');
    this.media = window.matchMedia(`(max-width: ${mediaVal})`)

    this.onMediaChange();
    this.media.addEventListener("change", () => this.onMediaChange())
  }

  ngOnDestroy(): void {
    this.media.removeEventListener("change", () => this.onMediaChange());
    this.subscription.unsubscribe();
  }

  onMediaChange() {
    if (this.media.matches) {
      this.previousNavbarModeBeforeMobile = this.navBarMode;
      this.setNavBarMode(NavbarMode.CLOSED);
    } else {
      this.setNavBarMode(this.previousNavbarModeBeforeMobile ?? this.navBarMode);
      this.previousNavbarModeBeforeMobile = null;
    }
  }

  onClickExpandCollapse() {
    this.setNavBarMode(this.navBarMode === NavbarMode.EXPANDED ? NavbarMode.COLLAPSED : NavbarMode.EXPANDED);
  }

  onBackdropClick() {
    this.setNavBarMode(NavbarMode.CLOSED);
  }

  onMenuItemClicked(event: IMainMenuItem) {
    this.selectedMenuItem = this.selectedMenuItem?.name !== event?.name ? event : null;
  }

  setNavBarMode(newNavBarMode: NavbarMode) {
    this.navbarModeChanged.emit(newNavBarMode);
  }

  get clientInitialized(): boolean {
    if (this.userInfoService.isClientPortal)
      return this.clientInfoService.getClientSession() ? true : false;
    else return true;
  }

}
