<div class="c4p-form-input-big">
  <span *ngIf="!editable" class="c4p-avatar-input">
    <img *ngIf="avatar" class="c4p-avatar" src="{{ avatar }}" />
    <ngx-avatars *ngIf="!avatar" name="{{ value }}" size="80" bgColor="#A0A0A0">
    </ngx-avatars>
  </span>

  <span *ngIf="editable" class="c4p-avatar-input-edit">
    <img
      *ngIf="avatar"
      class="c4p-avatar"
      src="{{ avatar }}"
      (click)="onAvatarClick()"
    />
    <ngx-avatars
      *ngIf="!avatar"
      name="{{ value }}"
      size="80"
      bgColor="#A0A0A0"
      (click)="onAvatarClick()"
    >
    </ngx-avatars>
  </span>

  <span
    style="
      position: relative;
      font-size: 10px;
      top: 24px;
      left: -80px;
      width: 80px;
      text-align: center;
      color: white;
      text-transform: uppercase;
      text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
    "
    >{{ status }}</span
  >
</div>
