import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'carefiles',
  },
  {
    path: 'carefiles',
    data: {
      breadcrumbLabel: '',
      verticalIndex: 0,
    },
    loadChildren: () =>
      import('../client-base/client-base.module').then(
        (m) => m.ClientBaseModule,
      ),
  },
  {
    path: 'exercises',
    data: {
      breadcrumbLabel: 'clientPortal.menu.Exercises',
      verticalIndex: 1,
    },
    loadChildren: () =>
      import('../exercises/exercises.module').then((m) => m.ExercisesModule),
  },
  {
    path: 'diary',
    data: {
      breadcrumbLabel: 'clientPortal.menu.Diary',
      verticalIndex: 1,
    },
    loadChildren: () =>
      import('../diary/diary.module').then((m) => m.DiaryModule),
  },
  {
    path: 'reports',
    data: {
      breadcrumbLabel: 'carefile.menu.Reports',
      verticalIndex: 1,
    },
    loadChildren: () =>
      import('../reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'files',
    data: {
      breadcrumbLabel: 'application.overview.Files',
      verticalIndex: 1,
    },
    loadChildren: () =>
      import('../documents/documents.module').then((m) => m.DocumentsModule),
  },
  {
    path: 'topics',
    data: {
      breadcrumbLabel: 'carefile.menu.Messages',
      verticalIndex: 1,
    },
    loadChildren: () =>
      import('../topics/topics.module').then((m) => m.TopicsModule),
  },
  {
    path: 'hours',
    data: {
      verticalIndex: 1,
    },
    loadChildren: () =>
      import('../hours/hours.module').then((m) => m.HoursModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
