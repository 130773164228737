<div id="navbar" class="navbar" class="{{navBarMode}}">

  <div class="navbar-top">
    <div class="navbar-avatar-container">
      <ngx-avatars
        [name]="userInfo?.fullName"
        src="assets/svg/logo.svg"
        class="navbar-avatar"></ngx-avatars>
    </div>

      <span class="navbar-chevron-button" (click)="onClickExpandCollapse()">{{navBarMode === 'navbar-expanded' ? '&lt;' : '&gt;'}}</span>
    
  </div>
  <div class="navbar-content">
    <ul *ngIf="clientInitialized">
      <ng-container *ngFor="let item of mainMenuItems">
        <app-navbar-menu-item *ngIf="item.hasPermission" [menuItem]="item" [selectedMenuItemName]="selectedMenuItem?.name" (menuItemClicked)="onMenuItemClicked($event)"></app-navbar-menu-item>
      </ng-container>
    </ul>
  </div>
  <div class="navbar-bottom"></div>

</div>

<div class="navbar-backdrop" *ngIf="navBarMode === 'navbar-mobile-opened'" (click)="onBackdropClick()"></div>
