import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';
import { MasterDataService } from '../../master-data/master-data.service';
import { APPLICATION_TYPES } from '../../../../../../c4p-portal-employee/src/app/application/const/application-types.const';

@Injectable({
  providedIn: 'root',
})
export class ApplicationTypeService {
  public values: IFormDropdownOption[] = [
    { value: '1', label: 'Application Type 1' },
    { value: '2', label: 'Application Type 2' },
    { value: '3', label: 'Application Type 3' },
    { value: '4', label: 'Application Type 4' },
  ];

  constructor(private masterDataService: MasterDataService) {
    this.getApplicationTypes();
  }

  public getApplicationTypes() {
    this.masterDataService
      .getMasterData('application-types')
      .subscribe((data) => {
        this.values = data;
      });
  }

  getFilteredApplicationTypes(type: string){
    return type !== APPLICATION_TYPES.ONLINE ?  this.values.filter((value) => value.value !== APPLICATION_TYPES.ONLINE) : this.values;
  }
}
