import {
  Component,
  Input,
  DoCheck,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from '@angular/core';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { DateUtils, FormUtils } from '../../utilities';
import {
  DEFAULT_TIME_SHOW_SPINNERS,
  DEFAULT_TIME_SHOW_SECONDS,
  DEFAULT_TIME_HOUR_STEP,
  DEFAULT_TIME_MINUTE_STEP,
  TIME_REGEX,
} from '../form-controls.const';
import { FormInputDateTimeComponent } from '../form-input-date-time/form-input-date-time.component';

@Component({
  selector: 'app-form-input-time',
  templateUrl: './form-input-time.component.html',
  styleUrls: ['./form-input-time.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormInputTimeComponent
  extends FormInputDateTimeComponent
  implements DoCheck
{
  @Input() showSpinners = DEFAULT_TIME_SHOW_SPINNERS;
  @Input() showSeconds = DEFAULT_TIME_SHOW_SECONDS;
  @Input() stepHour = DEFAULT_TIME_HOUR_STEP;
  @Input() stepMinute = DEFAULT_TIME_MINUTE_STEP;
  @Input() min: string;
  @Input() max: string;

  public adjustedTimeValue = null;
  onKeydown = TIME_REGEX;

  @ViewChild('timepickerInput') timepickerInput: ElementRef;

  ngOnInit() {
    super.ngOnInit();
    this.valueControl.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe((value) => {});
  }

  ngDoCheck(): void {
    this.adjustTouched();
  }

  public onTimeTouchedCallback(timepickerInput: any) {
    if(timepickerInput.value === ""){
      return;
    }
    let timeValue: string =
      this.stepMinute === 5
        ? FormUtils.roundTimeToNearestTenMinutes(timepickerInput.value)
        : timepickerInput.value;
    if (!timeValue) return; // skip empty
    let fixedTime: string =
      this.stepMinute === 5
        ? FormUtils.roundTimeToNearestTenMinutes(timeValue)
        : '';
    // check if valid time string
    if (timeValue.match(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)) {
      const [hours, minutes] = timeValue.split(':');
      const selectedTime = moment().set({ hours: +hours, minutes: +minutes });
      const roundDuration = moment.duration(this.stepMinute, 'minutes');

      //check if rounded correctly
      const lastCharacter = timeValue.slice(-1);
      if (
        this.stepMinute === 5 &&
        !(lastCharacter == '0' || lastCharacter == '5')
      ) {
        const rounded = this.round(selectedTime, roundDuration, 'ceil').format(
          'HH:mm',
        );

        fixedTime = rounded;
      }

      // check minmax value
      if (this.min) {
        const [minHours, minMinutes] = this.min.split(':');
        const minTime = moment()
          .set({ hours: +minHours, minutes: +minMinutes })
          .add(this.stepMinute, 'minutes');
        if (selectedTime.isBefore(minTime)) {
          fixedTime = minTime.format('HH:mm');
        }
      }
      if (this.max) {
        const [maxHours, maxMinutes] = this.max.split(':');
        const maxTime = moment()
          .set({ hours: +maxHours, minutes: +maxMinutes })
          .subtract(this.stepMinute, 'minutes');
        if (selectedTime.isAfter(maxTime)) {
          fixedTime = maxTime.format('HH:mm');
        }
      }
    } else {
      fixedTime = FormUtils.roundTimeToNearestTenMinutes(timeValue);
      //fixedTime = '00:00';
    }
    if (fixedTime) {
      setTimeout(() => {
        this.adjustedTimeValue = fixedTime;
        this.valueControl.setValue(fixedTime);
      });
    }
    this.onTouchedCallback();
  }

  writeValue(value) {
    if (this.timepickerInput) this.timepickerInput.nativeElement.value = value;
    this.valueControl.setValue(value, { emitEvent: false });
    this.adjustedTimeValue = value;
  }

  public setNow() {
    const now = DateUtils.getCurrentTenantDayMoment();
    const roundDuration = moment.duration(this.stepMinute, 'minutes');
    const rounded = this.round(now, roundDuration, 'ceil');
    this.valueControl.setValue(rounded.format('HH:mm'));
  }

  private round(
    date: moment.Moment,
    duration: moment.Duration,
    method: 'floor' | 'ceil',
  ) {
    return moment(Math[method](+date / +duration) * +duration);
  }

  private nearestMinutes(interval, someMoment) {
    const roundedMinutes =
      Math.round(someMoment.clone().minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
  }
}
