<app-panel type="popup-large">
  <div class="c4p-form-title c4p-flex-title">
    <span class="c4p-align-left">
      <span class="c4p-horizontal-divider"></span>
      <span *ngIf="data.required">{{
        '* ' + data.orderNum + '. ' + data.placeholder
      }}</span>
      <span *ngIf="!data.required">{{
        data.orderNum + '. ' + data.placeholder
      }}</span>
      <span class="c4p-horizontal-divider"></span>
      <span
        *ngIf="data.hint && !data.isClientPortal"
        tippy
        [tippyOptions]="{
          content: data.hint,
          arrow: true,
          allowHTML: true,
          placement: 'right-end',
          theme: 'light',
          maxWidth: '350px',
          interactive: true
        }"
      >
        <mat-icon class="c4p-hint-icons">info</mat-icon>
      </span>
    </span>
  </div>
  <ng-container [formGroup]="form">
    <app-panel-form-row mode="-questions" @fadeAnimation>
      <app-multi-line
        *ngIf="data.type === 'multiline'"
        class="question-text-area"
        formControlName="answer"
        name="answer"
        [placeholder]="data.placeholder"
        [orderNum]="data.orderNum"
        [hint]="data.hint"
        [isClientPortal]="data.isClientPortal"
        [fullView]="fullView"
      ></app-multi-line>
      <div *ngIf="data.type === 'richtext'" class="c4p-question-set-input">
        <quill-editor
          placeholder=""
          formControlName="answer"
          (onEditorCreated)="editorInit($event)"
        ></quill-editor>
      </div>
    </app-panel-form-row>
  </ng-container>
</app-panel>

<!-- buttons -->
<div style="display: flex">
  <span class="c4p-button-cancel" (click)="onDismiss()" translate
    >general.labels.Cancel</span
  >
  <span class="c4p-button-ok" *ngIf="isInputValid()" (click)="onConfirm()">
    <span>OK</span>
  </span>
</div>
