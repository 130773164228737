import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DropdownServiceBase } from '../../../services/dropdown-service.abstract';
import { CareFileModel } from '../../../models/carefile/carefile.model';
import { ClientModel } from '../../../models/client';
import { PaginationDataModel } from '../../../models/pagination/pagination-data.model';
import { HttpUtils } from '../../../utilities/http.utils';
import { PaginationResultModel } from '../../../models/pagination/pagination-result.model';

@Injectable({
  providedIn: 'root',
})
export class ClientOverviewService extends DropdownServiceBase<ClientModel> {
  public selectedCarefileId$ = new BehaviorSubject<any>(null);
  public clients$ = new BehaviorSubject<{
    data: ClientModel[];
    count: number;
  }>(null);

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
  ) {
    super();
    this.setupDropdown(this.getClients);
  }

  public getClients(paginationData: PaginationDataModel, filterData?: any) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/clients',
    });
    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );
    return this.http.get<PaginationResultModel<ClientModel>>(
      this.environment.clientManagementApiUrl,
      { params, headers },
    );
  }

  public getCarefileDetail(clientId: string): Observable<CareFileModel> {
    if (!clientId) return of(null);
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles',
      client_id: clientId.toString(),
      entity_type: 'Carefile',
      entity_id: clientId.toString(),
    });

    return this.http.get<CareFileModel>(
      `${this.environment.carefileManagementApiUrl}/client/${clientId}`,
      { headers },
    );
  }
}
