import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IFormDropdownOption } from '../../../form-controls';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class MentorStatusService {
  public lookupValues: string[] = [];
  public values$ = new BehaviorSubject<IFormDropdownOption[]>(null);

  public get values() {
    return this.values$.value;
  }

  constructor(private masterDataService: MasterDataService) {
    this.getMentorStatusValues();
  }

  public getMentorStatusValues() {
    this.masterDataService.getMasterData('mentor-status').subscribe((data) => {
      this.values$.next(data);
      for (let item of this.values) {
        this.lookupValues[item.value] = item.label;
      }
    });
  }
}
