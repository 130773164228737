<div
  class="c4p-widget-{{ widget.size }}"
  [ngStyle]="{ 'width.px': width, 'height.px': height !== null ? 431 : '' }"
  (mouseenter)="showTitle()"
  (mouseleave)="hideTitle()"
>
  <div class="c4p-widget-title" *ngIf="visibleTitle">
    <div>
      <a matTooltip="{{ 'general.labels.Size' | translate }}">
        <mat-icon
          matPrefix
          [matMenuTriggerFor]="menu"
          [matMenuTriggerData]=""
          class="c4p-widget-icons"
          >view_quilt</mat-icon
        >
      </a>
      <a
        *ngIf="widget.size != 'Fit'"
        (click)="resize('Fit')"
        matTooltip="{{ 'general.labels.Fit' | translate }}"
      >
        <mat-icon matPrefix class="c4p-widget-icons">open_in_full</mat-icon>
      </a>
      <a
        *ngIf="widget.size == 'Fit'"
        (click)="resize(previousSize)"
        matTooltip="{{ 'general.labels.Back' | translate }}"
      >
        <mat-icon matPrefix class="c4p-widget-icons">close_fullscreen</mat-icon>
      </a>
    </div>
  </div>
  <div class="c4p-widget-content">
    <ng-template widgetHost></ng-template>
  </div>
</div>

<mat-menu #menu="matMenu" class="c4p-menu">
  <ng-template matMenuContent let-name="name" let-rowIndex="rowIndex">
    <div *ngFor="let action of sizes">
      <div mat-menu-item class="c4p-menu-item" (click)="resize(action)">
        <mat-icon *ngIf="action == widget.size" class="c4p-action-menu-icon"
          >check_circle</mat-icon
        >
        <mat-icon
          *ngIf="action != widget.size"
          class="c4p-action-menu-icon"
        ></mat-icon>
        <span class="c4p-menu-title">{{ sizeNames[action] | translate }}</span>
      </div>
    </div>
  </ng-template>
</mat-menu>
