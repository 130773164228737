import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';
import { MasterDataService } from '../../master-data/master-data.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RelationService {
  public values: IFormDropdownOption[] = [];
  public lookupValues: string[] = [];

  constructor(
    private masterDataService: MasterDataService,
    private translate: TranslateService,
  ) {
    this.getRelationValues().subscribe();
  }

  public getRelationValues() {
    return this.masterDataService.getMasterData('relation').pipe(map((data) => {
        this.values = data;
        this.lookupValues[0] = 'masterData.relationType.organization';
        for (let item of this.values) {
          this.lookupValues[item.value] = this.translate.instant(item.label);
          item.translation = this.lookupValues[item.value];
        }
        this.changeLanguage();
        this.values.sort((a, b) =>
          a.translation !== b.translation
            ? a.translation < b.translation
              ? -1
              : 1
            : 0,
        );
        return this.values;
      }),
    );
  }

  public changeLanguage() {
    this.lookupValues[0] = 'masterData.relationType.organization';
    for (let item of this.values) {
      this.lookupValues[item.value] = this.translate.instant(item.label);
      item.translation = this.lookupValues[item.value];
    }
    this.values.sort((a, b) =>
      a.translation !== b.translation
        ? a.translation < b.translation
          ? -1
          : 1
        : 0,
    );
  }
}
