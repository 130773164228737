import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaginationDataModel } from '../../../models/pagination/pagination-data.model';
import { PaginationResultModel } from '../../../models/pagination/pagination-result.model';
import {
  BasicCareProgramModel,
  ClientCareProgramModel,
  CompleteCareProgramModel,
} from '../models';
import { CareProgramHttpService } from './care-program-http.service';
import { ClientOverviewService } from './client-overview.service';
import * as moment from 'moment';
import { DateUtils } from '../../../utilities';

@Injectable({
  providedIn: 'root',
})
export class CareProgramService {
  public selectedCareProgram$ = new BehaviorSubject<
    BasicCareProgramModel | CompleteCareProgramModel
  >(null);
  public confirmTriggered$ = new BehaviorSubject<boolean>(false);
  public confirmClicked$ = new BehaviorSubject<boolean>(false);
  public carePrograms$ = new BehaviorSubject<{
    data: BasicCareProgramModel[] | CompleteCareProgramModel[];
    count: number;
  }>(null);

  public resetCareprogramData() {
    this.careProgramForm.reset();
    (this.careProgramForm.get('therapists') as FormArray).clear();
    (this.careProgramForm.get('careLabel') as FormArray).clear();
    (this.careProgramForm.get('dSM5Diagnosis') as FormArray).clear();
    (this.careProgramForm.get('basicGGZProfile') as FormArray).clear();
    (this.careProgramForm.get('careTypeDeterminationGGZ') as FormArray).clear();
    this.selectedCareProgram$.next(null);
    this.confirmTriggered$.next(false);
    this.clientOverviewService.selectedCarefileId$.next({
      carefileId: null,
      initValue: true,
    });
  }

  public careProgramForm = this.formBuilder.group({
    id: [null],
    status: [{ value: null, disabled: true }, Validators.required],
    careProgramId: [null],
    carefileId: [null],
    client: [null, Validators.required],
    services: [null, Validators.required],
    defaultSetting: [null,Validators.required],
    privacy: [null],
    zpmType: ['GGZ'], // TODO: For now this iz default zpm type, but in future we will have more.
    registrationDate: [
      DateUtils.getCurrentTimestampDate(),
      Validators.required,
    ],
    endDate: [],
    therapists: this.formBuilder.array([]),
    fundingStream: ['1', Validators.required],
    careLabel: this.formBuilder.array([]),
    restitutionStartDate: [null],
    restitutionEndDate: [null],
    basicGGZProfile: this.formBuilder.array([]),
    dSM5Diagnosis: this.formBuilder.array([]),
    careTypeDeterminationGGZ: this.formBuilder.array([]),
    referral: this.formBuilder.group({
      referrer: this.formBuilder.group({
        careOrgName: [null],
        careProfessionalName: this.formBuilder.group({
          firstName: [null],
          initials: [null],
          givenName: [null],
          additions: [null],
          lastName: [null],
        }),
        agbCode: [null, [Validators.minLength(8), Validators.maxLength(8)]],
        careProviderType: [null],
        careProviderSpec: [null],
        professionCareProfessional: [null],
        careProviderRole: [null],
        dateOfReferral: [null],
        referralType: [null, Validators.required],
        referralFiles: [null],
      }),
    }),
    hasReferrer: [null],
    createdAt: [],
  });

  constructor(
    private formBuilder: FormBuilder,
    private careProgramHttpService: CareProgramHttpService,
    private clientOverviewService: ClientOverviewService,
  ) {}

  getCarePrograms(
    paginationData: PaginationDataModel,
    requestFrom: string,
    filterData?: any,
  ): Observable<
    PaginationResultModel<BasicCareProgramModel | CompleteCareProgramModel>
  > {
    return this.careProgramHttpService.getCarePrograms(
      paginationData,
      requestFrom,
      filterData,
    );
  }

  createCareProgram(
    entityType: any,
    carefileId?: string,
  ): Observable<BasicCareProgramModel | CompleteCareProgramModel> {
    return this.careProgramHttpService.createCareProgram(
      entityType,
      carefileId,
    );
  }

  getCareProgram(
    careProgramId: string,
    requestFrom: string,
    entityType: any,
  ): Observable<BasicCareProgramModel | CompleteCareProgramModel> {
    return this.careProgramHttpService.getCareprogram(
      careProgramId,
      requestFrom,
      entityType,
    );
  }

  searchClientsWhenNoCarePrograms(
    paginationData: PaginationDataModel,
    requestFrom: string,
    filterData?: any,
  ): Observable<PaginationResultModel<ClientCareProgramModel>> {
    return this.careProgramHttpService.searchClientsWhenNoCarePrograms(
      paginationData,
      requestFrom,
      filterData,
    );
  }

  createCareprogramFromFilters(
    client: ClientCareProgramModel,
  ): Observable<BasicCareProgramModel> {
    return this.careProgramHttpService.createCareprogramFromFilters(client);
  }

  public updateCareprogram(
    careprogram: BasicCareProgramModel | CompleteCareProgramModel,
    requestFrom: string,
    entityType: any,
  ): Observable<BasicCareProgramModel | CompleteCareProgramModel> {
    return this.careProgramHttpService.updateCareprogram(
      careprogram,
      requestFrom,
      entityType,
    );
  }

  public deleteCareprogram(
    careprogramId: string,
    requestFrom: string,
    entityType: string,
  ) {
    return this.careProgramHttpService.deleteCareprogram(
      careprogramId,
      requestFrom,
      entityType,
    );
  }

  checkActiveTherapist(startDate: Date, endDate: Date) {
    const newEndDate = endDate
      ? endDate
      : startDate
      ? new Date('2999/12/31')
      : null;
    const compareDate = DateUtils.getCurrentTenantDayMoment();
    return compareDate.isBetween(startDate, newEndDate, 'days', '[]');
  }
}
