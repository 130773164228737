import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../form-controls';
import { MasterDataService } from '../master-data/master-data.service';

@Injectable({ providedIn: 'root' })
export class LocationMasterDataService {
  public values: IFormDropdownOption[] = [];

  constructor(private masterDataService: MasterDataService) {
    this.getLocations();
  }

  public getLocations() {
    this.masterDataService.getMasterData('locations').subscribe((data) => {
      this.values = data;
    });
  }
}
