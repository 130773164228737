import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { CareFileModel } from '../../models/carefile/carefile.model';
import { PaginationDataModel } from '../../models/pagination/pagination-data.model';
import { HttpUtils } from '../../utilities/http.utils';
import { ReadByModel } from '../../models/comment/read-by.model';
import { DocumentModel } from '../../document';

@Injectable({
  providedIn: 'root',
})
export class CarefileService {
  public carefiles$ = new BehaviorSubject<{
    data: CareFileModel[];
    count: number;
  }>(null);

  constructor(
    private http: HttpClient,
    @Inject('environment') public environment: any,
  ) {}

  public getCarefiles(paginationData: PaginationDataModel, filterData?: any) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles',
    });
    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );
    return this.http.get<any>(`${this.environment.carefileManagementApiUrl}`, {
      headers,
      params,
    });
  }

  public getCarefileById(carefileId: any, tenantId: any) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: `/carefiles/:carefile_id`,
      tenant_id: tenantId,
      entity_type: 'Carefile',
      entity_id: carefileId,
    });
    return this.http.get<any>(
      `${this.environment.carefileManagementApiUrl}/${carefileId}`,
      { headers },
    );
  }

  public markAsRead(carefileId: string, readBy: ReadByModel) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles/:carefile_id/mark-as-read/:entity/:entity_id',
      entity_type: 'document',
      entity_id: carefileId,
    });
    return this.http.put<ReadByModel>(
      `${this.environment.carefileManagementApiUrl}/${carefileId}/mark-as-read/${readBy.entity}/${readBy.entityId}`,
      readBy,
      {
        headers,
      },
    );
  }

  public markAllAsRead(
    carefileId: string,
    employeeId: string,
    fullName: string,
    paginationData: PaginationDataModel,
    filterData?: any,
  ) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/files/:carefile_id/mark-all-as-read',
      entity_type: 'document',
      entity_id: carefileId,
    });

    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );
    return this.http.post<any>(
      `${this.environment.carefileManagementApiUrl}/files/${carefileId}/mark-all-as-read`,
      { carefileId, employee: { id: employeeId, fullName } },
      { headers, params },
    );
  }

  public markResourceAsRead(data: DocumentModel, entity: string) {
    if (data.status === 'active') {
      const readBy = new ReadByModel();
      readBy.entityId = data.id;
      readBy.entity = entity;
      readBy.tenantId = data.tenantId;
      return this.markAsRead(data.carefileId, readBy);
    }
  }
}
