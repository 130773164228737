import {
  Component,
  OnInit,
  Input,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AvatarEditComponent } from '../avatar-edit/avatar-edit.component';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  // Full name
  @Input() value: string = '';

  // Base64 image
  @Input() avatar: string = '';
  @Output() avatarChange = new EventEmitter();

  @Input() customAvatar: string = '';
  @Output() customAvatarChange = new EventEmitter();

  @Input() providerAvatar: string = '';

  @Input() useCustomAvatar: boolean = false;
  @Output() useCustomAvatarChange = new EventEmitter();

  @Output() onChange = new EventEmitter();

  // Status, possible values are active, inactive, draft, deleted, open, closed.
  // Every value will be presented in a different color (any new value should be added to style.sccs as .c4p-status-icon-newvalue)
  @Input() status: string = '';

  @Input() editable: boolean = true;

  showEdit: boolean = false;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  public onAvatarClick(): void {
    const dialogRef = this.dialog.open(AvatarEditComponent, {
      data: {
        avatar: this.avatar,
        customAvatar: this.customAvatar,
        providerAvatar: this.providerAvatar,
        useCustomAvatar: this.useCustomAvatar,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.avatar = res.avatar;
        this.avatarChange.emit(this.avatar);

        this.customAvatar = res.customAvatar;
        this.customAvatarChange.emit(this.customAvatar);

        this.useCustomAvatar = res.useCustomAvatar;
        this.useCustomAvatarChange.emit(this.useCustomAvatar);

        this.onChange.emit();
      }
    });
  }
}
