<app-panel
  [formGroup]="categoryForm"
  type="popup"
  style="overflow: hidden; max-width: 465px"
>
  <app-panel-title title="document.labels.Category"></app-panel-title>

  <app-panel-form-row>
    <app-form-input-text
      formControlName="name"
      placeholder="document.labels.Category"
    ></app-form-input-text>
  </app-panel-form-row>

  <app-panel-form-row>
    <ng-container
      *ngFor="let control of functionsFormArray.controls; let i = index"
      [formGroup]="control"
    >
      <app-form-input-checkbox
        style="padding-top: 10px"
        [placeholder]="control.value.name"
        formControlName="checked"
      ></app-form-input-checkbox>
    </ng-container>
  </app-panel-form-row>
</app-panel>

<div style="display: flex">
  <span class="c4p-button-cancel" (click)="dialogRef.close()" translate
    >general.labels.Cancel</span
  >
  <span class="c4p-button-ok" (click)="submit()">
    <span *ngIf="categoryForm.valid">OK</span>
  </span>
</div>
