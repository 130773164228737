import { PermissionService } from '../permissions/permission.service';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MismatchAnswer } from '../../models/mismatch-answer/mismatch-answer.model';

@Injectable({
  providedIn: 'root',
})
export class MismatchAnswersService {
  constructor(
    private http: HttpClient,
    @Inject('environment') public environment: any,
    private permissionService: PermissionService,
  ) {}

  public getMismatchAnswers(
    entity: string,
    entityId: string | number,
    entityVersion: number,
  ) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path:
        '/questionset-answers/:entity/:entity_id/:entity_version/mismatch',
      current_role: this.permissionService.currentRole,
      entity_type: entity,
      entity_id: entityId.toString(),
      sub_entity_type: 'QuestionSetAnswers',
    });

    return this.http.get<any>(
      `${this.environment.questionsetManagementApiUrl}/questionset-answers/${entity}/${entityId}/${entityVersion}/mismatch`,
      {
        headers,
      },
    );
  }

  public updateMismatchAnswer(mismatchAnswer: MismatchAnswer) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path:
        '/questionset-answers/:entity/:entity_id/:entity_version/mismatch',
      current_role: this.permissionService.currentRole,
      entity_type: this.getEnumKeyForValue(mismatchAnswer.entityType),
      entity_id: mismatchAnswer.entityId,
      sub_entity_type: 'QuestionSetAnswers',
    });

    return this.http.patch<any>(
      `${this.environment.questionsetManagementApiUrl}/questionset-answers/${mismatchAnswer.entityType}/${mismatchAnswer.entityId}/${mismatchAnswer.entityVersion}/mismatch`,
      mismatchAnswer,
      {
        headers,
      },
    );
  }

  private getEnumKeyForValue(value: number): string {
    switch (value) {
      case 1:
        return 'Application';
      case 2:
        return 'Intake';
      case 3:
        return 'Careplan';
      case 4:
        return 'Report';
      case 5:
        return 'Evaluation';
      default:
        return '';
    }
  }
}
