import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IFormDropdownOption } from '../form-controls/form-controls.const';
import { PaginationDataModel } from '../models/pagination/pagination-data.model';
import { PaginationResultModel } from '../models/pagination/pagination-result.model';

export declare type PaginationFunction<T> = (
  paginationData: PaginationDataModel,
  filterData?: any,
  requestFrom?: string,
) => Observable<PaginationResultModel<T>>;

export interface DrodownServiceConfig {
  labelProperty: string | string[];
  valueProperty?: string;
  separator?: string;
  objectAsValue?: boolean;
}

export abstract class DropdownServiceBase<T> {
  public values: IFormDropdownOption[] | T[] = [];
  public filteredValues: IFormDropdownOption[] | T[] = [];

  public filterParams: any = {};
  public paginationParams: PaginationDataModel = null;

  public requestFrom: string = '';

  private paginationFunction: PaginationFunction<T>;

  public filter(search: string): Observable<any[]> {
    return this.getData(search);
  }

  protected setupDropdown(paginationFunction: PaginationFunction<T>) {
    this.paginationFunction = paginationFunction;

    //this.getData();
  }

  private getData(search?: string) {
    let pagination = PaginationDataModel.dropdownPagination();
    if (this.paginationParams) pagination = this.paginationParams;

    let filter = search ? { search } : null;
    filter = { ...filter, ...this.filterParams };
    return this.paginationFunction(pagination, filter, this.requestFrom).pipe(
      map((data) => {
        return data.docs.slice();
      }),
    );
  }

  public retreiveMissingValue(id: string, searchItem = 'id') {
    return this.paginationFunction(
      PaginationDataModel.dropdownPagination(null, null, 99999),
      null,
      this.requestFrom,
    ).pipe(
      map((paginationResult) => {
        return paginationResult.docs.find((doc) => {
          return doc[searchItem] === id;
        });
      }),
    );
  }
}
