<nav
  mat-tab-nav-bar [tabPanel]="tabPanel"
  [ngClass]="{ 'nav-invalid': validationResults !== null }"
  [disablePagination]="true"
>
  <ng-container *ngFor="let link of navLinks">
    <a
      *ngIf="!link.hidden"
      [ngClass]="{
        'tab-invalid': validationResults ? validationResults[link.path] : false
      }"
      [routerLink]="link.path"
      #rla="routerLinkActive"
      [active]="rla.isActive"
      mat-tab-link
      routerLinkActive
    >
      <span
        [ngClass]="{
          'tab-text-invalid': validationResults
            ? validationResults[link.path]
            : false
        }"
      >
        {{ link.breadcrumbLabel | translate }}
      </span>
    </a>
  </ng-container>
</nav>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
