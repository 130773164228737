import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class QuestionTypeService {
  public values: IFormDropdownOption[] = [];
  public lookupValues: string[] = [];

  constructor(private masterDataService: MasterDataService) {
    this.getQuestionTypesValues();
  }

  public getQuestionTypesValues() {
    this.masterDataService.getMasterData('question-types').subscribe((data) => {
      this.values = data;
      for (const item of this.values) {
        this.lookupValues[item.value] = item.label;
      }
    });
  }
}
