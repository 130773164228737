<div class="c4p-form-input">
  <mat-form-field>
    <mat-label>{{ placeholder | translate }}</mat-label>

    <input
      [errorStateMatcher]="errorStateMatcher"
      [ngxMatDatetimePicker]="dateTimePicker"
      matInput
      [min]="minDate"
      [max]="maxDate"
      [(ngModel)]="value"
      [required]="required"
      (blur)="onTouchedCallback()"
      (keydown)="onKeydown($event)"
      maxlength="16"
      (dblclick)="setNow()"
      #datePickerInput
      #valueInput="ngModel"
      [disabled]="disabled"
      (change)="valueChangeHandler($event)"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="dateTimePicker"
    ></mat-datepicker-toggle>
    <ngx-mat-datetime-picker
      [showSeconds]="showSeconds"
      [stepHour]="stepHour"
      [stepMinute]="stepMinute"
      [defaultTime]="defaultTime"
      #dateTimePicker
    >
      <ngx-mat-datepicker-actions>
        <button
          mat-button
          style="border: 1px solid rgba(0, 0, 0, 0.12)"
          ngxMatDatepickerCancel
        >
          <span class="mat-button-wrapper"
            ><mat-icon
              role="img"
              class="mat-icon notranslate ng-tns-c291-642 material-icons mat-icon-no-color ng-star-inserted"
              aria-hidden="true"
              data-mat-icon-type="font"
              >cancel</mat-icon
            ></span
          >
        </button>
        <button
          mat-button=""
          mat-stroked-button
          ngxMatDatepickerApply
          cdkfocusinitial=""
          class="mat-focus-indicator ng-tns-c291-642 mat-button mat-stroked-button mat-button-base"
          ng-reflect-disabled="false"
        >
          <span class="mat-button-wrapper"
            ><mat-icon
              role="img"
              class="mat-icon notranslate ng-tns-c291-642 material-icons mat-icon-no-color ng-star-inserted"
              aria-hidden="true"
              data-mat-icon-type="font"
              >done</mat-icon
            ></span
          ><span
            matripple=""
            class="mat-ripple mat-button-ripple"
            ng-reflect-disabled="false"
            ng-reflect-centered="false"
            ng-reflect-trigger="[object HTMLButtonElement]"
          ></span
          ><span class="mat-button-focus-overlay"></span>
        </button>
      </ngx-mat-datepicker-actions>
    </ngx-mat-datetime-picker>

    <!--mat-error *ngFor="let error of valueInput.errors | keyvalue">
      <span
        *ngIf="
          errorLabels && errorLabels[error.key];
          else defaultValueControlErrors
        "
      >
        {{ errorLabels[error.key] | translate }}
      </span>
      <ng-container
        #defaultValueControlErrors
        *ngIf="DEFAULT_DATEPICKER_ERRORS.includes(error.key)"
      >
        <span>{{ DEFAULT_ERRORS.get(error.key) | translate }}&nbsp;</span>
        <span *ngIf="error.key === 'matDatetimePickerMin'">{{
          error.value?.min | appDate
        }}</span>
        <span *ngIf="error.key === 'matDatetimePickerMax'">{{
          error.value?.max | appDate
        }}</span>
      </ng-container>
    </mat-error-->
    <mat-error *ngFor="let error of ngControl.errors | keyvalue">
      <span
        *ngIf="
          errorLabels && errorLabels[error.key];
          else defaultNgControlErrors
        "
      >
        {{ errorLabels[error.key] | translate }}
      </span>
      <ng-container
        #defaultNgControlErrors
        *ngIf="DEFAULT_ERRORS.has(error.key)"
      >
        <span>{{ DEFAULT_ERRORS.get(error.key) | translate }}&nbsp;</span>
      </ng-container>
    </mat-error>
  </mat-form-field>
</div>
