<app-page-content>
  <app-panel [formGroup]="careprogramForm">
    <app-panel-title
      title="careprogram.titles.DSM5Diagnosis"
      titleIcon="new_label"
    >
      <ng-container panelButtons>
        <button
          *ngIf="!dsm5DiagnosisFormArray?.disabled"
          class="c4p-button"
          (click)="createDiagnosis()"
          translate
        >
          general.labels.Add
        </button>
      </ng-container>
    </app-panel-title>

    <div class="c4p-table-view-wrap" formArrayName="dSM5Diagnosis">
      <table
        mat-table
        [dataSource]="dsm5DiagnosisFormArray.value"
        width="100%"
        *ngIf="dsm5DiagnosisFormArray.controls.length > 0"
      >
        <ng-container matColumnDef="startDateDiagnosis">
          <th mat-header-cell *matHeaderCellDef translate>
            careprogram.labels.StartDateDiagnosis
          </th>
          <td mat-cell *matCellDef="let dSM5Diagnosis">
            {{ dSM5Diagnosis.startDateDiagnosis | appDate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="endDateDiagnosis">
          <th mat-header-cell *matHeaderCellDef translate>
            careprogram.labels.EndDateDiagnosis
          </th>
          <td mat-cell *matCellDef="let dSM5Diagnosis">
            {{ dSM5Diagnosis.endDateDiagnosis | appDate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="refCodeICD9CM">
          <th mat-header-cell *matHeaderCellDef translate>
            careprogram.labels.ICD9CMcode
          </th>
          <td mat-cell *matCellDef="let dSM5Diagnosis">
            {{ dSM5Diagnosis.diagnosis.refCodeICD9CM }}
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef translate>
            general.labels.Description
          </th>
          <td mat-cell *matCellDef="let dSM5Diagnosis">
            {{ dSM5Diagnosis.diagnosis.description }}
          </td>
        </ng-container>
        <ng-container matColumnDef="operations">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let row; let i = index"
            style="text-align: right"
          >
            <mat-icon
              matPrefix
              class="c4p-action-icons"
              (click)="viewDsm5Diagnosis(i)"
              >visibility</mat-icon
            >
            <mat-icon
              *ngIf="!dsm5DiagnosisFormArray.disabled"
              matPrefix
              class="c4p-action-icons"
              (click)="editDsm5Diagnosis(row, i)"
              >create</mat-icon
            >

            <mat-icon
              *ngIf="!dsm5DiagnosisFormArray?.disabled"
              matPrefix
              class="c4p-action-icons"
              (click)="deleteDsm5Diagnosis(i)"
              >delete</mat-icon
            >
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <app-panel-form-row
      *ngIf="dsm5DiagnosisFormArray.controls.length === 0"
      rowClass="c4p-empty-table-row"
    >
      <span translate>general.messages.FormEmpty</span>
    </app-panel-form-row>
  </app-panel>
</app-page-content>
