export class TenantConfiguration {
  // unique TenantConfiguration id
  tenantConfigurationId!: string;

  // unique Tenant id
  tenantId!: number | string;

  // Keycloak URL
  keycloakHost!: string;

  // unique Keycloak realm name
  keycloakRealmName!: string;

  // type of Keycloak client (enum: frontend, backend)
  type!: string;

  // Keycloak client ID, visible in Keycloak
  keycloakClientId!: string;

  // Keycloak client UUID, accessible only via Keycloak API
  keycloakClientUUID!: string;

  // Keycloak client secret - only for confidential clients
  keycloakClientSecret: string | null | undefined;

  // Format completness configuration
  formatCompletness: number;

  createdAt!: Date;
  createdBy!: number | string;
  updatedAt: Date | null | undefined;
  updatedBy: number | string | null | undefined;
}
