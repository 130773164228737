import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { EmployeeModel } from '../../../models/employee';
import { DropdownServiceBase } from '../../../services/dropdown-service.abstract';
import { PaginationDataModel } from '../../../models/pagination/pagination-data.model';
import { HttpUtils } from '../../../utilities/http.utils';

@Injectable({
  providedIn: 'root',
})
export class EmployeeOverviewService extends DropdownServiceBase<EmployeeModel> {
  public employees$ = new BehaviorSubject<{
    data: EmployeeModel[];
    count: number;
  }>(null);

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
  ) {
    super();

    this.setupDropdown(this.getEmployees);
  }

  public getEmployees(paginationData: PaginationDataModel, filterData?: any) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/employees',
    });
    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );
    return this.http.get<any>(`${this.environment.employeeManagementApiUrl}`, {
      params,
      headers,
    });
  }
}
