import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControlBase,
  FormInputDateComponent,
} from '../../../form-controls';
import {
  DEFAULT_MIN_DATE,
  DEFAULT_MAX_DATE,
  DATE_REGEX,
  DEFAULT_DATEPICKER_ERRORS_ARRAY,
} from '../../../form-controls/form-controls.const';
import * as moment_ from 'moment';
import { DateUtils } from '../../../utilities';
const moment = moment_;

@Component({
  selector: 'app-date-choice',
  templateUrl: './date-choice.component.html',
  styleUrls: ['./date-choice.component.css'],
})
export class DateChoiceComponent extends FormControlBase<DateChoiceComponent> {
  static DEFAULT_DATEPICKER_ERRORS_ARRAY = DEFAULT_DATEPICKER_ERRORS_ARRAY;
  get DEFAULT_DATEPICKER_ERRORS() {
    return FormInputDateComponent.DEFAULT_DATEPICKER_ERRORS_ARRAY;
  }
  @Input() minDate = DEFAULT_MIN_DATE;
  @Input() maxDate = DEFAULT_MAX_DATE;
  @Input() orderNum: number;
  @ViewChild('datePickerInput') datePickerInput: ElementRef;
  onKeydown = DATE_REGEX;

  @Input() hint: string;
  @Input() isClientPortal = false;
  @Output() tabEvent = new EventEmitter();
  // something is off with the material datepickers in the current cdk version 10.2.3
  // they don't work with formControls but do with ngModel
  // keeping this here until they fix it
  private _innerValue;
  get value() {
    return this._innerValue;
  }
  set value(value: any) {
    if (value !== this.value) {
      this._innerValue = value;
      this.valueControl.setValue(value);
    }
    this.stateChanges.next();
  }

  writeValue(value) {
    if (this.datePickerInput?.nativeElement && value === null) {
      // remove leftover strings when reseting the form
      this.datePickerInput.nativeElement.value = '';
      // set null value to remove default datepicker errors
      this.valueControl.setValue(null);
    }
    super.writeValue(value);
  }

  public setNow() {
    this.value = DateUtils.getCurrentTenantDayMoment();
  }

  onTab(el: HTMLElement) {
    this.tabEvent.emit(el.offsetHeight);
  }
}
