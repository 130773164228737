import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Dsm5DiagnosisService } from '../../../../services';
import { DSM5DiagnosisModel } from '../../models/care-program.model';

interface InputData {
  diagnosis: DSM5DiagnosisModel;
  viewOnly: boolean;
}

@Component({
  selector: 'app-dsm5-diagnosis-detail',
  templateUrl: './dsm5-diagnosis-detail.component.html',
  styleUrls: ['./dsm5-diagnosis-detail.component.scss'],
})
export class Dsm5DiagnosisDetailComponent implements OnInit, OnDestroy {
  public dSM5DiagnosisForm: FormGroup = null!;
  public viewOnly: boolean;
  private destroyed$ = new Subject<boolean>();

  public get startDateDiagnosisControl() {
    return this.dSM5DiagnosisForm.get('startDateDiagnosis');
  }

  public get endDateDiagnosisControl() {
    return this.dSM5DiagnosisForm.get('endDateDiagnosis');
  }

  public get descriptionControl() {
    return this.dSM5DiagnosisForm.get('diagnosis');
  }

  public get codeControl() {
    return this.dSM5DiagnosisForm.get('code');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: InputData,
    private dialogRef: MatDialogRef<Dsm5DiagnosisDetailComponent>,
    private formBuilder: FormBuilder,
    public dsm5DiagnosisService: Dsm5DiagnosisService,
  ) {}

  ngOnInit(): void {
    this.dSM5DiagnosisForm = this.dSM5DiagnosisBuildForm();
    if (this.data.diagnosis) {
      this.dSM5DiagnosisForm.patchValue(this.data.diagnosis);
      this.codeControl.setValue(this.data.diagnosis.diagnosis, {
        emitEvent: false,
      });
    }
    this.descriptionSubscription();
    this.dateSubscription();
    this.codeSubscription();
    this.dsm5DiagnosisService.filterDsm5DiagnosisValue(
      this.startDateDiagnosisControl.value,
      this.endDateDiagnosisControl.value,
    );
    this.viewOnly = this.data.viewOnly;
  }

  dateSubscription() {
    this.startDateDiagnosisControl.valueChanges.subscribe((sdd) => {
      this.dsm5DiagnosisService.filterDsm5DiagnosisValue(
        sdd,
        this.endDateDiagnosisControl.value,
      );
      this.descriptionControl.setValue(null);
    });
    this.endDateDiagnosisControl.valueChanges.subscribe((edd) => {
      this.dsm5DiagnosisService.filterDsm5DiagnosisValue(
        this.startDateDiagnosisControl.value,
        edd,
      );
      this.descriptionControl.setValue(null);
    });
  }

  descriptionSubscription() {
    this.descriptionControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((description) => {
        if (description) {
          this.codeControl.setValue(description, { emitEvent: false });
          description.refCodeICD9CM = description.refcode_icd9cm;
          description.refCodeICD10 = description.refcode_icd10;
        }
      });
  }

  codeSubscription() {
    this.codeControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((description) => {
        if (description) {
          this.descriptionControl.setValue(description, { emitEvent: false });
          description.refCodeICD9CM = description.refcode_icd9cm;
          description.refCodeICD10 = description.refcode_icd10;
        }
      });
  }

  getMaxDate(date?: moment.Moment): moment.Moment | null {
    if (date) {
      return moment(date).clone().subtract(1, 'days');
    }
  }

  getMinDate(date?: moment.Moment): moment.Moment {
    if (date) return moment(date).clone();
    else return null;
  }

  onDismiss() {
    this.dialogRef.close(null);
  }

  onConfirm() {
    if (this.dSM5DiagnosisForm.valid) {
      this.dSM5DiagnosisForm.removeControl('code', { emitEvent: false });
      this.dialogRef.close(this.dSM5DiagnosisForm.getRawValue());
    } else this.dSM5DiagnosisForm.markAllAsTouched();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  dSM5DiagnosisBuildForm(){
    return this.formBuilder.group({
      startDateDiagnosis: [null, Validators.required],
      endDateDiagnosis: [],
      diagnosis: [null, Validators.required],
      code: [null, Validators.required],
    });
  }
}
