import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  OnInit,
  QueryList,
  Input,
} from '@angular/core';
import { PanelTitleComponent } from '../panel-title/panel-title.component';
import { PanelFormRowComponent } from '../panel-form-row/panel-form-row.component';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit, AfterContentInit {
  @Input() type: string = '';

  @ContentChild(PanelTitleComponent) panelTitle: PanelTitleComponent;
  @ContentChildren(PanelFormRowComponent, { descendants: true })
  formRows: QueryList<PanelFormRowComponent>;

  constructor() {}

  ngOnInit(): void {
    if (this.type === 'popup') this.type = 'c4p-popup-form';
    else if (this.type === 'popup-large') this.type = 'c4p-popup-form-large';
    else if (this.type === 'popup-medium') this.type = 'c4p-popup-form-medium';
    else if (this.type === 'popup-share-and-sign') this.type = 'c4p-popup-form-share-and-sign';
    else if (this.type === 'side-sheet') this.type = 'side-sheet-form'
    else this.type = 'c4p-form';
  }

  ngAfterContentInit() {
    // ng-content is available at this point
  }
}
