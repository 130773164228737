import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { PhoneModel } from '../../models';
import { PhoneTypeService } from '../../services/referent/phone-type/phone-type.service';
import { CountryCodeService } from '../../services/referent/country-code/country-code.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
})
export class PhoneComponent implements OnInit {
  public phone: PhoneModel;
  public title: string;
  @ViewChild('phoneForm') phoneForm: NgForm;

  constructor(
    public phoneTypeService: PhoneTypeService,
    public countryCodeService: CountryCodeService,
    public dialogRef: MatDialogRef<PhoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PhoneModel,
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.title = 'general.titles.EditPhone';
      this.phone = { ...this.data };
    } else {
      this.title = 'general.titles.AddPhone';
      this.phone = new PhoneModel();
    }
  }

  public onDismiss(): void {
    this.dialogRef.close(null);
  }

  public onConfirm(): void {
    if (this.phoneForm.invalid) {
      this.phoneForm.control.markAllAsTouched();
    } else {
      this.phone.fullNumber = `${this.phone.countryCode}${this.phone.number}`;
      this.dialogRef.close(this.phone);
    }
  }
}
