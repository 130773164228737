import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PaginationDataModel, PaginationResultModel, SimpleSignInput } from '../../../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DigitalSignatureService {
  private readonly digitalSignatureUrl =
    this.environment.digitalSignatureApiUrl;
  private readonly documentManagementUrl = this.environment.documentManagementApiUrl;

  constructor(
    public http: HttpClient,
    @Inject('environment') private environment,
  ) {}

  public signDocument(simpleSignInput: SimpleSignInput) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/simple-sign/signPdf',
    });

    return this.http.post<any>(
      `${this.digitalSignatureUrl}/simple-sign/signPdf`,
      simpleSignInput,
      { headers },
    );
  }
  public getSignatureKeys(
    signatureKeys: string[]
  ): Observable<any> {
    const queryParams = signatureKeys.map(key => encodeURIComponent(key)).join(',');
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: `/simple-sign`,
      entity_type: queryParams,
    });
    return this.http.get<PaginationResultModel<PaginationDataModel>>(
      `${this.digitalSignatureUrl}/simple-sign/get-signatures?keys=${queryParams}`,
      { headers },
    );
  }

  public downloadDocument(
    signatureKeys: string,
    downloadType: string
  ): Observable<any> {
    const queryParams = signatureKeys;
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: `/simple-sign`,
      entity_type: queryParams,
    });

    const url = `${this.digitalSignatureUrl}/simple-sign/${queryParams}/download?downloadType=${downloadType}`;

    return this.http.get(url, { headers, responseType: 'blob'});
  }
}
