<div class="c4p-popup-form">
  <div class="c4p-form-row">
    <span
      class="c4p-form-label"
      style="max-width: 100%; margin: 15px 8px 15px 15px"
      translate
      >general.messages.IfYouHaveAnyProblemPleaseSendAnEmailTo</span
    ><a href="mailto:{{ emailAddress }}" style="margin: 15px 15px 15px 0px">{{
      emailAddress
    }}</a>
  </div>
</div>

<div style="display: flex">
  <span class="c4p-button-ok" (click)="onConfirm()"><span>OK</span></span>
</div>
