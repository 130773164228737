import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { IFormDropdownOption } from '../../../../form-controls/form-controls.const';
import { MasterDataService } from '../../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class BasicGgzProfileService {
  private filterBasicGGZ = new BehaviorSubject<IFormDropdownOption[]>([]);
  public labelOptions = new BehaviorSubject<IFormDropdownOption[]>([]);
  public codeOptions = new BehaviorSubject<IFormDropdownOption[]>([]);

  get basicGGZValues() {
    return this.filterBasicGGZ.value;
  }

  public basicGGZ: IFormDropdownOption[] | any[];
  constructor(private masterDataService: MasterDataService) {
    this.getBasicGGZProfileValues();
  }

  private getBasicGGZProfileValues() {
    this.masterDataService
      .getMasterData('basic-ggz-profiles', true)
      .subscribe((data) => {
        this.basicGGZ = data.map((basicGGZ) => basicGGZ.value);
        this.filterBasicGGZ.next(data);
      });
  }

  public filterGGZProfileValue(registrationDate) {
    const filteredBasicGGZ = this.basicGGZ.filter(
      (value) =>
        moment(registrationDate).isSameOrAfter(moment(value.startDate)) &&
        moment(registrationDate).isSameOrBefore(
          moment(value.endDate ? value.endDate : '9999-12-31'),
        ),
    );
    this.filterBasicGGZ.next(filteredBasicGGZ);
    const LABEL_OPTIONS = filteredBasicGGZ?.map((value) => {
      return {
        value: value.label,
        label: value.label,
      };
    });
    const CODE_OPTIONS = filteredBasicGGZ?.map((option) => {
      return {
        value: option.value,
        label: option.value,
      };
    });
    this.labelOptions.next(LABEL_OPTIONS);
    this.codeOptions.next(CODE_OPTIONS);
  }
}
