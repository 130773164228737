import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../../services';

export enum HourSource {
  HOUR = 'hour',
  CORRECTION = 'correction',
  MANUAL = 'manual',
  TEMPLATE = 'template',
}

@Pipe({
  name: 'hourSource',
})
export class HourSourcePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}
  transform(value: string): string {
    switch (value) {
      case HourSource.HOUR:
        return this.languageService.translation('billing.labels.Hour');
      case HourSource.CORRECTION:
        return this.languageService.translation('billing.labels.Correction');
      case HourSource.MANUAL:
        return this.languageService.translation('billing.labels.Manual');
      case HourSource.TEMPLATE:
        return this.languageService.translation('billing.labels.Template');
      default:
        return value;
    }
  }
}
