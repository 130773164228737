export * from './document.module';

export * from './components/document-detail/document-detail.component';
export * from './models/category.model';
export * from './models/document.model';
export * from './pages/category-overview/category-overview.component';
export * from './pages/document-overview/document-overview.component';
export * from './pages/documents/documents.component';
export * from './service/category/category.service';
export * from './service/document/document.service';
