import { IFormDropdownOption } from '../form-controls/form-controls.const';
import { AddressModel, PhoneModel, EmailModel } from '../models';

//@dynamic
export class ModelUtils {
  public static getFullName(
    firstName: string,
    namePrefix: string,
    lastName: string,
  ): string {
    return [firstName, namePrefix ? namePrefix : '', lastName].filter(Boolean).join(' ');
  }

  public static getAllAddresses(
    addresses: AddressModel[],
    separator?: string,
  ): string {
    return addresses
      ?.filter((address) => ModelUtils.objectHasValues(address))
      .map((address) => ModelUtils.getClientNetworkFullAddress(address))
      .join(separator ? separator : ' ');
  }

  public static getFullAddress(address: AddressModel): string {
    return [address?.streetName, address?.houseNumber]
      .filter(Boolean)
      .join(' ');
  }

  public static getCompleteAddresss(address: AddressModel): string {
    return [
      address?.streetName,
      address?.houseNumber +
        (address?.houseLetter ? address?.houseLetter : '') +
        (address?.addition ? '/' + address?.addition : ''),
    ]
      .filter(Boolean)
      .join(' ');
  }

  public static getClientNetworkFullAddress(address: AddressModel): string {
    return [
      address?.streetName,
      address?.houseNumber +
        (address?.houseLetter ? address?.houseLetter : '') +
        (address?.addition ? '/' + address?.addition : ''),
    ]
      .filter(Boolean)
      .join(' ')
      .concat(
        ', ',
        [address?.postalCode, address?.city].filter(Boolean).join(' '),
      );
  }

  public static getAllPhones(phones: PhoneModel[], separator?: string): string {
    return phones
      ?.filter((phone) => ModelUtils.objectHasValues(phone))
      .map((phone) => ModelUtils.getFullPhoneNumber(phone))
      .join(separator ? separator : ' ');
  }

  public static getFullPhoneNumber(phone: PhoneModel): string {
    return [phone?.countryCode, phone?.number].filter(Boolean).join(' ');
  }

  public static getAllEmails(emails: EmailModel[], separator?: string): string {
    return emails
      ?.filter((email) => ModelUtils.objectHasValues(email))
      .map((email) => email.email)
      .join(separator ? separator : ' ');
  }

  public static getLawType(law: string): 'WMO' | 'JW' {
    if (law === 'WMO') return 'WMO';
    if (law === 'Jeugdwet') return 'JW';

    return null;
  }

  public static searchInArray(
    values: any[],
    search: string,
    searchProperty?: string,
    extraSearchProperty?: string,
  ) {
    if (!values) {
      return;
    }

    if (!search) {
      return values.slice();
    } else {
      search = search.toLowerCase();
    }

    if (extraSearchProperty) {
      return values.filter(
        (option) =>
          option[searchProperty].toLowerCase().indexOf(search) > -1 ||
          option[extraSearchProperty].toLowerCase().indexOf(search) > -1,
      );
    }
    return values.filter(
      (option) => option[searchProperty].toLowerCase().indexOf(search) > -1,
    );
  }

  public static extractOptions(
    doms: any[],
    dropdownConfig: any,
  ): IFormDropdownOption[] {
    const labelProperty = dropdownConfig.labelProperty;
    const valueProperty = dropdownConfig.valueProperty;
    const disabledCheck = dropdownConfig.disabledCheck;

    if (doms) {
      return doms.map((dom) => {
        let option: IFormDropdownOption;

        if (labelProperty instanceof Array) {
          const label = labelProperty
            .map((labelKey) => dom[labelKey])
            .join(dropdownConfig.separator);
          option = { label, value: dom[valueProperty] };
        } else {
          option = { label: dom[labelProperty], value: dom[valueProperty] };
        }
        if (disabledCheck) option.disabled = disabledCheck(dom);
        return option;
      });
    }
  }

  public static objectHasValues(object: Object): boolean {
    return !ModelUtils.isObjectEmpty(object);
  }

  public static isObjectEmpty(object: Object): boolean {
    if (!object) return true;
    let hasValues = false;

    for (let value in object) {
      if (object[value] != null) {
        if (
          object[value] !== '' &&
          object[value] !== undefined &&
          object[value].length !== 0
        ) {
          hasValues = true;
        } else {
          object[value] = null;
        }
      }
    }
    return !hasValues;
  }

  public static getObjectPropertyWithString(
    path: string,
    obj: any,
    separator = '.',
  ) {
    var properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => prev && prev[curr], obj);
  }

  public static getRandomLetters(count = 2) {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return [...Array(count).keys()]
      .map((x) => letters[Math.floor(Math.random() * letters.length)])
      .join('');
  }
  public static generateDateBasedUniqueSuffix() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const letters = ModelUtils.getRandomLetters(4);
    return `${day}/${month}/${year}-${letters}`;
  }
}
