import { Component, EventEmitter, Input, Output, TemplateRef, Type, ViewEncapsulation } from '@angular/core';
import { ActionType, Button, FormerSidesheet, SideSheetConfig, SideSheetContent} from '../../../models/side-sheet';
import { SideSheetService } from '../../../services';
import { createButton } from '../../../services/side-sheet/initial-side-sheet-config';

@Component({
  selector: 'app-sidesheet-breadcrumb',
  templateUrl: './sidesheet-breadcrumb.component.html',
  styleUrls: ['./sidesheet-breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidesheetBreadcrumbComponent {


  @Input() formerSidesheets: FormerSidesheet[] = [];
  @Input() sideSheetConfig: SideSheetConfig;
  @Input() sideSheetContent: SideSheetContent;
  @Input() breadcrumbActionsTemplate: TemplateRef<any>;

  @Output() buttonClicked = new EventEmitter<Button>();
  closeButton: Button;

  constructor(private sideSheetService: SideSheetService) {
    this.closeButton = createButton('', ActionType.SIDE_SHEET_CLOSE);
  }

  loadComponent(component: Type<any>, content: SideSheetContent, config: SideSheetConfig): void {
    this.sideSheetService.loadComponent(component, content, config, false);
  }

  toggleFullScreen(): void {
    this.sideSheetConfig.fullscreen = !this.sideSheetConfig.fullscreen;
  }

  onCloseButton(){
    this.buttonClicked.emit(this.closeButton);
  }


}
