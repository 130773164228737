import {
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { PermissionService } from '../services/permissions/permission.service';

@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnDestroy {
  @Input() set hasPermission(permission: string) {
    this.updateView(permission);
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService,
  ) {}

  ngOnDestroy() {}

  private updateView(permission: string) {
    const hasPermission = this.permissionService.getPermission(permission);
    if (hasPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
