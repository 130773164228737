import { Pipe, PipeTransform } from '@angular/core';
import { TENANT_PIPE_FORMAT } from '../pipes.const';
import * as moment from 'moment-timezone';
import { UserInfoService } from './../../services/user-info/user-info.service';

@Pipe({
  name: 'tenantDate',
})
export class TenantDatePipe implements PipeTransform {
  constructor(private userService: UserInfoService) {}
  transform(value: any, args?: any): any {
    const tenantTimezone =
      this.userService.tenantTimeZone ||
      JSON.parse(localStorage.getItem('tenantTimeZone'));

    if ( args === "nullableDate" && value){
      return moment(value)
        .tz(tenantTimezone)
        .format(TENANT_PIPE_FORMAT);
    } else if (args === "nullableDate"){
    return ;
    }
    return moment(value)
      .tz(tenantTimezone)
      .format(args ? args : TENANT_PIPE_FORMAT);
  }
}
