import { Inject, Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IDLE_TIMEOUT } from '../../utilities';

export class CollaboratioModel {
  token: string;
  userId: string;
  action: string;
  userList: string[];
  docIdentifier: string;
  msgData: string;
  msgTo: string;
}

@Injectable({
  providedIn: 'root',
})
export class CollaborationService {
  private socket$: WebSocketSubject<any>;
  private wsSubscription: Subscription;
  private userId: string | number;
  private token: string;
  isUserIdle = false;

  public userListSubject = new BehaviorSubject<CollaboratioModel>(null);
  public readonlySubject = new BehaviorSubject<boolean>(null);

  constructor(@Inject('environment') public environment: any) {}

  public connect(userId: string | number, token: string) {
    this.userId = userId;
    this.token = token;
    this.socket$ = webSocket(this.environment.collaborationServiceWS);

    this.wsSubscription = this.socket$.subscribe(
      (msg) => this.receiveMessage(msg),
      (err) => this.onError(err),
      () => this.onLostConnection(),
    );
    this.sendMessage('connect');
    this.inactivityTime();
  }

  private onError(err: any) {
    console.log('Websocket error', err);
    if (err.type === 'close') {
      this.wsSubscription.unsubscribe();

      this.wsSubscription = this.socket$.subscribe(
        (msg) => this.receiveMessage(msg),
        (err) => this.onError(err),
        () => this.onLostConnection(),
      );
    }
  }

  private onLostConnection() {
    console.log('Websocket lost connection');
  }

  public sendMessage(
    action: string,
    docIdentifier?: string,
    msgData?: string,
    msgTo?: string,
  ) {
    if (this.socket$) {
      this.socket$.next({
        token: this.token,
        userId: this.userId,
        action: action,
        docIdentifier: docIdentifier,
        msgData: msgData,
        msgTo: msgTo,
      });
    }
  }

  public disconnect() {
    this.sendMessage('disconnect');
    this.socket$.complete();
    this.wsSubscription.unsubscribe();
  }

  private receiveMessage(msg) {
    if (msg.action == 'userList') {
      // for future use to lock user if he is not first in a list
      if (this.userId == msg.userList[0]) this.readonlySubject.next(false);
      else this.readonlySubject.next(true);
      // inform all listeners about new list of users
      this.userListSubject.next(msg);
    }
  }

  public inactivityTime() {
    var time;
    const resetTimer = (() => {
      if (this.isUserIdle) {
        this.sendMessage('userReactivate');
        this.isUserIdle = false;
      }
      clearTimeout(time);
      time = setTimeout(
        (() => {
          this.sendMessage('userIdle');
          this.isUserIdle = true;
        }).bind(this),
        IDLE_TIMEOUT,
      );
    }).bind(this);
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeydown = resetTimer;
  }
}
