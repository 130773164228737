import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../../services';
import { BillingPeriodicity } from './invoicePeriod.pipe';

@Pipe({
  name: 'billingPeriodicity',
})
export class BillingPeriodicityPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}
  transform(value: string): string {
    switch (value) {
      case BillingPeriodicity.PER_MONTH:
        return this.languageService.translation('billing.labels.Per_month');
      case BillingPeriodicity.PER_4_WEEKS:
        return this.languageService.translation('billing.labels.Per_4_weeks');
      default:
        return value;
    }
  }
}
