import {
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { LoadingProgressService } from '../../services';
import { routeAnimations } from '../animations';
import { TopBarService } from '../../services/top-bar/top-bar.service';
import { NavbarMode } from '../navbar/navbar.component';

@Component({
  selector: 'app-base',
  templateUrl: './app-base.component.html',
  styleUrls: ['./app-base.component.scss'],
  animations: [routeAnimations],
  encapsulation: ViewEncapsulation.None,
})
export class AppBaseComponent{
  navBarMode: NavbarMode = NavbarMode.COLLAPSED;

  public get isLoading$() {
    return this.loadingProgressService.isLoading$;
  }
  public get topBarTemplate$() {
    return this.topBarService.topBarTemplate$;
  }

  constructor(
    private loadingProgressService: LoadingProgressService,
    private topBarService: TopBarService
  ) { }


  onNavbarModeChanged(event: NavbarMode) {
    this.navBarMode = event;
  }
 
}
