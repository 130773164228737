import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BasicGgzProfileService } from '../../../../services';
import { BasicGGZProfileModel } from '../../models';
import * as moment from 'moment';
import { CareProgramService } from '../../..';

interface InputData {
  profile: BasicGGZProfileModel;
  viewOnly: boolean;
}

@Component({
  selector: 'lib-basic-ggz-profile',
  templateUrl: './basic-ggz-profile.component.html',
  styleUrls: ['./basic-ggz-profile.component.scss'],
})
export class BasicGgzProfileComponent implements OnInit, OnDestroy {
  public basicGGZProfileForm: FormGroup = null!;

  public get startDateControl() {
    return this.basicGGZProfileForm.get('startDate');
  }

  public get endDateControl() {
    return this.basicGGZProfileForm.get('endDate');
  }

  private get nameControl() {
    return this.basicGGZProfileForm.get('profile').get('name');
  }

  private get codeControl() {
    return this.basicGGZProfileForm.get('profile').get('code');
  }

  private get registrationDate() {
    return this.careprogramService.careProgramForm.get('registrationDate')
      .value;
  }

  public get GDS801CodeControl() {
    return this.basicGGZProfileForm.get('profile').get('GDS801Code');
  }

  public get identifierControl() {
    return this.basicGGZProfileForm.get('profile').get('identifier');
  }

  public viewOnly: boolean;
  private readonly destroyed$ = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: InputData,
    private dialogRef: MatDialogRef<BasicGgzProfileComponent>,
    private formBuilder: FormBuilder,
    public careprogramService: CareProgramService,
    public basicGGZProfileService: BasicGgzProfileService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.basicGGZProfileForm = this.basicGGZProfileBuildForm();
    if (this.data.profile) {
      this.basicGGZProfileForm.patchValue(this.data.profile);
    }
    if (this.viewOnly) this.basicGGZProfileForm.disable();
    this.nameSubscription();
    this.codeSubscription();
    this.dateSubscription();
    this.basicGGZProfileService.filterGGZProfileValue(this.registrationDate);

    this.viewOnly = this.data.viewOnly;
  }

  dateSubscription() {
    this.careprogramService.careProgramForm
      .get('registrationDate')
      .valueChanges.subscribe((date) => {
        this.basicGGZProfileService.filterGGZProfileValue(date);
        this.basicGGZProfileForm.reset();
      });
  }

  private nameSubscription() {
    this.nameControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((name) => {
        if (name) {
          const basicGGZOption = this.basicGGZProfileService.basicGGZ.find(
            (option) => option.label === name,
          );
          this.codeControl.setValue(basicGGZOption.value, { emitEvent: false });
          this.startDateControl.setValue(basicGGZOption.startDate, {
            emitEvent: false,
          });
          this.endDateControl.setValue(basicGGZOption.endDate, {
            emitEvent: false,
          });
          this.GDS801CodeControl.setValue(basicGGZOption.GDS801Code, {
            emitEvent: false,
          });
          this.identifierControl.setValue(basicGGZOption.identifier, {
            emitEvent: false,
          });
        } else {
          this.startDateControl.setValue(null, { emitEvent: false });
          this.endDateControl.setValue(null, { emitEvent: false });
          this.codeControl.setValue(null, { emitEvent: false });
          this.GDS801CodeControl.setValue(null, { emitEvent: false });
          this.identifierControl.setValue(null, { emitEvent: false });
          this.basicGGZProfileForm.markAsTouched();
        }
      });
  }

  private codeSubscription() {
    this.codeControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((code) => {
        if (code) {
          const basicGGZOption = this.basicGGZProfileService.basicGGZ.find(
            (option) => option.value === code,
          );
          this.nameControl.setValue(basicGGZOption.label, { emitEvent: false });
          this.startDateControl.setValue(basicGGZOption.startDate, {
            emitEvent: false,
          });
          this.endDateControl.setValue(basicGGZOption.endDate, {
            emitEvent: false,
          });
          this.GDS801CodeControl.setValue(basicGGZOption.GDS801Code, {
            emitEvent: false,
          });
          this.identifierControl.setValue(basicGGZOption.identifier, {
            emitEvent: false,
          });
        } else {
          this.startDateControl.setValue(null, { emitEvent: false });
          this.endDateControl.setValue(null, { emitEvent: false });
          this.nameControl.setValue(null, { emitEvent: false });
          this.GDS801CodeControl.setValue(null, { emitEvent: false });
          this.identifierControl.setValue(null, { emitEvent: false });
          this.basicGGZProfileForm.markAsTouched();
        }
      });
  }

  onConfirm() {
    if (this.basicGGZProfileForm.valid) {
      this.dialogRef.close(this.basicGGZProfileForm.getRawValue());
    } else this.basicGGZProfileForm.markAllAsTouched();
  }

  onDismiss() {
    this.dialogRef.close(null);
  }

  getMaxDate(date?: moment.Moment): moment.Moment | null {
    if (date) {
      return moment(date).clone().subtract(1, 'days');
    }
  }

  getMinDate(date?: moment.Moment): moment.Moment {
    if (date) return moment(date).clone().add(1, 'days');
    else return null;
  }
  basicGGZProfileBuildForm(){
    return this.formBuilder.group({
      startDate: [{ value: null, disabled: true }, Validators.required],
      endDate: [{ value: null, disabled: true }],
      profile: this.formBuilder.group({
        name: [null, Validators.required],
        code: [null, [Validators.required]],
        GDS801Code: [{ value: null, disabled: true }, Validators.required],
        identifier: [{ value: null, disabled: true }, Validators.required],
      })
    })
  }
}
