<div class="c4p-popup-form">
  <div class="c4p-form-title">
    <span class="c4p-horizontal-divider" translate
      >general.actions.Select_Role</span
    >
  </div>

  <div class="c4p-form-row">
    <app-form-input-dropdown
      #roleDropdown="ngModel"
      [options]="roleOptions"
      required="true"
      [(ngModel)]="selectedRoleIndex"
    ></app-form-input-dropdown>
  </div>
</div>

<div style="display: flex">
  <span
    *ngIf="hasRole"
    class="c4p-button-cancel"
    (click)="onDismiss()"
    translate
    >general.labels.Cancel</span
  >
  <span class="c4p-button-ok" (click)="onConfirm()"
    ><span *ngIf="roleDropdown.valid">OK</span></span
  >
</div>
