import {
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { FormInputDropdownComponent } from '../form-input-dropdown/form-input-dropdown.component';
import {
  BehaviorSubject,
  Observable,
  of,
  Subject,
  Subscription,
  timer,
} from 'rxjs';
import {
  BackendServiceType,
  DEFAULT_AUTOCOMPLETE_DEBOUNCE_TIME_AMOUNT,
  DEFAULT_LONG_REQUEST_DEBOUNCE_TIME_AMOUNT,
  IFormDropdownOption,
} from '../form-controls.const';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { FormControl, NgControl } from '@angular/forms';
import { MasterDataService } from '../../services/master-data/master-data.service';
import { TranslateService } from '@ngx-translate/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-form-input-autocomplete',
  templateUrl: './form-input-autocomplete.component.html',
  styleUrls: ['./form-input-autocomplete.component.scss'],
})
export class FormInputAutocompleteComponent extends FormInputDropdownComponent {
  filteredOptions$ = new BehaviorSubject<IFormDropdownOption[]>([]);

  @Input() paramName: string;
  @Input() service: BackendServiceType;
  @Input() urlSuffix: string = '';
  @Input() displayProperty: string;
  // define which property to return as result, if empty whole object will be returned
  @Input() valueProperty: string;
  @Input() baseOptions: IFormDropdownOption[];
  @Input() translateDisplay: boolean;

  autoCompleteControl = new FormControl();
  autocompleteControlSubscription: Subscription;

  @Input() extraParamNames: string[];
  @Input() extraParamValues: any[];
  @Input() valueAsOption: boolean = true;
  @Input() contactLogbookPersonFilter: boolean = false;
  @Output() activateButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() redirectEventWhenFetchedDataIsEmpty: EventEmitter<any> =
    new EventEmitter<any>();

  private readonly destroyed$ = new Subject<boolean>();
  private isClientNetworkRequestFetched: boolean = false;
  private localDebounceTime = DEFAULT_AUTOCOMPLETE_DEBOUNCE_TIME_AMOUNT;
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private masterDataService: MasterDataService,
    public translate: TranslateService,
  ) {
    super(ngControl);
  }

  ngOnInit() {
    super.ngOnInit();
    this.decideDebounceTimeByClientNetwork();
    if (this.baseOptions) {
      this.options = this.baseOptions;
    }

    this.autoCompleteControl.valueChanges
      .pipe(
        debounceTime(this.localDebounceTime),
        switchMap((value) =>
          this.baseOptions
            ? this.filter(value)
            : this.contactLogbookPersonFilter
            ? this.filterDataLogbook(value)
            : this.filterData(value),
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe((value) => {
        if (
          this.isClientNetworkRequestFetched &&
          value.length === 0 &&
          this.service === 'client-network'
        ) {
          this.createDelayUsingTimer(3000).subscribe(() => {
            this.redirectEventWhenFetchedDataIsEmpty.emit();
          });
        } else if (
          this.isClientNetworkRequestFetched &&
          this.service === 'client-network'
        ) {
          this.activateButton.emit();
        }
        this.filteredOptions$.next(value);
        this.isClientNetworkRequestFetched = false;
      });
  }

  private decideDebounceTimeByClientNetwork() {
    if (
      this.service === 'client-network' &&
      this.displayProperty === 'name' &&
      this.valueProperty === 'id' &&
      this.urlSuffix === 'autoselect'
    ) {
      this.localDebounceTime = DEFAULT_LONG_REQUEST_DEBOUNCE_TIME_AMOUNT;
    }
  }

  createDelayUsingTimer(delayValue: number) {
    return timer(delayValue).pipe(takeUntil(this.destroyed$));
  }

  writeValue(value: any): void {
    this.autoCompleteControl.setValue(
      value == null || value == '' ? null : value,
      { emitEvent: false },
    );
    this.value = value;
  }

  onSelectionChange(selected: MatAutocompleteSelectedEvent) {
    this.valueControl.setValue(selected.option.value);
  }

  getDisplayValue(value: any) {
    const options = this.filteredOptions$?.value
      ? this.filteredOptions$.value
      : this.options;
    const selectedOption = options
      .filter(Boolean)
      .find((option) => option.value === value);
    const displayValue = selectedOption ? selectedOption.label : value;
    const translatedDisplayValue =
      this.translateDisplay && displayValue
        ? this.translate.instant(displayValue)
        : displayValue;
    return translatedDisplayValue;
  }

  private filter(value): Observable<IFormDropdownOption[]> {
    this.options = [];
    if (!value || value == '') {
      this.options = this.baseOptions;
      this.addValueAsOption(value);
    } else {
      const option = this.baseOptions.filter((option) =>
        option.value.toLowerCase().includes(value.toLowerCase()),
      );
      this.options = option;
    }
    this.addValueAsOption(value);
    return of(this.options);
  }

  validateAndGetEmail(value: string): string {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const valid = emailRegex.test(value);

    if (valid) return value;
    return '';
  }

  private filterData(data: any): Observable<IFormDropdownOption[]> {
    const { value, searchBy } = this.setClientNetworkSearchByEmail(data);

    if (value === '') {
      this.valueControl.setValue(null);
      return of([]);
    } else if (typeof value === 'string') {
      const searchParams = {
        [this.paramName]: value,
        ...(searchBy && { searchBy }),
      };

      if (this.extraParamNames && this.extraParamValues) {
        this.extraParamNames.forEach((name, index) => {
          searchParams[name] = this.extraParamValues[index];
        });
      }
      this.isClientNetworkRequestFetched = true;
      return this.masterDataService.getAutocompleteData(
        searchParams,
        this.service,
        this.urlSuffix,
        this.displayProperty,
        this.valueProperty,
      );
    } else {
      return of();
    }
  }

  private setClientNetworkSearchByEmail(value: any) {
    let searchBy: boolean = false;
    if (
      this.service === 'client-network' &&
      this.displayProperty === 'name' &&
      this.valueProperty === 'id' &&
      this.urlSuffix === 'autoselect'
    ) {
      value = this.validateAndGetEmail(value);
      searchBy = !!this.validateAndGetEmail(value);
    }
    return { value, searchBy };
  }

  private filterDataLogbook(value: any): Observable<IFormDropdownOption[]> {
    // reset outter form value when there's an empty string entered to autocomplete
    this.options = [];
    if (value === '') {
      this.valueControl.setValue(null);
    } else if (typeof value === 'string') {
      const searchParams = {
        [this.paramName]: value,
      };

      if (this.extraParamNames && this.extraParamValues) {
        this.extraParamNames.forEach((name, index) => {
          searchParams[name] = this.extraParamValues[index];
        });
      }
      this.addValueAsOption(value);
      this.masterDataService
        .getAutocompleteData(
          searchParams,
          this.service,
          this.urlSuffix,
          this.displayProperty,
          this.valueProperty,
        )
        .subscribe((values) => {
          this.options = values;
          this.addValueAsOption(value);
          this.options = [
            ...new Set(this.options.map((option) => JSON.stringify(option))),
          ].map((option) => JSON.parse(option));
        });
    }
    return of(this.options);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private addValueAsOption(value) {
    if (!this.valueAsOption)
      this.options.splice(0, 0, { label: value, value: value });
  }
}
