import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormGroup } from '../../models/dynamic-form-group.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
@Component({
  selector: 'app-dynamic-form-group',
  templateUrl: './dynamic-form-group.component.html',
  styleUrls: ['./dynamic-form-group.component.scss'],
})
export class DynamicFormGroupComponent implements OnInit, OnChanges {
  @Input() dynamicFormGroup: DynamicFormGroup;
  @Input() formGroup: FormGroup;
  @Input() formGroupName: string;
  @Input() groupLabelStyle: string = 'normal';
  @Input() showAddRemoveButtons: boolean = true;
  @Input() showAddButton: boolean = true;
  @Input() showRemoveButton: boolean = true;
  @Input() showRemoveButtonOverride: string = '';
  @Input() showRemoveButtonLabel: string = 'general.actions.RemoveElement';
  @Input() includeAllowAllPermissions: boolean = true;
  @Input() showParentStyle: boolean = false;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formGroup.currentValue !== changes.formGroup.previousValue) {
      if (this.includeAllowAllPermissions) {
        const allowAllowPermission = this.formGroup.get('allowAllPermissions');
        if (allowAllowPermission) {
          allowAllowPermission.valueChanges.subscribe((value) => {
            this.toggleAllPermissions(this.formGroup, value);
          });
        }
      }
    }
  }

  toggleAllPermissions(formGroup, value) {
    Object.keys(formGroup.controls).forEach((control: any) => {
      if (
        formGroup.controls[control] &&
        formGroup.controls[control]['controls']
      ) {
        this.toggleAllPermissions(formGroup.controls[control], value);
      } else {
        formGroup.get(control).setValue(value, { emitEvent: false });
      }
    });
  }
  ngOnInit(): void {}
  onTouchedCallback(event: MatCheckboxChange) {
    const allowAllPermissionsControl = this.formGroup.get(
      'allowAllPermissions',
    );
    if (this.dynamicFormGroup.formGroupName === 'permissions') {
      allowAllPermissionsControl.setValue(event.checked);
    }
    const value = allowAllPermissionsControl.value;
    this.toggleAllPermissions(this.formGroup, value);
  }

  getParentStyle() {
    if (this.showParentStyle) {
      return null;
    } else {
      return {
        display: 'flex',
      };
    }
  }
}
