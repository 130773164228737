import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-identification-method',
  templateUrl: './identification-method.component.html',
  styleUrls: ['./identification-method.component.css']
})
export class IdentificationMethodComponent implements OnInit{

  @Input() identificationMethods:FormGroup;

  constructor(private fb:FormBuilder) {

  }
  ngOnInit(): void {
   // this.buildForm();
  }


  buildForm(){
    this.identificationMethods = this.fb.group({
      validateWithBsn:[],
      validateWithBirthYear:[],
      validateWithPhoneNumber:[],
      validateWithEmail:[],
      validateWithEmailCode:[]
    })
  }
}
