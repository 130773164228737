<div class="c4p-form-questions">
  <div class="c4p-form-questions-title" style="justify-content: space-between">
    <span class="c4p-align-left"> </span>

    <span class="c4p-align-right">
      <div
        class="c4p-button"
        *ngIf="entity === 'careplan'"
        (click)="onConfigureGoals()"
        translate
      >
        general.labels.Edit
      </div>
    </span>
  </div>
  <ng-container [formGroup]="selectedSocialAreaForm">
    <div class="c4p-form-goals-container">
      <div *ngIf="selectedSocialArea.additionalInfo && !scoringEnabled">
        <app-panel-form-row>
          <app-form-input-text-area
            [rows]="1"
            style="width: 100%"
            formControlName="additionalInfo"
            placeholder="socialarea.labels.AdditionalInformation"
          ></app-form-input-text-area>
        </app-panel-form-row>
      </div>
      <ng-container formArrayName="mainGoals">
        <div *ngFor="let control of mainGoalsFormArray.controls; let i = index">
          <ng-container [formGroup]="control">
            <app-panel-form-row
              *ngIf="control.value.selected == true"
              style="flex-wrap: nowrap"
            >
              <ng-template
                [ngIf]="!scoringEnabled && entity !== 'evaluation'"
                [ngIfElse]="summary"
              >
                <span style="flex-wrap: nowrap; display: flex">
                  <div class="c4p-form-input-nowidth">
                    <div class="c4p-form-box" *ngIf="control.value.evaluated">
                      {{ control.value.evaluated }}
                    </div>
                    <div class="c4p-form-box" *ngIf="!control.value.evaluated">
                      {{ control.value.startingLevel }}
                    </div>
                  </div>
                  <div class="c4p-form-input-nowidth">
                    <div class="c4p-form-box">
                      {{ control.value.objective }}
                    </div>
                  </div>
                  <app-label
                    [label]="control.value.name"
                    width="-nowidth"
                  ></app-label>
                </span>
              </ng-template>
              <ng-template #summary>
                <app-form-input-checkbox
                  *ngIf="entity === 'evaluation'"
                  width="-nowidth"
                  formControlName="achieved"
                  matTooltip="{{ 'socialarea.labels.Achieved' | translate }}"
                ></app-form-input-checkbox>
                <app-form-input-dropdown
                  *ngIf="entity === 'evaluation'"
                  width="-nowidth"
                  formControlName="score"
                  placeholder="carefile.labels.Score"
                  [options]="objectiveOverviewService.values"
                  blank="true"
                ></app-form-input-dropdown>
                <app-label
                  [label]="control.value.name"
                  width="-nowidth"
                ></app-label>
                <!-- TODO: implement rich text for evaluation entity type -->
                <button
                  mat-icon-button
                  *ngIf="entity === 'evaluation'"
                  (click)="openGoalHistory(control, 'main')"
                >
                  <mat-icon class="c4p-top-icons">visibility</mat-icon>
                </button>
                <app-form-input-text-area
                  placeholder="carefile.labels.Summary"
                  formControlName="summary"
                  [large]="true"
                  [autoResize]="true"
                  style="flex-grow: 1"
                ></app-form-input-text-area>
              </ng-template>
            </app-panel-form-row>
            <app-panel-form-row *ngIf="control.value.selected == true">
              <div [formGroup]="control.get('subGoals')">
                <div
                  *ngFor="
                    let subcontrol of control.get('subGoals')['controls'];
                    let j = index
                  "
                  style="margin-left: 28px"
                >
                  <ng-container [formGroup]="subcontrol">
                    <app-panel-form-row
                      *ngIf="subcontrol.value.selected == true"
                    >
                      <ng-template
                        [ngIf]="scoringEnabled || entity === 'evaluation'"
                        [ngIfElse]="objective"
                      >
                        <app-form-input-checkbox
                          *ngIf="entity === 'evaluation'"
                          width="-nowidth"
                          formControlName="achieved"
                          matTooltip="{{
                            'socialarea.labels.Achieved' | translate
                          }}"
                          (onChange)="
                            subGoalAchievedManuallyMarked($event, subcontrol)
                          "
                        ></app-form-input-checkbox>
                        <app-form-input-dropdown
                          *ngIf="entity === 'evaluation'"
                          width="-nowidth"
                          formControlName="displayScore"
                          placeholder="carefile.labels.Score"
                          [options]="objectiveOverviewService.values"
                          blank="true"
                        ></app-form-input-dropdown>
                        <app-form-input-dropdown
                          *ngIf="entity !== 'evaluation'"
                          width="-nowidth"
                          formControlName="score"
                          placeholder="carefile.labels.Score"
                          [options]="objectiveOverviewService.values"
                          blank="true"
                        ></app-form-input-dropdown>
                        <app-label
                          [label]="subcontrol.value.name"
                          width="-nowidth"
                        ></app-label>
                        <button
                          mat-icon-button
                          *ngIf="entity === 'evaluation'"
                          (click)="openGoalHistory(subcontrol, 'sub')"
                        >
                          <mat-icon class="c4p-top-icons">visibility</mat-icon>
                        </button>
                      </ng-template>

                      <ng-template #objective>
                        <span style="flex-wrap: nowrap; display: flex">
                          <div class="c4p-form-input-nowidth">
                            <div class="c4p-form-box">
                              {{ subcontrol.value.startingLevel }}
                            </div>
                          </div>
                          <div class="c4p-form-input-nowidth">
                            <div class="c4p-form-box">
                              {{ subcontrol.value.objective }}
                            </div>
                          </div>
                          <app-label
                            [label]="subcontrol.value.name"
                            width="-nowidth"
                          ></app-label>
                        </span>
                      </ng-template>
                    </app-panel-form-row>
                  </ng-container>
                </div>
              </div>
            </app-panel-form-row>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
