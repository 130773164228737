import { ModelUtils } from '../../utilities/model.utils';
import { AddressModel } from './../address/address.model';
import { EmailModel } from './../email/email.model';
import { PhoneModel } from './../phone/phone.model';

export class OrganizationModel {
  id: string;
  name!: string;
  contactPerson!: string;

  constructor(organization: OrganizationModel) {
    this.id = organization?.id ? organization?.id : null;
    this.name = organization?.name ? organization?.name : null;
    this.contactPerson = organization?.contactPerson
      ? organization?.contactPerson
      : null;
  }
}

export class ApplicationDataModel {
  applicationId: string;
  relation: string;
  isContactPerson?: boolean;
  jurisdiction?: boolean;
  jurisdictionType?: string;
  isLegalRepresentative?: boolean;
  autoInvoice?: boolean;
  description?: string;
}

export class CarefileClientNetworkServices {
  id: string;
  name: string;
  globalServiceId: string;
}

export class ClientNetworkModel {
  id?: string;
  tenantId!: string;
  number!: string | number;
  relationType!: string;
  organization?: string;
  relation?: string;

  carefileServices?: CarefileClientNetworkServices[];

  givenName!: string;
  lastName!: string;
  initials?: string | null | undefined;
  firstNames?: string | null | undefined;
  prefix?: string | null | undefined;
  gender?: string | null | undefined;
  dateOfBirth: Date | null | undefined;
  phones: PhoneModel[] | null | undefined;
  emails: EmailModel[] | null | undefined;
  addresses: AddressModel[] | null | undefined;
  fullName: string | null | undefined;
  useCustomAvatar: boolean;
  preferredLanguage: string | null | undefined;

  applicationsData: ApplicationDataModel[];

  status!: string;
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: any;
  updatedBy?: any;

  // only frontend
  fullAddress?: string;
  fullEmail?: string;
  fullPhone?: string;
  isLegalRepresentative?: string;
  name?: string;
  access?: boolean;
  //

  constructor(clientNetwork?: any) {
    if (clientNetwork) {
      Object.assign(this, clientNetwork);
      this.fullName = ModelUtils.getFullName(
        clientNetwork.givenName,
        clientNetwork.namePrefix,
        clientNetwork.lastName,
      );
      this.useCustomAvatar = false;

      this.id = clientNetwork.id ? clientNetwork.id : null;
      this.tenantId = clientNetwork.tenantId ? clientNetwork.tenantId : null;
      this.number = clientNetwork.number ? clientNetwork.number : null;
      this.relationType = clientNetwork.relationType
        ? clientNetwork.relationType
        : null;

      this.phones = clientNetwork.phones ? clientNetwork.phones : [];
      this.emails = clientNetwork.emails ? clientNetwork.emails : [];
      this.addresses = clientNetwork.addresses ? clientNetwork.addresses : [];
    } else {
      this.fullName = 'n / a';
    }
  }
}
