import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';
import { MasterDataService } from '../../master-data/master-data.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ReturnCodeService {
  public values: IFormDropdownOption[] = [];
  public lookupValues: string[] = [];

  constructor(
    private masterDataService: MasterDataService,
    private translate: TranslateService,
  ) {
    this.getReturnCodes();
  }

  public getReturnCodes() {
    this.masterDataService.getMasterData('return-codes').subscribe((data) => {
      this.values = data.map((returnCode) => {
        return this.createDisplayVersion(returnCode);
      });
      for (let item of this.values) {
        this.lookupValues[item.value] = item.label;
      }
    });
  }

  private createDisplayVersion(returnCode: {
    label: string;
    value: string;
  }): IFormDropdownOption {
    // kind of a nasty solution, check with berry and rethink fixing the returncode in the masterdata
    for (let i = returnCode.value.length; i < 4; i++) {
      returnCode.value = '0' + returnCode.value;
    }
    return {
      value: returnCode.value,
      label: returnCode.value + ' ' + this.translate.instant(returnCode.label),
    };
  }
}
