export class TableRowSelection {
  selectedRows = new Map<any,any>();
  rowSelectionType: 'multiple' | 'single' = 'single';
  rowSelectionProperty = 'id';
  selectAllRowsVisible = true;
  selectableRows = [];
  public isRowSelectable: (row: any) => boolean;

  constructor(init?: Partial<TableRowSelection>) {
    Object.assign(this, init);
    this.isRowSelectable = init?.isRowSelectable || this.defaultIsRowSelectable;
  }

  public setRows(rows) {
    this.selectableRows = rows?.filter(row => this.isRowSelectable(row));
  }

  public getSelectedRows() {
    return [...this.selectedRows.values()];
  }

  public setSelectedRows(rows: []) {
    this.clearSelectedRows();
    rows?.forEach(row => {
      this.onRowSelected(row, true);
    });
  }

  public addSelectedRows(rows: []) {
    rows?.forEach(row => {
      this.onRowSelected(row, true);
    });
  }

  public hasSelectedRows() {
    return this.selectedRows && this.selectedRows?.size > 0;
  }

  public clearSelectedRows() {
    this.selectedRows.clear();
  }


  public onRowSelected(rowVal, selected) {
    if (selected) {
      if (this.rowSelectionType === 'single') {
        this.clearSelectedRows();
        this.selectedRows.set(rowVal[this.rowSelectionProperty], rowVal);
      } else {
        this.selectedRows.set(rowVal[this.rowSelectionProperty], rowVal);
      }
    } else {
      this.selectedRows.delete(rowVal[this.rowSelectionProperty]);
    }
  }

  public onSelectAllRows() {
    if (this.isAllSelected()) {
      this.clearSelectedRows();
    } else {
      this.selectableRows?.forEach(row => {
        this.onRowSelected(row, true);
      });
    }
  }

  public isRowSelected(rowVal) {
    return this.selectedRows?.has(rowVal[this.rowSelectionProperty]);
  }

  public isAllSelected() {
    return this.selectableRows?.length > 0 && !this.selectableRows?.find(row => !this.isRowSelected(row));
  }

  private defaultIsRowSelectable(row) {
    return true;
  }
}
