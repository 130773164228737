import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MismatchAnswer,
  MismatchQuestion,
  MismatchQuestionSetAnswer,
} from '../../../models/mismatch-answer/mismatch-answer.model';
import { QuestionRenderHelper } from '../question-render/question-render.helper';

export class MismatchHelper {
  questionRenderHelper: QuestionRenderHelper;

  constructor(public fb: FormBuilder) {
    this.questionRenderHelper = new QuestionRenderHelper(this.fb);
  }

  public buildMismatchQuestionAnswerFormGroup(
    mismatchQuestion: MismatchQuestion,
  ): FormGroup {
    const mismatchQuestionAnswerFormGroup = this.fb.group({
      inheritAnswer: [false, Validators.nullValidator],
      entityQuestionAnswer: this.questionRenderHelper.buildAnswersFormGroup(
        mismatchQuestion.entityQuestionAnswer,
      ),
      inheritingQuestionAnswer: this.questionRenderHelper.buildAnswersFormGroup(
        mismatchQuestion.inheritingQuestionAnswer,
      ),
    });

    mismatchQuestionAnswerFormGroup.get('entityQuestionAnswer').disable();
    mismatchQuestionAnswerFormGroup.get('inheritingQuestionAnswer').disable();

    return mismatchQuestionAnswerFormGroup;
  }

  public buildMismatchQuestionSetAnswerFormGroup(
    mismatchQuestionSetAnswer: MismatchQuestionSetAnswer,
  ): FormGroup {
    const mismatchQuestionSetAnswerFormGroup = this.fb.group({
      questionSetAnswerId: mismatchQuestionSetAnswer.questionSetAnswerId,
      questionSetAnswerTitle: mismatchQuestionSetAnswer.questionSetAnswerTitle,
      mismatchedQuestions: this.fb.array([]),
    });

    if (mismatchQuestionSetAnswer.mismatchedQuestions) {
      for (let mismatchQuestion of mismatchQuestionSetAnswer.mismatchedQuestions) {
        const mismatchQuestionFormGroup =
          this.buildMismatchQuestionAnswerFormGroup(mismatchQuestion);
        (
          mismatchQuestionSetAnswerFormGroup.get(
            'mismatchedQuestions',
          ) as FormArray
        ).push(mismatchQuestionFormGroup);
      }
    }

    return mismatchQuestionSetAnswerFormGroup;
  }

  public buildMismatchAnswerFormGroup(
    mismatchAnswer: MismatchAnswer,
  ): FormGroup {
    const mismatchAnswersForm = this.fb.group({
      id: mismatchAnswer.id,
      entityId: mismatchAnswer.entityId,
      entityVersion: mismatchAnswer.entityVersion,
      entityType: mismatchAnswer.entityType,
      inheritingEntityId: mismatchAnswer.inheritingEntityId,
      inheritingEntityVersion: mismatchAnswer.inheritingEntityVersion,
      inheritingEntityType: mismatchAnswer.inheritingEntityType,
      tenantId: mismatchAnswer.tenantId,
      mismatchedQuestionSetAnswers: this.fb.array([]),
    });

    if (mismatchAnswer.mismatchedQuestionSetAnswers) {
      for (let mismatchQuestionSetAnswer of mismatchAnswer.mismatchedQuestionSetAnswers) {
        const mismatchQuestionSetAnswerFormGroup =
          this.buildMismatchQuestionSetAnswerFormGroup(
            mismatchQuestionSetAnswer,
          );
        (
          mismatchAnswersForm.get('mismatchedQuestionSetAnswers') as FormArray
        ).push(mismatchQuestionSetAnswerFormGroup);
      }
    }

    return mismatchAnswersForm;
  }
}
