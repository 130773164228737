import { Pipe, PipeTransform } from '@angular/core';
import { DEFAULT_DATE_TIME_FORMAT } from '../pipes.const';
import { UserInfoService } from '../../services';
import * as moment from 'moment-timezone';
@Pipe({
  name: 'appDateTime',
})
export class CustomDateTimePipe implements PipeTransform {
  constructor(private userService: UserInfoService) {}
  transform(value: any, args?: any): any {
    if (!value) return null;
    const tenantTimezone =
      this.userService.tenantTimeZone ||
      JSON.parse(localStorage.getItem('tenantTimeZone'));
    if (args === 'noFormat') {
      return moment(value).tz(tenantTimezone);
    }
    return moment(value).tz(tenantTimezone).format(DEFAULT_DATE_TIME_FORMAT);
  }
}
