import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  Type,
  Injector,
} from '@angular/core';
import { SideSheetService } from '../../services';
import {
  ActionType,
  Button,
  CheckBox,
  FormerSidesheet,
  Option,
  SelectBoxes,
  SideSheetButtonListener,
  SideSheetCheckboxListener,
  SideSheetComponentLoadListener,
  SideSheetConfig,
  SideSheetContent,
  SideSheetDropdownListener,
  SideSheetTooltipButtonListener,
  SidesheetRef
} from '../../models';
import {Subscription} from 'rxjs';
import {SideSheetHostDirective} from '../../directives/side-sheet-host.directive';
import {MatDrawer} from '@angular/material/sidenav';
import {
  createButton,
  createDefaultSideSheetConfig,
  createDefaultSideSheetContent,
} from '../../services/side-sheet/initial-side-sheet-config';
import { SIDE_SHEET_CONFIG, SIDE_SHEET_CONTENT } from '../../models/side-sheet/side-sheet-token';

@Component({
  selector: 'app-side-sheet',
  templateUrl: './side-sheet.component.html',
  styleUrls: ['./side-sheet.component.scss'],
})
export class SideSheetComponent implements OnInit, OnDestroy {
  @ViewChild('sideSheetElement') sideSheetElement: MatDrawer;
  @ViewChild(SideSheetHostDirective, { static: true })
  sideSheetHost!: SideSheetHostDirective;

  private subscription: Subscription = new Subscription();

  sideSheetConfig: SideSheetConfig = createDefaultSideSheetConfig();
  sideSheetContent: SideSheetContent = createDefaultSideSheetContent();
  formerSidesheets: FormerSidesheet[] = [];
  activeComponent: FormerSidesheet;

  constructor(
    private sideSheetService: SideSheetService,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.sideSheetService.sideSheetHost$.subscribe((data) => {
        this.loadComponent(
          data.component,
          data.content,
          data.config,
          data.sidesheetRef,
          data.clearContent,
        );
      }),
    );

    this.subscription.add(
      this.sideSheetService.closeSidesheetSubject$.subscribe((data) => {
        this.closeSideSheet();
      }),
    );

    this.subscription.add(
      this.sideSheetService.closeComponentSubject$.subscribe((data) => {
        this.closeLastComponent(data, true);
        this.showLastComponent();
      }),
    );
  }


  ngOnDestroy(): void {
    this.closeSideSheet();
    this.sideSheetElement.close();
    this.subscription.unsubscribe();
  }

  loadComponent(
    component: Type<any>,
    content: Partial<SideSheetContent>,
    config: Partial<SideSheetConfig>,
    sidesheetRef: SidesheetRef,
    clearContent: boolean = false,
  ): void {
    if (clearContent) {
      this.clearContent();
    }

    const foundIndex = this.formerSidesheets.findIndex(
      (item) => item.componentRef.componentType.name === component.name,
    );

    if (foundIndex === -1) {
      const former = new FormerSidesheet();
      former.config = { ...this.sideSheetConfig, ...config };
      former.content = content;
      former.sidesheetRef = sidesheetRef;
      const inject = {
        injector: this.createSideSheetInjector(
          this.injector,
          former.content,
          former.config,
        ),
      };
      former.componentRef = this.sideSheetHost.viewContainerRef.createComponent(
        component,
        inject,
      );

      if (this.activeComponent) {
        this.unloadComponent(this.activeComponent);
      }

      this.formerSidesheets.push(former);
    } else {
      const remainingArrayAfterFoundComponent = this.formerSidesheets.slice(
        foundIndex + 1,
      );
      remainingArrayAfterFoundComponent?.forEach((item) => {
        this.closeComponent(item, null, false);
      });

      this.formerSidesheets = this.formerSidesheets.slice(0, foundIndex + 1);
    }

    this.showLastComponent();

    this.sideSheetElement.open();
  }

  private showLastComponent() {
    if (this.formerSidesheets?.length > 0) {
      const item = this.formerSidesheets[this.formerSidesheets.length - 1];
      item.componentRef.location.nativeElement.style.display = 'block';
      this.sideSheetConfig = item.config;
      this.sideSheetContent = item.content;

      this.activeComponent = item;
      this.executeLoadEvent(item);
    }
  }

  private clearContent(): void {
    this.sideSheetHost.viewContainerRef.clear();
    this.formerSidesheets = [];
    this.sideSheetConfig = createDefaultSideSheetConfig();
    this.activeComponent = null;
  }

  closeLastComponent(data, closeSidesheetIfLastItem: boolean) {
    const latest = this.formerSidesheets.pop();
    this.closeComponent(latest, data, closeSidesheetIfLastItem);
    this.activeComponent = null;
  }

  closeSideSheet() {
    [...this.formerSidesheets]?.forEach((former) => {
      this.closeLastComponent(null, true);
    });
  }

  unloadComponent(item: FormerSidesheet) {
    this.executeUnloadEvent(item);
    item.componentRef.location.nativeElement.style.display = 'none';
  }

  closeComponent(
    item: FormerSidesheet,
    data,
    closeSidesheetIfLastItem: boolean = true,
  ) {
    this.unloadComponent(item);

    item.componentRef.destroy();
    item.sidesheetRef.close(data);

    if (this.formerSidesheets?.length === 0) {
      this.clearContent();
      if (closeSidesheetIfLastItem) {
        this.sideSheetElement.close();
      }
    }
  }

  createSideSheetInjector(
    parentInjector: Injector,
    content: SideSheetContent,
    config: SideSheetConfig,
  ): Injector {
    return Injector.create({
      providers: [
        { provide: SIDE_SHEET_CONTENT, useValue: content },
        { provide: SIDE_SHEET_CONFIG, useValue: config },
      ],
      parent: parentInjector,
    });
  }

  onBackdropClicked(){
    const tempButton = createButton("Backdrop", ActionType.BACKDROP_CLICK);
    this.executeButtonClick(tempButton);
  }

  executeLoadEvent(comp: FormerSidesheet) {
    const instanceAsSideSheet = <SideSheetComponentLoadListener>(
      comp.componentRef.instance
    );
    if (instanceAsSideSheet.onLoadComponent !== undefined) {
      instanceAsSideSheet.onLoadComponent({
        content: comp.content,
        config: comp.config,
      });
    }
  }

  executeUnloadEvent(comp: FormerSidesheet) {
    if (comp?.componentRef) {
      const instanceAsSideSheet = <SideSheetComponentLoadListener>(
        comp.componentRef.instance
      );
      if (instanceAsSideSheet.onUnloadComponent !== undefined) {
        instanceAsSideSheet.onUnloadComponent({
          content: comp.content,
          config: comp.config,
        });
      }
    }
  }

  executeButtonClick(button: Button) {
    const instanceAsSideSheet = <SideSheetButtonListener>(
      this.activeComponent.componentRef.instance
    );
    if (instanceAsSideSheet.onClickButton !== undefined) {
      instanceAsSideSheet.onClickButton({
        button,
        content: this.sideSheetContent,
        config: this.sideSheetConfig,
      });
    }
  }

  executeCheckboxSelection(checkbox: CheckBox) {
    const instanceAsSideSheet = <SideSheetCheckboxListener>(
      this.activeComponent.componentRef.instance
    );
    if (instanceAsSideSheet.onCheckboxSelected !== undefined) {
      instanceAsSideSheet.onCheckboxSelected({
        checkbox,
        content: this.sideSheetContent,
        config: this.sideSheetConfig,
      });
    }
  }

  executeDropdownSelection(data: { dropdown: SelectBoxes; option: Option }) {
    const instanceAsSideSheet = <SideSheetDropdownListener>(
      this.activeComponent.componentRef.instance
    );
    if (instanceAsSideSheet.onDropdownSelected !== undefined) {
      instanceAsSideSheet.onDropdownSelected({
        selectBoxes: data.dropdown,
        option: data.option,
        content: this.sideSheetContent,
        config: this.sideSheetConfig,
      });
    }
  }

  executeTooltipButtonClick(data) {
    const instanceAsSideSheet = <SideSheetTooltipButtonListener>(
      this.activeComponent.componentRef.instance
    );
    if (instanceAsSideSheet.onClickTooltipButton !== undefined) {
      instanceAsSideSheet.onClickTooltipButton({
        button: data.button,
        confirmed: data.confirmed,
        content: this.sideSheetContent,
        config: this.sideSheetConfig,
      });
    }
  }
}
