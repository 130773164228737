import { Component, OnInit, Inject } from '@angular/core';
import {
  BreadcrumbService,
  Breadcrumb,
} from '../../services/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  get breadcrumbs$() {
    return this.breadcrumbService.breadcrumbs$;
  }
  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {}
}
