<app-panel type="popup">
  <app-panel-title title="general.titles.Widgets">
    <ng-container panelButtons>
      <button class="c4p-button" (click)="loadDefault()" translate>
        general.actions.LoadDefault
      </button>
    </ng-container>
  </app-panel-title>

  <div [formGroup]="formArray">
    <app-panel-form-row
      mode="-questions"
      *ngFor="let control of formArray.controls; let i = index"
    >
      <ng-container [formGroupName]="i">
        <app-form-input-checkbox
          formControlName="visible"
          name="visible"
          [placeholder]="control.value.name"
        >
        </app-form-input-checkbox>
      </ng-container>
    </app-panel-form-row>
  </div>
</app-panel>

<div style="display: flex">
  <span class="c4p-button-cancel" (click)="onDismiss()" translate
    >general.labels.Cancel</span
  >
  <span class="c4p-button-ok" (click)="onConfirm()">
    <span>OK</span>
  </span>
</div>
