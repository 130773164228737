<ng-container>
  <app-panel type="popup" style="min-width: 500px">
    <app-panel-title
      title="document.labels.documentSignature"
      titleIcon="person_add_alt_1"
    >
    </app-panel-title>
    <app-panel-form-row>
      <div style='display:flex; padding-top: 10px; padding-left: 10px; ' *ngIf="!isSignedDocument">
        <span translate>document.labels.signingDocument &nbsp; </span>
        :&nbsp;{{ documentName }}
      </div>
      <div style='display:flex; padding-top: 10px; padding-left: 10px; background-color: #ffffcc; line-height: 20px;' *ngIf="isSignedDocument">
        <span translate>document.labels.signedDocument &nbsp; </span>
        :&nbsp;{{documentName}}
      </div>
    </app-panel-form-row>
    <app-panel-form-row>
      <mat-checkbox
              style="margin-left: 10px; padding-top: 10px" *ngIf="isEmployeePortal"
              [(ngModel)]="signatureIncludeRole" id="signatureIncludeRole"
      >
        <span class="c4p-ellipsis" translate
        >document.labels.signatureIncludeRole</span
        >
      </mat-checkbox>
    </app-panel-form-row>
    <app-panel-form-row>
      <mat-checkbox
        style="margin-left: 10px; padding-top: 10px"
        [(ngModel)]="signatureConfirmation"
      >
        <span class="c4p-ellipsis" translate
          >document.labels.signatureConfirmation</span
        >
      </mat-checkbox>
    </app-panel-form-row>
    <app-panel-form-row>
      <span style="color: red; padding-left: 10px"> {{ errorMessage }} </span>
    </app-panel-form-row>
    <app-panel-form-row>
      <span style="padding-left: 10px" translate
        >document.labels.signatureStatus &nbsp;</span
      >:&nbsp;
      <mat-spinner *ngIf="showSpinner" [diameter]="20"></mat-spinner>
      <span *ngIf="!showSpinner && signatureStatus"
        ><mat-icon>done</mat-icon></span
      >
      <span *ngIf="!showSpinner && !signatureStatus"
        ><mat-icon>close</mat-icon></span
      >
    </app-panel-form-row>
    <div style="display: flex; padding-top: 10px; overflow: hidden">
      <span
        *ngIf="!(signatureConfirmation && signatureStatus && !errorOccurred)"
        [ngStyle]="{
        'opacity': showSpinner ? '0.5' : '1',
        'cursor': showSpinner ? 'initial' : 'cursor'
        }"
        class="c4p-button-cancel"
        (click)="close()"
        translate
        >general.labels.Cancel</span
      >
      <span
        [ngStyle]="{
        'opacity': showSpinner ? '0.5' : '1',
        'cursor': showSpinner ? 'initial' : 'cursor'
        }"
        *ngIf="signatureConfirmation && signatureStatus && !errorOccurred"
        class="c4p-button-cancel"
        (click)="close()"
        translate
        >general.actions.Close</span
      >
      <span class="c4p-button-ok" disabled="true"  [ngStyle]="{
        'opacity': showSpinner ? '0.5' : '1',
        'cursor': showSpinner ? 'initial' : 'cursor'
        }"
        (click)="submit()">
        <span
          *ngIf="signatureConfirmation && !signatureStatus && !errorOccurred"
          translate
          >general.actions.ConfirmAndSign</span
        >
        <span
          *ngIf="signatureConfirmation && !signatureStatus && errorOccurred"
          translate
          >general.actions.RetrySign</span
        >
      </span>
    </div>
  </app-panel>
</ng-container>
