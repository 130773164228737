<ng-container [formGroup]="filtersForm">
  <app-panel-form-row>
    <app-form-input-text
      formControlName="documentName"
      placeholder="document.labels.DocumentName"
    ></app-form-input-text>
    <app-form-input-text
      formControlName="description"
      placeholder="document.labels.Description"
    ></app-form-input-text>
    <app-form-input-dropdown
      formControlName="services"
      placeholder="document.labels.Service"
      [multiple]="true"
      [options]="documentService.services"
      [objectAsValue]="true"
      [valueProperty]="documentService.isCarefile() ? 'id' : 'globalServiceId'"
      labelProperty="name"
    ></app-form-input-dropdown>
    <!-- Below needs to be category -->
    <app-form-input-dropdown
      formControlName="categories"
      placeholder="document.labels.Category"
      [multiple]="true"
      [options]="documentService.categories"
      [objectAsValue]="true"
      valueProperty="name"
      labelProperty="name"
    ></app-form-input-dropdown>
    <app-form-input-text
      formControlName="documentType"
      placeholder="document.labels.DocumentType"
    ></app-form-input-text>
    <app-form-input-text
      formControlName="createdByName"
      placeholder="general.labels.CreatedBy"
    ></app-form-input-text>
    <app-form-input-date
      formControlName="dateFrom"
      placeholder="application.filter.From"
    ></app-form-input-date>
    <app-form-input-date
      formControlName="dateUntil"
      placeholder="application.filter.Until"
    ></app-form-input-date>
    <!-- <app-form-input-dropdown
      formControlName="status"
      placeholder="application.filter.Status"
      [options]="STATUSES"
      multiple="true"
    ></app-form-input-dropdown> -->

    <button class="c4p-button" (click)="filtersForm.reset()" translate>
      general.actions.Reset
    </button>
  </app-panel-form-row>
</ng-container>
