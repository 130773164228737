<app-page-content>
  <app-panel [formGroup]="careprogramForm">
    <app-panel-title
      title="careprogram.titles.BasicGGZProfile"
      titleIcon="new_label"
    >
      <ng-container panelButtons>
        <button
          *ngIf="!basicGGZFormArray.disabled"
          class="c4p-button"
          (click)="createBasicGGZProfile()"
          translate
        >
          general.labels.Add
        </button>
      </ng-container>
    </app-panel-title>

    <div class="c4p-table-view-wrap" formArrayName="basicGGZProfile">
      <table
        mat-table
        [dataSource]="basicGGZFormArray.value"
        width="100%"
        *ngIf="basicGGZFormArray.controls.length > 0"
      >
        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef translate>
            general.labels.StartDate
          </th>
          <td mat-cell *matCellDef="let basicGGZProfile">
            {{ basicGGZProfile.startDate | appDate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef translate>
            general.labels.EndDate
          </th>
          <td mat-cell *matCellDef="let basicGGZProfile">
            {{ basicGGZProfile.endDate | appDate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef translate>
            general.labels.Code
          </th>
          <td mat-cell *matCellDef="let basicGGZProfile">
            {{ basicGGZProfile['profile']['code'] }}
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef translate>
            general.labels.Name
          </th>
          <td mat-cell *matCellDef="let basicGGZProfile">
            {{ basicGGZProfile['profile']['name'] }}
          </td>
        </ng-container>
        <ng-container matColumnDef="operations">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let row; let i = index"
            style="text-align: right"
          >
            <mat-icon
              matPrefix
              class="c4p-action-icons"
              (click)="viewBasicGGZ(i)"
              >visibility</mat-icon
            >
            <mat-icon
              *ngIf="!basicGGZFormArray.disabled"
              matPrefix
              class="c4p-action-icons"
              (click)="editBasicGGZProfile(row, i)"
              >create</mat-icon
            >
            <mat-icon
              *ngIf="!basicGGZFormArray.disabled"
              matPrefix
              class="c4p-action-icons"
              (click)="deleteBasicGGZ(i)"
              >delete</mat-icon
            >
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <app-panel-form-row
      *ngIf="basicGGZFormArray.controls.length === 0"
      rowClass="c4p-empty-table-row"
    >
      <span translate>general.messages.FormEmpty</span>
    </app-panel-form-row>
  </app-panel>
</app-page-content>
