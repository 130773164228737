import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../../services';

export enum BillingAndInvoiceMethod {
  DECLARATION = 'declaration',
  DECLARATIE = 'declaratie',
  INVOICE = 'invoice',
  DEBIT_INVOICE = 'debetfactuur',
  CREDIT_INVOICE = 'creditfactuur',
  DEBIT = 'debit',
  CREDIT = 'credit',
  CORRECTION_DECLARATION = 'correctie-declaratie',
}

@Pipe({
  name: 'billingAndInvoiceMethod',
})
export class BillingAndInvoiceMethodPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}
  transform(value: string): string {
    switch (value) {
      case BillingAndInvoiceMethod.DECLARATION:
        return this.languageService.translation(
          'billing.enum.invoiceType.declaratie',
        );
      case BillingAndInvoiceMethod.DECLARATIE:
        return this.languageService.translation(
          'billing.enum.invoiceType.declaratie',
        );
      case BillingAndInvoiceMethod.INVOICE:
        return this.languageService.translation('billing.labels.Invoice');
      case BillingAndInvoiceMethod.DEBIT_INVOICE:
        return this.languageService.translation(
          'billing.enum.invoiceType.debetfactuur',
        );
      case BillingAndInvoiceMethod.CREDIT_INVOICE:
        return this.languageService.translation(
          'billing.enum.invoiceType.creditfactuur',
        );
      case BillingAndInvoiceMethod.DEBIT:
        return this.languageService.translation(
          'billing.enum.invoiceType.debit',
        );
      case BillingAndInvoiceMethod.CREDIT:
        return this.languageService.translation(
          'billing.enum.invoiceType.credit',
        );
      case BillingAndInvoiceMethod.CORRECTION_DECLARATION:
        return this.languageService.translation(
          'billing.enum.invoiceType.correctie-declaratie',
        );
      default:
        return value;
    }
  }
}
