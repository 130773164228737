import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
  HttpHeaders,
  HttpContextToken,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { DataWrapper } from '../../models/gatekeeper/data-wrapper.model';
import { GatekeeperService } from './gatekeeper.service';
import { GatekeeperComponent } from '../../general-components/gatekeeper/gatekeeper.component';
import { MatDialog } from '@angular/material/dialog';
import { switchMap } from 'rxjs/operators';
import { LanguageService } from '../language/language.service';
import { DialogComponent } from '../../general-components/dialog/dialog.component';

export const CAREFILE_DATA_WRAPPER = new HttpContextToken<DataWrapper>(
  () => null,
);

const SKIPPED_CONFIGS: Array<{ method: string; endpoint: string }> = [
  { method: 'GET', endpoint: '/carefiles/:carefile_id' },
];

@Injectable()
export class GatekeeperInterceptor implements HttpInterceptor {
  constructor(
    private gatekeeperService: GatekeeperService,
    @Inject('environment') public environment: any,
    public dialog: MatDialog,
    public languageService: LanguageService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const index = req.url.indexOf('api');

    if (index === -1) {
      return next.handle(req);
    }

    // handle carefile gatekeeper
    const carefileDataWrapper: DataWrapper = req.context.get(
      CAREFILE_DATA_WRAPPER,
    );
    if (carefileDataWrapper) {
      const request = this.createRequestFromDataWrapper(
        carefileDataWrapper,
        req,
      );
      return next.handle(request);
    }

    const method = req.method;
    const endpoint = req.headers.get('origin_path');
    if (
      SKIPPED_CONFIGS.some(
        (config) => config.method === method && config.endpoint === endpoint,
      )
    ) {
      const request = this.createRequestFromDataWrapper(<any>{}, req);
      return next.handle(request);
    }

    const config = this.gatekeeperService.requiresValidation(method, endpoint);

    const bypassConfirmation = !!req.headers.get('bypassConfirmation');

    if (config && (config.requireConfirmation || config.requireReason)) {
      return this.dialog
        .open(GatekeeperComponent, {
          data: config,
        })
        .afterClosed()
        .pipe(
          switchMap((dataWrapper: DataWrapper) => {
            if (dataWrapper) {
              const request = this.createRequestFromDataWrapper(
                dataWrapper,
                req,
              );
              return next.handle(request);
            } else {
              return EMPTY;
            }
          }),
        );
    } else if (req.method === 'DELETE' && !bypassConfirmation) {
      return this.dialog
        .open(DialogComponent, {
          data: {
            title: 'general.messages.DeleteItem',
            message: 'general.labels.AreYouSureDelete',
            confirmText: 'general.actions.delete',
          },
        })
        .afterClosed()
        .pipe(
          switchMap((confirmed) => {
            if (confirmed) {
              return next.handle(req);
            } else {
              return EMPTY;
            }
          }),
        );
    } else {
      return next.handle(req);
    }
  }

  private createRequestFromDataWrapper(
    dataWrapper: DataWrapper,
    req: HttpRequest<any>,
  ): HttpRequest<any> {
    dataWrapper.wrappedBody = req.body;
    dataWrapper.method = req.method;
    dataWrapper.endpoint = req.headers.get('origin_path');
    let headers: HttpHeaders = req.headers;
    if (req.method === 'GET') {
      const dataWrapperHeader = btoa(JSON.stringify(dataWrapper));
      headers = headers.set('data_wrapper', dataWrapperHeader);
    }
    return req.clone({
      headers,
      method: req.method,
      url: req.url,
      body: dataWrapper,
    });
  }
}
