import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CarefileServiceModel } from '../models/carefile-service.model';
import { DropdownServiceBase } from '../../../services/dropdown-service.abstract';
import { PaginationDataModel } from '../../../models/pagination/pagination-data.model';
import { PaginationResultModel } from '../../../models/pagination/pagination-result.model';
import { HttpUtils } from '../../../utilities/http.utils';

@Injectable()
export class CarefileServiceService extends DropdownServiceBase<CarefileServiceModel> {
  public carefileServices$ = new BehaviorSubject<{
    data: CarefileServiceModel[];
    count: number;
  }>(null);

  public selectedService$ = new BehaviorSubject<CarefileServiceModel>(null);

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
  ) {
    super();

    this.setupDropdown(this.getCarefileServices);
  }

  public getCarefileServices(
    paginationData: PaginationDataModel,
    filterData?: any,
  ): Observable<PaginationResultModel<CarefileServiceModel>> {
    if (!filterData.carefileId)
      return of({
        docs: [],
        hasNextPage: false,
        hasPrevPage: null,
        limit: null,
        nextPage: null,
        page: null,
        pagingCounter: null,
        prevPage: null,
        totalDocs: null,
        totalPages: null,
      });

    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles/:carefile_id/services',
      entity_id: filterData.carefileId,
    });
    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
      'carefileId',
    );

    return this.http.get<any>(
      `${this.environment.carefileManagementApiUrl}/${filterData.carefileId}/services`,
      { headers, params },
    );
  }
}
