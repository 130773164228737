import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'appDuration',
  pure: true,
})
export class DurationPipe implements PipeTransform {
  transform(startTime: string, endTime: any): any {
    if (startTime && endTime) {
      const [startHours, startMinutes] = startTime.split(':');
      const startMoment = moment().set({
        hours: +startHours,
        minutes: +startMinutes,
      });
      const [endHours, endMinutes] = endTime.split(':');
      const endMoment = moment().set({
        hours: +endHours,
        minutes: +endMinutes,
      });
      return endMoment.diff(startMoment) < 3600001
        ? moment.utc(endMoment.diff(startMoment)).format('HH:mm')
        : moment.utc(endMoment.diff(startMoment)).format('kk:mm');
    }
  }
}
