import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AnswerTypeConfigurationModel } from '../../../models/question-set-answer/answer-type-configuration.model';
import { FormatCleanerService } from '../../../services/format-cleaner/format-cleaner.service';

@Component({
  selector: 'app-question-render-form',
  templateUrl: './question-render-form.component.html',
  styleUrls: ['./question-render-form.component.css'],
})
export class QuestionRenderFormComponent implements OnInit {
  @Input() inputFormGroup: FormGroup;
  optionsForChoice: [options: AnswerTypeConfigurationModel[]];

  constructor(public formatCleanerService: FormatCleanerService) {}

  ngOnInit(): void {
    if (this.typeControlValue === 4 || this.typeControlValue === 5) {
      const options = [];
      for (let option of this.answerControlValue.typeConfiguration) {
        options.push({ label: option.displayText, value: option._id });
      }
      this.optionsForChoice[this.idControlValue] = options;
    }
  }

  get titleControlValue() {
    return this.inputFormGroup.get('title').value;
  }
  get hintControlValue() {
    return this.inputFormGroup.get('hint').value;
  }
  get orderNumControlValue() {
    return this.inputFormGroup.get('order').value;
  }
  get mandatoryControlValue() {
    return this.inputFormGroup.get('isMandatory').value;
  }
  get commentRequiredControlValue() {
    return this.inputFormGroup.get('commentRequired').value;
  }
  get answerControlValue() {
    return this.inputFormGroup.get('answer').value;
  }
  get focusRichTextControlValue() {
    return this.inputFormGroup.get('focusRichText').value;
  }
  get idControlValue() {
    return this.inputFormGroup.get('id').value;
  }
  get typeControlValue() {
    return this.inputFormGroup.get('type').value;
  }

  onEditorCreated(event) {
    event.root.focus();
  }

  onRichContent() {
    this.inputFormGroup
      .get('answer')
      .patchValue(
        this.formatCleanerService.cleanUpHtml(this.answerControlValue),
      );
    this.inputFormGroup.controls.focusRichText.patchValue(false);
  }
}
