<div class="c4p-form-input-small">
  <mat-checkbox
    [ngModel]="value"
    (ngModelChange)="onChange($event)"
    [disabled]="readonly"
    [labelPosition]="'after'"
    class="c4p-ellipsis"
  >
    {{ label | translate }}
  </mat-checkbox>
</div>
