<div class="c4p-align-right" *ngIf="items && items.length > 0">
  <div *ngFor="let menu of additionalMenus">
    <app-page-menu-additional
      [items]="menu.menuItems"
      [name]="menu.name"
    ></app-page-menu-additional>
  </div>

  <div *ngFor="let item of inlineItems">
    <button
      class="page-menu-button"
      [class.disabled-inline-action]="item.disabled"
      [disabled]="item.disabled"
      mat-icon-button
      (click)="item.method(item.tooltip)"
      matTooltip="{{ item.tooltip | translate }}"
    >
      <mat-icon matPrefix class="c4p-action-icons">{{ item.icon }}</mat-icon>
    </button>
  </div>

  <button
    class="page-menu-button"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    *ngIf="menuItems.length > 0"
  >
    <mat-icon class="c4p-action-icons">more_horiz</mat-icon>
  </button>

  <mat-menu #menu="matMenu" class="c4p-menu">
    <div *ngFor="let item of menuItems">
      <div
        [disabled]="item.disabled"
        mat-menu-item
        class="c4p-menu-item"
        (click)="item.method(item.tooltip)"
      >
        <mat-icon class="c4p-action-menu-icon">{{ item.icon }}</mat-icon>
        <span class="c4p-menu-title">{{ item.tooltip | translate }}</span>
      </div>
    </div>
  </mat-menu>
</div>
