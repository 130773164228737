import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls/form-controls.const';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class DateUseService {
  public values: IFormDropdownOption[] = [];

  constructor(private masterDataService: MasterDataService) {
    this.getDateUseValues();
  }

  public getDateUseValues() {
    this.masterDataService
      .getMasterData('date-use')
      .subscribe((data) => (this.values = data));
  }
}
