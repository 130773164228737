import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ActionType, Button, SideSheetConfig, SideSheetContent} from '../../../models/side-sheet';
import { SideSheetService } from '../../../services';
import { Subscription } from 'rxjs';
import { createButton } from '../../../services/side-sheet/initial-side-sheet-config';

@Component({
  selector: 'app-sidesheet-title-bar',
  templateUrl: './sidesheet-title-bar.component.html',
  styleUrls: ['./sidesheet-title-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidesheetTitleBarComponent implements OnInit, OnDestroy {
  @Input() sideSheetConfig: SideSheetConfig;
  @Input() sideSheetContent: SideSheetContent;
  @Input() titleBarActionsTemplate: TemplateRef<any>;

  @Output() buttonClicked = new EventEmitter<Button>();
  // @Output() closeSidesheet = new EventEmitter<Button>();

  closeButton: Button;

  private subscription: Subscription = new Subscription();

  constructor(private sideSheetService: SideSheetService) {
    this.closeButton = createButton('', ActionType.SIDE_SHEET_CLOSE);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleFullScreen(): void {
    this.sideSheetConfig.fullscreen = !this.sideSheetConfig.fullscreen;
  }

  onCloseButton(){
    this.buttonClicked.emit(this.closeButton);
  }
}
