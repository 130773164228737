<mat-card appearance="outlined" class="c4p-card-view">
  <div class="c4p-note-view-header" [style.background-color]="data?.color">
    <ng-container *ngFor="let field of firstPart">
      <span *ngIf="field.type == 'date'" style="display: inline-block">
        <span class="c4p-font-large c4p-ellipsis notes-title">{{
          data[field.id] | appDateTime
        }}</span>
      </span>
      <span
        *ngIf="field.type == 'boolean'"
        style="display: block; float: right"
      >
        <span *ngFor="let action of options.menuActions">
          <div
            *ngIf="action.icon === 'push_pin'"
            [class.disabled-inline-action]="
              !action.hasPermission || !action.conditionMethod(data)
            "
          >
            <a
              (click)="action.method(data)"
              matTooltip="{{ action.tooltip | translate }}"
            >
              <mat-icon
                matPrefix
                class="c4p-action-icons"
                [color]="data[field.id] ? 'primary' : ''"
                >{{ action.icon }}</mat-icon
              >
            </a>
          </div>
        </span>
      </span>
    </ng-container>
  </div>

  <div class="c4p-card-view-content" [style.background-color]="data?.color">
    <ng-container *ngFor="let field of secondPart" style="padding-top: 12px">
      <div
        *ngIf="field.type == 'string' && field.format != 'title'"
        class="c4p-card-view-value"
      >
        <div
          class="c4p-font-normal card-txt ql-editor"
          [innerHTML]="data[field.id] | html"
        ></div>
      </div>
    </ng-container>
  </div>

  <!-- For now we will use menuActions so we can share same configuration for table or card -->
  <div
    class="c4p-card-notes-footer"
    *ngIf="options.menuActions && options.menuActions.length > 0"
    [style.background-color]="data?.color"
  >
    <span>
      <span *ngFor="let action of options.menuActions">
        <span
          *ngIf="action.icon !== 'push_pin'"
          [class.disabled-inline-action]="
            !action.hasPermission || !action.conditionMethod(data)
          "
        >
          <a
            (click)="action.method(data)"
            matTooltip="{{ action.tooltip | translate }}"
          >
            <mat-icon matPrefix class="c4p-action-icons">{{
              action.icon
            }}</mat-icon>
          </a>
        </span>
      </span>
    </span>
    <ng-container *ngFor="let field of thirdPart">
      <div
        *ngIf="field.type == 'string' && field.format == 'title'"
        class="c4p-card-view-value"
      >
        <span class="c4p-font-normal c4p-ellipsis">{{ data[field.id] }}</span>
      </div>
    </ng-container>
  </div>
</mat-card>

<mat-menu #menu="matMenu" class="c4p-menu">
  <ng-template matMenuContent let-name="name" let-rowIndex="rowIndex">
    <div *ngFor="let action of options.menuActions">
      <div
        mat-menu-item
        class="c4p-menu-item"
        (click)="action.method(options.data)"
      >
        <mat-icon class="c4p-action-menu-icon">{{ action.icon }}</mat-icon>
        <span class="c4p-menu-title">{{ action.tooltip | translate }}</span>
      </div>
    </div>
  </ng-template>
</mat-menu>
