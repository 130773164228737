import { Component, Input } from '@angular/core';
import { FormControlBase } from '../abstract-form-control';
import {
  DEFAULT_TEXT_AREA_TYPE,
  DEFAULT_TEXT_AREA_ROWS,
  DEFAULT_TEXT_AREA_COLUMNS,
  DEFAULT_TEXT_AREA_DISABLE_RESIZE,
  DEFAULT_TEXT_AREA_LARGE,
  DEFAULT_AUTO_RESIZE,
} from '../form-controls.const';

@Component({
  selector: 'app-form-input-text-area',
  templateUrl: './form-input-text-area.component.html',
  styleUrls: ['./form-input-text-area.component.scss'],
})
export class FormInputTextAreaComponent extends FormControlBase<FormInputTextAreaComponent> {
  @Input() type = DEFAULT_TEXT_AREA_TYPE;
  @Input() cols = DEFAULT_TEXT_AREA_COLUMNS;
  @Input() rows = DEFAULT_TEXT_AREA_ROWS;
  @Input() disableResize = DEFAULT_TEXT_AREA_DISABLE_RESIZE;
  @Input() large = DEFAULT_TEXT_AREA_LARGE;
  @Input() xLarge = DEFAULT_TEXT_AREA_LARGE;
  @Input() autoResize = DEFAULT_AUTO_RESIZE;
}
