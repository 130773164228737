import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserInfoService } from '../user-info/user-info.service';

@Injectable({
  providedIn: 'root',
})
export class FormatCompletenessService {
  private url = `${this.environment.tenantManagementApiUrl}`;

  private tenantId: string | number;

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') public environment: any,
    private userInfoService: UserInfoService,
  ) {
    this.tenantId = this.userInfoService.tenantId;
  }

  public getFormatCompletenessForTenant(): Observable<number> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/tenants/:tenant_id/format-completeness',
      entity_type: 'Tenant',
      entity_id: this.tenantId.toString(),
    });
    return this.httpClient.get<number>(
      `${this.url}/${this.tenantId}/format-completeness`,
      { headers },
    );
  }

  public updateFormatCompleteness(
    formatCompleteness: number,
  ): Observable<number> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/tenants/:tenant_id/format-completeness',
      entity_type: 'Tenant',
      entity_id: this.tenantId.toString(),
    });
    return this.httpClient.patch<number>(
      `${this.url}/${this.tenantId}/format-completeness`,
      { formatCompleteness },
      { headers },
    );
  }
}
