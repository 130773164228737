import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT } from '../form-controls.const';

interface CheckListItem {
  id?: string;
  name: string;
  value: boolean;
}

@Component({
  selector: 'app-input-checkbox-list',
  templateUrl: './input-checkbox-list.component.html',
  styleUrls: ['./input-checkbox-list.component.scss'],
})
/**
 * @deprecated
 */
export class InputCheckboxListComponent implements OnInit, OnDestroy {
  // Full list of checkboxes, an array should contain list of labels
  @Input() list: string[] = [];

  // List of labels that are checked
  @Input() selected: string[] = [];

  // We are using this if full list is changed by extern service (refresh)
  @Input() selectedObservable: Observable<any[]>;

  // Readonly indicator, by default it is false
  @Input() readonly: boolean = false;

  // This event emits new array of checked labels
  @Output() selectedChange = new EventEmitter();

  public checkList: CheckListItem[] = [];
  public dataSubscription: any;
  debouncer: Subject<string[]> = new Subject<string[]>();

  constructor() {
    this.debouncer
      .pipe(debounceTime(DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT))
      .subscribe((value) => this.selectedChange.emit(value));
  }

  ngOnInit(): void {
    if (this.selectedObservable) {
      this.dataSubscription = this.selectedObservable.subscribe(
        (value: any[]) => {
          if (value) {
            this.selected = [...value];
            this.checkList = [];
            for (let listItem of this.list) {
              let value = false;
              for (let selectedItem of this.selected) {
                if (listItem == selectedItem) {
                  value = true;
                  break;
                }
              }
              let checkListItem = {
                name: listItem,
                value: value,
              };
              this.checkList.push(checkListItem);
            }
          }
        },
      );
    } else {
      for (let listItem of this.list) {
        let value = false;
        for (let selectedItem of this.selected) {
          if (listItem == selectedItem) {
            value = true;
            break;
          }
        }
        let checkListItem = {
          name: listItem,
          value: value,
        };
        this.checkList.push(checkListItem);
      }
    }
  }

  ngOnDestroy() {
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
  }

  public onChange(index: number): void {
    if (this.checkList[index].value) {
      this.selected.push(this.checkList[index].name);
    } else {
      this.selected.splice(
        this.selected.indexOf(this.checkList[index].name),
        1,
      );
    }
    this.debouncer.next(this.selected);
  }
}
