import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormControlsModule } from '../form-controls';
import { GeneralComponentsModule } from '../general-components';
import { GoogleChartsModule } from 'angular-google-charts';
import { AddressComponent } from './address/address.component';
import { PhoneComponent } from './phone/phone.component';
import { DirectivesModule } from '../directives';
import { QuestionSetRenderComponent } from './question-set-render/question-set-render.component';
import { SingleLineComponent } from './question-set-render/single-line/single-line.component';
import { MultiLineComponent } from './question-set-render/multi-line/multi-line.component';
import { SingleChoiceComponent } from './question-set-render/single-choice/single-choice.component';
import { MultipleChoiceComponent } from './question-set-render/multiple-choice/multiple-choice.component';
import { DateChoiceComponent } from './question-set-render/date-choice/date-choice.component';
import { PipesModule, TenantDatePipe } from '../pipes';
import { DateTimeChoiceComponent } from './question-set-render/date-time-choice/date-time-choice.component';
import { AddQuestionSetComponent } from './question-set-render/add-question-set/add-question-set.component';
import { QuillModule } from 'ngx-quill';
import { QuestionSetStepperComponent } from './question-set-stepper/question-set-stepper.component';
import { ScaleChoiceComponent } from './question-set-render/scale-choice/scale-choice.component';
import { AdditionalCommentComponent } from './question-set-render/additional-comment/additional-comment.component';
import { QuestionRenderComponent } from './question-set-render/question-render/question-render.component';
import { CommentComponent } from './comment/comment.component';
import { SocialAreaConfigFormComponent } from './social-area/social-area-config-form/social-area-config-form.component';
import { GoalsConfigFormComponent } from './social-area/goal-config-form/goal-config-form.component';
import { SocialAreaComponent } from './social-area/social-area-form/social-area-form.component';
import { GoalFormComponent } from './social-area/social-area-form/goals-form/goal-form.component';
import { SocialAreaService } from './social-area/social-area.service';
import { ObjectiveOverviewService } from './social-area/objective-overview.service';
import { SocialAreaOverviewService } from './social-area/social-area-overview.service';
import { GoalHistoryComponent } from './social-area/social-area-form/goal-history/goal-history.component';
import { EvaluationsService } from './social-area/evaluations.service';
import { MismatchAnswersComponent } from './question-set-render/mismatch-answers/mismatch-answers.component';
import { QuestionRenderFormComponent } from './question-set-render/question-render-form/question-render-form.component';
import { QuestionSetCompareComponent } from './question-set-render/question-set-compare/question-set-compare.component';
import { QuestionSetSelectVersioningComponent } from './question-set-render/question-set-select-versioning/question-set-select-versioning.component';
import { CareProgramOverviewComponent } from './care-program/pages/care-program-overview/care-program-overview.component';
import { CareProgramOverviewFilterFormComponent } from './care-program/forms/care-program-overview-filter-form/care-program-overview-filter-form.component';
import { CareProgramSectionComponent } from './care-program/pages/care-program-detail/care-program-section/care-program-section.component';
import { CareLabelSectionComponent } from './care-program/pages/care-program-detail/care-label-section/care-label-section.component';
import { BasicGgzProfileSectionComponent } from './care-program/pages/care-program-detail/basic-ggz-profile-section/basic-ggz-profile-section.component';
import { Dsm5DiagnosisSectionComponent } from './care-program/pages/care-program-detail/dsm5-diagnosis-section/dsm5-diagnosis-section.component';
import { ReferralSectionComponent } from './care-program/pages/care-program-detail/referral-section/referral-section.component';
import { CareLabelDetailComponent } from './care-program/components/care-label-detail/care-label-detail.component';
import { MainTherapistComponent } from './care-program/components/main-therapist/main-therapist.component';
import { Dsm5DiagnosisDetailComponent } from './care-program/components/dsm5-diagnosis-detail/dsm5-diagnosis-detail.component';
import { CarefileServiceService } from './care-program/services/carefile-service.service';
import { DocumentModule } from '../document';
import { BasicGgzProfileComponent } from './care-program/components/basic-ggz-profile/basic-ggz-profile.component';
import { CareTypeDeterminationSectionComponent } from './care-program/pages/care-program-detail/care-type-determination-section/care-type-determination-section.component';
import { CareTypeDeterminationDetailComponent } from './care-program/components/care-type-determination-detail/care-type-determination-detail.component';
import { CareProgramFormatsSectionComponent } from './care-program/pages/care-program-detail/care-program-formats-section/care-program-formats-section.component';
import { QuestionExpandComponent } from './question-set-render/question-expand/question-expand.component';
import { MainTherapistSectionComponent } from './care-program/components/main-therapist-section/main-therapist-section.component';
import { MainTherapistDetailComponent } from './care-program/components/main-therapist-section/main-therapist-detail/main-therapist-detail.component';

const moduleComponents = [
  AddressComponent,
  PhoneComponent,
  QuestionSetRenderComponent,
  SingleLineComponent,
  MultiLineComponent,
  SingleChoiceComponent,
  MultipleChoiceComponent,
  DateChoiceComponent,
  DateTimeChoiceComponent,
  AddQuestionSetComponent,
  QuestionSetStepperComponent,
  ScaleChoiceComponent,
  AdditionalCommentComponent,
  QuestionRenderComponent,
  CommentComponent,
  SocialAreaConfigFormComponent,
  GoalsConfigFormComponent,
  SocialAreaComponent,
  GoalFormComponent,
  GoalHistoryComponent,
  MismatchAnswersComponent,
  QuestionRenderFormComponent,
  QuestionSetCompareComponent,
  QuestionSetSelectVersioningComponent,
  CareProgramOverviewComponent,
  CareProgramOverviewFilterFormComponent,
  CareProgramSectionComponent,
  CareLabelSectionComponent,
  BasicGgzProfileSectionComponent,
  Dsm5DiagnosisSectionComponent,
  ReferralSectionComponent,
  CareLabelDetailComponent,
  MainTherapistComponent,
  Dsm5DiagnosisDetailComponent,
  BasicGgzProfileComponent,
  CareTypeDeterminationSectionComponent,
  CareTypeDeterminationDetailComponent,
  CareProgramFormatsSectionComponent,
  QuestionExpandComponent,
  MainTherapistSectionComponent,
  MainTherapistDetailComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FormControlsModule,
    GeneralComponentsModule,
    GoogleChartsModule,
    TranslateModule,
    DirectivesModule,
    PipesModule,
    QuillModule,
    DocumentModule,
  ],
  declarations: [...moduleComponents],
  exports: [...moduleComponents],
  providers: [
    SocialAreaService,
    ObjectiveOverviewService,
    SocialAreaOverviewService,
    EvaluationsService,
    CarefileServiceService,
    TenantDatePipe,
  ],
})
export class SharedFormsModule {}
