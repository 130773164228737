import { TableColumn } from './table-columns.interface';

export class TableUtils {

  public static reorderPermanentColumns(cols: TableColumn[]) {
    const leftMostCols = [];
    const centerCols = [];
    const rightMostCols = [];

    cols.forEach(column => {
      const col = { ...column };
      if (col.permanent && (col.frozenRight === true || col.frozenLeft === true) && col.hideOnSettings === true) {
        if (col.frozenRight === true) {
          rightMostCols.push(col);
        } else if (col.frozenLeft === true) {
          leftMostCols.push(col);
        }
      } else {
        centerCols.push(col);
      }
    });

    return { leftMostCols, centerCols, rightMostCols };
  }
}
