import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ITableOption } from '../table-view/table-view.component';
import { ObjectListComponent } from '../object-list/object-list.component';
import { PermissionService } from '../../services';

@Component({
  selector: 'app-card-view-2',
  templateUrl: './card-view-2.component.html',
  styleUrls: ['./card-view-2.component.scss'],
})
export class CardView2Component implements OnInit {
  @Input() options: ITableOption = null;
  @Input() data: object;
  @Input() tableMenu: ITableOption[];

  firstPart: any[] = [];
  secondPart: any[] = [];

  get menuActions(): any[] {
    return this.tableMenu
      ? this.tableMenu
      : this.options?.menuActions
      ? this.options.menuActions
      : [];
  }

  constructor(
    private dialog: MatDialog,
    private permissionService: PermissionService,
  ) {}

  ngOnInit(): void {
    for (let field of this.options.columns) {
      if (field.position && field.position == 'second') {
        this.secondPart.push(field);
      } else {
        this.firstPart.push(field);
      }
    }
  }

  showObjects(data: any[]) {
    const dialogRef = this.dialog.open(ObjectListComponent, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
      }
    });
  }

  openEditOrView() {
    const edit = this.menuActions.find((action) => action.icon === 'create');
    const view = this.menuActions.find(
      (action) => action.icon === 'visibility',
    );

    if (edit) {
      edit.method(this.data);
    } else if (view) {
      view.method(this.data);
    }
  }
}
