import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../../../general-components';
import { BasicGgzProfileComponent } from '../../../components/basic-ggz-profile/basic-ggz-profile.component';
import {
  BasicGGZProfileModel,
  CompleteCareProgramModel,
} from '../../../models';
import { CareProgramService } from '../../../services/care-program.service';

@Component({
  selector: 'app-basic-ggz-profile-section',
  templateUrl: './basic-ggz-profile-section.component.html',
  styleUrls: ['./basic-ggz-profile-section.component.scss'],
})
export class BasicGgzProfileSectionComponent implements OnInit {
  public get careprogramForm(): FormGroup {
    return this.careprogramService.careProgramForm;
  }

  public get basicGGZFormArray(): FormArray {
    return this.careprogramForm.get('basicGGZProfile') as FormArray;
  }

  public displayedColumns = [
    'startDate',
    'endDate',
    'code',
    'name',
    'operations',
  ];

  constructor(
    private careprogramService: CareProgramService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.initGGZProfileData();
  }

  private initGGZProfileData() {
    const selectedCareProgram: CompleteCareProgramModel = this
      .careprogramService.selectedCareProgram$
      .value as CompleteCareProgramModel;
    if (
      selectedCareProgram &&
      selectedCareProgram.basicGGZProfile?.length > 0 &&
      this.basicGGZFormArray.length === 0
    ) {
      selectedCareProgram.basicGGZProfile.forEach((basicGGZProfile) => {
        this.basicGGZFormArray.push(
          this.buildBasicGGZFormGroup(basicGGZProfile),
        );
      });
    }
  }

  private buildBasicGGZFormGroup(basicGGZ: BasicGGZProfileModel): FormGroup {
    const basicGGZFormGroup = this.formBuilder.group({
      startDate: [basicGGZ.startDate, Validators.required],
      endDate: [basicGGZ.endDate],
      profile: this.formBuilder.group({
        name: [basicGGZ.profile.name, Validators.required],
        code: [basicGGZ.profile.code, Validators.required],
        identifier: [basicGGZ.profile.identifier, Validators.required],
        GDS801Code: [basicGGZ.profile.GDS801Code, Validators.required],
      }),
    });

    if (this.basicGGZFormArray.disabled) basicGGZFormGroup.disable();
    return basicGGZFormGroup;
  }
  public createBasicGGZProfile() {
    const dialogRef = this.dialog.open(BasicGgzProfileComponent, {
      data: {
        profile: null,
        viewOnly: false,
      },
    });

    dialogRef.afterClosed().subscribe((profileModel: BasicGGZProfileModel) => {
      if (profileModel) {
        const basicGGZFormGroup = this.buildBasicGGZFormGroup(profileModel);
        this.basicGGZFormArray.push(basicGGZFormGroup);
        basicGGZFormGroup.markAsDirty();
        this.basicGGZFormArray.markAsTouched();
      }
    });
  }

  public editBasicGGZProfile(
    basicGGZModel: BasicGGZProfileModel,
    index: number,
  ) {
    const dialogRef = this.dialog.open(BasicGgzProfileComponent, {
      data: {
        profile: basicGGZModel,
        viewOnly: false,
      },
    });

    dialogRef.afterClosed().subscribe((careLabel) => {
      if (careLabel) {
        const basicGGZFormGroup = this.basicGGZFormArray.get([index]);
        basicGGZFormGroup.patchValue(careLabel);
        basicGGZFormGroup.markAsDirty();
      }
    });
  }

  public deleteBasicGGZ(index: number) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'general.messages.DeleteItem',
        message:
          'careprogram.messages.AreYouSureYouWantToDeleteBasicGGZProfile',
        confirmText: 'general.actions.delete',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.basicGGZFormArray.markAsDirty();
        this.basicGGZFormArray.removeAt(index);
        this.basicGGZFormArray.markAsTouched();
      }
    });
  }

  public viewBasicGGZ(index: number) {
    const basicGGZ = this.basicGGZFormArray.get([index]).value;
    const dialogRef = this.dialog.open(BasicGgzProfileComponent, {
      data: {
        profile: basicGGZ,
        viewOnly: true,
      },
    });

    dialogRef.afterClosed().subscribe(() => {});
  }
}
