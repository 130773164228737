import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../../form-controls/form-controls.const';
import { MasterDataService } from '../../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class CareProviderRoleService {
  public values: IFormDropdownOption[];
  constructor(private masterDataService: MasterDataService) {
    this.getCareProviderRole();
  }

  private getCareProviderRole() {
    this.masterDataService
      .getMasterData('care-provider-roles')
      .subscribe((data) => {
        this.values = data;
      });
  }
}
