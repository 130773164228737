<div class="c4p-form-questionset-stepper">
  <app-panel-grid>
    <app-panel-grid>
      <div style="text-align: center">
        <app-chart
          [data]="chartData.weekData"
          label="clientPortal.labels.AnsweredQuestions"
          type="LineChart"
          [options]="chartOptions"
          [columns]="columns"
        ></app-chart>
        <div style="margin-bottom: 10px" class="c4p-view-value c4p-ellipsis">
          <span class="c4p-font-normal" translate
            >clientPortal.labels.Week</span
          >
          <span class="c4p-font-normal">{{ ': ' + chartData.title }}</span>
        </div>
        <button class="c4p-button" (click)="getPreviousWeek()" translate>
          clientPortal.labels.PreviousWeek
        </button>
        <button class="c4p-button" (click)="getNextWeek()" translate>
          clientPortal.labels.NextWeek
        </button>
      </div>

      <mat-calendar
        [selected]="selectedDate"
        (selectedChange)="onDateSelect($event)"
        [maxDate]="maxDate"
      ></mat-calendar>
    </app-panel-grid>

    <app-slide-panel
      style="max-width: 100%; height: 100%"
      [activePane]="isLeftVisible ? 'left' : 'right'"
    >
      <div leftPane style="position: sticky">
        <form [formGroup]="diaryFormGroup">
          <mat-vertical-stepper
            formArrayName="diarySubjects"
            [linear]="isLinear"
            #stepper
          >
            <mat-step
              *ngFor="let control of diarySubjects.controls; let i = index"
              [stepControl]="control"
            >
              <ng-container
                *ngFor="
                  let answerControl of control.get('answers')['controls'];
                  let j = index
                "
              >
                <form [formGroup]="answerControl">
                  <ng-template matStepLabel>{{
                    control.value.title
                  }}</ng-template>

                  <ng-container [ngSwitch]="answerControl.value.type">
                    <!-- Singleline -->
                    <app-single-line
                      *ngSwitchCase="1"
                      formControlName="answer"
                      name="answer"
                      [placeholder]="answerControl.value.title"
                      [orderNum]="answerControl.value.order"
                      [hint]="answerControl.value.hint"
                    ></app-single-line>

                    <!-- Multiline -->
                    <app-multi-line
                      *ngSwitchCase="2"
                      formControlName="answer"
                      name="answer"
                      [placeholder]="answerControl.value.title"
                      [orderNum]="answerControl.value.order"
                      [hint]="answerControl.value.hint"
                    ></app-multi-line>

                    <!-- Rich text -->
                    <div *ngSwitchCase="3">
                      <div
                        class="c4p-question-set-input-small"
                        style="word-wrap: break-word"
                      >
                        <span
                          *ngIf="answerControl.value.isMandatory"
                          class="c4p-question-label"
                          >{{
                            '* ' +
                              answerControl.value.order +
                              '. ' +
                              answerControl.value.title
                          }}</span
                        >
                        <span
                          *ngIf="!answerControl.value.isMandatory"
                          class="c4p-question-label"
                          >{{
                            answerControl.value.order +
                              '. ' +
                              answerControl.value.title
                          }}</span
                        >
                        <span
                          *ngIf="answerControl.value.hint"
                          tippy
                          [tippyOptions]="{
                            arrow: true,
                            allowHTML: true,
                            placement: 'right',
                            content: answerControl.hint,
                            theme: 'light',
                            maxWidth: '350px',
                            placement: 'right-end',
                            interactive: true
                          }"
                        >
                          <mat-icon class="c4p-hint-icons">info</mat-icon>
                        </span>
                      </div>
                      <div
                        *ngIf="
                          !answerControl.value.answer &&
                          !answerControl.value.focusRichText
                        "
                        (click)="onRichContent(answerControl, i)"
                      >
                        <p>
                          <i
                            ><span translate>
                              questionset.messages.ClickHereToAnswer
                            </span></i
                          >
                        </p>
                      </div>
                      <div
                        *ngIf="!answerControl.value.focusRichText"
                        (click)="onRichContent(answerControl, i)"
                        [innerHTML]="control.value.answer"
                        class="word-break-normal"
                      ></div>
                      <div
                        *ngIf="
                          answerControl.value.focusRichText &&
                          !diarySubjects.at(i).get('answers').disabled
                        "
                        class="c4p-question-set-input"
                      >
                        <quill-editor
                          placeholder=""
                          formControlName="answer"
                          (onBlur)="onRichContent(answerControl, i)"
                          (onEditorCreated)="onEditorCreated($event)"
                        ></quill-editor>
                      </div>
                    </div>

                    <!-- Multiple choice -->
                    <app-multiple-choice
                      *ngSwitchCase="4"
                      formControlName="answer"
                      name="answer"
                      [placeholder]="answerControl.value.title"
                      [orderNum]="answerControl.value.order"
                      [options]="optionsForChoice[answerControl.value.id]"
                      [hint]="answerControl.value.hint"
                      [required]="answerControl.value.isMandatory"
                      [disable]="diarySubjects.at(i).get('answers').disabled"
                    ></app-multiple-choice>

                    <!-- Single choice -->
                    <app-single-choice
                      *ngSwitchCase="5"
                      formControlName="answer"
                      name="answer"
                      [placeholder]="answerControl.value.title"
                      [orderNum]="answerControl.value.order"
                      [options]="optionsForChoice[answerControl.value.id]"
                      [hint]="answerControl.value.hint"
                    ></app-single-choice>

                    <!-- Date -->
                    <app-date-choice
                      *ngSwitchCase="6"
                      formControlName="answer"
                      name="answer"
                      [placeholder]="answerControl.value.title"
                      [orderNum]="answerControl.value.order"
                      [hint]="answerControl.value.hint"
                    ></app-date-choice>

                    <!-- Date Time -->
                    <app-date-time-choice
                      *ngSwitchCase="7"
                      formControlName="answer"
                      name="answer"
                      [placeholder]="answerControl.value.title"
                      [orderNum]="answerControl.value.order"
                      [hint]="answerControl.value.hint"
                    ></app-date-time-choice>

                    <app-scale-choice
                      *ngSwitchCase="8"
                      formControlName="answer"
                      name="answer"
                      [placeholder]="answerControl.value.title"
                      [orderNum]="answerControl.value.order"
                      [hint]="answerControl.value.hint"
                      [minLimit]="0"
                      [maxLimit]="5"
                    >
                    </app-scale-choice>

                    <app-additional-comment
                      *ngIf="answerControl.value.commentRequired"
                      formControlName="comment"
                      name="comment"
                      placeholder="clientPortal.labels.Explanation"
                    >
                    </app-additional-comment>
                  </ng-container>
                </form>
              </ng-container>
              <div>
                <button
                  class="c4p-button"
                  *ngIf="i != 0 && i != diarySubjects.length - 1"
                  matStepperPrevious
                  (click)="validateStep(control)"
                  translate
                >
                  clientPortal.questionSetStepper.Back
                </button>
                <button
                  class="c4p-button"
                  *ngIf="i != diarySubjects.length - 1"
                  matStepperNext
                  (click)="validateStep(control)"
                  translate
                >
                  clientPortal.questionSetStepper.Next
                </button>
                <button
                  *ngIf="
                    i == diarySubjects.length - 1 && completenessStatus != 3
                  "
                  class="c4p-button"
                  (click)="submitQuestionSet()"
                  translate
                >
                  general.actions.Submit
                </button>
              </div>
            </mat-step>
          </mat-vertical-stepper>
        </form>
      </div>
      <div rightPane>
        <form [formGroup]="diaryFormGroup">
          <mat-vertical-stepper
            formArrayName="diarySubjects"
            [linear]="isLinear"
            #stepper
          >
            <mat-step
              *ngFor="let control of diarySubjects.controls; let i = index"
              [stepControl]="control"
            >
              <ng-container
                *ngFor="
                  let answerControl of control.get('answers')['controls'];
                  let j = index
                "
              >
                <form [formGroup]="answerControl">
                  <ng-template matStepLabel>{{
                    control.value.title
                  }}</ng-template>

                  <ng-container [ngSwitch]="answerControl.value.type">
                    <!-- Singleline -->
                    <app-single-line
                      *ngSwitchCase="1"
                      formControlName="answer"
                      name="answer"
                      [placeholder]="answerControl.value.title"
                      [orderNum]="answerControl.value.order"
                      [hint]="answerControl.value.hint"
                    ></app-single-line>

                    <!-- Multiline -->
                    <app-multi-line
                      *ngSwitchCase="2"
                      formControlName="answer"
                      name="answer"
                      [placeholder]="answerControl.value.title"
                      [orderNum]="answerControl.value.order"
                      [hint]="answerControl.value.hint"
                    ></app-multi-line>

                    <!-- Rich text -->
                    <div *ngSwitchCase="3">
                      <div
                        class="c4p-question-set-input-small"
                        style="word-wrap: break-word"
                      >
                        <span
                          *ngIf="answerControl.value.isMandatory"
                          class="c4p-question-label"
                          >{{
                            '* ' +
                              answerControl.value.order +
                              '. ' +
                              answerControl.value.title
                          }}</span
                        >
                        <span
                          *ngIf="!answerControl.value.isMandatory"
                          class="c4p-question-label"
                          >{{
                            answerControl.value.order +
                              '. ' +
                              answerControl.value.title
                          }}</span
                        >
                        <span
                          *ngIf="answerControl.value.hint"
                          tippy
                          [tippyOptions]="{
                            arrow: true,
                            allowHTML: true,
                            placement: 'right',
                            content: answerControl.hint,
                            theme: 'light',
                            maxWidth: '350px',
                            placement: 'right-end',
                            interactive: true
                          }"
                        >
                          <mat-icon class="c4p-hint-icons">info</mat-icon>
                        </span>
                      </div>
                      <div
                        *ngIf="
                          !answerControl.value.answer &&
                          !answerControl.value.focusRichText
                        "
                        (click)="onRichContent(answerControl, i)"
                      >
                        <p>
                          <i
                            ><span translate>
                              questionset.messages.ClickHereToAnswer
                            </span></i
                          >
                        </p>
                      </div>
                      <div
                        *ngIf="!answerControl.value.focusRichText"
                        class="word-break-normal"
                        (click)="onRichContent(answerControl, i)"
                        [innerHTML]="control.value.answer"
                      ></div>
                      <div
                        *ngIf="
                          answerControl.value.focusRichText &&
                          !diarySubjects.at(i).get('answers').disabled
                        "
                        class="c4p-question-set-input"
                      >
                        <quill-editor
                          placeholder=""
                          formControlName="answer"
                          (onBlur)="onRichContent(answerControl, i)"
                          (onEditorCreated)="onEditorCreated($event)"
                        ></quill-editor>
                      </div>
                    </div>

                    <!-- Multiple choice -->
                    <app-multiple-choice
                      *ngSwitchCase="4"
                      formControlName="answer"
                      name="answer"
                      [placeholder]="answerControl.value.title"
                      [orderNum]="answerControl.value.order"
                      [options]="optionsForChoice[answerControl.value.id]"
                      [hint]="answerControl.value.hint"
                      [required]="answerControl.value.isMandatory"
                      [disable]="diarySubjects.at(i).get('answers').disabled"
                    ></app-multiple-choice>

                    <!-- Single choice -->
                    <app-single-choice
                      *ngSwitchCase="5"
                      formControlName="answer"
                      name="answer"
                      [placeholder]="answerControl.value.title"
                      [orderNum]="answerControl.value.order"
                      [options]="optionsForChoice[answerControl.value.id]"
                      [hint]="answerControl.value.hint"
                    ></app-single-choice>

                    <!-- Date -->
                    <app-date-choice
                      *ngSwitchCase="6"
                      formControlName="answer"
                      name="answer"
                      [placeholder]="answerControl.value.title"
                      [orderNum]="answerControl.value.order"
                      [hint]="answerControl.value.hint"
                    ></app-date-choice>

                    <!-- Date Time -->
                    <app-date-time-choice
                      *ngSwitchCase="7"
                      formControlName="answer"
                      name="answer"
                      [placeholder]="answerControl.value.title"
                      [orderNum]="answerControl.value.order"
                      [hint]="answerControl.value.hint"
                    ></app-date-time-choice>

                    <app-scale-choice
                      *ngSwitchCase="8"
                      formControlName="answer"
                      name="answer"
                      [placeholder]="answerControl.value.title"
                      [orderNum]="answerControl.value.order"
                      [hint]="answerControl.value.hint"
                      [minLimit]="0"
                      [maxLimit]="5"
                    >
                    </app-scale-choice>

                    <app-additional-comment
                      *ngIf="answerControl.value.commentRequired"
                      formControlName="comment"
                      name="comment"
                      placeholder="clientPortal.labels.Explanation"
                    >
                    </app-additional-comment>
                  </ng-container>
                </form>
              </ng-container>
              <div>
                <button
                  class="c4p-button"
                  *ngIf="i != 0 && i != diarySubjects.length - 1"
                  matStepperPrevious
                  (click)="validateStep(control)"
                  translate
                >
                  clientPortal.questionSetStepper.Back
                </button>
                <button
                  class="c4p-button"
                  *ngIf="i != diarySubjects.length - 1"
                  matStepperNext
                  (click)="validateStep(control)"
                  translate
                >
                  clientPortal.questionSetStepper.Next
                </button>
                <button
                  *ngIf="
                    i == diarySubjects.length - 1 && completenessStatus != 3
                  "
                  class="c4p-button"
                  (click)="submitQuestionSet()"
                  translate
                >
                  general.actions.Submit
                </button>
              </div>
            </mat-step>
          </mat-vertical-stepper>
        </form>
      </div>
    </app-slide-panel>
  </app-panel-grid>
</div>
