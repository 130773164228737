<div
  *ngIf="mode == 'normal'"
  class="cp4-collaboration-avatar details cp4-collaboration-avatar-active"
  #trigger="cdkOverlayOrigin"
  cdkOverlayOrigin
>
  <ngx-avatars
    [name]="user.name"
    [src]="user.avatar"
    size="24"
    bgColor="#808080"
  ></ngx-avatars>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
  >
    <mat-card appearance="outlined" class="c4p-collaboration-detail-card">
      <mat-grid-list
        cols="3"
        rowHeight="fit"
        class="grid-layout-custom"
        [style.height]="80 + user.phones?.length * 15 + 'px'"
      >
        <mat-grid-tile colspan="1" [rowspan]="4 + (user.phones?.length - 1)">
          <ngx-avatars
            [name]="user.name"
            [src]="user.avatar"
            size="80"
            bgColor="#808080"
          ></ngx-avatars>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" rowspan="2">
          <h4 style="width: 100%">{{ user.name }}</h4>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" rowspan="1">
          <p style="width: 100%; font-size: 13px; color: grey">
            {{ user.primaryEmail }}
          </p>
        </mat-grid-tile>
        <div *ngFor="let phone of user.phones">
          <mat-grid-tile colspan="2" rowspan="1">
            <p style="width: 100%; font-size: 13px; color: grey">
              {{ phone.fullNumber }}
              <span class="dot"></span>
              <span translate>
                {{
                  phone.type == '1'
                    ? 'masterData.phoneType.work'
                    : 'masterData.phoneType.private'
                }}
              </span>
            </p>
          </mat-grid-tile>
        </div>
      </mat-grid-list>
    </mat-card>
  </ng-template>
</div>

<div
  *ngIf="mode != 'normal'"
  class="cp4-collaboration-avatar details cp4-collaboration-avatar-inactive"
  #trigger1="cdkOverlayOrigin"
  cdkOverlayOrigin
>
  <ngx-avatars
    [name]="user.name"
    [src]="user.avatar"
    size="24"
    bgColor="#808080"
    class="c4p-dimmed"
  ></ngx-avatars>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger1"
    [cdkConnectedOverlayOpen]="isOpen"
  >
    <mat-card appearance="outlined" class="c4p-collaboration-detail-card">
      <mat-grid-list
        cols="3"
        rowHeight="fit"
        class="grid-layout-custom"
        [style.height]="80 + user.phones?.length * 15 + 'px'"
      >
        <mat-grid-tile colspan="1" [rowspan]="4 + (user.phones?.length - 1)">
          <ngx-avatars
            [name]="user.name"
            [src]="user.avatar"
            size="80"
            bgColor="#808080"
          ></ngx-avatars>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" rowspan="2">
          <h4 style="width: 100%">{{ user.name }}</h4>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" rowspan="1">
          <p style="width: 100%; font-size: 13px; color: grey">
            {{ user.primaryEmail }}
          </p>
        </mat-grid-tile>
        <div *ngFor="let phone of user.phones">
          <mat-grid-tile colspan="2" rowspan="1">
            <p style="width: 100%; font-size: 13px; color: grey">
              {{ phone.fullNumber }}
              <span class="dot"></span>
              <span translate>
                {{
                  phone.type == '1'
                    ? 'masterData.phoneType.work'
                    : 'masterData.phoneType.private'
                }}
              </span>
            </p>
          </mat-grid-tile>
        </div>
      </mat-grid-list>
    </mat-card>
  </ng-template>
</div>
