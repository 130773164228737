import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent implements OnInit {
  @Input() label: string = '';
  @Input() width: string = '';
  @Input() isValid = true;
  @Input() marginLeft: string = '22px';
  @Input() labelValue: {
    label: string;
    value: string;
    translatedLabel?: string;
  };

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.labelValue &&
      (this.labelValue.translatedLabel = this.translate.instant(
        this.labelValue.label,
      ));
  }
}
