import { Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { TableColumn } from '../../../models';

@Component({
  selector: 'app-tuula-table-skeleton',
  templateUrl: './tuula-table-skeleton.component.html',
  styleUrl: './tuula-table-skeleton.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class TuulaTableSkeletonComponent implements OnInit{

  @Input() columns: TableColumn[];
  @Input() totalRowWidth;
  @Input() defaultCellWidth;
  @Input() tableRowSize;
  @Input() rowCount = 5;

  tableDispayColumns = [];
  skeletonRows;

  constructor() { }

  ngOnInit(): void {
    this.tableDispayColumns = this.columns.filter((column) => !column.hidden).map(col => col.prop);
    const count = this.rowCount > 10 ? 10 : this.rowCount;
    this.skeletonRows = Array(count).fill({});
  }


}
