import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { slideFromTop } from '../../../../general-components/animations';

import {
  ITableAction,
  ITableOption,
  ITableView,
  TableViewComponent,
} from '../../../../general-components/table-view/table-view.component';
import { TableViewInterface } from '../../../../general-components/table-view/table-view.interface';
import { PaginationDataModel, StatusEnum } from '../../../../models';
import { CarefileService, PageMenuService } from '../../../../services';
import { SettingTypeService } from '../../../../services/referent/care-program/setting-type/setting-type.service';
import { PermissionService } from '../../../../services/permissions/permission.service';
import { ZpmTypeService } from '../../../../services/referent/care-program/zpm-type/zpm-type.service';
import { UserInfoService } from '../../../../services/user-info/user-info.service';
import {
  BasicCareProgramModel,
  CareProgramOverviewModel,
  ClientCareProgramModel,
} from '../../models';
import { CareProgramService } from '../../services/care-program.service';
import { FundingStreamService } from '../../../../services/referent/care-program/funding-stream/funding-stream.service';
@Component({
  selector: 'care-program-overview',
  templateUrl: './care-program-overview.component.html',
  styleUrls: ['./care-program-overview.component.scss'],
  animations: [slideFromTop],
})
export class CareProgramOverviewComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public carefileView: boolean = true;
  public carefileId: string;
  private tenantId: any;
  private carefileStatus: string;
  private careprogramsLength = 0;
  private readonly destroyed$ = new Subject<boolean>();
  private createCareProgramColumn = {
    id: 'createCareProgram',
    name: 'general.labels.Add',
    type: 'button',
    format: 'labelled',
    method: (data) => {
      this.createFromFilters(data);
    },
  };
  private get careprogramFromFilters(): boolean {
    return !this.carefileView && this.careprogramsLength === 0;
  }
  private defaultMenuActions: ITableAction[] = [
    {
      icon: 'visibility',
      tooltip: 'general.actions.View',
      method: (data) => this.view(data),
      permission:
        'careprogram:zpm:view|careprogram:zpm:full|careprogram:zpm:carefile:full',
      permissionOperator: 'or',
    },
    {
      icon: 'create',
      tooltip: 'general.actions.Edit',
      method: (data) => this.edit(data),
      permission: 'careprogram:zpm:full|careprogram:zpm:carefile:full',
      permissionOperator: 'or',
    },
    {
      icon: 'delete',
      tooltip: 'general.actions.Delete',
      method: (data) => this.delete(data),
      permission: 'careprogram:zpm:full|careprogram:zpm:carefile:full',
      permissionOperator: 'or',
    },
  ];
  public tableOptions: ITableOption = null!;

  public tableView: ITableView = {
    pageIndex: 0,
    pageSize: 10,
    sortColumn: 'createdAt',
    sortDirection: 'desc',
    search: '',
    additionalFilters: {},
  };

  menuActions = [
    {
      icon: 'filter_list',
      tooltip: 'general.actions.ToggleFilters',
      method: () => (this.toggleFilters = !this.toggleFilters),
    },
  ];

  private _filterParams = {};
  public get filterParams() {
    return this._filterParams;
  }
  public set filterParams(filterParams: any | null) {
    this._filterParams = filterParams;
    this.tableView.pageIndex = 0;
  }
  @ViewChild(TableViewComponent) tableViewComponent: TableViewComponent;
  public viewInterface: TableViewInterface;

  private _toggleFilters = false;
  public get toggleFilters() {
    return this._toggleFilters;
  }
  public set toggleFilters(toggled: boolean) {
    this.tableView.pageIndex = 0;
    this.tableView.search = null;
    this._filterParams = this.userInfoService.getFiltersForPage('CareProgram');
    // reset table component
    if (this.viewInterface) this.viewInterface.pageIndex = 0;
    this._toggleFilters = toggled;
    this.refresh();
  }

  constructor(
    private careProgramService: CareProgramService,
    private permissionService: PermissionService,
    private userInfoService: UserInfoService,
    private pageMenuService: PageMenuService,
    private zpmTypeService: ZpmTypeService,
    private fundingStreamService: FundingStreamService,
    private settingTypeService: SettingTypeService,
    private carefileService: CarefileService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.tableOptions = this.buildTable();
    this.carefileView = this.route.snapshot.data.isCarefileCareProgram;
    if (this.carefileView) {
      // TODO find better way
      this.carefileId = this.route.snapshot['_routerState'].url.split('/')[2];
      this.tenantId = this.userInfoService.tenantId;
      this.carefileService
        .getCarefileById(this.carefileId, this.tenantId)
        .subscribe((carefile) => {
          if (carefile.status === StatusEnum.Archived) {
            this.tableOptions.showAdd = false;
            this.defaultMenuActions.forEach((item) => {
              if (item.icon === 'delete') {
                item.conditionMethod = () => false;
              }
            });
          }
        });
    }

    this.setMenuItems();
  }

  ngAfterViewInit(): void {
    this.initSettings();
  }

  private setMenuItems(): void {
    this.pageMenuService.setMenuItems(this.menuActions);
  }

  public refresh() {
    if (!this.filterParams) {
      this.filterParams = {};
    }
    this.assignEntityType();
    // TODO update case if no careprograms retreived
    this.refreshCarePrograms();
  }

  private refreshCarePrograms() {
    if (
      this.tableViewComponent.checkIfColumnExists(
        this.createCareProgramColumn.id,
      )
    ) {
      this.tableViewComponent.removeColumn(this.createCareProgramColumn.id);
      this.tableOptions.showAdd = true;
      this.tableOptions.menuActions = this.defaultMenuActions;
    }

    let requestFrom = 'CareProgramAdmin';
    if (this.carefileView) requestFrom = 'Carefile';

    this.careProgramService
      .getCarePrograms(
        PaginationDataModel.fromTableView(this.tableView),
        requestFrom,
        this.filterParams,
      )
      .subscribe((result) => {
        this.careProgramService.carePrograms$.next({
          data: result.docs,
          count: result.totalDocs,
        });
        if (
          result.docs.length == 0 &&
          this.permissionService.getPermission('careprogram:zpm:full')
        ) {
          this.searchClientsWhenNoCareprograms();
        }
      });
  }

  private searchClientsWhenNoCareprograms() {
    let requestFrom = 'CareProgramAdmin';
    if (this.carefileView) requestFrom = 'Carefile';

    if (
      !this.tableViewComponent.checkIfColumnExists(
        this.createCareProgramColumn.id,
      )
    ) {
      this.tableViewComponent.addColumn(this.createCareProgramColumn, true);
      this.tableOptions.showAdd = true;
      this.tableOptions.menuActions = [];
    }
    this.assignEntityType();
    this.careProgramService
      .searchClientsWhenNoCarePrograms(
        PaginationDataModel.fromTableView(this.tableView),
        requestFrom,
        this.filterParams,
      )
      .pipe(
        map((clientsPaginated) => {
          const docs = clientsPaginated.docs.map((doc) => {
            const careprogram = new BasicCareProgramModel();
            careprogram.client = doc;
            return careprogram;
          });
          return {
            docs: docs,
            count: clientsPaginated.totalDocs,
          };
        }),
      )
      .subscribe((careprograms) => {
        this.careProgramService.carePrograms$.next({
          data: careprograms.docs,
          count: careprograms.count,
        });
      });
  }
  // This is called only from administartion careprogram
  private createFromFilters(client: ClientCareProgramModel): void {
    this.careProgramService
      .createCareprogramFromFilters(client)
      .subscribe((result) => {
        this.toastr.success(
          this.translate.instant('careprogram.messages.CareProgramCreated'),
        );
        this.router.navigate(['care-program', 'edit', result.id]);
      });
  }

  public create(): void {
    const entityType = this.carefileView ? 'Carefile' : 'false';
    this.careProgramService
      .createCareProgram(entityType, this.carefileId)
      .subscribe((createdCareprogram) => {

        this.toastr.success(
          this.translate.instant('careprogram.messages.CareProgramCreated'),
        );
        if (entityType && entityType !== 'false')
          this.router.navigate([
            'carefiles',
            this.carefileId,
            'care-program',
            'edit',
            createdCareprogram.id,
          ]);
        else
          this.router.navigate(['care-program', 'edit', createdCareprogram.id]);
      });
  }

  public edit(data) {
    if (this.carefileView) {
      this.router.navigate([
        'carefiles',
        this.carefileId,
        'care-program',
        'edit',
        data.id,
      ]);
    } else this.router.navigate(['care-program', 'edit', data.id]);
  }

  view(data) {
    if (this.carefileView) {
      this.router.navigate([
        'carefiles',
        this.carefileId,
        'care-program',
        'view',
        data.id,
      ]);
    } else this.router.navigate(['care-program', 'view', data.id]);
  }

  public back() {
    this.router.navigate(['carefiles', this.carefileId, 'carefile']);
  }

  private assignEntityType() {
    if (this.carefileView) {
      this.filterParams['entityType'] = 'Carefile';
      this.filterParams['carefileId'] = this.carefileId;
    } else {
      this.filterParams['entityType'] = 'false';
      this.filterParams['carefileId'] = null;
    }
  }

  public setFilters(): void {
    this.userInfoService.setTableViewForPage('CareProgram', this.tableView);
  }

  public initSettings(): void {
    this.userInfoService.userSettings$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: any) => {
        if (value) {
          if (this.userInfoService.hasFilters('CareProgram'))
            this.handleFilterToggle();
          this.cdref.detectChanges();
        }
      });
  }

  public handleFilterToggle(): void {
    this.tableView.search = null;
    // reset params
    this._filterParams = this.userInfoService.getFiltersForPage('CareProgram');
    this._toggleFilters = true;

    let userTableSetting =
      this.userInfoService.getTableViewForPage('CareProgram');
    if (userTableSetting) {
      this.tableView = userTableSetting;
      let data: any = {};
      data.pageIndex = this.tableView.pageIndex;
      data.pageSize = this.tableView.pageSize;
      if (this.viewInterface) this.viewInterface.pageSizeIndex = data;
    }
  }

  public delete(data: CareProgramOverviewModel): void {
    let entityType;
    let requestFrom;

    this.carefileView ? (entityType = 'Carefile') : (entityType = '');
    this.carefileView
      ? (requestFrom = 'Carefile')
      : (requestFrom = 'CareProgramAdmin');

    this.careProgramService
      .deleteCareprogram(data.id, requestFrom, entityType)
      .subscribe(
        () => {
          this.toastr.success(
            this.translate.instant('careprogram.messages.Deleted'),
          );
          this.refresh();
        },
        () => {},
      );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  buildTable():ITableOption{
    return {
      name: 'careprogram.titles.Careprogram',
      dataObservable: this.careProgramService.carePrograms$.pipe(
          map((careProgramsPaginated) => {
            if (!this.carefileView)
              this.careprogramsLength = careProgramsPaginated.count;
            const docs = careProgramsPaginated.data.map((careProgram) => {
              const overviewModel = new CareProgramOverviewModel(careProgram);
              if (this.careprogramFromFilters)
                overviewModel.createCareProgram = true;
              return overviewModel;
            });
            return {
              data: docs,
              count: careProgramsPaginated.count,
            };
          }),
      ),
      columns: [
        {
          id: 'registrationDate',
          name: 'careprogram.labels.RegistrationDate',
          type: 'date',
          format: 'date',
        },
        {
          id: 'endDate',
          name: 'general.labels.EndDate',
          type: 'date',
          format: 'date',
        },
        {
          id: 'fullName',
          name: 'careprogram.labels.ClientFullName',
          type: 'string',
          format: '',
        },
        {
          id: 'status',
          name: 'general.labels.Status',
          type: 'status',
          format: '',
        },
        {
          id: 'bsn',
          name: 'careprogram.labels.BSN',
          type: 'string',
          format: '',
        },
        {
          id: 'dateOfBirth',
          name: 'client.labels.DateOfBirth',
          type: 'date',
          format: 'date',
        },
        {
          id: 'therapists',
          name: 'careprogram.labels.MainTherapist',
          type: 'objectList',
          objectProperty: 'fullName',
        },
        {
          id: 'defaultSetting',
          name: 'careprogram.labels.DefaultSetting',
          type: 'lookup',
          lookupValues: this.settingTypeService.lookupValues,
          format: '',
        },
        {
          id: 'fundingStream',
          name: 'careprogram.labels.FundingStream',
          type: 'lookup',
          lookupValues: this.fundingStreamService.lookupValues,
          format: '',
        },
      ],
      inlineActions: [],
      menuActions: this.defaultMenuActions,
      showAdd: this.permissionService.getPermission(
          'careprogram:zpm:full|careprogram:zpm:carefile:full',
          'or',
      ),
      showHeader: true,
      showPagination: true,
      showSearch: true,
      externPagination: true,
      defaultPageSize: 10,
      defaultSortColumn: 'createdAt',
      defaultSortDirection: 'asc',
    };
  }
}
