import { PartialPersonSummary } from './person.summary';

export class SignatureInfo {
  signedBy: PartialPersonSummary;
  signedDate: Date;
  signerIp: string;
  requestedBy: PartialPersonSummary;
  requestedDate: Date | null;
  requestorIp: string;
  documentId: string;
  operation: string;
  signerIdentificationMethod: string;
  signatureIncludeRole: boolean;
}

export type PartialSignatureInfo = Partial<SignatureInfo>;
