import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpUtils } from '../../../utilities';
import { DropdownServiceBase } from '../../../services/dropdown-service.abstract';
import { EmployeeModel } from '../../../models/employee/employee.model';
import { PaginationDataModel } from '../../../models/pagination/pagination-data.model';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CarefileEmployeeService extends DropdownServiceBase<EmployeeModel> {
  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
  ) {
    super();
    this.setupDropdown(this.getCarefileEmployees);
  }

  public getCarefileEmployees(
    paginationData: PaginationDataModel,
    filterData?: any,
    requestFrom?: string,
  ) {
    let headers: HttpHeaders = new HttpHeaders({
      origin_path: '/carefiles/:carefile_id/employees',
      entity_type: 'Carefile',
    });
    if (requestFrom) headers = headers.set('request_from', requestFrom);

    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );
    const carefileId = filterData.carefileId;
    if (!filterData.carefileId)
      return of({
        docs: [],
        hasNextPage: false,
        hasPrevPage: null,
        limit: null,
        nextPage: null,
        page: null,
        pagingCounter: null,
        prevPage: null,
        totalDocs: null,
        totalPages: null,
      });

    return this.http.get<any>(
      `${this.environment.carefileManagementApiUrl}/${carefileId}/employees`,
      { headers, params },
    );
  }
}
