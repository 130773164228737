import {Component, OnInit} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
  SideSheetButtonEvent,
  SideSheetService,
  SideSheetButtonListener,
  ActionType,
  UserInfoService,
} from 'c4p-portal-util';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import { NotificationCategoryModel } from '../../models/fcm-message/notification-category.model';
import { NotificationSettingsService } from '../../services/notification/notification-settings.service';
import { ConfigurationModel } from '../../models/fcm-message/configuration.model';
@Component({
  selector: 'notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.css'],
})
export class NotificationSettingsComponent implements OnInit, SideSheetButtonListener {
  private selectedSubjects = new Subject<string[]>();
  private readonly destroyed$ = new Subject<boolean>();
  public loading: boolean;
  form: FormGroup = null!;
  subsData: NotificationCategoryModel[] = [];

  constructor(
    private notificationSettingsService: NotificationSettingsService,
    public userInfoService: UserInfoService,
    public formBuilder: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    public sideSheetService: SideSheetService,
  ) {
  }

  ngOnInit(): void {
    this.form = this.buildForm();
    this.notificationSettingsService
        .getNotificationSubjects()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((data) => {
          data.sort((a, b) => this.translate.instant('notification.titles.' + a.name).localeCompare(this.translate.instant('notification.titles.' + b.name)));
          data = data.filter(res => res.name !== 'DocumentDeleted' && res.name !== 'TaskAssignment');
          this.subsData = data;
          this.addCheckboxes();
        });
  }

  onClickButton(event: SideSheetButtonEvent) {
    if(event?.button?.actionType === ActionType.BACK){
      this.sideSheetService.closeComponent();
    }
    if(event?.button?.actionType === ActionType.OK){
      this.onConfirm();
    }
    if(event?.button?.actionType === ActionType.SIDE_SHEET_CLOSE){
      this.sideSheetService.closeSideSheet();
    }
  }

  buildForm(){
    return this.formBuilder.group({
      subjects: new FormArray([]),
    });
  }
  getSelectedSubjects(): Observable<string[]> {
    this.notificationSettingsService
      .getConfigurationsByUserId(this.userInfoService.userId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((configuration: ConfigurationModel) => {
        if (configuration && configuration.notificationCategories) {
          let selectedSubjects = [];
          for (let subject of configuration.notificationCategories) {
            selectedSubjects.push(subject.name);
          }
          this.selectedSubjects.next(selectedSubjects);
        }
      });
    return this.selectedSubjects.asObservable();
  }

  get subsFormArray() {
    return this.form.controls.subjects as FormArray;
  }

  private addCheckboxes() {
    this.loading = true;
    this.getSelectedSubjects()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((selected) => {
        this.subsData.forEach((x: NotificationCategoryModel) =>
          this.subsFormArray.push(
            new FormControl(selected.includes(x.name) ? true : false),
          ),
        );
        this.loading = false;
      });
  }

  public onConfirm() {
    const selectedSubs: Array<string> = this.form.value.subjects
      .map((checked, i) => (checked ? this.subsData[i].name : null))
      .filter((c) => c !== null);
    let configurationSubs: ConfigurationModel = {
      userId: this.userInfoService.userId,
      notificationCategories: [],
    };
    selectedSubs.forEach((subject) => {
      configurationSubs.notificationCategories.push({ name: subject });
    });
    this.notificationSettingsService
      .updateConfiguration(configurationSubs)
      .pipe(take(1))
      .subscribe(data=>{
        this.toastr.success(
          this.translate.instant('general.labels.Success'),
        );
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }


}
