import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CareTypeDeterminationService } from '../../../../services';
import { CareTypeDeterminationModel } from '../../models/care-program.model';
import { CareProgramService } from '../../services/care-program.service';

interface InputData {
  careTypeDetermination: CareTypeDeterminationModel;
  viewOnly: boolean;
}

@Component({
  selector: 'app-care-type-determination-detail',
  templateUrl: './care-type-determination-detail.component.html',
  styleUrls: ['./care-type-determination-detail.component.scss'],
})
export class CareTypeDeterminationDetailComponent implements OnInit, OnDestroy {
  public careTypeDeterminationForm: FormGroup = null!;


  public get startDateControl() {
    return this.careTypeDeterminationForm.get('startDate');
  }

  public get endDateControl() {
    return this.careTypeDeterminationForm.get('endDate');
  }

  private get nameControl() {
    return this.careTypeDeterminationForm.get('name');
  }

  private get codeControl() {
    return this.careTypeDeterminationForm.get('code');
  }

  private get identifierControl() {
    return this.careTypeDeterminationForm.get('identifier');
  }

  private get gds801CodeControl() {
    return this.careTypeDeterminationForm.get('GDS801Code');
  }

  public viewOnly: boolean;

  private readonly destroyed$ = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: InputData,
    private dialogRef: MatDialogRef<CareTypeDeterminationDetailComponent>,
    private formBuilder: FormBuilder,
    public careprogramService: CareProgramService,
    public careTypeDeterminationService: CareTypeDeterminationService,
  ) {}

  ngOnInit(): void {
    this.careTypeDeterminationForm = this.careTypeDeterminationBuildForm()
    if (this.data.careTypeDetermination) {
      this.careTypeDeterminationForm.patchValue(
        this.data.careTypeDetermination,
      );
    }
    if (this.data.viewOnly) this.careTypeDeterminationForm.disable();
    this.nameSubscription();
    this.codeSubscription();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onConfirm() {
    if (this.careTypeDeterminationForm.valid) {
      this.dialogRef.close(this.careTypeDeterminationForm.getRawValue());
    } else this.careTypeDeterminationForm.markAllAsTouched();
  }

  onDismiss() {
    this.dialogRef.close(null);
  }

  private nameSubscription() {
    this.nameControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((name) => {
        if (name) {
          const careTypeDeterminationOption =
            this.careTypeDeterminationService.values.find(
              (option) => option.label === name,
            );
          this.startDateControl.setValue(
            careTypeDeterminationOption.value.startDate,
            { emitEvent: false },
          );
          this.endDateControl.setValue(
            careTypeDeterminationOption.value.endDate,
            { emitEvent: false },
          );
          this.codeControl.setValue(careTypeDeterminationOption.value.code, {
            emitEvent: false,
          });
          this.identifierControl.setValue(
            careTypeDeterminationOption.value.identifier,
            { emitEvent: false },
          );
          this.gds801CodeControl.setValue(
            careTypeDeterminationOption.value.GDS801Code,
            { emitEvent: false },
          );
        } else {
          this.startDateControl.setValue(null, { emitEvent: false });
          this.endDateControl.setValue(null, { emitEvent: false });
          this.codeControl.setValue(null, { emitEvent: false });
          this.identifierControl.setValue(null, { emitEvent: false });
          this.gds801CodeControl.setValue(null, { emitEvent: false });
          this.careTypeDeterminationForm.markAllAsTouched();
        }
      });
  }

  private codeSubscription() {
    this.codeControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((code) => {
        if (code) {
          const careTypeDeterminationOption =
            this.careTypeDeterminationService.values.find(
              (option) => option.value.code === code,
            );
          this.startDateControl.setValue(
            careTypeDeterminationOption.value.startDate,
            { emitEvent: false },
          );
          this.endDateControl.setValue(
            careTypeDeterminationOption.value.endDate,
            { emitEvent: false },
          );
          this.nameControl.setValue(careTypeDeterminationOption.value.name, {
            emitEvent: false,
          });
          this.identifierControl.setValue(
            careTypeDeterminationOption.value.identifier,
            { emitEvent: false },
          );
          this.gds801CodeControl.setValue(
            careTypeDeterminationOption.value.GDS801Code,
            { emitEvent: false },
          );
        } else {
          this.startDateControl.setValue(null, { emitEvent: false });
          this.endDateControl.setValue(null, { emitEvent: false });
          this.nameControl.setValue(null, { emitEvent: false });
          this.identifierControl.setValue(null, { emitEvent: false });
          this.gds801CodeControl.setValue(null, { emitEvent: false });
          this.careTypeDeterminationForm.markAllAsTouched();
        }
      });
  }
  careTypeDeterminationBuildForm(){
    return this.formBuilder.group({
      identifier: [{ value: null, disabled: true }, Validators.required],
      GDS801Code: [{ value: null, disabled: true }, Validators.required],
      name: [null, Validators.required],
      code: [null, Validators.required],
      startDate: [{ value: null, disabled: true }, Validators.required],
      endDate: [{ value: null, disabled: true }, Validators.required],
    });
  }
}
