import { IFormDropdownOption } from '../../../form-controls/form-controls.const';

export const CAREPROGRAM_STATUSES: IFormDropdownOption[] = [
  { label: 'general.status.Open', value: 'open' },
  { label: 'general.status.Closed', value: 'closed' },
];

export const FundingStream: IFormDropdownOption[] = [
  { label: 'GGZ', value: 'GGZ' },
  { label: 'FZ', value: 'FZ' },
  { label: 'Beide', value: 'Beide' },
];
