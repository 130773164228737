import { Pipe, PipeTransform} from '@angular/core';
import { JS_DATE_TIME_FORMAT } from '../pipes.const';
import {TranslateService} from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
@Pipe({
  name: 'notificationDate'
})
export class NotificationDatePipe extends DatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
    super(translateService.defaultLang);
  }
    
    transform(value: any, _args?:any): any {
      if (!value) {
        return '';
      }
      const date = new Date(value);
      const now = new Date();
      const diff = (now.getTime() - date.getTime()) / 1000;
      if (diff > 0  && diff < 600) { // 600 seconds = 10 minutes
        return `${this.translateService.instant('notification.labels.JustNow')}`;
      } else {
  
      return super.transform(value, JS_DATE_TIME_FORMAT);
      }
    }  
   
      
}