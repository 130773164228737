<div class="content-wrapper">
  <!-- Customizable Header Section -->
  <div class="header">
    <div class="headerText">
      <ng-content select="[headerText]"></ng-content>
    </div>

    <div class="button-bar">
      <mat-icon
        class="mat-icon c4p-action-icons material-icons"
        (click)="addClicked($event)"
        [matTooltip]="'Add'"
        >note_add</mat-icon
      >
    </div>
  </div>

  <!-- Content Section (to wrap an existing component) -->
  <div class="content">
    <ng-content></ng-content>
  </div>

  <!-- Customizable Footer Section -->
  <div class="footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
