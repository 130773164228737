import { Observable } from 'rxjs';
import { CommentModel } from './../../models/comment/comment.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserInfoService } from '../../services';
import { UserTypeEnum } from '../../models';
import { ReadByModel } from '../../models/comment/read-by.model';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  private readonly commentUrl = this.environment.commentApiUrl;
  tenantId: any;
  userType: string;
  currentUserId: any;
  currentUserName: string;
  constructor(
    private http: HttpClient,
    @Inject('environment') private environment: any,
    private userInfoService: UserInfoService,
  ) {
    this.tenantId = this.userInfoService.tenantId;
    this.userType = this.userInfoService.userType;
    this.userInfoService.userInfo$.subscribe((info) => {
      if (info && info.id) {
        this.currentUserId = info.id;
        this.currentUserName = info.fullName;
      }
    });
  }

  public getComments(entity: string, entityId) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/comments/:entity/:entity_id',
      entity_id: entityId.toString(),
      sub_entity_type: 'Comments',
    });
    return this.http.get<any>(`${this.commentUrl}/${entity}/${entityId}`, {
      headers,
    });
  }

  public createComment(comment: CommentModel): Observable<CommentModel> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/comments/:entity/:entity_id',
      entity_id: comment.entityId,
    });
    return this.http.post<CommentModel>(
      `${this.commentUrl}/${comment.entity}/${comment.entityId}`,
      comment,
      { headers },
    );
  }

  public updateComment(comment: CommentModel): Observable<CommentModel> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/comments/:entity/:entity_id/:comment_id',
      entity_id: comment.entityId,
      sub_entity_id: comment.id,
      sub_entity_type: 'Comments',
    });
    return this.http.patch<CommentModel>(
      `${this.commentUrl}/${comment.entity}/${comment.entityId}/${comment.id}`,
      comment,
      { headers },
    );
  }

  public deleteComment(commentId: string, entity: string, entityId, bypassConfirmation: string = '') {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/comments/:entity/:entity_id/:comment_id',
      entity_id: entityId.toString(),
      sub_entity_id: commentId,
      sub_entity_type: 'Comments',
      bypassConfirmation: bypassConfirmation,
    });
    return this.http.delete<any>(
      `${this.commentUrl}/${entity}/${entityId}/${commentId}`,
      { headers },
    );
  }

  prepareReadBy(entity) {
    const clientReadBy = [];
    const clientNetworkReadBy = [];
    const employeeReadBy = [];
    const readBy = new ReadByModel();
    readBy.tenantId = this.tenantId;
    readBy.entityId = null;
    readBy.entity = entity;
    readBy.subEntity = 'comment';
    readBy.user = {
      id: this.currentUserId,
      fullName: this.currentUserName,
      userType: this.userType,
    };
    if (this.userType === UserTypeEnum.Client) {
      clientReadBy.push(readBy);
    } else if (this.userType === UserTypeEnum.Employee) {
      employeeReadBy.push(readBy);
    } else if (this.userType === UserTypeEnum.ClientNetwork) {
      clientNetworkReadBy.push(readBy);
    }
    return {
      clients: clientReadBy,
      employees: employeeReadBy,
      clientNetworks: clientNetworkReadBy,
    };
  }
}
