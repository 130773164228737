import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormControlComponent } from './components/dynamic-form-control/dynamic-form-control.component';
import { FormControlsModule } from '../form-controls';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicFormGroupComponent } from './components/dynamic-form-group/dynamic-form-group.component';
import { DynamicFormArrayComponent } from './components/dynamic-form-array/dynamic-form-array.component';
import { GeneralComponentsModule } from '../general-components';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';

const moduleComponents = [
  DynamicFormControlComponent,
  DynamicFormGroupComponent,
  DynamicFormArrayComponent,
];

export const translateModule = TranslateModule.forChild();

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormControlsModule,
    GeneralComponentsModule,
    MatIconModule,
    TranslateModule,
    MatCheckboxModule
  ],
  declarations: [...moduleComponents],
  exports: [...moduleComponents],
})
export class DynamicFormsModule {}
