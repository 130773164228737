import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { SocialAreaConfigFormComponent } from '../social-area-config-form/social-area-config-form.component';
import { SocialAreaHelper } from '../social-area.helper';
import {
  MainGoalModel,
  SocialAreaModel,
  SubGoalModel,
} from '../../../models/social-area/social-area.model';
import { SocialAreaService } from '../social-area.service';
import {
  ITableOption,
  ITableView,
} from '../../../general-components/table-view/table-view.component';
import { PermissionService } from '../../../services/permissions/permission.service';
import { PaginationDataModel } from '../../../models/pagination/pagination-data.model';
import { CollaborationService } from '../../../services';
import { takeUntil } from 'rxjs/operators';
import { UpdateTimeByUtils } from '../../../utilities';

@Component({
  selector: 'app-social-area',
  templateUrl: './social-area-form.component.html',
  styleUrls: ['./social-area-form.component.scss'],
})
export class SocialAreaComponent implements OnInit, OnChanges, OnDestroy {
  @Input() entityId: string;
  @Input() entity: string;
  @Input() entityVersion: number;
  @Input() viewMode = false;
  @Input() previewMode? = false;
  @Input() unachievedSocialAreasEvaluation?: SocialAreaModel[] = [];
  @Input() scoringEnabled = false;
  @Input() scoringUnanswered$ = new Subject<void>();
  @Input() isSideSheet = false;

  @Input() carefileId?: string = null;
  @Input() careplanId?: string = null;
  @Input() careplanVersion?: number = null;
  @Input() careplan = null;
  @Input() isClientPortal = false;
  @Output() updateTime = new EventEmitter<any>();

  private readonly destroyed$ = new Subject<boolean>();

  socialAreaHelper: SocialAreaHelper = new SocialAreaHelper(
    this.formBuilder,
    this.destroyed$,
  );

  isSocialAreaVisible: boolean = true;

  socialAreas: SocialAreaModel[];
  selectedSocialArea: SocialAreaModel;
  searchControl: FormControl = new FormControl();
  socialAreas$ = new BehaviorSubject<any>(null);

  public tableOptions: ITableOption = null!;

  // default table view
  public tableView: ITableView = {
    pageIndex: 0,
    pageSize: 10,
    sortColumn: 'name',
    sortDirection: 'asc',
    search: '',
  };

  tabs: { name: string; socialArea: SocialAreaModel }[] = [];
  selectedIndex = 0;

  public menuAction = [
    {
      icon: 'delete',
      tooltip: 'general.actions.Remove',
      method: (row) => this.removeArea(row),
      conditionMethod: () => {
        return (
          !this.viewMode &&
          this.entity === 'careplan' &&
          this.permissionService.getPermission(
            'careplan:create|careplan:manage',
            'or',
          )
        );
      },
    },
  ];

  constructor(
    public formBuilder: FormBuilder,
    public dialog: MatDialog,
    public permissionService: PermissionService,
    private socialAreaService: SocialAreaService,
    private collaborationService: CollaborationService,
  ) {
    this.tableOptions = this.buildTable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.previewMode) {
      if (
        changes.viewMode &&
        changes.viewMode.previousValue !== changes.viewMode.currentValue
      ) {
        this.tableOptions.showAdd = this.viewMode
          ? false
          : this.entity === 'careplan' &&
            this.permissionService.getPermission(
              'careplan:create|careplan:manage',
              'or',
            );
      }

      if (
        changes.entityVersion &&
        changes.entityVersion.previousValue !==
          changes.entityVersion.currentValue
      ) {
        this.entityVersion = changes.entityVersion.currentValue;
        this.tabs = [];
        if (!changes?.entityVersion?.firstChange) {
          this.getSocialAreas();
        }
      }

      if (
        changes.entityId &&
        changes.entityId.previousValue !== changes.entityId.currentValue
      ) {
        this.entityId = changes.entityId.currentValue;
        this.tabs = [];
        if (!changes?.entityVersion?.firstChange) {
          this.getSocialAreas();
        }
      }
    }
  }

  ngOnInit(): void {
    this.setMenuAction();
    this.getSocialAreas(true);
    this.tableOptions.showAdd = this.viewMode
      ? false
      : this.entity === 'careplan' &&
        this.permissionService.getPermission(
          'careplan:create|careplan:manage',
          'or',
        );
  }

  onToggle(): void {
    this.isSocialAreaVisible = !this.isSocialAreaVisible;
  }

  private getSocialAreas(init?: boolean) {
    this.socialAreaService
      .getSocialAreas(
        new PaginationDataModel('createdAt', 'asc', 999, ''),
        null,
        this.entity,
        this.entityId,
        this.entityVersion,
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.socialAreas = <SocialAreaModel[]>data.docs;
        this.refreshSelectedArea(true, init && this.previewMode);
      });
  }

  private refreshSelectedArea(
    updateOpenTabs?: boolean,
    initAndPreviewMode?: boolean,
  ): void {
    if (initAndPreviewMode) {
      this.socialAreas = this.filterSocialAreasFromEvaluation();
    }
    const updateAt = this.socialAreas.map((item) => ({
      updatedAt: new Date(item?.updatedAt) ?? null,
      updatedBy: item?.updatedBy?.fullName ?? null,
    }));

    this.updateTime.emit(UpdateTimeByUtils.getLatestObj(updateAt));

    this.socialAreas$.next({
      data: this.socialAreas,
      count: this.socialAreas.length,
    });
    if (this.tabs.length > 0 && updateOpenTabs) {
      this.updateTabs(this.socialAreas);
    }
  }

  public onFilterSocialAreas(): void {
    if (!this.viewMode) {
      const dialogRef = this.dialog.open(SocialAreaConfigFormComponent, {
        data: {
          entityId: this.entityId,
          entity: this.entity,
          socialAreas: this.socialAreas,
        },
      });
      dialogRef.afterClosed().subscribe((dialogData) => {
        if (dialogData) {
          this.socialAreas = dialogData;
          const newSocialAreas = dialogData.filter(
            (x) => x.isNew && x.selected,
          );
          const oldSocialAreas = dialogData.filter((x) => !x.isNew);

          if (newSocialAreas.length > 0) {
            if (!this.previewMode) {
              this.socialAreaService
                .createSocialAreas(
                  this.entityId,
                  this.entityVersion,
                  this.entity,
                  { selected_social_areas: newSocialAreas },
                )
                .subscribe((data) => {
                  this.socialAreas = oldSocialAreas.concat(data);
                  this.socialAreaService
                    .updateSocialAreas(
                      this.entityId,
                      this.entityVersion,
                      this.entity,
                      { selected_social_areas: this.socialAreas },
                    )
                    .subscribe((data) => {
                      this.socialAreas = data;
                      this.refreshSelectedArea(true);
                    });
                });
            } else {
              this.socialAreas = oldSocialAreas.concat(newSocialAreas);
              this.refreshSelectedArea();
            }
          } else {
            this.socialAreas = oldSocialAreas;
            if (!this.previewMode) {
              this.socialAreaService
                .updateSocialAreas(
                  this.entityId,
                  this.entityVersion,
                  this.entity,
                  { selected_social_areas: this.socialAreas },
                )
                .pipe(takeUntil(this.destroyed$))
                .subscribe((data) => {
                  this.socialAreas = data;
                  this.refreshSelectedArea(true);
                });
            }
          }
        }
      });
    }
  }

  updateArea(value: SocialAreaModel): void {
    if (!this.previewMode) {
      this.socialAreaService
        .updateSocialAreas(this.entityId, this.entityVersion, this.entity, {
          selected_social_areas: [value],
        })
        .subscribe((result) => {
          const ind = this.socialAreas.findIndex(
            (area) => area.id === value.id,
          );
          this.socialAreas[ind] = result[0];
          this.selectedSocialArea = result[0];
          const tabIndex = this.tabs.findIndex(
            (tab) => tab.socialArea.id === this.selectedSocialArea.id,
          );
          if (tabIndex >= 0) {
            this.tabs[tabIndex].socialArea = this.selectedSocialArea;
          }
          this.refreshSelectedArea();
        });
    }
  }

  updateTabs(docs: SocialAreaModel[]) {
    if (this.tabs.length > 0) {
      const tabsForDeletion = [];
      for (let [i, tab] of this.tabs.entries()) {
        const tabData = docs.find(
          (doc) =>
            doc.refId === tab.socialArea.refId &&
            doc.name === tab.socialArea.name,
        );
        if (tabData) {
          this.tabs[i] = { name: tabData.name, socialArea: tabData };
        } else {
          tabsForDeletion.push(tab);
        }
      }
      if (tabsForDeletion.length > 0) {
        for (let deletionTab of tabsForDeletion) {
          const index = this.tabs.findIndex(
            (tab) =>
              tab.name === deletionTab.name &&
              tab.socialArea.refId === deletionTab.socialArea.refId,
          );
          if (index > -1) {
            this.removeTab(index);
          }
        }
      }
    }
  }

  private removeArea(row: any): void {
    if (!this.viewMode) {
      row.selected = false;
      if (!this.previewMode) {
        this.socialAreaService
          .updateSocialAreas(this.entityId, this.entityVersion, this.entity, {
            selected_social_areas: this.socialAreas,
          })
          .subscribe((data) => {
            this.socialAreas = data;
            this.refreshSelectedArea(true);
          });
      } else {
        const socialAreaIndex = this.socialAreas.findIndex(
          (sa) =>
            sa.id === row.id && sa.name === row.name && sa.refId === row.refId,
        );
        if (socialAreaIndex > -1) this.socialAreas.splice(socialAreaIndex, 1);
        this.refreshSelectedArea(true);
      }
    }
  }

  public onRowClick(data: any): void {
    this.selectedSocialArea = data;
    let index = this.tabs.findIndex((x) => x.name == data.name);

    if (index >= 0) this.selectedIndex = index;
    else {
      this.tabs.push({ name: data.name, socialArea: this.selectedSocialArea });
      this.selectedIndex = this.tabs.length - 1;
      this.collaborationService.sendMessage(
        'userEnter',
        'socialarea' +
          this.tabs[this.selectedIndex].socialArea.id +
          'entity' +
          this.entityId +
          this.entity +
          this.entityVersion,
      );
    }
  }

  closeTab(event: any, index: number) {
    event.stopPropagation();
    this.removeTab(index);
  }

  private removeTab(index: number) {
    if (index > -1) {
      this.collaborationService.sendMessage(
        'userLeave',
        'socialarea' +
          this.tabs[index].socialArea.id +
          'entity' +
          this.entityId +
          this.entity +
          this.entityVersion,
      );
      this.tabs.splice(index, 1);
      if (this.tabs.length > 0 && index > this.tabs.length - 1)
        this.selectedIndex = this.tabs.length - 1;
    }
  }

  ngOnDestroy() {
    for (let tab of this.tabs) {
      this.collaborationService.sendMessage(
        'userLeave',
        'socialarea' +
          tab.socialArea.id +
          'entity' +
          this.entityId +
          this.entity +
          this.entityVersion,
      );
    }
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  setMenuAction() {
    if (!this.isClientPortal) this.tableOptions.menuActions = this.menuAction;
  }

  private filterSocialAreasFromEvaluation(): SocialAreaModel[] {
    const result: {
      careplanSocialArea: SocialAreaModel;
      evaluationSocialArea: SocialAreaModel;
    }[] = [];
    for (let careplanSocialArea of [...this.socialAreas]) {
      const evaluationSocialArea = this.unachievedSocialAreasEvaluation.find(
        (sa) =>
          sa.refId === careplanSocialArea.refId &&
          sa.name === careplanSocialArea.name,
      );
      if (evaluationSocialArea) {
        result.push({
          careplanSocialArea,
          evaluationSocialArea,
        });
      }
    }
    // social areas filtered, now main goals and sub goals
    for (let r of result) {
      // filtering maingoals
      const resultMainGoals: {
        careplanMainGoal: MainGoalModel;
        evaluationMainGoal: MainGoalModel;
      }[] = [];
      for (let careplanMainGoal of r.careplanSocialArea.mainGoals) {
        const evaluationMainGoal = r.evaluationSocialArea.mainGoals.find(
          (evalMainGoal) =>
            evalMainGoal.refId == careplanMainGoal.refId &&
            evalMainGoal.name === careplanMainGoal.name,
        );
        if (evaluationMainGoal) {
          resultMainGoals.push({
            careplanMainGoal,
            evaluationMainGoal,
          });
        }
      }
      // filtering subgoals
      for (let resultMainGoal of resultMainGoals) {
        if (resultMainGoal.careplanMainGoal.subGoals) {
          let resultSubGoals: {
            careplanSubGoal: SubGoalModel;
            evaluationSubGoal: SubGoalModel;
          }[] = [];
          for (let careplanSubGoal of resultMainGoal.careplanMainGoal
            .subGoals) {
            const evaluationSubGoal =
              resultMainGoal.evaluationMainGoal.subGoals.find(
                (evalSubGoal) =>
                  evalSubGoal.refId === careplanSubGoal.refId &&
                  evalSubGoal.name === careplanSubGoal.name,
              );
            if (evaluationSubGoal) {
              resultSubGoals.push({
                careplanSubGoal,
                evaluationSubGoal,
              });
            }
          }
          resultMainGoal.careplanMainGoal.subGoals = resultSubGoals.map(
            (resultSub) => resultSub.careplanSubGoal,
          );
        }
      }
      r.careplanSocialArea.mainGoals = resultMainGoals.map(
        (resultMain) => resultMain.careplanMainGoal,
      );
    }
    return result.map((r) => r.careplanSocialArea);
  }
  buildTable(){
    return {
      name: 'carefile.titles.SocialAreas',
      dataObservable: this.socialAreas$,
      columns: [
        {
          id: 'name',
          name: 'carefile.labels.Area',
          type: 'string',
          format: '',
          width: 80,
        },
      ],
      inlineActions: null,
      menuActions: [],
      showAdd: true,
      showHeader: false,
      showPagination: true,
      showSearch: false,
      externPagination: true,
      defaultPageSize: 10,
      defaultSortColumn: 'name',
      defaultSortDirection: 'asc',
    };
  }
}
