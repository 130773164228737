import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IWidget } from '../../models';

@Injectable()
export class DashboardService {
  constructor(
    private http: HttpClient,
    @Inject('environment') public environment: any,
  ) {}

  public getDashboard(page: string, name: string) {
    // Why do we have HTTP call out of component instead of service?
    const httpHeaders: HttpHeaders = new HttpHeaders({
      origin_path: '/dashboards',
    });
    return this.http.get<any>(
      `${this.environment.dashboardServiceApiUrl}?page=${page}&name=${name}`,
      {
        headers: httpHeaders,
      },
    );
  }
}
