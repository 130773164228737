import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IWidget } from '../../models';
import { UserInfoService } from '../../services/user-info/user-info.service';
import { DashboardSettingsComponent } from './dashboard-settings/dashboard-settings.component';
import { DashboardService } from './dashboard.service';
import { EmptyWidget } from './empty-widget/empty.widget';

@Injectable()
export class DashboardInterface implements OnDestroy {
  private _widgets: IWidget[] = [];
  widgets$ = new Subject<IWidget[]>();

  private readonly destroyed$ = new Subject<boolean>();
  private page: string = '';
  private name: string = '';
  private mapping: any;
  public widgetHeightChanged$ = new BehaviorSubject<number>(null);

  constructor(
    public dialog: MatDialog,
    private userInfoService: UserInfoService,
    private dashboardService: DashboardService,
  ) {}

  /** Add widget to dashboard, used for testing purpose
   * @param widget widget interface object
   * @param mapping mapping between widget.component, which is string and implementation
   */
  addWidget(widget: IWidget, mapping: any): void {
    widget.implementation = mapping[widget.component];
    if (!widget.position) widget.position = this._widgets.length;
    this._widgets.push(widget);
    this.widgets$.next(this._widgets);
  }

  private mapComponent(widget: IWidget, mapping: any): void {
    if (mapping) {
      widget.implementation = mapping.show ? mapping.component : EmptyWidget;
      if (mapping.show) {
        widget.data = mapping.data;
        widget.id = mapping.id;
      } else {
        widget.size = 'Fit';
        widget.data = mapping.show;
        widget.visible = false;
      }
    } else {
      widget.implementation = EmptyWidget;
      widget.size = 'Fit';
    }
  }

  /** Open modal dialog for dashboard settings */
  showSettings(): void {
    const dialogRef = this.dialog.open(DashboardSettingsComponent, {
      data: this._widgets,
    });

    dialogRef
      .afterClosed()
      .subscribe((dialogData: { mode: string; data: IWidget[] }) => {
        if (dialogData) {
          if (dialogData.mode === 'default') {
            this.dashboardService
              .getDashboard(this.page, this.name)
              .subscribe((data) => {
                this._widgets = data.widgets;
                for (let widget of this._widgets) {
                  this.mapComponent(widget, this.mapping[widget.component]);
                }
                this.widgets$.next(this._widgets);
              });
          } else {
            for (let i = 0; i < dialogData.data.length; i++) {
              this._widgets[i].visible = dialogData.data[i].visible;
            }
            this.widgets$.next(this._widgets);
          }
        }
      });
  }

  /** Create dashboard
   * @param page name of the page e.g. CareFile, Employee, ...
   * @param name name of dashboard so we can have multiple dashboards on same page
   * @param mapping mapping between widget.component, which is string and implementation
   */
  create(page: string, name: string, mapping: any) {
    this.page = page;
    this.name = name;
    this.mapping = mapping;
    this.userInfoService.userSettings$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: any) => {
        if (value) {
          // for now there will be no personal dashboards, we will read dashboard configuration only from dashboard backend
          // this._widgets = this.userInfoService.getDashboardForPage(this.page, this.name);
          if (this._widgets.length == 0) {
            this.dashboardService
              .getDashboard(this.page, this.name)
              .subscribe((data) => {
                data.widgets = data.widgets.sort((a, b) => a.position - b.position);
                this._widgets = data.widgets;
                for (let widget of this._widgets) {
                  this.mapComponent(widget, this.mapping[widget.component]);
                }
                this.widgets$.next(this._widgets);
              });
          } else {
            for (let widget of this._widgets) {
              this.mapComponent(widget, this.mapping[widget.component]);
            }
            this.widgets$.next(this._widgets);
          }
        }
      });
  }

  /** Save widget's positions and sizes */
  save() {
    this.userInfoService.setDashboardForPage(
      this.page,
      this.name,
      this._widgets,
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
