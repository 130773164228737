import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class GenderService {
  public values: IFormDropdownOption[] = [];
  public lookupValues: string[] = [];

  constructor(private masterDataService: MasterDataService) {
    this.getGenders();
  }

  public getGenders() {
    this.masterDataService.getMasterData('gender').subscribe((data) => {
      this.values = data;
      for (let item of this.values) {
        this.lookupValues[item.value] = item.label;
      }
    });
  }
}
