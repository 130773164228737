import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GatekeeperModel } from '../../models/gatekeeper/gatekeeper.model';

const LOCAL_STORAGE_GATEKEEPER_KEY = 'gatekeepers';

@Injectable({
  providedIn: 'root',
})
export class GatekeeperService {
  constructor(
    private http: HttpClient,
    @Inject('environment') public environment: any,
  ) {}

  public getAllGatekeepers(): Observable<string[]> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/gatekeepers/all',
    });
    return this.http.get<any>(
      `${this.environment.gatekeeperServiceApiUrl}/all`,
      { headers },
    );
  }

  public getConfigurations(resourceType: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/configurations',
    });
    return this.http.get<any>(
      `${this.environment.gatekeeperConfigurationsServiceApiUrl}`,
      { params: { resourceType }, headers },
    );
  }

  public requiresValidation(method: string, endpoint: string): GatekeeperModel {
    const gatekeepers: GatekeeperModel[] = JSON.parse(
      sessionStorage.getItem(LOCAL_STORAGE_GATEKEEPER_KEY),
    );
    if (!gatekeepers) {
      return null;
    }
    const result = gatekeepers.find(
      (g) =>
        g.method === method && g.endpoint === endpoint && g.status === 'active',
    );
    return result;
  }

  // SESSION STORAGE MGMT
  public storeGatekeeper(gatekeeper: GatekeeperModel) {
    const gatekeepers: GatekeeperModel[] = JSON.parse(
      sessionStorage.getItem(LOCAL_STORAGE_GATEKEEPER_KEY),
    );
    gatekeepers.push(gatekeeper);
    sessionStorage.setItem(
      LOCAL_STORAGE_GATEKEEPER_KEY,
      JSON.stringify(gatekeepers),
    );
  }

  public removeGatekeeper(id: number | string) {
    let gatekeepers: GatekeeperModel[] = JSON.parse(
      sessionStorage.getItem(LOCAL_STORAGE_GATEKEEPER_KEY),
    );
    gatekeepers = gatekeepers.filter((g) => g.id !== id);
    sessionStorage.setItem(
      LOCAL_STORAGE_GATEKEEPER_KEY,
      JSON.stringify(gatekeepers),
    );
  }

  public updateGatekeeper(gatekeeper: GatekeeperModel) {
    const gatekeepers: GatekeeperModel[] = JSON.parse(
      sessionStorage.getItem(LOCAL_STORAGE_GATEKEEPER_KEY),
    );
    const index = gatekeepers.findIndex((g) => g.id === gatekeeper.id);
    gatekeepers[index] = gatekeeper;
    sessionStorage.setItem(
      LOCAL_STORAGE_GATEKEEPER_KEY,
      JSON.stringify(gatekeepers),
    );
  }

  public getStoredGatekeepers(): GatekeeperModel[] {
    const gatekeepers: GatekeeperModel[] = JSON.parse(
      sessionStorage.getItem(LOCAL_STORAGE_GATEKEEPER_KEY),
    );
    return gatekeepers;
  }
}
