import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinPipe',
})
export class CustomJoinPipe implements PipeTransform {
  transform(value: any, joinField: string): string {
    const result = value.map((v) => v[joinField]);
    return result.join(', ');
  }
}
