import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../../form-controls';
import { MasterDataService } from '../../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class SpravatoService {
  public values: IFormDropdownOption[] = [];

  public lookupValues: string[] = [];

  constructor(private masterDataService: MasterDataService) {
    this.getSpravato();
  }

  public getSpravato() {
    this.masterDataService.getMasterData('spravato').subscribe((data) => {
      this.values = data;
      for (let item of this.values) {
        this.lookupValues[item.value] = item.label;
      }
    });
  }
}
