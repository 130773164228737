import { Component, Inject, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import {
  DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT,
  IFormDropdownOption,
} from '../../../../form-controls';
import { ITableOption } from '../../../../general-components';
import { EvaluationsService } from '../../evaluations.service';
import { SocialAreaService } from '../../social-area.service';

@Component({
  selector: 'app-goal-history',
  templateUrl: './goal-history.component.html',
  styleUrls: ['./goal-history.component.scss'],
})
export class GoalHistoryComponent implements OnInit {
  private readonly destroyed$ = new Subject<boolean>();
  goalType: string = null;
  entityId: string = null;
  entity: string = null;
  entityVersion: number = null;
  careplanId: string = null;
  goalName: string = null;
  goalRefId: string = null;
  socialAreaId: string = null;
  careplan = null;
  carefileId: string = null;
  averageScore: number = null;

  scoreHistoryTableOptions: ITableOption = null!;

  queryOptions: IFormDropdownOption[] = [
    {
      label: 'socialarea.labels.SinceStart',
      value: 'start',
    },
    {
      label: 'socialarea.labels.LatestEvaluation',
      value: 'latest',
    },
    {
      label: 'socialarea.labels.SpecificEvaluation',
      value: 'specific',
    },
    {
      label: 'socialarea.labels.Timebased',
      value: 'timebased',
    },
  ];

  tableView = null;

  refresh() {
    this.filterParams.sortColumn = this.tableView.sortColumn;
    if (this.tableView.sortDirection && this.tableView.sortDirection !== '') {
      this.filterParams.sortDirection = this.tableView.sortDirection;
    }
    this.refreshGoalHistory();
  }

  goalHistoryForm: FormGroup = null!;

  evaluationFilterObject = null;

  filterParams: any = {};

  constructor(
    public evaluationsService: EvaluationsService,
    public socialAreaService: SocialAreaService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<GoalHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.entityId = this.data.entityId;
    this.entity = this.data.entity;
    this.entityVersion = this.data.entityVersion;
    this.goalType = this.data.goalType;
    this.goalName = this.data.goalName;
    this.goalRefId = this.data.goalRefId;
    this.socialAreaId = this.data.socialAreaId;
    this.careplan = this.data.careplan;
    this.carefileId = this.data.carefileId;
    this.evaluationFilterObject = {
      carefileId: this.data.carefileId,
      careplan: this.data.careplanId,
      evaluateOnGoals: true,
    };

  }

  ngOnInit(): void {
    this.scoreHistoryTableOptions = this.buildTable();
    this.goalHistoryForm = this.goalHistoryBuildForm();
    this.initializeForm();

    this.filterParams = {
      carefileId: this.data.carefileId,
      queryType: this.queryTypeControl().value,
      goalName: this.goalName,
      goalRefId: this.goalRefId,
      socialAreaId: this.socialAreaId,
      sortColumn: 'date',
      sortDirection: 'asc',
    };
    this.refreshGoalHistory();
  }

  initializeForm() {
    this.queryTypeControl()
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        this.specificEvaluationIdControl().setValue(null, { emitEvent: false });
        this.fromDateControl().setValue(null, { emitEvent: false });
        this.untilDateControl().setValue(null, { emitEvent: false });
        result === 'specific'
          ? this.specificEvaluationIdControl().setValidators(
              Validators.required,
            )
          : this.specificEvaluationIdControl().clearValidators();
      });

    this.goalHistoryForm.valueChanges
      .pipe(
        debounceTime(DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT),
        takeUntil(this.destroyed$),
      )
      .subscribe((value) => {
        if (this.goalHistoryForm.valid) {
          this.filterParams = value;
          this.filterParams.carefileId = this.carefileId;
          this.filterParams.goalName = this.goalName;
          this.filterParams.goalRefId = this.goalRefId;
          this.filterParams.socialAreaId = this.socialAreaId;
          Object.keys(this.filterParams).forEach(
            (k) => this.filterParams[k] == null && delete this.filterParams[k],
          );
          if (this.filterParams.specificEvaluationId) {
            this.filterParams.specificEvaluationId =
              value.specificEvaluationId.id;
          }
          this.refreshGoalHistory();
        }
      });
  }

  refreshGoalHistory() {
    this.socialAreaService
      .getGoalHistory(
        this.entity,
        this.entityId,
        this.entityVersion,
        this.goalType,
        this.filterParams,
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        if (result) {
          this.averageScore = result.averageScore;
          this.socialAreaService.goalHistory$.next(result.history);
        } else {
          this.averageScore = 0;
          this.socialAreaService.goalHistory$.next(null);
        }
      });
  }

  onDismiss(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.socialAreaService.goalHistory$.next(null);
    this.dialogRef.close(null);
  }

  queryTypeControl() {
    return this.goalHistoryForm.get('queryType') as FormControl;
  }

  specificEvaluationIdControl() {
    return this.goalHistoryForm.get('specificEvaluationId') as FormControl;
  }

  fromDateControl() {
    return this.goalHistoryForm.get('fromDate') as FormControl;
  }

  untilDateControl() {
    return this.goalHistoryForm.get('untilDate') as FormControl;
  }
  goalHistoryBuildForm(){
    return this.fb.group({
      queryType: [this.queryOptions[0].value, Validators.required],
      fromDate: [],
      untilDate: [],
      specificEvaluationId: [],
    });
  }
  buildTable():ITableOption{
    return {
      name: 'socialarea.titles.ScoreHistory',
      dataObservable: this.socialAreaService.goalHistory$.pipe(
          map((goalScores) => {
            if (goalScores) {
              goalScores.forEach((goalScore) => {
                if (goalScore) {
                  goalScore.displayCareplan = `${this.careplan.description} (${goalScore.entityVersion})`;
                }
              });
            }
            return goalScores;
          }),
      ),
      columns: [
        {
          id: 'score',
          name: 'carefile.labels.Score',
          type: 'string',
          format: '',
        },
        {
          id: 'date',
          name: 'document.labels.Date',
          type: 'date',
          format: 'date',
        },
        {
          id: 'displayCareplan',
          name: 'carefile.labels.CareplanVersion',
          type: 'string',
        },
        {
          id: 'createdBy',
          name: 'general.labels.CreatedBy',
          type: 'object',
          objectProperty: 'fullName',
          format: '',
        },
      ],
      inlineActions: [],
      menuActions: [],
      showAdd: false,
      showHeader: true,
      showSearch: false,
      externPagination: false,
      defaultPageSize: 100,
      defaultSortColumn: 'createdAt',
      defaultSortDirection: 'desc',
    };
  }
}
