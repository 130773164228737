export class QuestionSetAnswerModel {
  qsaId!: number;
  questionSetId!: number;
  entityId!: string | number;
  entityType: string; // Application, Intake Careplan
  entityVersion: number;
  tenantId!: number | string;
  name: string;
  totalQuestions: number;
  mandatoryQuestions: number;
  answeredQuestions: number;
  createdAt!: Date;
  createdBy!: number | string; // user id
  updatedAt: Date | null | undefined;
  updatedBy: number | string | null | undefined;
}
