import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss'],
})
/**
 * @deprecated
 */
export class InputCheckboxComponent implements OnInit {
  // Checkbox value, by default it is false
  @Input() value: boolean = false;

  // Label for checkbox
  @Input() label: string = '';

  // Readonly indicator, by default it is false
  @Input() readonly: boolean = false;

  // This event emits new value, true or false
  @Output() valueChange = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public onChange(value: boolean): void {
    this.valueChange.emit(value);
  }
}
