<app-panel [formGroup]="careprogramForm">
  <div class="c4p-font-normal warning" *ngIf="errorMessage">
    {{ '*' + errorMessage }}
  </div>
  <app-panel-title
    title="careprogram.labels.MainTherapist"
    titleIcon="new_label"
    [ngClass]="{ 'invalid-title': showWarningMessage }"
  >
    <ng-container panelButtons>
      <button
        *ngIf="!mainTherapistFormArray?.disabled"
        class="c4p-button"
        (click)="createMainTherapist()"
        translate
      >
        general.labels.Add
      </button>
    </ng-container>
  </app-panel-title>

  <div class="c4p-table-view-wrap" formArrayName="therapists">
    <table
      mat-table
      [dataSource]="mainTherapistFormArray.value"
      width="100%"
      *ngIf="mainTherapistFormArray.controls.length > 0"
    >
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef translate>
          careprogram.labels.MainTherapist
        </th>
        <td mat-cell *matCellDef="let therapist">
          {{ therapist['employee']['fullName'] }}
        </td>
      </ng-container>
      <ng-container matColumnDef="employeeNumber">
        <th mat-header-cell *matHeaderCellDef translate>
          employee.labels.EmployeeNumber
        </th>
        <td mat-cell *matCellDef="let therapist">
          {{ therapist['employee']['employeeNumber'] }}
        </td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef translate>
          general.labels.StartDate
        </th>
        <td mat-cell *matCellDef="let therapist">
          {{ therapist.startDate | appDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef translate>
          general.labels.EndDate
        </th>
        <td mat-cell *matCellDef="let therapist">
          {{ therapist.endDate | appDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="operations">
        <th mat-header-cell *matHeaderCellDef></th>
        <td
          mat-cell
          *matCellDef="let row; let i = index"
          style="text-align: right"
        >
          <mat-icon
            matPrefix
            class="c4p-action-icons"
            (click)="viewMainTherapist(i)"
            >visibility</mat-icon
          >
          <mat-icon
            *ngIf="!mainTherapistFormArray.disabled"
            matPrefix
            class="c4p-action-icons"
            (click)="editMainTherapist(row, i)"
            >create</mat-icon
          >
          <mat-icon
            *ngIf="!mainTherapistFormArray.disabled"
            matPrefix
            class="c4p-action-icons"
            (click)="deleteMainTherapist(i)"
            >delete</mat-icon
          >
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <app-panel-form-row
    *ngIf="mainTherapistFormArray.controls.length === 0"
    rowClass="c4p-empty-table-row"
  >
    <span translate>general.messages.FormEmpty</span>
  </app-panel-form-row>
</app-panel>
