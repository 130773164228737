import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PermissionService } from '../../services';

export interface IMenuItems {
  icon: string;
  tooltip: string;
  method: any;
  additionalMenus: IAdditionalMenu[];
  permission?: string;
  permissionOperator?: 'and' | 'or';
  hasPermission?: boolean;
}

interface IAdditionalMenu {
  name: string;
  menuItems: IMenuItems[];
}

@Component({
  selector: 'app-page-menu-additional',
  templateUrl: './page-menu-additional.component.html',
  styleUrls: ['./page-menu-additional.component.scss'],
})
export class PageMenuAdditionalComponent implements OnInit, OnDestroy {
  @Input() items: IMenuItems[];
  @Input() name: string;

  constructor(private permissionsService: PermissionService) {}

  ngOnInit(): void {
    for (let item of this.items) {
      if (item.permission) {
        item.hasPermission = this.permissionsService.getPermission(
          item.permission,
          item.permissionOperator,
        );
      } else {
        item.hasPermission = true;
      }
    }
  }

  ngOnDestroy(): void {}
}
