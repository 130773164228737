// https://dev.to/zhiyueyi/create-a-simple-breadcrumb-in-angular-ag5
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { PermissionService } from '../permissions/permission.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionRouterGuard implements CanActivate {
  constructor(
    private router: Router,
    public permissionService: PermissionService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Promise<boolean> {
    if (
      route.routeConfig &&
      route.routeConfig.data &&
      route.routeConfig.data.permission
    ) {
      return this.permissionService.getPermission(
        route.routeConfig.data.permission,
        route.routeConfig.data.permissionOperator,
      );
    }
    return true;
  }
}
