import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PaginationDataModel } from '../../models/pagination/pagination-data.model';
import { QuestionModel } from '../../models/question-set/question.model';
import { HttpUtils } from '../../utilities';

@Injectable({
  providedIn: 'root',
})
export class QuestionBaseService {
  public questions$ = new BehaviorSubject<{
    data: QuestionModel[];
    count: number;
  }>(null);

  constructor(
    public http: HttpClient,
    @Inject('environment') public environment: any,
  ) {}

  public getQuestions(paginationData: PaginationDataModel, filterData?: any) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/questions',
    });
    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );
    return this.http.get<any>(
      `${this.environment.questionsetManagementApiUrl}/questions`,
      { params, headers },
    );
  }

  public deleteQuestion(questionId: number | string) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/questions/:question_id',
      entity_type: 'Questions',
      entity_id: questionId.toString(),
    });
    return this.http.delete<any>(
      `${this.environment.questionsetManagementApiUrl}/questions/${questionId}`,
      { headers },
    );
  }

  public createQuestion(question: QuestionModel) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/questions',
      entity_type: 'Questions',
    });
    return this.http.post<any>(
      `${this.environment.questionsetManagementApiUrl}/questions`,
      question,
      { headers },
    );
  }

  public updateQuestion(question: QuestionModel) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/questions/:question_id',
      entity_type: 'Questions',
      entity_id: question.id.toString(),
    });
    return this.http.put<any>(
      `${this.environment.questionsetManagementApiUrl}/questions/${question.id}`,
      question,
      { headers },
    );
  }
}
