import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormInput } from '../../models/dynamic-form-input.model';

@Component({
  selector: 'app-dynamic-form-control',
  templateUrl: './dynamic-form-control.component.html',
  styleUrls: ['./dynamic-form-control.component.scss'],
})
export class DynamicFormControlComponent implements OnInit {
  @Input() dynamicFormInput: DynamicFormInput<any>;
  @Input() formGroup: FormGroup;
  @Input() groupLabelStyle: string = 'normal';
  @Input() showAddRemoveButtons: boolean = true;

  constructor() {}

  ngOnInit(): void {}
}
