import { TemporaryAddressDateModel } from './temporary-address-date.model';

export class AddressModel {
  // Unique ID of the Address
  id!: string;

  identifier!: number;

  // Employee number to which the address belongs to
  // employeeNumber!: number;
  parentId!: number | string;

  // Type of the address
  addressType!: string;

  // Postal code of the address
  postalCode!: string;

  // Street house number
  houseNumber!: number;

  // House letter, if any, that is part of the house number
  houseLetter: string | null | undefined;

  // ???
  addition: string | null | undefined;

  // Street name
  streetName!: string;

  // used for table view
  fullStreetName?: string;

  // The designation is not entered anywhere in V1, and it
  // is used by iCommunication. Possible values are:
  // AB = Aan boord (On board)
  // BY = Bij (At)
  // TO = Tegenover (Opposite)
  // WW = Woonwagen (Caravan)
  //
  // iCommunication sends two letters so it will be necessary
  // to convert those letters into a full string in Dutch.
  designation!: string; // (see with Maurits)

  // City where the address is located
  city!: string;

  // Name of the municipality to whose jurisdiction the city
  // belongs to.
  municipality!: string;

  // Country name (The Netherlands should be default)
  country!: string;

  temporaryAddressDates: TemporaryAddressDateModel | null | undefined;

  startDate: Date | null | undefined;
  endDate: Date | null | undefined;

  // The following data is only for the backend and *MUST NOT BET* exposed
  // on the client side (check if it would make sense to make it available
  // to HR)
  isDeleted!: boolean;

  // Status of address, can be active or deleted
  status!: string;

  createdAt!: Date;
  createdBy!: number | string;
  updateAt: Date | null | undefined;
  updatedBy: number | string | null | undefined;
}
