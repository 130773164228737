<app-panel type="popup-large" style="overflow: hidden">
  <app-panel-title title="document.labels.ViewDocument"></app-panel-title>
  <div class='document-iframe'>
    <iframe *ngIf='documentUrl' [src]="documentUrl"> </iframe>
  </div>
</app-panel>

<div style="display: flex">
  <span class="c4p-button-cancel" (click)="dialogRef.close()" translate
    >general.actions.Close</span
  >
</div>
