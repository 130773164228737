import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { IFormDropdownOption } from '../../../form-controls/form-controls.const';
import { MasterDataService } from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class VatService {
  public lookupValues: string[] = [];

  public get values() {
    return this.values$.value;
  }

  public values$ = new BehaviorSubject<IFormDropdownOption[]>(null);

  constructor(
    private masterDataService: MasterDataService,
    private translate: TranslateService,
  ) {
    this.getFinancingModalities();
  }

  public getFinancingModalities() {
    this.masterDataService
      .getMasterData('financing-modality')
      .subscribe((data) => {
        data.sort((a, b) => {
          return a.value - b.value;
        });

        for (let item of data) {
          this.lookupValues[item.value] = item.label;
          const name = this.translate.instant(item.label);
          item.label = `${name} - ${item.percentage}`;
        }
        this.values$.next(data);
      });
  }
}
