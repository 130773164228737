import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMainMenuItem, IMainSubMenuItem } from '../../models';

@Component({
  selector: 'app-navbar-menu-item',
  templateUrl: './navbar-menu-item.component.html',
  styleUrl: './navbar-menu-item.component.scss'
})
export class NavbarMenuItemComponent {
  @Input() menuItem!: IMainMenuItem;
  @Input() selectedMenuItemName: string | undefined;

  @Output() menuItemClicked = new EventEmitter<IMainMenuItem>();

  onMenuItemClicked(event: any) {
    this.menuItemClicked.emit(this.menuItem);
  }

  onExternalLink(item: IMainSubMenuItem) {
    if (item?.route?.startsWith('http')) {
      window.open(item.route, '_blank');
    }
  }
}
