import { Injectable } from '@angular/core';
import { IFormDropdownOption } from '../../../form-controls';

export enum InvoiceTo {
  COMMISSIONING_COMPANY = 'commissioningCompany',
  CLIENT = 'client',
}

@Injectable({
  providedIn: 'root',
})
export class InvoiceToService {
  public values: IFormDropdownOption[] = [
    {
      label: 'productCatalog.titles.CommissioningCompany',
      value: InvoiceTo.COMMISSIONING_COMPANY,
    },
    { label: 'general.menu.Client', value: InvoiceTo.CLIENT },
  ];

  public lookupValues: string[] = [];

  constructor() {
    for (let i = 0; i < this.values.length; i++) {
      this.lookupValues[i] = this.values[i].label;
    }
    for (const item of this.values) {
      this.lookupValues[item.value] = item.label;
    }
  }
}
